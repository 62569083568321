import React from 'react';
import { useParams } from 'react-router-dom';
import { CopyInitialContentJob } from '../../../../type/job';
import { MakeoverCustomProcess } from '../../../../type/MakeoverCustomProcess';
import { Button } from '../../../component/Button';
import { DataList } from '../../../component/DataList';
import { Layout } from '../../../component/Layout';
import { LoadingSpinnerBlock } from '../../../component/LoadingSpinner';
import { useChurch } from '../../../hooks/useChurch';
import { useFeatureFlagMutation } from '../../../hooks/useChurch';
import { useJobStart, useJobStatus } from '../../../hooks/useJob';
import { useMakeoverCustomProcess, useMakeoverCustomProcessCreate } from '../../../hooks/useMakeoverCustomProcess';
import { DATE_FULL_FORMATTER } from '../../../lib/formatting';
import { MakeoverActions } from '../../Churches/route/Makeovers';

export const Makeover = () => {
  const params = useParams<{ churchId: string }>();
  const churchId = params.churchId ?? '';
  const { data: church } = useChurch(churchId);

  const { data, isLoading, isError } = useMakeoverCustomProcess({ id: churchId });

  if (isLoading) {
    return (
      <Layout.Panel>
        <LoadingSpinnerBlock />
      </Layout.Panel>
    );
  }

  if (isError || data === undefined) {
    return <MakeoverNotFound church={church} />;
  }

  return <MakeoverData church={church} data={data} />;
};

const MakeoverNotFound = (props: { church: { id: string } }) => {
  const { mutate, isPending } = useMakeoverCustomProcessCreate(props.church);

  return (
    <Layout.Panel>
      <Layout.Card.Message
        headline="Makeover not found!"
        buttons={
          <Button size="small" shape="pill" onClick={() => mutate()} loading={isPending}>
            Start a Makeover
          </Button>
        }
      ></Layout.Card.Message>
    </Layout.Panel>
  );
};

const MakeoverData = (props: { church: { id: string }; data: MakeoverCustomProcess }) => {
  return (
    <Layout.Panel>
      <Layout.Header
        headline="Makeover Data"
        buttons={
          <>
            <MakeoverContent churchId={props.church.id} />
            <MakeoverBlocker churchId={props.church.id} />
            <MakeoverActions churchId={props.church.id} />
          </>
        }
      />
      <Layout.Card>
        <Layout.Header headline="Status" />
        <Layout.Group>
          <DataList inline>
            <DataList.Item>
              <DataList.Label>Created:</DataList.Label>
              <DataList.Value>{DATE_FULL_FORMATTER(props.data.applicationCreatedAt)}</DataList.Value>
            </DataList.Item>
            <DataList.Item>
              <DataList.Label>Submitted:</DataList.Label>
              <DataList.Value>{DATE_FULL_FORMATTER(props.data.applicationSubmittedAt)}</DataList.Value>
            </DataList.Item>
            <DataList.Item>
              <DataList.Label>Approved:</DataList.Label>
              <DataList.Value>{DATE_FULL_FORMATTER(props.data.applicationApprovedAt)}</DataList.Value>
            </DataList.Item>
            <DataList.Item>
              <DataList.Label>Rejected:</DataList.Label>
              <DataList.Value>{DATE_FULL_FORMATTER(props.data.applicationRejectedAt)}</DataList.Value>
            </DataList.Item>
            <DataList.Item>
              <DataList.Label>Completed:</DataList.Label>
              <DataList.Value>{DATE_FULL_FORMATTER(props.data.processCompletedAt)}</DataList.Value>
            </DataList.Item>
            <DataList.Item>
              <DataList.Label>Delivered:</DataList.Label>
              <DataList.Value>{DATE_FULL_FORMATTER(props.data.processDeliveredAt)}</DataList.Value>
            </DataList.Item>
          </DataList>
        </Layout.Group>
      </Layout.Card>
    </Layout.Panel>
  );
};

const UnblockMakeoverProcessFeatureFlag = 'unblock-makeover-process';

const MakeoverBlocker = (props: { churchId: string }) => {
  const { data, isPending, mutate } = useFeatureFlagMutation(props.churchId, UnblockMakeoverProcessFeatureFlag);

  if (data) {
    return (
      <Button
        size="small"
        shape="pill"
        variant="primary"
        onClick={() => mutate(false)}
        loading={isPending}
        title="Removes a Feature Flag from the church, restoring the default behavior of blocking all Apps"
      >
        Block apps
      </Button>
    );
  }

  return (
    <Button
      size="small"
      shape="pill"
      variant="secondary"
      onClick={() => mutate(true)}
      loading={isPending}
      title="Sets a Feature Flag on the church, unblocking all Apps"
    >
      Unblock apps
    </Button>
  );
};

const MakeoverContent = (props: { churchId: string }) => {
  const { job, isPending, handleClick } = useCopyInitialContentJob(props.churchId);

  if (job) {
    return <MakeoverContentStatus job={job} />;
  }

  return (
    <Button size="small" shape="pill" variant="secondary" onClick={handleClick} loading={isPending}>
      Initialize Makeover Content
    </Button>
  );
};

const MakeoverContentStatus = (props: { job: any }) => {
  const { data } = useJobStatus<CopyInitialContentJob>(props.job);

  if (data?.status === 'failed') {
    return (
      <Button size="small" shape="pill" error variant="primary">
        Failed
      </Button>
    );
  }

  if (data?.status === 'completed') {
    return (
      <Button size="small" shape="pill" variant="primary">
        Completed
      </Button>
    );
  }
  return (
    <Button size="small" shape="pill" variant="secondary" loading>
      Initialize Makeover Content
    </Button>
  );
};

const useCopyInitialContentJob = (churchId: string) => {
  const [job, setJob] = React.useState<CopyInitialContentJob>();

  const { mutateAsync, isPending } = useJobStart<CopyInitialContentJob>();

  const startJob = async () => {
    const result = await mutateAsync({
      type: 'CopyInitialContent',
      ownerId: churchId,
      payload: {
        toChurchId: churchId,
      },
    });

    setJob(result);
  };

  const handleClick = () => {
    const confirmed = window.confirm(
      'Are you sure you want to copy the makeover content onto this church? This cannot be undone!'
    );

    if (confirmed !== true) {
      return;
    }

    startJob();
  };

  return {
    job,
    isPending,
    handleClick,
  };
};

import { Headline } from '@foyyay/control-elements';
import React, { useContext, useState } from 'react';
import { Button } from '../../../../../../../component/Button';
import { RelativeDate } from '../../../../../../../component/Date';
import { Layout } from '../../../../../../../component/Layout';
import { Table } from '../../../../../../../component/Table';
import {
  BILLING_INTERVAL_TO_MONTH_COUNT,
  BILLING_STATUS_TO_NAME,
  BillingIntervalLabelMap,
  BillingItem,
  BillingItemProduct,
  BillingStatus,
} from '../../../../../../../constant';
import { AMOUNT_FORMATTER, relativeDate } from '../../../../../../../lib/formatting';
import { Platform, entityPlatform } from '../../../../../../../lib/platform';
import { SubscriptionControllerContext } from '../../state/context';
import {
  createManualInvoice,
  itemIsHighlightedSelector,
  selectActiveProductItems,
  selectDiscountItems,
  selectLastInvoice,
  selectNextInvoice,
  selectProductItems,
  selectSubscription,
  selectSubscriptionHasEnded,
  updateItem,
} from '../../state/reducer';
import { ProductAdd } from './ProductAdd';
import { getNewDate } from './input';

export const SubscriptionProducts = () => {
  const { useSelector } = useContext(SubscriptionControllerContext);

  const subscription = useSelector(selectSubscription);
  const lastInvoice = useSelector(selectLastInvoice);
  const productItems = useSelector(selectProductItems);
  const activeProductCount = useSelector(selectActiveProductItems).length;
  const [productAddOpen, setProductAddOpen] = useState(false);
  const canAddProducts = entityPlatform(subscription) !== Platform.RebelGive;

  const zeroDate = new Date(0);
  const earliestEditableDate = new Date(lastInvoice?.period_started_at ?? zeroDate);
  const [endingCutoffDate, setEndingCutoffDate] = useState(earliestEditableDate);
  const hasOlderProducts =
    productItems.filter((item) => item.ended_at !== undefined && new Date(item.ended_at) <= earliestEditableDate)
      .length > 0;

  const doShowAddPanel = () => setProductAddOpen(true);
  const doHideAddPanel = () => setProductAddOpen(false);

  const handleAddNewClicked = () => doShowAddPanel();

  let handleEndingCutoffDateClicked = () => setEndingCutoffDate(zeroDate);

  let endingCutoffDateLabel = `Show older items`;
  if (endingCutoffDate.getTime() === zeroDate.getTime()) {
    endingCutoffDateLabel = 'Hide older items';
    handleEndingCutoffDateClicked = () => setEndingCutoffDate(earliestEditableDate);
  }

  const renderOlderItemsToggle = hasOlderProducts === true;
  const renderAddButton = canAddProducts === true;
  const renderAdd = canAddProducts === true && productAddOpen === true;

  return (
    <Layout.Panel>
      {renderAddButton && (
        <Layout.Header
          buttons={
            <>
              {renderOlderItemsToggle && (
                <Button shape="pill" size="small" variant="primary" onClick={handleEndingCutoffDateClicked}>
                  {endingCutoffDateLabel}
                </Button>
              )}
              <Button shape="pill" size="small" variant="primary" onClick={handleAddNewClicked}>
                + Add Product
              </Button>
            </>
          }
        />
      )}
      <Headline>Products: {activeProductCount}</Headline>
      {renderAdd && <ProductAdd onClose={doHideAddPanel} />}
      <Table.OverflowAuto>
        <Table>
          <Table.Thead>
            <Table.Row>
              <Table.Header>Name</Table.Header>
              <Table.Header>$/{BillingIntervalLabelMap[subscription.interval]}</Table.Header>
              <Table.Header>Description</Table.Header>
              <Table.Header></Table.Header>
              <Table.Header></Table.Header>
            </Table.Row>
          </Table.Thead>
          <Table.Tbody>
            {productItems.map((productItem) => (
              <ProductRow key={productItem.id} productItem={productItem} endingCutoffDate={endingCutoffDate} />
            ))}
          </Table.Tbody>
        </Table>
      </Table.OverflowAuto>
    </Layout.Panel>
  );
};

const ProductRow = (props) => {
  const { useSelector, dispatch } = useContext(SubscriptionControllerContext);
  const subscription = useSelector(selectSubscription);
  const subscriptionHasEnded = useSelector(selectSubscriptionHasEnded);
  const lastInvoice = useSelector(selectLastInvoice);
  const nextInvoice = useSelector(selectNextInvoice);
  const discountItems = useSelector(selectDiscountItems);
  const isHighlighted = useSelector(itemIsHighlightedSelector(props.productItem));

  const lastInvoiceDate = lastInvoice?.period_started_at ?? subscription.started_at ?? subscription.created_at;
  const nextInvoiceDate = nextInvoice?.period_started_at;

  const doUpdateProduct = (productItem, updateValues) => {
    dispatch(updateItem({ item: productItem, updates: updateValues }));
  };

  const doCreateManualInvoice = (productItem: BillingItemProduct, cost: number) => {
    dispatch(
      createManualInvoice({
        invoice: {
          items: [
            {
              cost: cost,
              description: productItem.data.description,
              item: productItem,
            },
          ],
          period_started_at: new Date(),
          period_ended_at: nextInvoiceDate,
        },
      })
    );
  };

  const handleEndClicked = (event, productItem: BillingItemProduct) => {
    const message = [`When should [${productItem.data.product_name}] end?`];

    const discountDescriptions = discountItems
      .filter((discountItem) => (discountItem.data.applies_to_items ?? []).includes(productItem.id))
      .map((discountItem) => ` - ${discountItem.data.description}`);

    if (discountDescriptions.length > 0) {
      message.push(
        '',
        `The following ${discountDescriptions.length > 1 ? 'discounts' : 'discount'} will end at the same time`,
        ...discountDescriptions
      );
    }

    const endAt = getNewDate(
      message.join('\n'),
      new Date(nextInvoiceDate ?? new Date()),
      new Date(productItem.started_at ?? productItem.created_at)
    );

    if (endAt === undefined) {
      return;
    }

    doUpdateProduct(productItem, { ended_at: endAt });
  };

  const handleStartClicked = (event, productItem: BillingItemProduct) => {
    const restartProduct = window.confirm(`Re-add this product to the subscription?`);
    if (restartProduct !== true) {
      return;
    }
    doUpdateProduct(productItem, { ended_at: null });
  };

  const handleCreateManualClicked = (event, productItem: BillingItemProduct) => {
    const message = [
      `How much do you want to`,
      `charge for ${productItem.data.product_name}?`,
      `for use until ${relativeDate(nextInvoiceDate)}`,
      '',
      'Note: Must be at least $1.00',
    ];

    const invoiceAmount = getAmount(message.join('\n'));

    if (invoiceAmount === undefined) {
      return;
    }

    doCreateManualInvoice(productItem, invoiceAmount);
  };

  let onStatusClicked;
  if (props.productItem.ended_at === undefined) {
    onStatusClicked = (event) => handleEndClicked(event, props.productItem);
  } else if (new Date(props.productItem.ended_at) > new Date(lastInvoiceDate)) {
    onStatusClicked = (event) => handleStartClicked(event, props.productItem);
  }

  let onCreateManualClicked;
  if (props.productItem.ended_at === undefined) {
    onCreateManualClicked = (event) => handleCreateManualClicked(event, props.productItem);
  }

  if (subscriptionHasEnded === true) {
    onStatusClicked = undefined;
    onCreateManualClicked = undefined;
  }
  if (entityPlatform(subscription) === Platform.RebelGive) {
    onStatusClicked = undefined;
    onCreateManualClicked = undefined;
  }

  let creatManualWording = '';
  if (onCreateManualClicked !== undefined) {
    creatManualWording = createWording(props.productItem, subscriptionHasEnded);
  }

  let visibility = 'inherit';
  if (props.productItem.ended_at !== undefined && new Date(props.productItem.ended_at) <= props.endingCutoffDate) {
    visibility = 'collapse';
  }

  return (
    <Table.Row
      style={{ visibility: visibility }}
      key={props.productItem.id}
      strong={isHighlighted}
      highlight={isHighlighted}
    >
      <Table.Cell>{props.productItem.data.product_name}</Table.Cell>
      <Table.Cell>
        {AMOUNT_FORMATTER(props.productItem.data.monthly_cost * BILLING_INTERVAL_TO_MONTH_COUNT[subscription.interval])}
      </Table.Cell>
      <Table.Cell>{props.productItem.data.description}</Table.Cell>
      <Table.Cell onClick={onStatusClicked}>{status(props.productItem, subscriptionHasEnded)}</Table.Cell>
      <Table.Cell onClick={onCreateManualClicked}>{creatManualWording}</Table.Cell>
    </Table.Row>
  );
};

const status = (item, subscriptionHasEnded) => {
  if (subscriptionHasEnded === true) {
    return '';
  }

  if (new Date(item.ended_at) <= new Date()) {
    return <RelativeDate date={item.ended_at}>Ended</RelativeDate>;
  }

  const statusParts = [BILLING_STATUS_TO_NAME[item.status ?? BillingStatus.Active]];
  if (item.ended_at !== undefined) {
    statusParts.push('Ending');
  }

  return <RelativeDate date={item.ended_at}>{statusParts.join(' - ')}</RelativeDate>;
};

const createWording = (item: BillingItem, subscriptionHasEnded: boolean): string => {
  if (subscriptionHasEnded === true) {
    return '';
  }

  if (item.ended_at === undefined) {
    return 'Create Invoice';
  }

  return '';
};

const getAmount = (message: string, defaultAnswer?: string): number | undefined => {
  const answer = window.prompt(message, defaultAnswer);

  if (answer === null) {
    return;
  }

  if (answer.trim().match(/^\d+(\.\d\d)?$/) === null) {
    window.alert('Please enter an amount as either 12 or 2.50');
    return getAmount(message, answer);
  }

  return Math.round(parseFloat(answer.trim()) * 100);
};

import { useQuery } from '@tanstack/react-query';
import { EmailHttpApi } from '../api/EmailHttpApi';

export const useEmails = (query: string) => {
  return useQuery({
    queryKey: ['emails', query],
    queryFn: async () => await EmailHttpApi.search(query),
    enabled: query.length > 0,
  });
};

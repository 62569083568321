export const ACTION_LOAD_SESSION = 'ACTION_LOAD_SESSION';
export const ACTION_UNLOAD_SESSION = 'ACTION_UNLOAD_SESSION';
export const ACTION_LOAD_MANAGED_POLICIES = 'ACTION_LOAD_MANAGED_POLICIES';
export const ACTION_LOAD_MANAGED_POLICY = 'ACTION_LOAD_MANAGED_POLICY';
export const ACTION_CREATE_MANAGED_POLICY = 'ACTION_CREATE_MANAGED_POLICY';
export const ACTION_UPDATE_MANAGED_POLICY = 'ACTION_UPDATE_MANAGED_POLICY';
export const ACTION_DESTROY_MANAGED_POLICY = 'ACTION_DESTROY_MANAGED_POLICY';
export const ACTION_LOAD_RESOURCE_POLICIES = 'ACTION_LOAD_RESOURCE_POLICIES';
export const ACTION_LOAD_RESOURCE_POLICY = 'ACTION_LOAD_RESOURCE_POLICY';
export const ACTION_CREATE_RESOURCE_POLICY = 'ACTION_CREATE_RESOURCE_POLICY';
export const ACTION_UPDATE_RESOURCE_POLICY = 'ACTION_UPDATE_RESOURCE_POLICY';
export const ACTION_DESTROY_RESOURCE_POLICY = 'ACTION_DESTROY_RESOURCE_POLICY';
export const ACTION_LOAD_IDENTITY_POLICIES = 'ACTION_LOAD_IDENTITY_POLICIES';
export const ACTION_LOAD_IDENTITY_POLICY = 'ACTION_LOAD_IDENTITY_POLICY';
export const ACTION_CREATE_IDENTITY_POLICY = 'ACTION_CREATE_IDENTITY_POLICY';
export const ACTION_UPDATE_IDENTITY_POLICY = 'ACTION_UPDATE_IDENTITY_POLICY';
export const ACTION_DESTROY_IDENTITY_POLICY = 'ACTION_DESTROY_IDENTITY_POLICY';

export const frozenObject = Object.freeze({});
export const frozenArray = Object.freeze([]);

export interface Action {
  type: string;
  payload?: any;
}

export function buildAction(action: string, payload?: any): Action {
  return {
    type: action,
    payload: payload,
  };
}

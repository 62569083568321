import React from 'react';
import { useParams } from 'react-router-dom';
import { ButtonConsoleLog } from '../../../component/ButtonConsoleLog';
import { Layout } from '../../../component/Layout';
import { LoadingSpinnerBlock } from '../../../component/LoadingSpinner';
import { Table } from '../../../component/Table';
import { useChurch } from '../../../hooks/useChurch';
import { Platform, entityPlatform } from '../../../lib/platform';
import { useSiteEngines } from '../../../hooks/useSiteEngines';

export const Domains = () => {
  const params = useParams<{ churchId: string }>();
  const { data: church } = useChurch(params.churchId);
  const { data, isLoading } = useSiteEngines(params.churchId);
  const { siteEngines = [], domains: domainsBySiteId = {} } = data ?? {};

  const webSite = siteEngines.find((site) => site.category === 'Web') ?? {};
  const domains = domainsBySiteId[webSite.id];

  let platform: Platform | undefined;
  if (church !== undefined) {
    platform = entityPlatform(church);
  }

  if (church !== undefined && isLoading) {
    return (
      <Layout.Panel>
        <LoadingSpinnerBlock />
      </Layout.Panel>
    );
  }

  if (domains === undefined) {
    return (
      <Layout.Card.Message>
        <Layout.Header headline={'No Web Domains Found'} />
      </Layout.Card.Message>
    );
  }
  return (
    <Layout.Panel platform={platform}>
      <Layout.Header headline="Web Domains" />
      <Layout.Group>
        <Table.OverflowAuto>
          <Table>
            <Table.Thead></Table.Thead>
            <Table.Tbody>
              {(domains?.production ?? []).map((domain) => {
                return (
                  <Table.Row key={domain}>
                    <Table.Cell>Production</Table.Cell>
                    <Table.Cell>
                      <a href={`https://${domain}`} target="_blank" rel="noopener noreferrer">
                        {domain}
                      </a>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
              {(domains?.preview ?? []).map((domain) => {
                return (
                  <Table.Row key={domain}>
                    <Table.Cell>Preview</Table.Cell>
                    <Table.Cell>
                      <a href={`https://${domain}`} target="_blank" rel="noopener noreferrer">
                        {domain}
                      </a>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Tbody>
          </Table>
        </Table.OverflowAuto>
      </Layout.Group>
      <Layout.Group>
        <Layout.Flex justifyContent="center">
          <ButtonConsoleLog data={data} />
        </Layout.Flex>
      </Layout.Group>
    </Layout.Panel>
  );
};

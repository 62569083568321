import React, { useContext } from 'react';
import { Link, useParams, useResolvedPath } from 'react-router-dom';
import { Button } from '../../../../../component/Button';
import { Layout } from '../../../../../component/Layout';
import { LoadingSpinnerBlock } from '../../../../../component/LoadingSpinner';
import { Table } from '../../../../../component/Table';
import { useGiftSchedules } from '../../../../../hooks/useGiftSchedules';
import { GiftscheduleColumns } from '../constants/constants';
import { useFormatGiftschedules } from '../hooks/useFormatGiftschedules';

export const GiftschedulesTable = () => {
  const params = useParams<{ personId: string }>();
  const { data = [], isLoading } = useGiftSchedules(params.personId);
  const giftschedules = useFormatGiftschedules(data);

  if (isLoading) {
    return (
      <Layout.Card.Message>
        <LoadingSpinnerBlock />
      </Layout.Card.Message>
    );
  }

  if (giftschedules?.length < 1) {
    return (
      <Layout.Card.Message>
        <Layout.Header headline="No Giftschedules to display" />
      </Layout.Card.Message>
    );
  }

  return (
    <Table.OverflowAuto>
      <Table>
        <TableHead columns={GiftscheduleColumns} />
        {giftschedules ? (
          <TableBody rows={giftschedules} columns={GiftscheduleColumns} />
        ) : (
          <Table.LoadingTbody rowSpan={5} />
        )}
      </Table>
    </Table.OverflowAuto>
  );
};

const TableHead = (props) => {
  return (
    <Table.Thead>
      <Table.Row>
        {props.columns.map((column) => {
          return <Table.Header key={column.key}>{column.label}</Table.Header>;
        })}
        <Table.Header align="right" />
      </Table.Row>
    </Table.Thead>
  );
};

const TableBody = (props: { rows: Array<any>; columns: Array<any> }) => {
  return (
    <Table.Tbody>
      {props.rows.map((giftschedule) => (
        <GiftscheduleRow key={giftschedule.id} giftschedule={giftschedule} columns={props.columns} />
      ))}
    </Table.Tbody>
  );
};

const RowContext = React.createContext({} as { viewUrl: string; giftschedule: any });

const GiftscheduleRow = (props: any) => {
  const viewUrl = `${useResolvedPath('').pathname}/${props.giftschedule.id}`;

  const context = {
    giftschedule: props.giftschedule,
    viewUrl: viewUrl,
  };

  return (
    <RowContext.Provider value={context}>
      <Table.Row to={viewUrl} muted={props.giftschedule?.active === false}>
        {props.columns.map((column) => {
          return (
            <Table.Cell key={column.key} align="left">
              {props.giftschedule?.[column.key]}
            </Table.Cell>
          );
        })}
        <Table.Cell maxWidth="100%" align="right" type="buttons">
          <ViewButton />
        </Table.Cell>
      </Table.Row>
    </RowContext.Provider>
  );
};

const ViewButton = () => {
  const { viewUrl } = useContext(RowContext);
  return (
    <Button shape="pill" size="small" variant="secondary" to={viewUrl} forwardedAs={Link}>
      View
    </Button>
  );
};

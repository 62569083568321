import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useLocationEffect = (callback: (location?: ReturnType<typeof useLocation>) => void) => {
  const location = useLocation();

  useEffect(() => {
    callback(location);
  }, [location, callback]);
};

import React from 'react';
import { Button } from '../../../../../../../component/Button';
import { useMerchantActivation } from '../../../../../../../hooks/useMerchant';

export const MerchantDeactivate = (props: any) => {
  const church = props.church;
  const merchant = props.merchant;
  const { mutateAsync: deactivate, isPending } = useMerchantActivation(church, merchant);

  const onDeactivateClick = async () => {
    const confirmation = window.confirm('Are you sure you want to deactivate this merchant?');
    if (confirmation !== true) {
      return;
    }
    await deactivate(false);
  };

  return (
    <Button shape="pill" size="small" loading={isPending} onClick={onDeactivateClick} style={{ background: 'red' }}>
      Deactivate Merchant
    </Button>
  );
};

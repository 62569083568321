import { Buffer } from 'buffer';

const DOUBLE_ENCODING = ['base64', 'base64', 'hex'];

// NOTE: This will cause the value string to grow to 4-5 times the length
//       due to each encoding addit it's own reduction in fidelity.

export function doubleEncode(value) {
  return DOUBLE_ENCODING.reduce((acc, next) => Buffer.from(acc, 'utf8').toString(next), value);
}

export function doubleDecode(value) {
  return DOUBLE_ENCODING.reduceRight((acc, next) => Buffer.from(acc, next).toString('utf8'), value);
}

export function toBase64(value) {
  value = JSON.stringify(value);
  return Buffer.from(value, 'utf8').toString('base64');
}

export function fromBase64(value) {
  return JSON.parse(Buffer.from(value, 'base64').toString('utf8'));
}

import * as dateFns from 'date-fns';

const InThePastThresholdSeconds = 10 * 1000;

export const getNewDate = (
  message: string,
  defaultAnswer: Date | string = '',
  minimumDate: Date = new Date(0)
): Date | undefined => {
  const asOf = new Date();

  let fullMessage = message;
  if (asOf >= minimumDate && Math.abs(dateFns.differenceInMinutes(new Date(defaultAnswer), asOf)) > 1) {
    fullMessage = [message, '', `Enter 'now' to use ${dateFns.format(asOf, 'P p')}.`].join('\n');
  }

  return getNewDateInternal(fullMessage, defaultAnswer, minimumDate, asOf);
};

const getNewDateInternal = (
  message: string,
  defaultAnswer: Date | string,
  minimumDate: Date,
  asOf: Date
): Date | undefined => {
  if (defaultAnswer instanceof Date) {
    defaultAnswer = dateFns.format(defaultAnswer, 'P p');
  }

  const answer = window.prompt(message, defaultAnswer);
  if (answer === null) {
    return;
  }

  let newDate = new Date(answer);
  if (answer.toLowerCase() === 'now') {
    newDate = asOf;
  }

  if (dateFns.isValid(newDate) !== true) {
    if (answer !== '') {
      window.alert(['The date you entered', answer, 'is not a valid date'].join('\n'));
    }
    return getNewDateInternal(message, answer, minimumDate, asOf);
  }

  if (/\d:\d\d/.test(answer) !== true) {
    newDate.setHours(asOf.getHours());
    newDate.setMinutes(asOf.getMinutes());
    newDate.setSeconds(asOf.getSeconds());
    newDate.setMilliseconds(asOf.getMilliseconds());
  }

  // Either we're off by seconds in which case this syncs us up with whatever was being used as the minimum, or...
  // We're off my minutes or more, in which case this change will have no effect.
  if (newDate < minimumDate) {
    newDate.setSeconds(minimumDate.getSeconds());
    newDate.setMilliseconds(minimumDate.getMilliseconds());
  }

  if (newDate < minimumDate) {
    window.alert(['The new date must be after', dateFns.format(minimumDate, 'P p')].join('\n'));
    return getNewDateInternal(message, defaultAnswer, minimumDate, asOf);
  }

  if (Date.now() - newDate.getTime() > InThePastThresholdSeconds) {
    const areYouSure = window.confirm(
      [dateFns.format(newDate, 'P p'), 'is in the past', '', 'Are you sure?'].join('\n')
    );
    if (areYouSure !== true) {
      return getNewDateInternal(message, defaultAnswer, minimumDate, asOf);
    }
  }

  return newDate;
};

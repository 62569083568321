import _isEmpty from 'lodash/isEmpty';
import React, { useContext } from 'react';
import { Layout } from '../../../../../../../component/Layout';
import { Attribute, GridColumns } from '../../../../../../../component/LayoutParts';
import { ObjectsTable } from '../../../../../../../component/ObjectsTable';
import { useRpMerchant } from '../../../../../../../hooks/useRpMerchant';
import { FORMAT_HINTS, objectGridAttributeLibrary } from '../../../../../../../lib/objectGridAttributeLibrary';
import { MerchantContext } from '../../context/MerchantContext';

// Note: detailed (nested rpmerchant objects) could have display details based on processor type
const PROCESSOR_TYPES = Object.freeze({
  CARDCONNECT: 'processor_cardconnect',
});

// Each merchant will have an RpMerchant.
export const DataCardConnect = (props: any) => {
  const { merchant } = useContext(MerchantContext);

  const { data: rpmerchant, isLoading } = useRpMerchant(merchant.rebelpay_merchant_id);

  if (isLoading) {
    return null;
  }

  return (
    <>
      {rpmerchant.processor_type === PROCESSOR_TYPES.CARDCONNECT && !_isEmpty(rpmerchant.processor_data) && (
        <CardConnectData data={rpmerchant.processor_data} />
      )}
      {rpmerchant.cardconnect_mid && <CardConnectMid data={rpmerchant.cardconnect_mid} />}
    </>
  );
};

// Some RpMerchant processors will be of the CardConnect type.
const CardConnectData = (props: any) => {
  const cardConnect = props.data || {};
  const processorFormatHints = {
    merchantId: FORMAT_HINTS.COPILOT,
  };
  const hideAttributes = [
    'bankDetail',
    'bluechexData',
    'cardConnectMid',
    'demographic',
    'fees',
    'merchantContactInfo',
    'merchantStatus',
    'ownership',
    'pricing',
    'processing',
    'signatureStatus',
  ];

  const cardConnectKeys = getObjectKeys(cardConnect, undefined, undefined, hideAttributes);

  return (
    <>
      <Layout.Panel>
        <Layout.Header headline={'CardConnect Data'} />
        <GridColumns>
          {cardConnectKeys.map((key: string, index: number) => (
            <div key={`card-connect-div-${index}`} style={{ maxWidth: '60rem', overflow: 'hidden' }}>
              <Attribute
                key={`card-connect-attribute-${index}`}
                // add space in camel case key names, and replace underscores with spaces
                name={key.replace(/([a-z])([A-Z])/g, `$1 $2`).replace(/_/g, ' ')}
              >
                {objectGridAttributeLibrary(key, cardConnect[key], processorFormatHints[key])}
              </Attribute>
            </div>
          ))}
        </GridColumns>
      </Layout.Panel>
      {cardConnect.bankDetail && <CardConnectBankDetail data={cardConnect.bankDetail} />}
      {cardConnect.demographic && <CardConnectDemographic data={cardConnect.demographic} />}
      {cardConnect.merchantContactInfo && <CardConnectMerchantContact data={cardConnect.merchantContactInfo} />}
      {cardConnect.merchantStatus && <CardConnectMerchantStatus data={cardConnect.merchantStatus} />}
      {cardConnect.ownership && <CardConnectMerchantOwnership data={cardConnect.ownership} />}
    </>
  );
};
const CardConnectBankDetail = (props: any) => {
  const bankDetail = props.data || { depositBank: {}, withdrawalBank: {} };
  const bankDetailFormatHints = {};
  const statement = '';
  const hideAttributes: Array<string> = [];

  const depositBankKeys = getObjectKeys(bankDetail.depositBank, undefined, undefined, hideAttributes);
  const withdrawlBankKeys = getObjectKeys(bankDetail.withdrawalBank, ['bankAcctTypeCd'], undefined, hideAttributes);

  return (
    <>
      <Layout.Panel>
        <Layout.Header headline={'CardConnect: Bank Detail for Deposit'} statement={statement} />
        <GridColumns>
          {depositBankKeys.map((key: string, index: number) => (
            <div key={`object-grid-div-${index}`} style={{ maxWidth: '60rem', overflow: 'hidden' }}>
              <Attribute
                key={`object-grid-attribute-${index}`}
                // add space in camel case key names, and replace underscores with spaces
                name={key.replace(/([a-z])([A-Z])/g, `$1 $2`).replace(/_/g, ' ')}
              >
                {objectGridAttributeLibrary(key, bankDetail.depositBank[key], bankDetailFormatHints[key])}
              </Attribute>
            </div>
          ))}
        </GridColumns>
        <Layout.Header headline={'CardConnect: Bank Detail for Withdrawal'} statement={statement} />
        <GridColumns>
          {withdrawlBankKeys.map((key: string, index: number) => (
            <div key={`object-grid-div-${index}`} style={{ maxWidth: '60rem', overflow: 'hidden' }}>
              <Attribute
                key={`object-grid-attribute-${index}`}
                // add space in camel case key names, and replace underscores with spaces
                name={key.replace(/([a-z])([A-Z])/g, `$1 $2`).replace(/_/g, ' ')}
              >
                {objectGridAttributeLibrary(key, bankDetail.withdrawalBank[key], bankDetailFormatHints[key])}
              </Attribute>
            </div>
          ))}
        </GridColumns>
      </Layout.Panel>
    </>
  );
};

const CardConnectDemographic = (props: any) => {
  const demographic = props.data || { businessAddress: {}, mailingAddress: {} };
  const demographicFormatHints = {};
  const statement = '';
  const hideAttributes: Array<string> = ['businessAddress', 'mailingAddress'];

  const demographicKeys = getObjectKeys(demographic, ['websiteAddress'], undefined, hideAttributes);

  return (
    <>
      <Layout.Panel>
        <Layout.Header headline={'CardConnect: Demographic'} statement={statement} />
        <GridColumns>
          {demographicKeys.map((key: string, index: number) => (
            <div key={`object-grid-div-${index}`} style={{ maxWidth: '60rem', overflow: 'hidden' }}>
              <Attribute
                key={`object-grid-attribute-${index}`}
                // add space in camel case key names, and replace underscores with spaces
                name={key.replace(/([a-z])([A-Z])/g, `$1 $2`).replace(/_/g, ' ')}
              >
                {objectGridAttributeLibrary(key, demographic[key], demographicFormatHints[key])}
              </Attribute>
            </div>
          ))}
        </GridColumns>

        <ObjectsTable
          data={[demographic.businessAddress, demographic.mailingAddress]}
          keys={['coutryCd', 'zip', 'stateCd', 'address2', 'city', 'address1']}
          firstAttributes={['address1', 'address2', 'city', 'stateCd', 'zip', 'coutryCd']}
        />
      </Layout.Panel>
    </>
  );
};

const CardConnectMerchantContact = (props: any) => {
  const merchantContact = props.data || {};
  const merchantContactFormatHints = {
    contactPhone: FORMAT_HINTS.PHONE,
    contactEmail: FORMAT_HINTS.EMAIL,
  };
  const statement = '';

  const merchantContactKeys = getObjectKeys(merchantContact, ['contactName'], undefined, undefined);

  return (
    <>
      <Layout.Panel>
        <Layout.Header headline={'CardConnect: Merchant Contact'} statement={statement} />
        <GridColumns>
          {merchantContactKeys.map((key: string, index: number) => (
            <div key={`object-grid-div-${index}`} style={{ maxWidth: '60rem', overflow: 'hidden' }}>
              <Attribute
                key={`object-grid-attribute-${index}`}
                // add space in camel case key names, and replace underscores with spaces
                name={key.replace(/([a-z])([A-Z])/g, `$1 $2`).replace(/_/g, ' ')}
              >
                {objectGridAttributeLibrary(key, merchantContact[key], merchantContactFormatHints[key])}
              </Attribute>
            </div>
          ))}
        </GridColumns>
      </Layout.Panel>
    </>
  );
};

const CardConnectMerchantStatus = (props: any) => {
  const merchantStatus = props.data || {};
  const merchantStatusFormatHints = {};
  const statement = '';

  const merchantKeys = getObjectKeys(merchantStatus, undefined, undefined, undefined);

  return (
    <>
      <Layout.Panel>
        <Layout.Header headline={'CardConnect: Merchant Status'} statement={statement} />
        <GridColumns>
          {merchantKeys.map((key: string, index: number) => (
            <div key={`object-grid-div-${index}`} style={{ maxWidth: '60rem', overflow: 'hidden' }}>
              <Attribute
                key={`object-grid-attribute-${index}`}
                // add space in camel case key names, and replace underscores with spaces
                name={key.replace(/([a-z])([A-Z])/g, `$1 $2`).replace(/_/g, ' ')}
              >
                {objectGridAttributeLibrary(key, merchantStatus[key], merchantStatusFormatHints[key])}
              </Attribute>
            </div>
          ))}
        </GridColumns>
      </Layout.Panel>
    </>
  );
};

const CardConnectMerchantOwnership = (props: any) => {
  const ownership = props.data || {};
  const statement = '';

  const ownerAddressData = ownership.owner.ownerAddress;
  const ownerAddressKeys = getObjectKeys(ownerAddressData, ['address1'], undefined, undefined);
  const ownerAddressFormatHints = { zip: FORMAT_HINTS.NONE };

  const merchantOwnerData = ownership.owner;
  const merchantOwnerKeys = getObjectKeys(merchantOwnerData, ['ownerPhone'], undefined, ['ownerAddress', 'ownerSSN']);
  const merchantOwnerFormatHints = {
    ownerEmail: FORMAT_HINTS.EMAIL,
    ownerPhone: FORMAT_HINTS.PHONE,
  };

  const ownershipData = ownership;
  const ownershipKeys = getObjectKeys(ownershipData, undefined, undefined, ['owner']);

  return (
    <>
      <Layout.Panel>
        <Layout.Header headline={'CardConnect: Merchant Ownership'} statement={statement} />
        <GridColumns>
          {ownershipKeys.map((key: string, index: number) => (
            <div key={`object-grid-div-${index}`} style={{ maxWidth: '60rem', overflow: 'hidden' }}>
              <Attribute
                key={`object-grid-attribute-${index}`}
                // add space in camel case key names, and replace underscores with spaces
                name={key.replace(/([a-z])([A-Z])/g, `$1 $2`).replace(/_/g, ' ')}
              >
                {objectGridAttributeLibrary(key, ownershipData[key])}
              </Attribute>
            </div>
          ))}
        </GridColumns>

        {merchantOwnerData && (
          <>
            <Layout.Header headline={'Merchant Owner'} statement={statement} />
            <GridColumns>
              {merchantOwnerKeys.map((key: string, index: number) => (
                <div key={`object-grid-div-${index}`} style={{ maxWidth: '60rem', overflow: 'hidden' }}>
                  <Attribute
                    key={`object-grid-attribute-${index}`}
                    // add space in camel case key names, and replace underscores with spaces
                    name={key.replace(/([a-z])([A-Z])/g, `$1 $2`).replace(/_/g, ' ')}
                  >
                    {objectGridAttributeLibrary(key, merchantOwnerData[key], merchantOwnerFormatHints[key])}
                  </Attribute>
                </div>
              ))}
            </GridColumns>

            {ownerAddressData && (
              <>
                <Layout.Header headline={'Owner Address'} statement={statement} />
                <GridColumns>
                  {ownerAddressKeys.map((key: string, index: number) => (
                    <div key={`object-grid-div-${index}`} style={{ maxWidth: '60rem', overflow: 'hidden' }}>
                      <Attribute
                        key={`object-grid-attribute-${index}`}
                        // add space in camel case key names, and replace underscores with spaces
                        name={key.replace(/([a-z])([A-Z])/g, `$1 $2`).replace(/_/g, ' ')}
                      >
                        {(objectGridAttributeLibrary(key, ownerAddressData[key]), ownerAddressFormatHints[key])}
                      </Attribute>
                    </div>
                  ))}
                </GridColumns>
              </>
            )}
          </>
        )}
      </Layout.Panel>
    </>
  );
};

const CardConnectMid = (props: any) => {
  const mid = props.data || {};
  const midFormatHints = {};
  const statement = '';
  const objectKeys = getObjectKeys(mid, undefined, undefined, undefined);

  return (
    <>
      <Layout.Panel>
        <Layout.Header headline={'CardConnect: MID'} statement={statement} />
        <GridColumns>
          {objectKeys.map((key: string, index: number) => (
            <div key={`object-grid-div-${index}`} style={{ maxWidth: '60rem', overflow: 'hidden' }}>
              <Attribute
                key={`object-grid-attribute-${index}`}
                // add space in camel case key names, and replace underscores with spaces
                name={key.replace(/([a-z])([A-Z])/g, `$1 $2`).replace(/_/g, ' ')}
              >
                {objectGridAttributeLibrary(key, mid[key], midFormatHints[key])}
              </Attribute>
            </div>
          ))}
        </GridColumns>
      </Layout.Panel>
    </>
  );
};

const getObjectKeys = (data, firstKeys, lastKeys, hideKeys) => {
  let objectKeys = [...Object.keys(data)].sort();
  const hasData = objectKeys.length > 0;
  if (firstKeys !== undefined && !_isEmpty(firstKeys) && hasData) {
    // sort the first keys
    firstKeys.forEach((key: string, index: number) => {
      if (objectKeys.includes(key)) {
        objectKeys.splice(index, 0, objectKeys.splice(objectKeys.indexOf(key), 1)[0]);
      }
    });
  }

  if (lastKeys !== undefined && !_isEmpty(lastKeys) && hasData) {
    // sort the last keys
    lastKeys.forEach((key: string) => {
      if (objectKeys.includes(key)) {
        objectKeys.splice(objectKeys.length, 0, objectKeys.splice(objectKeys.indexOf(key), 1)[0]);
      }
    });
  }

  if (hideKeys !== undefined && !_isEmpty(hideKeys) && hasData) {
    // hide these keys
    hideKeys.forEach((key: string) => {
      if (objectKeys.includes(key)) {
        objectKeys.splice(objectKeys.indexOf(key), 1);
      }
    });
  }
  return objectKeys.filter((key) => key !== undefined && data[key] !== undefined);
};

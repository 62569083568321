import { RpMerchant } from '../../type/Merchant';
import { GIVING_API_URL, SUPPORT_API_URL } from '../constant';
import { apiCall } from '../lib/fetch';

const getRpMerchant = async (
  rpMerchant: RpMerchant
): Promise<{ data: { rpmerchant: RpMerchant }; type: 'rpmerchant' }> => {
  return await apiCall('GET', GIVING_API_URL, `/merchants?rebelpay_merchant_id=${rpMerchant.id}`);
};

// Note: that updatingRebelpayMerhant also triggers an update from processors within Rebelpay's system as well.
const updateRebelpayMerchant = async (rpMerchant: RpMerchant, data: any): Promise<any> => {
  return await apiCall('POST', SUPPORT_API_URL, '/merchant/rebelpay/update', {
    ...data,
    rpmerchant_id: rpMerchant.id,
  });
};

// This function does not care about the rpmerchant value in state.
const refreshRebelpayMerchant = async (rpMerchant: RpMerchant): Promise<any> => {
  return await apiCall('POST', SUPPORT_API_URL, '/merchant/rebelpay/refresh', {
    rpmerchant_id: rpMerchant.id,
  });
};

const submitRebelpayMerchant = async (rpMerchant: RpMerchant): Promise<any> => {
  return await apiCall('POST', SUPPORT_API_URL, '/merchant/rebelpay/submit', {
    rpmerchant_id: rpMerchant.id,
  });
};

export const RpMerchantHttpApi = {
  getRpMerchant: getRpMerchant,
  updateRebelpayMerchant: updateRebelpayMerchant,
  refreshRebelpayMerchant: refreshRebelpayMerchant,
  submitRebelpayMerchant: submitRebelpayMerchant,
};

import * as CSS from 'csstype';
import React, { useCallback } from 'react';
import styled, { ThemedStyledProps, css } from 'styled-components';
import { useDeprecatedProp } from '../hooks/useDeprecatedProp';
import { Breadcrumbs } from './Breadcrumbs';
import { Button, Buttons } from './Button';
import { Byline } from './Byline';
import { Headline } from './Headline';
import { Panel as OldPanel } from './Panel';
import { SimpleLoadingSpinner as LoadingSpinner } from './SimpleLoadingSpinner';
import { Statement } from './Statement';
import { FontStyles, L2, P, TypographyStyles } from './Typography';
import { SIZE, media } from './style-utils';

export const SITE_HEADER_HEIGHT = SIZE[12];

export const LayoutStyles = {
  Container: css`
    padding-left: ${SIZE[2]};
    padding-right: ${SIZE[2]};

    ${media.tabletPortraitAndUp`
      padding-left: ${SIZE[4]};
      padding-right: ${SIZE[4]};
    `}
  `,
  Section: css`
    padding: ${SIZE[1]} 0;

    ${media.print`
      padding: 0px;
    `}
  `,
  Row: css`
    max-width: 100%;
    padding: ${SIZE[1]} 0;
  `,
  Header: css<HeaderProps>`
    ${(props) =>
      (props.buttons || props.right) &&
      css`
        min-height: ${SIZE[10]}; /* Fix mismatched height of headline and buttons */
      `}
  `,
  Footer: css`
    padding: ${SIZE[3]} 0 ${SIZE[1]};

    ${media.print`
      padding: 0px;
    `}
  `,
  Flex: css<FlexProps>`
    display: flex;
    justify-content: ${(props) => props.justifyContent ?? 'flex-start'};
    align-items: ${(props) => props.alignItems ?? 'flex-start'};
    flex-wrap: ${(props) => (props.wrap ? 'wrap' : 'no-wrap')};
    gap: ${(props) => props.gap ?? '1.2rem'};

    ${media.print`
      padding: 0px;
      margin:0px;
    `}
  `,
  Column: css<ColumnProps>`
    max-width: 100%;
    min-width: 0;
    flex-grow: ${(props) => (props.grow === true ? 1 : props.grow) ?? 0};
    flex-shrink: ${(props) => (props.shrink === true ? 1 : props.shrink) ?? 0};
    flex-basis: ${(props) => props.basis ?? 'auto'};
  `,
  ButtonGroup: css<ButtonGroupProps>`
    display: inline-flex;
    flex: 0 1 auto;
    gap: ${(props) => props.gap ?? SIZE[1]};
    align-items: ${(props) => props.alignItems ?? 'center'};
    justify-content: ${(props) => props.justifyContent ?? 'center'};

    & > * {
      flex: 0 0 auto;
    }
  `,
  ButtonStack: css<ButtonStackProps>`
    display: inline-flex;
    flex-direction: column;
    flex: 1 0 auto;
    justify-content: ${(props) => props.justifyContent ?? 'flex-start'};
    gap: ${SIZE[2]};

    ${(props) =>
      props.widthMode === 'full' &&
      css`
        width: 100%;
      `}

    & > * {
      flex: 0 0 auto;
    }
  `,
  Panel: css<ThemedStyledProps<object, any> & { platform?: string }>`
    background-color: ${(props) => props.theme.Panel.Color.Default.Background};
    border-radius: ${SIZE[2]};
    border-top-color: transparent;
    border-top-width: ${SIZE[3]};
    box-shadow: 0px 1px 8px 0px ${(props) => props.theme.Panel.Color.Default.BoxShadow};
    outline: none;
    padding: ${SIZE[1]} ${SIZE[2]};
    position: relative;

    ${(props) =>
      props.platform === 'rebelgive' &&
      css`
        border-top-color: rgb(11 186 230 / 24%);
      `}

    ${(props) =>
      props.platform === 'nucleus' &&
      css`
        border-top-color: rgb(82 0 153 / 12%);
      `}
  
    border-top-style: solid;

    & + & {
      margin-top: ${SIZE[4]};
    }

    ${media.tabletPortraitAndUp`
      padding-top: ${SIZE[2]};
      padding-bottom: ${SIZE[2]};
    `}

    ${media.print`
      border: none;
      box-shadow: none;
      padding-left: 0;
      padding-right: 0;
    `}

    ${Headline} {
      max-width: 56rem;
      ${FontStyles.H2};
    }
  `,
  Card: css`
    position: relative;
    padding-top: ${SIZE[2]};
    padding-bottom: ${SIZE[2]};
    border-radius: ${SIZE[4]};
    background-color: ${(props) => props.theme.Card.Color.Default.Background};

    ${Headline} {
      max-width: 56rem;
      ${FontStyles.H3};
    }

    ${OldPanel.Message} ${Headline} {
      max-width: 46rem;
    }
  `,
  Bordered: css`
    background-color: ${(props) => props.theme.Panel.Color.Default.Background};
    border-color: ${(props) => props.theme.Card.Color.Default.Background};
    border-width: ${SIZE[1]};
    border-style: solid;
    padding-left: calc(${SIZE[2]} - ${SIZE[1]});
    padding-right: calc(${SIZE[2]} - ${SIZE[1]});

    ${media.tabletPortraitAndUp`
      padding-left: calc(${SIZE[4]} - ${SIZE[1]});
      padding-right: calc(${SIZE[4]} - ${SIZE[1]});
    `}
  `,
  VSpace: css<{ size?: number }>`
    display: block;
    flex: 0 0 auto;
    padding-top: ${(props) => SIZE[props.size || 1]};
  `,
  HSpace: css<{ size?: number }>`
    display: inline-block;
    flex: 0 0 auto;
    padding-left: ${(props) => SIZE[props.size || 1]};
  `,
  ExtraHeaderSpace: css``,
};

const Container = styled.div`
  ${LayoutStyles.Container};
`;

const Section = styled.div`
  ${LayoutStyles.Section};
`;

const Row = styled.div`
  ${LayoutStyles.Row};
`;

const Group = (props: { children: React.ReactNode } & React.ComponentProps<typeof Section>): JSX.Element => {
  return (
    <Section {...props}>
      {React.Children.map(props.children, (child) => {
        return <>{child && <Row>{child}</Row>}</>;
      })}
    </Section>
  );
};

const Groups = (props: { children?: React.ReactNode }): JSX.Element => {
  return (
    <>
      {React.Children.map(props.children, (child) => {
        return <>{child && <Group>{child}</Group>}</>;
      })}
    </>
  );
};

const Footer = styled((props: React.ComponentProps<typeof Group>) => {
  return (
    <Group forwardedAs="footer" {...props}>
      {props.children}
    </Group>
  );
})`
  ${LayoutStyles.Footer};
`;

const ButtonGroup = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['wrap'].includes(prop) && defaultValidatorFn(prop),
})<ButtonGroupProps>`
  ${LayoutStyles.ButtonGroup};
`;

ButtonGroup.defaultProps = {
  wrap: true,
};

interface ButtonGroupProps {
  justifyContent?: CSS.Property.JustifyContent;
  alignItems?: CSS.Property.AlignItems;
  wrap?: boolean;
  gap?: CSS.Property.Gap;
}

const ButtonStack = styled.div<ButtonStackProps>`
  ${LayoutStyles.ButtonStack};
`;

interface ButtonStackProps {
  justifyContent?: CSS.Property.JustifyContent;
  widthMode?: 'auto' | 'full';
}

const TRANSIENT_PROPS = ['wrap', 'grow', 'shrink', 'basis', 'justifyContent', 'alignItems', 'gap'];

const Flex = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !TRANSIENT_PROPS.includes(prop) && defaultValidatorFn(prop),
})<FlexProps>`
  ${LayoutStyles.Flex};
`;

interface FlexProps {
  alignItems?: CSS.Property.AlignItems;
  justifyContent?: CSS.Property.JustifyContent;
  wrap?: boolean;
  gap?: CSS.Property.Gap;
}

const Column = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !TRANSIENT_PROPS.includes(prop) && defaultValidatorFn(prop),
})<ColumnProps>`
  ${LayoutStyles.Column};
`;

interface ColumnProps {
  basis?: CSS.Property.FlexBasis;
  grow?: boolean | CSS.Property.FlexGrow;
  shrink?: boolean | CSS.Property.FlexShrink;
  justifyContent?: CSS.Property.JustifyContent;
}

const PageHeader = styled((props: PageHeaderProps) => {
  const showLeft = !!(props.left || props.headline || props.statement);
  const showRight = !!(props.right || props.buttons);
  return (
    <Container as="header" className={props.className} style={props.style}>
      <Group>
        <Flex wrap>
          <Column grow shrink basis="24rem" style={{ minWidth: 'min-content' }}>
            {showLeft && (
              <>
                {props.showBreadcrumbs && <Breadcrumbs />}
                {props.byline && <Byline>{props.byline}</Byline>}
                {props.headline && <Headline>{props.headline}</Headline>}
                {props.statement && <Statement>{props.statement}</Statement>}
                {props.left && props.left}
              </>
            )}
          </Column>
          <Column basis="auto">
            {showRight && (
              <>
                {props.byline && <Byline style={{ visibility: 'hidden', userSelect: 'none' }}>.</Byline>}
                {/* hidden byline for spacing - @eric */}
                {props.buttons && <ButtonGroup>{props.buttons}</ButtonGroup>}
                {props.right && props.right}
              </>
            )}
          </Column>
        </Flex>
      </Group>
      <Section />
    </Container>
  );
})``;

interface PageHeaderProps {
  buttons?: React.ReactNode;
  byline?: React.ReactNode;
  className?: string;
  headline?: React.ReactNode;
  left?: React.ReactNode;
  right?: React.ReactNode;
  showBreadcrumbs?: boolean;
  statement?: React.ReactNode;
  style?: React.CSSProperties;
}

PageHeader.defaultProps = {
  showBreadcrumbs: true,
};

const HeaderByline = styled(Byline)<{ error?: any }>`
  ${FontStyles.L1}
  color: ${(props) =>
    props.error ? props.theme.Label.Color.Error.Foreground : props.theme.Label.Color.Default.Foreground};
  user-select: none;
  text-transform: uppercase;
`;

const Header = styled((props: HeaderProps) => {
  const showLeft = !!(props.byline || props.headline || props.subheadline || props.statement || props.left);
  const showRight = !!(props.right || props.buttons);
  return (
    <Group forwardedAs="header" className={props.className} style={props.style}>
      <Flex wrap={props.wrap}>
        {props.icon && (
          <Column basis="4.6rem">
            <HeaderIcon>{props.icon}</HeaderIcon>
          </Column>
        )}
        <Column grow basis="20rem">
          {showLeft && (
            <>
              {props.byline && <HeaderByline>{props.byline}</HeaderByline>}
              {props.headline && <Headline as={props.headlineAs}>{props.headline}</Headline>}
              {props.subheadline && <L2>{props.subheadline}</L2>}
              {props.statement && <Statement>{props.statement}</Statement>}
              {props.left && props.left}
            </>
          )}
        </Column>
        <Column>
          {showRight && (
            <>
              {props.buttons && <ButtonGroup>{props.buttons}</ButtonGroup>}
              {props.right && props.right}
            </>
          )}
        </Column>
      </Flex>
    </Group>
  );
})`
  ${LayoutStyles.Header}
`;

const HeaderIcon = styled.div`
  color: ${({ theme }) => theme.Typography.Color.Default.Heading};
`;

interface HeaderProps {
  buttons?: React.ReactNode;
  byline?: React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
  headline?: React.ReactNode;
  headlineAs?: string | React.ComponentType<any>;
  left?: React.ReactNode;
  right?: React.ReactNode;
  statement?: React.ReactNode;
  style?: React.CSSProperties;
  subheadline?: React.ReactNode;
  wrap?: boolean;
}

Header.defaultProps = {
  headlineAs: 'h2',
  wrap: true,
};

const ActionsBar = styled((props: ActionsBarProps) => {
  const showLeft = !!(props.left || props.buttonsLeft);
  const showRight = !!(props.right || props.buttonsRight);
  return (
    <Section className={props.className} style={props.style}>
      <Flex wrap={props.wrap}>
        <Column shrink style={{ marginRight: 'auto' }}>
          {showLeft && (
            <Row>
              {props.buttonsLeft && <ButtonGroup wrap={props.wrap}>{props.buttonsLeft}</ButtonGroup>}
              {props.left && props.left}
            </Row>
          )}
        </Column>
        <Column grow={1} shrink={2}>
          {showRight && (
            <Row style={{ textAlign: 'right' }}>
              {props.buttonsRight && (
                <ButtonGroup justifyContent="flex-end" wrap={props.wrap}>
                  {props.buttonsRight}
                </ButtonGroup>
              )}
              {props.right && props.right}
            </Row>
          )}
        </Column>
      </Flex>
    </Section>
  );
})`
  ${media.print`
    display: none;
  `}
`;

ActionsBar.defaultProps = {
  wrap: true,
};

interface ActionsBarProps {
  buttonsLeft?: React.ReactNode;
  buttonsRight?: React.ReactNode;
  className?: string;
  left?: React.ReactNode;
  right?: React.ReactNode;
  style?: React.CSSProperties;
  wrap?: boolean;
}

const SaveButtons = (props: SaveButtonProps): JSX.Element | null => {
  useDeprecatedProp(props, 'disabled');
  useDeprecatedProp(props, 'saveDisabled');
  useDeprecatedProp(props, 'cancelDisabled');

  const renderCancel = props.onCancel !== undefined;
  let saveEnabled = props.saveEnabled === true && props.enabled === true && props.saving !== true;
  let cancelEnabled = props.cancelEnabled === true && props.enabled === true && props.saving !== true;

  if (props.saveDisabled !== undefined || props.disabled !== undefined) {
    saveEnabled = props.disabled !== true && props.saveDisabled !== true && props.saving !== true;
  }
  if (props.cancelDisabled !== undefined || props.disabled !== undefined) {
    cancelEnabled = props.disabled !== true && props.cancelDisabled !== true && props.saving !== true;
  }

  const onSaveClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (saveEnabled !== true) {
        return;
      }
      e.stopPropagation();
      e.preventDefault();
      props.onSave(e);
    },
    [props, saveEnabled]
  );

  const onCancelClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (cancelEnabled !== true) {
        return;
      }
      e.stopPropagation();
      e.preventDefault();
      if (props.onCancel) {
        props.onCancel(e);
      }
    },
    [cancelEnabled, props]
  );

  if (props.display !== true) {
    return null;
  }

  return (
    <Buttons className={props.className} style={props.style}>
      <Button
        disabled={saveEnabled !== true}
        onClick={onSaveClick}
        loading={props.saving}
        shape="rounded"
        size="medium"
        variant="primary"
      >
        {props.saveText}
      </Button>
      {renderCancel && (
        <Button
          disabled={cancelEnabled !== true}
          onClick={onCancelClick}
          variant="secondary"
          shape="rounded"
          size="medium"
        >
          {props.cancelText}
        </Button>
      )}
    </Buttons>
  );
};

const SaveButtonsFooter = (props: SaveButtonProps): JSX.Element | null => {
  return (
    <Footer>
      <SaveButtons {...props} />
    </Footer>
  );
};

interface SaveButtonProps {
  /** @deprecated */
  cancelDisabled?: boolean;
  cancelEnabled?: boolean;
  cancelText?: React.ReactNode;
  /** @deprecated */
  disabled?: boolean;
  display?: boolean;
  enabled?: boolean;
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onSave: (e: React.MouseEvent<HTMLButtonElement>) => void;
  /** @deprecated */
  saveDisabled?: boolean;
  saveEnabled?: boolean;
  saveText?: React.ReactNode;
  saving?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

SaveButtons.defaultProps = {
  cancelEnabled: true,
  cancelText: 'or, Discard my changes',
  display: true,
  enabled: true,
  saveEnabled: true,
  saveText: 'Save changes',
  saving: false,
};

const Page = (props: PageProps): JSX.Element => {
  const renderHeader = props.header !== undefined;
  const renderSidebar = props.sidebar !== undefined;
  const renderMain = props.children !== undefined || props.main !== undefined;
  return (
    <PageGridContainer className={props.className} style={props.style}>
      {renderHeader && <PageGridHeader>{props.header}</PageGridHeader>}
      {renderSidebar && (
        <PageGridSidebar>
          <PageSidebarStickyScrollingContainer>
            <PageSidebarContainer>{props.sidebar}</PageSidebarContainer>
          </PageSidebarStickyScrollingContainer>
        </PageGridSidebar>
      )}
      {renderMain && (
        <PageGridMain>
          {props.main}
          {props.children}
        </PageGridMain>
      )}
    </PageGridContainer>
  );
};

interface PageProps {
  children?: React.ReactNode;
  className?: string;
  header?: React.ReactNode;
  main?: React.ReactNode;
  sidebar?: React.ReactNode;
  style?: React.CSSProperties;
}

const PageGridHeader = styled.div``;
const PageGridMain = styled.div``;
const PageGridSidebar = styled.div``;

const PageSidebarStickyScrollingContainer = styled.div`
  width: 100%;

  ${media.tabletLandscapeAndUp`
    padding-right: ${SIZE[4]};
    width: auto;
    // make sidebar sticky
    position: sticky;
    top: ${SITE_HEADER_HEIGHT};
    // make sidebar scrollable when it's too long
    max-height: ${`calc(100vh - ${SITE_HEADER_HEIGHT})`};
    overflow-y: auto;
  `}

  ${media.print`
    display: none;
  `}
`;

const PageSidebarContainer = styled.div`
  max-width: 100%;
  width: 100%;
  top: ${SITE_HEADER_HEIGHT};

  ${media.tabletLandscapeAndUp`
    width: 20rem;
  `}
`;

const PageGridContainer = styled.div`
  display: grid;
  max-width: 100%;

  grid-template-areas:
    'header'
    'sidebar'
    'main';
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);

  ${PageGridHeader} {
    grid-area: header;
    max-width: 100%;
  }

  ${PageGridSidebar} {
    grid-area: sidebar;
    max-width: 100%;
  }

  ${PageGridMain} {
    grid-area: main;
    max-width: 100%;
  }

  ${media.tabletLandscapeAndUp`
    grid-template-areas:
      'sidebar header'
      'sidebar  main'
      'sidebar  main';
    grid-template-columns: auto minmax(0, 1fr);
  `}
`;

const PageDeprecated = (props: PageDeprecatedProps): JSX.Element => {
  const renderSidebar = props.sidebar !== undefined;
  const renderMain = props.children !== undefined || props.main !== undefined;
  return (
    <PageDeprecatedFlexContainer className={props.className} style={props.style}>
      {renderSidebar && (
        <PageDeprecatedColumnSidebar>
          <PageDeprecatedSidebarContainer>{props.sidebar}</PageDeprecatedSidebarContainer>
        </PageDeprecatedColumnSidebar>
      )}
      {renderMain && (
        <Column grow shrink>
          {props.main}
          {props.children}
        </Column>
      )}
    </PageDeprecatedFlexContainer>
  );
};

interface PageDeprecatedProps {
  children?: React.ReactNode;
  className?: string;
  main?: React.ReactNode;
  sidebar?: React.ReactNode;
  style?: React.CSSProperties;
}

const PageDeprecatedFlexContainer = styled(Flex)`
  flex-wrap: wrap;

  ${media.tabletLandscapeAndUp`
    flex-wrap: nowrap;
  `}
`;

const PageDeprecatedColumnSidebar = styled(Column)`
  width: 100%;

  ${media.tabletLandscapeAndUp`
    padding-right: ${SIZE[4]};
    width: auto;

    // make sidebar sticky
    position: sticky;
    top: ${SITE_HEADER_HEIGHT};
    // make sidebar scrollable when it's too long
    max-height: ${`calc(100vh - ${SITE_HEADER_HEIGHT})`};
    overflow-y: auto;
  `}
  ${media.print`
    display: none;
  `}
`;

const PageDeprecatedSidebarContainer = styled.div`
  max-width: 100%;
  width: 100%;
  top: ${SITE_HEADER_HEIGHT};

  ${media.tabletLandscapeAndUp`
    width: 20rem;
  `}
`;

LayoutStyles.ExtraHeaderSpace = css`
  /* Add extra space above headers, except the first one */
  ${Header} {
    margin-top: ${SIZE[2]};
  }
  ${ActionsBar} + ${Header}, ${Header}:first-child {
    margin-top: 0;
  }

  ${media.print`
    margin-top: 0px;
    margin-left:${SIZE[0]};
  `}
`;

const Panel = styled.div`
  ${LayoutStyles.Panel};
  ${LayoutStyles.Container};
  ${LayoutStyles.ExtraHeaderSpace}
`;

const Card = styled.div`
  ${LayoutStyles.Card};
  padding-left: ${SIZE[4]};
  padding-right: ${SIZE[4]};
  ${LayoutStyles.ExtraHeaderSpace}
`;

const BorderedCard = styled.div`
  ${LayoutStyles.Card};
  ${LayoutStyles.Container};
  ${LayoutStyles.Bordered}
  ${LayoutStyles.ExtraHeaderSpace}
`;

const Banner = styled(Card)`
  border-radius: ${SIZE[2]};
`;

const FooterHeadline = styled(L2)``;

const FooterBody = styled(P)`
  overflow-wrap: break-word;
`;

const FooterCard = styled(Card)`
  width: auto;
  padding: ${SIZE[3]} ${SIZE[2]};
  margin-left: -${SIZE[1]};
  margin-right: -${SIZE[1]};
  margin-bottom: -${SIZE[2]};
  border-radius: ${SIZE[2]};

  ${media.tabletPortraitAndUp`
    padding: ${SIZE[4]};
    margin-left: -${SIZE[2]};
    margin-right: -${SIZE[2]};
    margin-bottom: -${SIZE[2]};
  `}

  ${FooterHeadline} {
    margin-top: ${SIZE[4]};
  }

  ${FooterHeadline}:first-of-type {
    margin-top: 0;
  }
`;

const FooterBanner = styled(Card)`
  width: auto;
  padding: ${SIZE[2]} ${SIZE[3]};
  margin-left: -${SIZE[2]};
  margin-right: -${SIZE[2]};
  margin-bottom: -${SIZE[4]};
  border-radius: 0 0 ${SIZE[2]} ${SIZE[2]};
  background: ${(props) => props.theme.Layout.Footer.Banner.Color.Default.Background};
  color: ${(props) => props.theme.Layout.Footer.Banner.Color.Default.Foreground};

  ${media.tabletPortraitAndUp`
    padding: ${SIZE[3]} ${SIZE[4]};
    margin-left: -${SIZE[4]};
    margin-right: -${SIZE[4]};
    margin-bottom: -${SIZE[4]};
  `}

  ${FooterHeadline} {
    margin-top: ${SIZE[4]};
    color: inherit;
  }

  ${FooterHeadline}:first-of-type {
    margin-top: 0;
  }

  ${FooterBody} {
    color: inherit;
    opacity: 0.7;
  }
`;

const Message = styled(OldPanel.Message)``;

const CardComingSoon = (props: React.ComponentProps<typeof OldPanel.ComingSoonMessage>) => {
  return (
    <Card>
      <OldPanel.ComingSoonMessage {...props} />
    </Card>
  );
};

const CardMessage = styled((props) => {
  return (
    <Card>
      <OldPanel.Message {...props} />
    </Card>
  );
})``;

const CardHeader = styled(Header)`
  ${Headline} {
    ${TypographyStyles.H2};
    color: ${(props) => props.theme.Typography.Color.Default.Label};
  }
`;

const LoadingPanel = (props: { children: React.ReactNode }): JSX.Element => {
  return (
    <Panel style={{ minHeight: '35vh' }}>
      {props.children}
      <LoadingSpinner />
    </Panel>
  );
};

export const Layout = {
  ActionsBar: ActionsBar,
  ButtonGroup: ButtonGroup,
  ButtonStack: ButtonStack,
  Banner: Banner,
  Card: Object.assign(Card, {
    Bordered: BorderedCard,
    ComingSoon: CardComingSoon,
    Message: CardMessage,
    Header: CardHeader,
  }),
  Flex: Object.assign(Flex, {
    Column: Column,
  }),
  Footer: Object.assign(Footer, {
    Card: FooterCard,
    Banner: FooterBanner,
    Headline: FooterHeadline,
    Body: FooterBody,
    SaveButtons: SaveButtons,
  }),
  Group: Group,
  Groups: Groups,
  Header: Header,
  LoadingPanel: LoadingPanel,
  Message: Message,
  Page: Page,
  PageDeprecated: PageDeprecated,
  PageHeader: PageHeader,
  Panel: Panel,
  SaveButtons: SaveButtonsFooter,
};

import { get as _get } from 'lodash';
import React, { useContext, useState } from 'react';
import { Button } from '../../../../../../component/Button';
import { ButtonConsoleLog } from '../../../../../../component/ButtonConsoleLog';
import { ButtonCopy } from '../../../../../../component/ButtonCopy';
import { DataList } from '../../../../../../component/DataList';
import { CircleIconXRounded, IconPreview } from '../../../../../../component/Icons';
import { Layout } from '../../../../../../component/Layout';
import { H3 } from '../../../../../../component/Typography';
import { SIZE } from '../../../../../../component/style-utils';
import { CURRENCY_CANADIAN_DOLLAR, CURRENCY_US_DOLLAR } from '../../../../../../constant';
import { useRpMerchant } from '../../../../../../hooks/useRpMerchant';
import {
  DISPLAY_ADDRESS_FORMATTER,
  DISPLAY_DATE_FORMATTER,
  EMAIL_FORMATTER,
  TEL_FORMATTER,
} from '../../../../../../lib/objectGridAttributeLibrary';
import { MerchantsContext } from '../../components/MerchantsContext';
import { MerchantContext } from '../context/MerchantContext';
import { MerchantActivate } from './merchant/MerchantActivate';
import { MerchantDeactivate } from './merchant/MerchantDeactivate';

export const MerchantDetail = (props: any) => {
  const { church } = useContext(MerchantsContext);
  const { merchant } = useContext(MerchantContext);

  const [showAccountOwner, setShowAccountOwner] = useState(false);
  const [showBankInformation, setShowBankInformation] = useState(false);

  const { data: rpmerchant = {}, isLoading } = useRpMerchant(merchant.rebelpay_merchant_id);

  if (isLoading) {
    return null;
  }

  const hasRebelpay = merchant.rebelpay_merchant_id !== undefined;
  const unboarded = hasRebelpay === true && merchant.boarded_at === undefined;
  const isClosed = merchant.closed_at !== undefined;
  const isActive = hasRebelpay === true && merchant.boarded_at !== undefined && merchant.closed_at === undefined;

  const hasProcessorId = rpmerchant.processor_id !== undefined;
  const hasBluchexData =
    rpmerchant.bluechex_data !== undefined &&
    Object.values(rpmerchant.bluechex_data).some((value: any) => ['waiting', undefined].includes(value)) !== true &&
    _get(rpmerchant, 'bluechex_data.cardconnect_mid') !== undefined &&
    Object.values(rpmerchant.bluechex_data.cardconnect_mid).some((value: any) => value === undefined) !== true;
  let merchantCanActivate = false;
  switch (rpmerchant.currency) {
    case CURRENCY_CANADIAN_DOLLAR:
      merchantCanActivate = hasProcessorId;
      break;
    case CURRENCY_US_DOLLAR:
      merchantCanActivate = hasBluchexData;
      break;
    default:
      merchantCanActivate = false;
  }

  const showActivateButton = (unboarded === true && merchantCanActivate === true) || isClosed === true;

  return (
    <>
      <Layout.Panel>
        <Layout.Header
          headline={`${merchant.name_legal ?? ''} Merchant`.trimStart()}
          buttons={
            <>
              {showActivateButton && <MerchantActivate church={church} merchant={merchant} />}
              {isActive && <MerchantDeactivate church={church} merchant={merchant} />}
            </>
          }
        />
        <Layout.Card>
          <Layout.Header headline="Status" />
          <DataList inline labelWidth="25rem">
            <DataList.Item>
              <DataList.Label>Created At</DataList.Label>
              <DataList.Value>{DISPLAY_DATE_FORMATTER(merchant.created_at)}</DataList.Value>
            </DataList.Item>
            {merchant.applied_at && (
              <DataList.Item>
                <DataList.Label>Applied At</DataList.Label>
                <DataList.Value>{DISPLAY_DATE_FORMATTER(merchant.applied_at)}</DataList.Value>
              </DataList.Item>
            )}
            {merchant.approved_at && (
              <DataList.Item>
                <DataList.Label>Approved At</DataList.Label>
                <DataList.Value>{DISPLAY_DATE_FORMATTER(merchant.approved_at)}</DataList.Value>
              </DataList.Item>
            )}
            {merchant.boarded_at && (
              <DataList.Item>
                <DataList.Label>Boarded At</DataList.Label>
                <DataList.Value>{DISPLAY_DATE_FORMATTER(merchant.boarded_at)}</DataList.Value>
              </DataList.Item>
            )}
            {merchant.live_at && (
              <DataList.Item>
                <DataList.Label>Live At</DataList.Label>
                <DataList.Value>{DISPLAY_DATE_FORMATTER(merchant.live_at)}</DataList.Value>
              </DataList.Item>
            )}
            {merchant.closed_at && (
              <DataList.Item>
                <DataList.Label>Closed At</DataList.Label>
                <DataList.Value>{DISPLAY_DATE_FORMATTER(merchant.closed_at)}</DataList.Value>
              </DataList.Item>
            )}
            <DataList.Item>
              <DataList.Label>Updated At</DataList.Label>
              <DataList.Value>{DISPLAY_DATE_FORMATTER(merchant.updated_at)}</DataList.Value>
            </DataList.Item>
          </DataList>
        </Layout.Card>

        <Layout.Group>
          <Layout.Header />
          <H3>Church Information</H3>
          <DataList inline labelWidth="25rem">
            <DataList.Item>
              <DataList.Label>Legal Name</DataList.Label>
              <DataList.Value>{merchant.name_legal}</DataList.Value>
            </DataList.Item>

            <DataList.Item>
              <DataList.Label>Mailing Address</DataList.Label>
              <DataList.Value>{DISPLAY_ADDRESS_FORMATTER(merchant.address_mailing)}</DataList.Value>
            </DataList.Item>
            <DataList.Item>
              <DataList.Label>Phone</DataList.Label>
              <DataList.Value>{TEL_FORMATTER(merchant.phone)}</DataList.Value>
            </DataList.Item>
            <DataList.Item>
              <DataList.Label>Tax Id</DataList.Label>
              <DataList.Value>{merchant.tax_id}</DataList.Value>
            </DataList.Item>
            <DataList.Item>
              <DataList.Label>Resistered Non-Profit</DataList.Label>
              <DataList.Value>{merchant.registered_nonprofit === true ? 'Yes' : 'No'}</DataList.Value>
            </DataList.Item>
          </DataList>
          <Layout.Header
            headline={
              <Layout.Flex>
                <Layout.Flex.Column>
                  <H3>Account Owner</H3>
                </Layout.Flex.Column>
                <Layout.Flex.Column>
                  <Button
                    size="small"
                    shape="circle"
                    icon={showAccountOwner ? <CircleIconXRounded /> : <IconPreview />}
                    onClick={() => setShowAccountOwner(!showAccountOwner)}
                    style={{ height: '2.5rem', width: '2.5rem' }}
                  />
                </Layout.Flex.Column>
              </Layout.Flex>
            }
          />
          {showAccountOwner === true && (
            <DataList inline labelWidth="25rem">
              <DataList.Item>
                <DataList.Label>First Name</DataList.Label>
                <DataList.Value>{merchant.owner_name_first}</DataList.Value>
              </DataList.Item>
              <DataList.Item>
                <DataList.Label>Last Name</DataList.Label>
                <DataList.Value>{merchant.owner_name_last}</DataList.Value>
              </DataList.Item>
              <DataList.Item>
                <DataList.Label>Address</DataList.Label>
                <DataList.Value>{DISPLAY_ADDRESS_FORMATTER(merchant.owner_address)}</DataList.Value>
              </DataList.Item>
              <DataList.Item>
                <DataList.Label>Phone</DataList.Label>
                <DataList.Value>{TEL_FORMATTER(merchant.owner_phone)}</DataList.Value>
              </DataList.Item>
              <DataList.Item>
                <DataList.Label>Email</DataList.Label>
                <DataList.Value>{merchant.owner_email}</DataList.Value>
              </DataList.Item>
              <DataList.Item>
                <DataList.Label>Owner Birth Date</DataList.Label>
                <DataList.Value>{merchant.owner_dob}</DataList.Value>
              </DataList.Item>
              <DataList.Item>
                <DataList.Label>Owner Tax Id</DataList.Label>
                <DataList.Value>{merchant.owner_tax_id}</DataList.Value>
              </DataList.Item>
            </DataList>
          )}
          <Layout.Header
            headline={
              <Layout.Flex>
                <Layout.Flex.Column>
                  <H3>Bank Information</H3>
                </Layout.Flex.Column>
                <Layout.Flex.Column>
                  <Button
                    size="small"
                    shape="circle"
                    icon={showBankInformation ? <CircleIconXRounded /> : <IconPreview />}
                    onClick={() => setShowBankInformation(!showBankInformation)}
                    style={{ height: '2.5rem', width: '2.5rem' }}
                  />
                </Layout.Flex.Column>
              </Layout.Flex>
            }
          />
          {showBankInformation && (
            <DataList inline labelWidth="25rem">
              <DataList.Item>
                <DataList.Label>Country</DataList.Label>
                <DataList.Value>{merchant.bank_country_code}</DataList.Value>
              </DataList.Item>

              <DataList.Item>
                <DataList.Label>Currency</DataList.Label>
                <DataList.Value>{merchant.bank_currency_code}</DataList.Value>
              </DataList.Item>

              <DataList.Item>
                <DataList.Label>Format</DataList.Label>
                <DataList.Value>{merchant.bank_number_format}</DataList.Value>
              </DataList.Item>

              <DataList.Item>
                <DataList.Label>Number Routing</DataList.Label>
                <DataList.Value>{merchant.bank_number_routing}</DataList.Value>
              </DataList.Item>

              <DataList.Item>
                <DataList.Label>Number Account</DataList.Label>
                <DataList.Value>{merchant.bank_number_account}</DataList.Value>
              </DataList.Item>

              <DataList.Item>
                <DataList.Label>Type</DataList.Label>
                <DataList.Value>{merchant.bank_type_account}</DataList.Value>
              </DataList.Item>
            </DataList>
          )}
        </Layout.Group>
        <Layout.Footer>
          <Layout.Card style={{ borderRadius: SIZE[1] }}>
            <Layout.Header headline="Identification" />
            <Layout.Group>
              <DataList inline labelWidth="18rem">
                <DataList.Item>
                  <DataList.Label>Productgiving Id</DataList.Label>
                  <DataList.Value>
                    {merchant.productgiving_id} <ButtonCopy data={merchant.productgiving_id} />
                  </DataList.Value>
                </DataList.Item>
                <DataList.Item>
                  <DataList.Label>Merchant Id</DataList.Label>
                  <DataList.Value>{merchant.id}</DataList.Value>
                </DataList.Item>
                <DataList.Item>
                  <DataList.Label>RP Merchant Id</DataList.Label>
                  <DataList.Value>{merchant.rebelpay_merchant_id}</DataList.Value>
                </DataList.Item>
                <DataList.Item>
                  <DataList.Label>System Email</DataList.Label>
                  <DataList.Value style={{ wordWrap: 'break-word' }}>
                    {EMAIL_FORMATTER(merchant.system_email)}
                  </DataList.Value>
                </DataList.Item>
              </DataList>
            </Layout.Group>
          </Layout.Card>
          <Layout.Group>
            <Layout.Flex justifyContent="center">
              <ButtonConsoleLog data={merchant} />
            </Layout.Flex>
          </Layout.Group>
        </Layout.Footer>
      </Layout.Panel>
    </>
  );
};

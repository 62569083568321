import { ButtonCancel, ButtonPrimary, InputRow, InputText, RadioGroup, RadioOption } from '@foyyay/control-elements';
import { unwrapResult } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Layout } from '../../../../../../../component/Layout';
import { Table } from '../../../../../../../component/Table';
import {
  BILLING_INTERVAL_TO_MONTH_COUNT,
  BillingIntervalLabelMap,
  BillingItemDiscount,
  BillingItemProduct,
  BillingItemType,
} from '../../../../../../../constant';
import { AMOUNT_FORMATTER } from '../../../../../../../lib/formatting';
import { SubscriptionControllerContext } from '../../state/context';
import { createItem, selectActiveProductItems, selectSubscription } from '../../state/reducer';

enum Kind {
  Amount = 'amount',
  Percent = 'percent',
}

const DESCRIPTIONS = Object.freeze([
  'Nucleus 2 Member Promo Discount',
  'Nucleus 2 Partner Promo Discount',
  'Nucleus 1 Customer Discount (Charter Member)',
  'Nucleus 1 Customer Discount (Unified Billing)',
  'Nucleus 1 Customer Scholarship Discount (Unified Billing)',
  'Nucleus 1 Interim Discount',
  'Bundle Discount (10%)',
  'Bundle Discount (20%)',
  'Bundle Discount (30%)',
]);

export const DiscountAdd = (props) => {
  const { dispatch, useSelector } = useContext(SubscriptionControllerContext);
  const discountableProducts = useSelector(selectActiveProductItems);
  const subscription = useSelector(selectSubscription);

  const [creating, setCreating] = useState(false);
  const [description, setDescription] = useState<BillingItemDiscount['data']['description']>('');
  const [kind, setKind] = useState<Kind>(Kind.Amount);
  const [value, setValue] = useState('');
  const [appliesTo, setAppliesTo] = useState<Array<string> | undefined>(undefined);

  const doClose = () => props.onClose();

  const doCreateDiscount = async () => {
    setCreating(true);
    let numberValue = parseFloat(value);

    if (Kind.Amount === kind) {
      numberValue = Math.round(numberValue * 100);
    } else {
      const intValue = Math.round(numberValue);
      if (intValue === numberValue) {
        numberValue = intValue;
      }
    }

    const params = {
      type: BillingItemType.Discount,
      data: {
        description: description,
        applies_to_items: appliesTo,
        [kind]: numberValue,
      },
    };

    try {
      await dispatch(createItem({ item: params })).then(unwrapResult);
    } catch (error) {
      console.error(error);
      setCreating(false);
      return;
    }
    doClose();
  };

  const handleCloseClicked = () => {
    if (creating === true) {
      return;
    }
    doClose();
  };

  const handleCreateClicked = () => {
    if (creating === true) {
      return;
    }
    doCreateDiscount();
  };

  const handleKindChanged = (kind: Kind) => {
    if (creating === true) {
      return;
    }

    setKind(kind);
    setValue('');
  };

  const handleValueChanged = (amount: string) => {
    if (creating === true) {
      return;
    }
    setValue(amount);
  };

  const handleDescriptionChanged = (description: string) => {
    if (creating === true) {
      return;
    }
    setDescription(description);
  };

  const handleAppliesTypeChanged = (appliesToProducts: string) => {
    if (creating === true) {
      return;
    }
    if (appliesToProducts === 'true') {
      setAppliesTo([]);
    } else {
      setAppliesTo(undefined);
    }
  };

  const handleProductClicked = (product: BillingItemProduct) => {
    if (creating === true) {
      return;
    }
    if (appliesTo === undefined) {
      return;
    }

    if (appliesTo.includes(product.id) === true) {
      setAppliesTo(appliesTo.filter((id) => id !== product.id));
    } else {
      setAppliesTo([...appliesTo, product.id]);
    }
  };

  let placeholder = '';
  let valueError;
  if (kind === Kind.Amount) {
    placeholder = 'Amount';
    valueError = value !== undefined && isNaN(parseFloat(value)) === false && parseFloat(value) < 1;
  } else {
    placeholder = 'Percentage';
    valueError =
      value !== undefined && isNaN(parseFloat(value)) === false && (parseFloat(value) <= 0 || parseFloat(value) > 100);
  }

  const appliesToProducts = `${appliesTo !== undefined}`;
  const descriptionPresetValue = DESCRIPTIONS.includes(description) === true ? description : '';
  const canCreate =
    valueError === false && value !== '' && description.length > 2 && (appliesTo === undefined || appliesTo.length > 0);

  const renderProductTable = appliesTo !== undefined;

  return (
    <Layout.Panel>
      <Layout.Header
        headline="Add Discount"
        buttons={<ButtonCancel onClick={handleCloseClicked}>Cancel</ButtonCancel>}
      />

      <InputRow>
        <RadioGroupList value={descriptionPresetValue} onChange={handleDescriptionChanged}>
          {DESCRIPTIONS.map((preset) => (
            <RadioOption key={preset} value={preset} label={preset} />
          ))}
          <RadioOption value={''} label="Custom" />
        </RadioGroupList>
      </InputRow>

      <InputRow>
        <InputText placeholder="Description" value={description} onChange={handleDescriptionChanged} />
      </InputRow>

      <InputRow>
        <RadioGroup value={kind} onChange={handleKindChanged}>
          <RadioOption value={Kind.Amount} label="Flat Amount ($)" />
          <RadioOption value={Kind.Percent} label="Percentage (%)" />
        </RadioGroup>
      </InputRow>

      <InputRow>
        <InputText
          placeholder={placeholder}
          type="number"
          value={value}
          onChange={handleValueChanged}
          error={valueError}
        />
      </InputRow>

      <InputRow>
        <RadioGroup value={appliesToProducts} onChange={handleAppliesTypeChanged}>
          <RadioOption value={'false'} label="Entire Subscription" />
          <RadioOption value={'true'} label="Specific Products" />
        </RadioGroup>
      </InputRow>

      {renderProductTable && (
        <Table.OverflowAuto>
          <Table>
            <Table.Thead>
              <Table.Row>
                <Table.Header></Table.Header>
                <Table.Header>Name</Table.Header>
                <Table.Header>$/{BillingIntervalLabelMap[subscription.interval]}</Table.Header>
                <Table.Header>Description</Table.Header>
              </Table.Row>
            </Table.Thead>
            <Table.Tbody>
              {discountableProducts.map((product) => {
                return (
                  <Table.Row key={product.id} onClick={(event) => handleProductClicked(product)}>
                    <Table.Cell>
                      <input type="checkbox" checked={(appliesTo ?? []).includes(product.id)} readOnly />
                    </Table.Cell>
                    <Table.Cell>{product.data.product_name}</Table.Cell>
                    <Table.Cell>
                      {AMOUNT_FORMATTER(
                        product.data.monthly_cost * BILLING_INTERVAL_TO_MONTH_COUNT[subscription.interval]
                      )}
                    </Table.Cell>
                    <Table.Cell>{product.data.description}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Tbody>
          </Table>
        </Table.OverflowAuto>
      )}

      <InputRow>
        <ButtonPrimary disabled={canCreate === false} onClick={handleCreateClicked} loading={creating}>
          Add
        </ButtonPrimary>
      </InputRow>
    </Layout.Panel>
  );
};

DiscountAdd.propTypes = {
  onClose: PropTypes.func,
};

DiscountAdd.defaultProps = {
  onClose: () => {},
};

const RadioGroupList = styled(RadioGroup)`
  flex-direction: column;
`;

import { orderBy as _orderBy, upperFirst as _upperFirst } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Layout } from '../../../component/Layout';
import { LoadingSpinnerBlock } from '../../../component/LoadingSpinner';
import { Table } from '../../../component/Table';
import { usePersonConnections } from '../../../hooks/usePerson';
import { LINK_FORMATTER } from '../../../lib/objectGridAttributeLibrary';

export const ChurchConnections = () => {
  const params = useParams<{ personId: string }>();
  const personId = params.personId ?? '';
  const { data: connections, isLoading } = usePersonConnections(personId);

  if (isLoading) {
    return (
      <Layout.Panel>
        <LoadingSpinnerBlock />
      </Layout.Panel>
    );
  }

  return (
    <>
      {connections.ownedChurches?.length > 0 && (
        <Layout.Panel>
          <Layout.Header headline="Owned Churches" />
          <Layout.Group>
            <Table.OverflowAuto>
              <Table>
                <Table.Thead>
                  <Table.Row>
                    <Table.Header>Church</Table.Header>
                    <Table.Header>Name</Table.Header>
                    <Table.Header>Platform</Table.Header>
                  </Table.Row>
                </Table.Thead>
                <Table.Tbody>
                  {connections.ownedChurches.map((ownedChurch) => {
                    return (
                      <Table.Row key={ownedChurch.id}>
                        <Table.Cell>{LINK_FORMATTER(ownedChurch.id)}</Table.Cell>
                        <Table.Cell>{ownedChurch.name}</Table.Cell>
                        <Table.Cell>{_upperFirst(ownedChurch.platform)}</Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Tbody>
              </Table>
            </Table.OverflowAuto>
          </Layout.Group>
        </Layout.Panel>
      )}
      {connections.churchpersons?.length > 0 && (
        <Layout.Panel>
          <Layout.Header headline="Church Persons" />
          <Layout.Group>
            <Table.OverflowAuto>
              <Table>
                <Table.Thead>
                  <Table.Row>
                    <Table.Header>Church</Table.Header>
                    <Table.Header>Type</Table.Header>
                    <Table.Header>Id</Table.Header>
                  </Table.Row>
                </Table.Thead>
                <Table.Tbody>
                  {_orderBy(connections.churchpersons, ['owner_id', 'type'], ['asc', 'asc']).map((churchperson) => {
                    return (
                      <Table.Row key={churchperson.id}>
                        <Table.Cell>{LINK_FORMATTER(churchperson.owner_id)}</Table.Cell>
                        <Table.Cell>{_upperFirst(churchperson.type)}</Table.Cell>
                        <Table.Cell>{churchperson.id}</Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Tbody>
              </Table>
            </Table.OverflowAuto>
          </Layout.Group>
        </Layout.Panel>
      )}
    </>
  );
};

import { ButtonPrimary, InputText, Label, TextAreaLimitedChar } from '@foyyay/control-elements';
import React, { FormEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { readResourcePolicy, updateResourcePolicy } from '../../../../action/policy';
import { Layout } from '../../../../component/Layout';
import { useAppDispatch } from '../../../../hooks/hooks';

function EditResourceComponent() {
  const dispatch = useAppDispatch();
  const params = useParams<{ resource: string }>();
  const [loading, setLoading] = useState(false);
  const [policyContent, setPolicyContent] = useState('{}');
  const [isValid, setIsValid] = useState(true);
  const [error, setError] = useState('');

  const loadPolicy = async () => {
    setLoading(true);
    try {
      const result = await dispatch(readResourcePolicy(params.resource ?? ''));
      setPolicyContent(JSON.stringify(result.policy.policyContent, null, 2));
    } catch (error: any) {
      setError(error.body.message ?? error.message ?? JSON.stringify(error.body));
    }
    setLoading(false);
  };

  useEffect(() => {
    loadPolicy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (isValid === false) {
      return;
    }

    setError('');
    setLoading(true);
    try {
      const result = await dispatch(updateResourcePolicy(params.resource ?? '', policyContent));
      setPolicyContent(JSON.stringify(result.policy.policyContent, null, 2));
    } catch (error: any) {
      setError(error.body.message ?? error.message ?? JSON.stringify(error.body));
    }
    setLoading(false);
  };

  const updatePolicyContent = (value: string) => {
    setPolicyContent(value);

    try {
      JSON.parse(value);
      setIsValid(true);
      setError('');
    } catch (error) {
      setIsValid(false);
      setError('Not valid JSON');
    }
  };

  return (
    <Layout.Panel>
      <form onSubmit={onSubmit}>
        <Label htmlFor="resource">Resource</Label>
        <InputText
          data-lpignore="true"
          name="resource"
          value={params.resource}
          disabled={true}
          title={'title string'}
          required
        />
        <Label htmlFor="content">Policy Content</Label>
        <TextAreaLimitedChar
          name="content"
          value={policyContent}
          onChange={updatePolicyContent}
          title={'title string'}
          required
        />
        <ButtonPrimary disabled={isValid === false} loading={loading}>
          Update Policy
        </ButtonPrimary>
        <div>
          <Label error={error.length > 0}>{error}</Label>
        </div>
      </form>
    </Layout.Panel>
  );
}

export const EditResource = EditResourceComponent;

import { Headline, Statement } from '@foyyay/control-elements';
import React from 'react';
import { Layout } from '../../../../../../../component/Layout';
import { CURRENCY_US_DOLLAR } from '../../../../../../../constant';
import { useRpMerchant } from '../../../../../../../hooks/useRpMerchant';
import { RpUpdateBluechex } from './RpUpdateBluechex';
import { RpUpdateProcessor } from './RpUpdateProcessor';

export const RpMerchantUpdate = (props: any) => {
  const { data: rpmerchant, isLoading } = useRpMerchant(props.rpMerchantId);
  const church = props.church;

  if (isLoading) {
    return null;
  }

  const hasBluechex = rpmerchant.currency === CURRENCY_US_DOLLAR;

  return (
    <>
      <Layout.Panel>
        <Headline>Routes Cardpoint Merchant</Headline>
        <Statement>
          Routes the CardPoint processor ID for this merchant. It is found in the url of the CardPointe merchant
          account.
        </Statement>
        <RpUpdateProcessor church={church} rpmerchant={rpmerchant} {...props} />
      </Layout.Panel>

      {hasBluechex && (
        <Layout.Panel>
          <Headline>BlueChex Merchant</Headline>
          <Statement>Add the BlueChex data needed to activate a merchant.</Statement>
          <RpUpdateBluechex church={church} rpmerchant={rpmerchant} {...props} />
        </Layout.Panel>
      )}
    </>
  );
};

import React from 'react';
import styled from 'styled-components';

interface SubscriptionActionProps {
  onClick: React.MouseEventHandler;
  children?: React.ReactNode;
}

export const SubscriptionAction = (props: SubscriptionActionProps): JSX.Element => {
  return (
    <span>
      <Button onClick={props.onClick}>{props.children ?? null}</Button>
    </span>
  );
};

const Button = styled.button`
  font-size: 1.5rem;
`;

import { Headline, SectionTitle, Statement } from '@foyyay/control-elements';
import _isEmpty from 'lodash/isEmpty';
import React from 'react';
import { objectGridAttributeLibrary } from '../lib/objectGridAttributeLibrary';
import { Table } from './Table';

interface Props<T extends object> {
  headline?: React.ReactNode;
  statement?: React.ReactNode;
  data?: T[];
  keys?: (keyof T & string)[];
  firstAttributes?: (keyof T & string)[];
  rowKey?: keyof T & string;
  lastAttributes?: (keyof T & string)[];
  hideAttributes?: (keyof T & string)[];
  hints?: { [key in keyof T & string]?: string };
}

export const ObjectsTable = <T extends object>(props: Props<T>): JSX.Element => {
  const headline = props.headline ?? '';
  const statement = props.statement ?? '';
  const hints = props.hints ?? {};

  const data = props.data ?? [];
  const keys = props.keys ?? [];

  const firstKeys = props.firstAttributes ?? ['id'];
  const rowKey = props.rowKey ?? firstKeys[0];
  const lastKeys = props.lastAttributes;
  const hideKeys = props.hideAttributes;

  let objectKeys = [...keys];
  let hasKeys = objectKeys.length > 0;

  if (!_isEmpty(firstKeys) && hasKeys) {
    // sort the first keys
    firstKeys.forEach((key, index) => {
      if (objectKeys.includes(key)) {
        objectKeys.splice(index, 0, objectKeys.splice(objectKeys.indexOf(key), 1)[0]);
      }
    });
  }

  if (!_isEmpty(lastKeys) && hasKeys) {
    // sort the last keys
    lastKeys!.forEach((key) => {
      if (objectKeys.includes(key)) {
        objectKeys.splice(objectKeys.length, 0, objectKeys.splice(objectKeys.indexOf(key), 1)[0]);
      }
    });
  }

  if (!_isEmpty(hideKeys) && hasKeys) {
    // hide these keys
    hideKeys!.forEach((key) => {
      if (objectKeys.includes(key)) {
        objectKeys.splice(objectKeys.indexOf(key), 1);
      }
    });
  }
  // objectKeys = objectKeys.filter((key: string) => key !== undefined && data[key] !== undefined);
  objectKeys = objectKeys.filter(
    (key: any) => key !== undefined ?? (data[0] !== undefined && data[0][key] !== undefined)
  );
  hasKeys = objectKeys.length > 0;

  return (
    <>
      {(props.headline || props.statement) && hasKeys && (
        <SectionTitle>
          <Headline>{headline}</Headline>
          <Statement>{statement}</Statement>
        </SectionTitle>
      )}

      {hasKeys && (
        <Table.OverflowAuto>
          <Table>
            <Table.Thead>
              <Table.Row>
                {objectKeys.map((key) => (
                  <Table.Header key={key}>
                    {/** note add spaces in camelCase string and underscores with spaces */}
                    {key.replace(/([a-z])([A-Z])/g, `$1 $2`).replace(/_/g, ' ')}
                  </Table.Header>
                ))}
              </Table.Row>
            </Table.Thead>
            <Table.Tbody>
              {data.map((object: any, index: number) => {
                return (
                  <Table.Row key={`table-row-${object[rowKey]}-${index}`}>
                    {objectKeys.map((key: string, index: number) => {
                      return (
                        <Table.Cell key={`table-cell-${key}-${index}`}>
                          {objectGridAttributeLibrary(key, object[key], hints[key])}
                        </Table.Cell>
                      );
                    })}
                  </Table.Row>
                );
              })}
            </Table.Tbody>
          </Table>
        </Table.OverflowAuto>
      )}
    </>
  );
};

import { ButtonPrimary, InputText, Label, TextAreaLimitedChar } from '@foyyay/control-elements';
import React, { FormEvent, useState } from 'react';
import { createResourcePolicy } from '../../../../action/policy';
import { Layout } from '../../../../component/Layout';
import { useAppDispatch } from '../../../../hooks/hooks';

function CreateResourceComponent() {
  const dispatch = useAppDispatch();
  const [saving, setSaving] = useState(false);
  const [resource, setResource] = useState('');
  const [policyContent, setPolicyContent] = useState('{}');
  const [isValid, setIsValid] = useState(true);
  const [error, setError] = useState('');

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (isValid === false) {
      return;
    }

    setError('');
    setSaving(true);
    try {
      await dispatch(createResourcePolicy(resource, JSON.parse(policyContent)));
    } catch (error: any) {
      setError(error.body);
    }
    setSaving(false);
  };

  const updatePolicyContent = (value: string) => {
    setPolicyContent(value);

    try {
      JSON.parse(value);
      setIsValid(true);
      setError('');
    } catch (error) {
      setIsValid(false);
      setError('Not valid JSON');
    }
  };

  return (
    <Layout.Panel>
      <form onSubmit={onSubmit}>
        <Label htmlFor="resource">Resource ID</Label>
        <InputText
          data-lpignore="true"
          name="resource"
          value={resource}
          onChange={setResource}
          title={'title string'}
          required
        />
        <Label htmlFor="content">Policy Content</Label>
        <TextAreaLimitedChar
          name="content"
          value={policyContent}
          onChange={updatePolicyContent}
          title={'title string'}
          required
        />
        <ButtonPrimary disabled={isValid === false} loading={saving}>
          Create Policy
        </ButtonPrimary>
        <div>
          <Label error={error.length > 0}>{error}</Label>
        </div>
      </form>
    </Layout.Panel>
  );
}

export const CreateResource = CreateResourceComponent;

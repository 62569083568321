import { UseMutationResult, UseQueryResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { MakeoverCustomProcess } from '../../type/MakeoverCustomProcess';
import {
  getCustomProcessCacheKey,
  useCustomProcess,
  useCustomProcessUpdate,
  useInvalidateCustomProcessQuery,
} from './useCustomProcess';
import { Nullable } from '../../type/utility';
import { MakeoverHttpApi } from '../api/MakeoverHttpApi';

export const useMakeoverCustomProcess = (church: { id: string }): UseQueryResult<MakeoverCustomProcess, unknown> => {
  return useCustomProcess(church, 'makeover', church.id);
};

export const useMakeoverCustomProcessCreate = (church: {
  id: string;
}): UseMutationResult<MakeoverCustomProcess, unknown, void> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: getCustomProcessCacheKey(church.id, 'makeover', church.id),
    mutationFn: async (updates) => {
      const response = await MakeoverHttpApi.create(church);
      return response.makeoverCustomProcess;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(getCustomProcessCacheKey(church.id, 'makeover', church.id), data);
    },
  });
};

export const useMakeoverCustomProcessUpdate = (church: {
  id: string;
}): UseMutationResult<MakeoverCustomProcess, unknown, Nullable<MakeoverCustomProcess>> => {
  return useCustomProcessUpdate(church, 'makeover', church.id);
};

export const useInvalidateMakeoverCustomProcessQuery = (church: { id: string }): (() => Promise<void>) => {
  return useInvalidateCustomProcessQuery(church, 'makeover', church.id);
};

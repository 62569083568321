import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { PersonHttpApi } from '../api/PersonHttpApi';
import { Source } from '../../type/Sources';

export const useSources = (personId?: string): UseQueryResult<Source[], Error> => {
  return useQuery({
    staleTime: 1000 * 60 * 5,
    queryKey: ['person', 'sources', personId],
    queryFn: async () => {
      if (personId === undefined) {
        throw new Error('personId is required');
      }
      return await PersonHttpApi.listSources(personId);
    },
    enabled: personId !== undefined,
  });
};

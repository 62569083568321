import styled, { css } from 'styled-components';
import { font, media } from './style-utils';

export const FontStyles = {
  H1: css`
    ${font(34, 'Black', -1.5, 32)}
    ${media.tabletPortraitAndUp`
        ${font(38, 'Black', -1.75)}
    `}
  `,
  H2: css`
    ${font(30, 'Bold', -1, 30)}
    ${media.tabletPortraitAndUp`
        ${font(32, 'Bold', -1)}
    `}
  `,
  H3: css`
    ${font(26, 'Bold', -0.5)}
  `,
  H4: css`
    ${font(22, 'Medium', -0.75)}
  `,
  L1: css`
    ${font(10, 'Bold', 1.25, 12)}

    ${media.tabletPortraitAndUp`
      ${font(12, 'Bold', 1.5, 14)}
    `}
  `,
  L2: css`
    ${font(20, 'Bold', -0.75)}
  `,
  L3: css`
    ${font(16, 'Bold', -0.5)}
  `,
  L4: css`
    ${font(18, 'Bold', -0.5)}
  `,
  L5: css`
    ${font(16, 'Bold', -0.5)}
  `,
  L6: css`
    ${font(14, 'Book', -0.5)}
  `,
  P: css`
    ${font(14, 'Book', -0.5, 18)}
    ${media.tabletPortraitAndUp`
      ${font(16, 'Book', -0.5, 20)}
    `}
  `,
  P2: css`
    ${font(20, 'Book', -0.75, 20)}
  `,
  P3: css`
    ${font(16, 'Book', -0.5, 22)}
  `,
  F1: css`
    ${font(18, 'Bold', -0.5, 18)}
    ${media.tabletPortraitAndUp`
      ${font(20, 'Bold', -0.5, 20)}
    `}
  `,
  B1: css`
    ${font(16, 'Bold', -0.5, 18)}
  `,
};

export const TypographyStyles = {
  H1: css`
    ${FontStyles.H1};
    color: ${({ theme }) => theme.Typography.Color.Default.Heading};
    margin: 0;
  `,
  H2: css`
    ${FontStyles.H2};
    color: ${({ theme }) => theme.Typography.Color.Default.Heading};
    margin: 0;
  `,
  H3: css`
    ${FontStyles.H3};
    color: ${({ theme }) => theme.Typography.Color.Default.Heading};
    margin: 0;
  `,
  H4: css`
    ${FontStyles.H4};
    color: ${({ theme }) => theme.Typography.Color.Default.Heading};
    margin: 0;
  `,
  L1: css`
    ${FontStyles.L1};
    color: ${({ theme }) => theme.Typography.Color.Default.Label};
    text-transform: uppercase;
  `,
  L2: css`
    ${FontStyles.L2};
    color: ${({ theme }) => theme.Typography.Color.Default.Label};
  `,
  L3: css`
    ${FontStyles.L3};
    opacity: 0.5;
    color: ${({ theme }) => theme.Typography.Color.Default.Label};
    text-transform: uppercase;
  `,
  L4: css`
    ${FontStyles.L4};
    color: ${({ theme }) => theme.Typography.Color.Default.Label};
  `,
  L5: css`
    ${FontStyles.L5};
    opacity: 0.35;
    color: ${({ theme }) => theme.Typography.Color.Default.Label};
  `,
  L6: css`
    ${FontStyles.L6};
    color: ${({ theme }) => theme.Typography.Color.Default.Label};
  `,
  P: css`
    ${FontStyles.P};
    color: ${({ theme }) => theme.Typography.Color.Default.Paragraph};
    font-family: ${({ theme }) => theme.Typography.Paragraph};
    margin: 0;
  `,
  P2: css`
    ${FontStyles.P2};
    color: ${({ theme }) => theme.Typography.Color.Default.Paragraph};
    font-family: ${({ theme }) => theme.Typography.Paragraph};
    margin: 0;
  `,
  P3: css`
    ${FontStyles.P3};
    color: ${({ theme }) => theme.Typography.Color.Default.Paragraph};
    font-family: ${({ theme }) => theme.Typography.Paragraph};
    opacity: 0.8;
    margin: 0;
  `,
  OL: css``,
  UL: css``,
  F1: css`
    ${FontStyles.F1};
    color: ${({ theme }) => theme.Typography.Color.Default.Field};
    padding: 0;
    margin: 0;
  `,
  B1: css`
    ${FontStyles.B1};
    color: ${({ theme }) => theme.Typography.Color.Default.Byline};
    margin: 0;
  `,
  A: css``,
};

export const H1 = styled.h1`
  ${TypographyStyles.H1};
`;

export const H2 = styled.h2`
  ${TypographyStyles.H2};
`;

export const H3 = styled.h3`
  ${TypographyStyles.H3};
`;

export const H4 = styled.h4`
  ${TypographyStyles.H4};
`;

export const L1 = styled.div`
  ${TypographyStyles.L1};
`;

export const L2 = styled.div`
  ${TypographyStyles.L2};
`;

export const L3 = styled.div`
  ${TypographyStyles.L3};
`;

export const L4 = styled.div`
  ${TypographyStyles.L4};
`;

export const L5 = styled.div`
  ${TypographyStyles.L5};
`;

export const L6 = styled.div`
  ${TypographyStyles.L6};
`;

export const P = styled.p`
  ${TypographyStyles.P};
`;

export const P2 = styled.p`
  ${TypographyStyles.P2};
`;

export const P3 = styled.p`
  ${TypographyStyles.P3};
`;

export const OL = styled.ol`
  ${TypographyStyles.P};
  ${TypographyStyles.OL};
`;

export const UL = styled.ul`
  ${TypographyStyles.P};
  ${TypographyStyles.UL};
`;

export const A = styled.a`
  ${TypographyStyles.A};
  color: ${({ theme }) => theme.Typography.Color.Default.Link};
  margin: 0;
  text-decoration: none !important;

  @media (hover: hover) {
    :hover {
      color: ${({ theme }) => theme.Typography.Color.Hover.Link};
      text-decoration: underline !important;
    }
  }
`;

export const A2 = styled.a`
  ${TypographyStyles.A};
  color: currentColor;
  margin: 0;
  text-decoration: underline;

  @media (hover: hover) {
    :hover {
      color: ${({ theme }) => theme.Typography.Color.Hover.Link};
      text-decoration: underline;
    }
  }
`;

// Static Text Field used on Paid Subscription Panel
export const F1 = styled.p`
  ${TypographyStyles.F1};
`;

export const B1 = styled.div`
  ${TypographyStyles.B1};
`;

export const BoldFirstLine = styled.div`
  & > p {
    ${font(26, 'Medium', -1, 30)};
    color: ${({ theme }) => theme.Typography.Color.Default.Paragraph};
    margin: 0;
  }

  & > p + p {
    margin-top: 3.4rem;
  }

  & > p:first-of-type::first-line {
    ${font(32, 'Black', -1.23, 44)};
    color: ${({ theme }) => theme.Typography.Color.Default.Label};
  }
`;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { GiftscheduleDetail } from './routes/GiftscheduleDetail';
import { GiftschedulesList } from './routes/GiftscheduleList';

export const Giftschedules = () => {
  return (
    <Routes>
      <Route path={'/:giftscheduleId/*'} element={<GiftscheduleDetail />} />
      <Route path="/" element={<GiftschedulesList />} />
    </Routes>
  );
};

import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { SIZE, STANDARD_SPRING_TRANSITION, toRem } from './style-utils';

const BaseProgressCircle = (props) => {
  const radius = (props.width - props.strokeWidth) / 2;
  const centerPosition = props.strokeWidth / 2 + radius;
  const linecapWidth = props.linecap !== 'butt' ? props.strokeWidth / 2 : 0;
  const circumference = radius * 2 * Math.PI;
  const progressAsCircumferenceRemaining = calculateProgressAsCircumferenceRemaining(
    circumference,
    props.progress,
    linecapWidth
  );
  return (
    <Container {...props}>
      <Layer>
        <SvgProgressCircle viewBox={`0 0 ${props.width} ${props.width}`} xmlns="http://www.w3.org/2000/svg">
          <g stroke="currentColor" strokeWidth={props.strokeWidth} fill="none" fillRule="evenodd">
            <circle className="LoadingCircle_track" opacity=".17" cx={centerPosition} cy={centerPosition} r={radius} />
            <circle
              className={`LoadingCircle_progress ${props.animation}`}
              cx={centerPosition}
              cy={centerPosition}
              r={radius}
              strokeDasharray={circumference}
              strokeDashoffset={progressAsCircumferenceRemaining}
              strokeLinecap={props.linecap}
            />
          </g>
        </SvgProgressCircle>
      </Layer>
      {React.Children.map(props.children, (child, index) => (
        <Layer key={index}>{child}</Layer>
      ))}
    </Container>
  );
};

BaseProgressCircle.propTypes = {
  width: PropTypes.number,
  strokeWidth: PropTypes.number,
  progress: PropTypes.number,
  animation: PropTypes.string,
  linecap: PropTypes.string,
};

BaseProgressCircle.defaultProps = {
  width: 42,
  strokeWidth: 6,
  progress: 0,
  linecap: 'round',
};

export const ProgressCircle = styled(BaseProgressCircle)`
  position: relative;
  display: inline-block;
`;

export const BaseLoadingSpinner = styled(BaseProgressCircle).attrs({ progress: 25, animation: 'rotate' })``;

export const LoadingSpinner = styled(BaseLoadingSpinner)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const LoadingSpinnerBlock = styled(BaseLoadingSpinner)`
  position: relative;
  margin: ${SIZE[4]} auto;
`;

export const LoadingSpinnerInline = styled(BaseLoadingSpinner)`
  position: relative;
`;

export const FloatingLoadingSpinner = styled((props) => {
  return (
    <FixedPositionWrapper>
      <LoadingSpinnerFloatingMotionContainer
        initial="hide"
        animate={props.display === true ? 'show' : 'hide'}
        variants={floatingLoadingSpinnerAnimationVariants}
        transition={STANDARD_SPRING_TRANSITION}
      >
        <LoadingSpinner />
      </LoadingSpinnerFloatingMotionContainer>
    </FixedPositionWrapper>
  );
})``;
FloatingLoadingSpinner.propTypes = {
  display: PropTypes.bool,
};
FloatingLoadingSpinner.defaultProps = {};
const floatingLoadingSpinnerAnimationVariants = {
  show: { opacity: 1, y: 0, scale: 1, pointerEvents: 'auto' },
  hide: { opacity: 0, y: -40, scale: 0.7, pointerEvents: 'none' },
};

const LoadingSpinnerFloatingMotionContainer = styled(motion.div)`
  height: ${SIZE[10]};
  width: ${SIZE[10]};
  border-radius: ${SIZE[10]};
  background: ${(props) => props.theme.FloatingButton.Color.Default.Background};
  box-shadow: 0 ${SIZE[1]} ${SIZE[4]} 0 ${(props) => props.theme.FloatingButton.Color.Default.BoxShadow};
`;

const FixedPositionWrapper = styled.div`
  position: fixed;
  top: ${SIZE[12]}; /* Header height */
  left: 50%;
  margin-top: ${SIZE[3]};
  transform: translateX(-50%);
  pointer-events: none;
  z-index: 9000;
`;

const SvgPulsingLogo = (props) => (
  <svg {...props} viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
    <path
      className="pulse"
      fill="currentColor"
      d="M33.685 33.386h1.165a3.512 3.512 0 003.512-3.512V25.24a2.47 2.47 0 012.47-2.47h1.832c.351 0 .636.286.636.638v19.175a.637.637 0 01-1.059.476l-9.148-8.116a.891.891 0 01.592-1.558zM31.616 32.614H30.45a3.512 3.512 0 00-3.512 3.512v4.633a2.47 2.47 0 01-2.47 2.47h-1.832A.637.637 0 0122 42.59V23.416a.637.637 0 011.06-.476l9.147 8.116a.891.891 0 01-.591 1.558z"
    />
  </svg>
);

const LoadingSpinnerWithLogo = (props) => (
  <LoadingSpinner {...props} width={66}>
    <SvgPulsingLogo />
  </LoadingSpinner>
);

export const AppLoadingSpinner = styled(LoadingSpinnerWithLogo)`
  position: fixed;
`;

function calculateProgressAsCircumferenceRemaining(circumference, progress, linecapWidth = 0) {
  const circumferenceRemaining = Math.min(circumference, ((100 - progress) / 100) * circumference + linecapWidth);
  // Compensate for linecap adjustment
  if (circumferenceRemaining <= linecapWidth) {
    return 0;
  }
  if (circumferenceRemaining >= circumference && progress !== 0) {
    return circumferenceRemaining - progress * 0.1;
  }
  return circumferenceRemaining;
}

const rotate = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const pulse = keyframes`
  0% {
    opacity: 0.17;
  }
  100% {
    opacity: 1;
  }
`;

const Container = styled.div`
  width: ${({ width }) => toRem(width) + 'rem'};
  height: ${({ width }) => toRem(width) + 'rem'};
  color: ${({ theme }) => theme.ProgressCircle.Color.Default.Foreground};
`;

const Layer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  .rotate {
    animation: ${rotate} 0.625s linear infinite;
    transform-origin: center center;
  }

  .pulse {
    animation: ${pulse} 1.5s ease-in-out infinite alternate;
  }

  > svg {
    display: block;
  }
`;

const SvgProgressCircle = styled.svg`
  display: block;
  width: 100%;
  transform-origin: center;
  transform: rotate(-90deg);
`;

export const LOADING_SPINNER_SIZES = {
  small: { width: 24, strokeWidth: 4 },
  medium: { width: 30, strokeWidth: 5 },
  large: { width: 42, strokeWidth: 6 },
  xlarge: { width: 42, strokeWidth: 6 },
};

import { Headline } from '@foyyay/control-elements';
import React, { useContext } from 'react';
import { RelativeDate } from '../../../../../../../component/Date';
import { Layout } from '../../../../../../../component/Layout';
import { Table } from '../../../../../../../component/Table';
import {
  BILLING_STATUS_TO_NAME,
  InvoiceModel,
  InvoicePurpose,
  InvoicePurposeToName,
} from '../../../../../../../constant';
import { AMOUNT_FORMATTER } from '../../../../../../../lib/formatting';
import { entityPlatform } from '../../../../../../../lib/platform';
import { SubscriptionControllerContext } from '../../state/context';
import { selectInvoices, selectNextInvoices, selectSubscription } from '../../state/reducer';

export const InvoiceList = () => {
  const { useSelector } = useContext(SubscriptionControllerContext);
  const invoices = useSelector(selectInvoices);
  const nextInvoices = useSelector(selectNextInvoices);

  const renderTable = invoices.length + nextInvoices.length > 0;

  return (
    <Layout.Panel>
      <Headline>Invoices: {invoices.length}</Headline>
      {renderTable && (
        <Table.OverflowAuto>
          <Table>
            <Table.Thead>
              <Table.Row>
                <Table.Header>Purpose</Table.Header>
                <Table.Header>Period Start</Table.Header>
                <Table.Header>Period End</Table.Header>
                <Table.Header>Next Charge</Table.Header>
                <Table.Header>Amount</Table.Header>
                <Table.Header>Status</Table.Header>
              </Table.Row>
            </Table.Thead>
            <Table.Tbody>
              {nextInvoices.map((invoice) => (
                <InvoicePreviewRow key={invoice.id} invoice={invoice} />
              ))}
              {invoices.map((invoice) => (
                <InvoiceRow key={invoice.id} invoice={invoice} />
              ))}
            </Table.Tbody>
          </Table>
        </Table.OverflowAuto>
      )}
    </Layout.Panel>
  );
};

interface InvoicePreviewRowProps {
  invoice: InvoiceModel;
}

const InvoicePreviewRow = (props: InvoicePreviewRowProps) => {
  const { getInvoiceUrl } = useContext(SubscriptionControllerContext);

  return (
    <>
      <Table.Row to={getInvoiceUrl({ id: 'next' })}>
        <Table.Cell>{invoicePurpose(props.invoice.purpose)}</Table.Cell>
        <Table.Cell>
          <RelativeDate date={props.invoice?.period_started_at ?? props.invoice?.created_at} />
        </Table.Cell>
        <Table.Cell>
          <RelativeDate date={props.invoice?.period_ended_at} />
        </Table.Cell>
        <Table.Cell>
          <RelativeDate date={props.invoice?.next_charge_at} />
        </Table.Cell>
        <Table.Cell>{AMOUNT_FORMATTER(props.invoice?.total)}</Table.Cell>
        <Table.Cell>Preview</Table.Cell>
      </Table.Row>
      <Table.Row />
    </>
  );
};

interface InvoiceRowProps {
  invoice: InvoiceModel;
}

const InvoiceRow = (props: InvoiceRowProps) => {
  const { useSelector, getInvoiceUrl } = useContext(SubscriptionControllerContext);
  const subscription = useSelector(selectSubscription);

  return (
    <Table.Row
      collapsed={true}
      platform={entityPlatform(subscription)}
      key={props.invoice.id}
      to={getInvoiceUrl(props.invoice)}
    >
      <Table.Cell>{invoicePurpose(props.invoice.purpose)}</Table.Cell>
      <Table.Cell>
        <RelativeDate date={props.invoice.period_started_at ?? props.invoice.created_at} />
      </Table.Cell>
      <Table.Cell>
        <RelativeDate date={props.invoice.period_ended_at} />
      </Table.Cell>
      <Table.Cell>
        <RelativeDate date={props.invoice.next_charge_at} />
      </Table.Cell>
      <Table.Cell>{AMOUNT_FORMATTER(props.invoice.total)}</Table.Cell>
      <Table.Cell>{invoiceStatus(props.invoice)}</Table.Cell>
    </Table.Row>
  );
};

const invoicePurpose = (purpose?: InvoicePurpose): string => {
  if (purpose === undefined) {
    return '';
  }

  return InvoicePurposeToName[purpose] ?? purpose;
};

const invoiceStatus = (invoice: InvoiceModel): string => {
  if (invoice.status_override !== undefined) {
    return BILLING_STATUS_TO_NAME[invoice.status_override];
  }

  if (invoice.status === undefined) {
    return 'Unknown Status';
  }

  return BILLING_STATUS_TO_NAME[invoice.status];
};

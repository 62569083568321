import { ACTION_LOAD_SESSION, ACTION_UNLOAD_SESSION, buildAction } from './helper';
import { apiCall } from '../lib/fetch';
import { PLATFORM_API_URL } from '../constant';

export function loadSession() {
  // dispatch is happening in the context of redux (thunk) and you can do async things here
  return async function (dispatch: Function, getState: Function) {
    const state = getState();

    // Cached and still valid?
    if (state.user.sessionExpiresAt !== undefined && state.user.sessionExpiresAt > Date.now()) {
      return state.user.sessionData;
    }

    let userData = apiCall('GET', PLATFORM_API_URL, '/me');
    try {
      userData = await userData;
    } catch (error) {
      // If we are not allowed to read the me route, unload the session.
      dispatch(unloadSession());
      throw error;
    }

    dispatch(buildAction(ACTION_LOAD_SESSION, userData));
    return userData;
  };
}

export function unloadSession() {
  return buildAction(ACTION_UNLOAD_SESSION);
}

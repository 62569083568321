import { createTheme as createThemeOld, createPalette as createPaletteOld } from '@foyyay/control-elements';
import { getEnv } from './lib/getenv';
import { Platform } from './lib/platform';
import { createPalette, createTheme } from './component/Theme';
import { deepFreeze } from './lib/deepFreeze';

export const GOOGLE_MAPS_API_KEY = getEnv('REACT_APP_GOOGLE_MAPS_API_KEY');
export const CONTROL_CENTER_URL = getEnv('REACT_APP_CONTROL_CENTER_URL');
export const NUCLEUS_DASHBOARD_URL = getEnv('REACT_APP_NUCLEUS_DASHBOARD_URL');
export const PLATFORM_API_URL = getEnv('REACT_APP_PLATFORM_API_URL');
export const BILLING_API_URL = getEnv('REACT_APP_BILLING_API_URL');
export const GIVING_API_URL = getEnv('REACT_APP_GIVING_API_URL');
export const SUPPORT_API_URL = getEnv('REACT_APP_SUPPORT_API_URL');
export const NUCLEUS_PLATFORM_API_URL = getEnv('REACT_APP_NUCLEUS_PLATFORM_API_URL');

export const SENTRY_ENVIRONMENT = getEnv('REACT_APP_SENTRY_ENVIRONMENT');
export const SENTRY_IO_DSN = getEnv('REACT_APP_SENTRY_IO_DSN');
export const BUILD_COMMIT_REF = getEnv('REACT_APP_BUILD_COMMIT_REF');

export const defaultThemeOld = createThemeOld(createPaletteOld('#4D1F08'));
export const defaultTheme = createTheme(createPalette('#4D1F08'));

export const USER_SESSION_TIMEOUT = 5 * 60 * 1000;

// Do not try to "fix" this regex until you've read and understand this page!!
// https://www.regular-expressions.info/email.html
export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const DATE_REGEX = /^([0-9]{2}\/){2}[0-9]{4}$/;

export const NO_OP = async () => {};

const AWS_REGION = getEnv('REACT_APP_AWS_REGION');
const AWS_USER_POOL_ID = getEnv('REACT_APP_AWS_USER_POOL_ID');
const AWS_USER_POOL_WEB_CLIENT_ID = getEnv('REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID');
//const AWS_COOKIE_DOMAIN = process.env['REACT_APP_AWS_COOKIE_DOMAIN'];

export const AWS_AMPLIFY_CONFIG = Object.freeze({
  // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
  // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

  // REQUIRED - Amazon Cognito Region
  region: AWS_REGION,

  // OPTIONAL - Amazon Cognito Federated Identity Pool Region
  // Required only if it's different from Amazon Cognito Region
  // identityPoolRegion: 'XX-XXXX-X',

  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: AWS_USER_POOL_ID,

  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: AWS_USER_POOL_WEB_CLIENT_ID,

  // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  // mandatorySignIn: false,

  // OPTIONAL - Configuration for cookie storage
  // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
  /*   cookieStorage: Object.freeze({
    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    domain: AWS_COOKIE_DOMAIN,
    // OPTIONAL - Cookie path
    // path: '/',
    // OPTIONAL - Cookie expiration in days
    expires: 365,
    // OPTIONAL - Cookie secure flag
    // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    secure: false,
  }), */

  // OPTIONAL - customized storage object
  // storage: new MyStorage(),

  // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
  // authenticationFlowType: 'USER_PASSWORD_AUTH',
});

export const REDIRECT_PARAM_KEY = 'redirect';

export const PLATFORM_REBELGIVE = 'rebelgive';
export const PLATFORM_NUCLEUS = 'nucleus';

// REBELPAY_API start
export const REBELPAY_API_BASE_URL = getEnv('REACT_APP_REBELPAY_API_BASE_URL');
export const REBELPAY_MERCHANT_ID = getEnv('REACT_APP_REBELPAY_MERCHANT_ID');
export const REBELPAY_API_KEY_PUBLIC = getEnv('REACT_APP_REBELPAY_API_KEY_PUBLIC');

export const CURRENCY_US_DOLLAR = 'USD';
export const CURRENCY_CANADIAN_DOLLAR = 'CAD';
export const CURRENCIES = [CURRENCY_US_DOLLAR, CURRENCY_CANADIAN_DOLLAR];

export const TOKEN_EXPIRATION_BUFFER = 15000;
export const TOKEN_CREATION_KEY_ACH = 'bank_account_ach';
export const TOKEN_CREATION_KEY_EFT = 'bank_account_eft'; // NOT SUPPORTED YET.
export const TOKEN_CREATION_KEY_CARD = 'card';
export const TOKEN_CREATION_KEY_PLAID = 'plaid_token';

export const SOURCE_TYPE_ACH_DEBIT = 'ach_debit';
export const SOURCE_TYPE_CARD = 'card';
export const SOURCE_TYPE_CASH = 'cash';
export const SOURCE_TYPE_CHECK = 'check';
export const SOURCE_TYPES = [SOURCE_TYPE_ACH_DEBIT, SOURCE_TYPE_CARD, SOURCE_TYPE_CASH, SOURCE_TYPE_CHECK];
export const SOURCE_TYPE_OTHER = 'other';

export const BANK_NUMBER_FORMAT_ACH = 'ACH';
export const BANK_NUMBER_FORMAT_EFT = 'EFT';
export const BANK_NUMBER_FORMATS = [BANK_NUMBER_FORMAT_ACH, BANK_NUMBER_FORMAT_EFT];

export const BANK_ACCOUNT_TYPE_BUSINESS = 'BIZ';
export const BANK_ACCOUNT_TYPE_CHECKING = 'GL';
export const BANK_ACCOUNT_TYPE_SAVINGS = 'SAVINGS';
export const BANK_ACCOUNT_TYPES = [BANK_ACCOUNT_TYPE_BUSINESS, BANK_ACCOUNT_TYPE_CHECKING, BANK_ACCOUNT_TYPE_SAVINGS];
// REBELPAY_API end

export const PAYMENT_METHOD_ACH = 'ACH';
export const PAYMENT_METHOD_CC = 'CC';
export const PAYMENT_METHODS = [PAYMENT_METHOD_ACH, PAYMENT_METHOD_CC];

// GIVING_API start
export const FILTER_DATE_GIVEN = 'date_given';
export const FILTER_DATE_PAIDOUT = 'date_paidout';
export const FILTER_DATE_DEPOSITED = 'date_deposited'; // DEPRECATED
export const FILTER_DATES = [FILTER_DATE_GIVEN, FILTER_DATE_PAIDOUT, FILTER_DATE_DEPOSITED];

export const FILTER_FREQUENCY_ONE_TIME = 'one_time';
export const FILTER_FREQUENCY_RECURRING = 'recurring';
export const FILTER_FREQUENCIES = [FILTER_FREQUENCY_ONE_TIME, FILTER_FREQUENCY_RECURRING];
export const FILTER_SORT_DIRECTION_DESCENDING = 'Desc';
export const FILTER_SORT_DIRECTION_ASCENDING = 'Asc';
export const FILTER_SORT_DIRECTIONS = [FILTER_SORT_DIRECTION_DESCENDING, FILTER_SORT_DIRECTION_ASCENDING];

export const FILTER_SCHEDULE_STATUS_ACTIVE = 'active';
export const FILTER_SCHEDULE_STATUS_INACTIVE = 'inactive';

export const FILTER_STATUS_PENDING = 'pending';
export const FILTER_STATUS_SUCCEEDED = 'succeeded';
export const FILTER_STATUS_PAIDOUT = 'paidout';
export const FILTER_STATUS_REFUNDED = 'refunded';
export const FILTER_STATUS_FAILED = 'failed';
export const FILTER_STATUS_DEPOSITED = 'deposited'; // DEPRECATED
export const FILTER_STATUSES = [
  FILTER_STATUS_PENDING,
  FILTER_STATUS_SUCCEEDED,
  FILTER_STATUS_PAIDOUT,
  FILTER_STATUS_REFUNDED,
  FILTER_STATUS_FAILED,
  FILTER_STATUS_DEPOSITED,
];

export const COLUMNS_REPORT_DEFAULT = 'default-columns';
export const COLUMNS_REPORT_CHOICE = 'choice-columns';
export const COLUMNS_REPORT_OPTIONS = [COLUMNS_REPORT_DEFAULT, COLUMNS_REPORT_CHOICE];

export const SOURCE_TYPE_NAME_MAP = {
  [SOURCE_TYPE_ACH_DEBIT]: 'ACH',
  [SOURCE_TYPE_CARD]: 'Credit Card',
};

export const INTERVAL_WEEK = 'INTERVAL_WEEK';
export const INTERVAL_MONTH = 'INTERVAL_MONTH';
export const INTERVAL_YEAR = 'INTERVAL_YEAR';
export const INTERVAL_DATES = 'INTERVAL_DATES';
export const INTERVALS = [INTERVAL_WEEK, INTERVAL_MONTH, INTERVAL_YEAR];

export const VOID_CODE_ACCOUNT_CLOSED = 'account_closed';
export const VOID_CODE_ACCOUNT_INVALID = 'account_invalid';
export const VOID_CODE_ACCOUNT_NOT_FOUND = 'no_account';
export const VOID_CODE_CUSTOMER_ADVISES_NOT_AUTHORIZED = 'customer_advises_not_authorized';
// Customer Advises Not Authorized
//   What is the official NACHA return code description?
//   Customer Advises Not Authorized; Item Is Ineligible, Notice Not Provided, Signatures Not Genuine, or Item Altered
//   In what circumstance should the return code be used?
//   Member never authorized payment.
//   Amount of transaction is different than what was authorized.
//   Transaction was initiated earlier than what had been agreed upon.
//   At the time of the return request, would the item have been debited/paid from the member’s account? Yes
//   From the settlement date, how long does the member have to request that the item be returned? 60 days
//   What form is required? Written Statement of Unauthorized Debit
export const VOID_CODE_DUPLICATE_ENTRY = 'duplicate_entry';
export const VOID_CODE_INSUFFICIENT_FUNDS = 'insufficient_funds';
export const VOID_CODE_NON_TRANSACTION_ACCOUNT = 'non_transaction_account';
export const VOID_CODE_PAYMENT_STOPPED = 'payment_stopped';
// Payment Stopped
//   What is the official NACHA return code description?
//   Payment Stopped or Stop Payment on Item
//   In what circumstance should the return code be used?
//   Member is trying to prevent debit from occurring.
//   At the time of the return request, would the item have been debited/paid from the member’s account? No
//   From the settlement date, how long does the member have to request that the item be returned? 2 days
//   What form is required? Stop payment form

export const VOID_CODES = Object.freeze([
  VOID_CODE_ACCOUNT_CLOSED,
  VOID_CODE_ACCOUNT_INVALID,
  VOID_CODE_ACCOUNT_NOT_FOUND,
  VOID_CODE_CUSTOMER_ADVISES_NOT_AUTHORIZED,
  VOID_CODE_DUPLICATE_ENTRY,
  VOID_CODE_INSUFFICIENT_FUNDS,
  VOID_CODE_NON_TRANSACTION_ACCOUNT,
  VOID_CODE_PAYMENT_STOPPED,
]);

// GIVING_API end

export const VOID_DESCRIPTION_ACCOUNT_CLOSED = 'Account Closed';
export const VOID_DESCRIPTION_ACCOUNT_INVALID = 'Invalid Account';
export const VOID_DESCRIPTION_ACCOUNT_NOT_FOUND = 'Could not find account or name on account is wrong';
export const VOID_DESCRIPTION_CUSTOMER_ADVISES_NOT_AUTHORIZED = 'Account holder advises not authorized';
export const VOID_DESCRIPTION_DUPLICATE_ENTRY = 'Duplicate Entry';
export const VOID_DESCRIPTION_INSUFFICIENT_FUNDS = 'Insufficient funds to complete transactions';
export const VOID_DESCRIPTION_NON_TRANSACTION_ACCOUNT = 'Non-Transaction Account';
export const VOID_DESCRIPTION_PAYMENT_STOPPED = 'Account holder is trying to prevent debit from occurring';

export const VOID_DESCRIPTIONS = Object.freeze([
  VOID_DESCRIPTION_ACCOUNT_CLOSED,
  VOID_DESCRIPTION_ACCOUNT_INVALID,
  VOID_DESCRIPTION_ACCOUNT_NOT_FOUND,
  VOID_DESCRIPTION_CUSTOMER_ADVISES_NOT_AUTHORIZED,
  VOID_DESCRIPTION_DUPLICATE_ENTRY,
  VOID_DESCRIPTION_INSUFFICIENT_FUNDS,
  VOID_DESCRIPTION_NON_TRANSACTION_ACCOUNT,
  VOID_DESCRIPTION_PAYMENT_STOPPED,
]);

export const VOID_CODE_TO_DESCRIPTION_MAP = Object.freeze({
  [VOID_CODE_ACCOUNT_CLOSED]: VOID_DESCRIPTION_ACCOUNT_CLOSED,
  [VOID_CODE_ACCOUNT_INVALID]: VOID_DESCRIPTION_ACCOUNT_INVALID,
  [VOID_CODE_ACCOUNT_NOT_FOUND]: VOID_DESCRIPTION_ACCOUNT_NOT_FOUND,
  [VOID_CODE_CUSTOMER_ADVISES_NOT_AUTHORIZED]: VOID_DESCRIPTION_CUSTOMER_ADVISES_NOT_AUTHORIZED,
  [VOID_CODE_DUPLICATE_ENTRY]: VOID_DESCRIPTION_DUPLICATE_ENTRY,
  [VOID_CODE_INSUFFICIENT_FUNDS]: VOID_DESCRIPTION_INSUFFICIENT_FUNDS,
  [VOID_CODE_NON_TRANSACTION_ACCOUNT]: VOID_DESCRIPTION_NON_TRANSACTION_ACCOUNT,
  [VOID_CODE_PAYMENT_STOPPED]: VOID_DESCRIPTION_PAYMENT_STOPPED,
});

export const ROLE = Object.freeze({
  // - Customer Support: Full and complete access and control
  CustomerSupport: 'platform:role:customer_support',
  // - Account Owner: Full and complete access and control
  Owner: 'platform:role:owner',
  // - Administrator: Access to everything
  Administrator: 'platform:role:administrator',
  // - Pastoral Admin: Full admin, but personal giver data hidden
  PastoralAdministrator: 'platform:role:pastoral_administrator',
  // - Financial Assistant: Access to most things (except church settings)
  FinancialAssistant: 'platform:role:financial_assistant',
  // - Reporting: Read-only access (and no church settings)
  Reporting: 'platform:role:reporting',
});

export const GOOGLE_MAPS_SCRIPT_ID = 'google-maps-script-loader';

export const KEY_PREFIX_PERSON = 'person';
export const KEY_PREFIX_CHURCHPERSON = 'churchperson';
export const KEY_PREFIX_CHURCH = 'church';

export const TRANSACTION_METHOD_FLOW = 'flow';
export const TRANSACTION_METHOD_MANUAL = 'manual';
export const TRANSACTION_METHOD_SCHEDULE = 'schedule';

export const TRANSACTION_METHODS = Object.freeze([
  TRANSACTION_METHOD_FLOW,
  TRANSACTION_METHOD_MANUAL,
  TRANSACTION_METHOD_SCHEDULE,
]);

export const DAY_OF_THE_WEEK_TO_NAME_MAP = Object.freeze([
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]);

export const BILLING_INTERVAL_TO_NAME = Object.freeze({
  INTERVAL_MONTH: 'Monthly',
  INTERVAL_YEAR: 'Annual',
});

export const SOURCE_TYPE_TO_NAME = Object.freeze({
  card: 'Credit Card',
  ach_debit: 'Bank Account',
});

export enum BillingStatus {
  Active = 'STATUS_ACTIVE',
  Canceled = 'STATUS_CANCELED',
  Paid = 'STATUS_PAID',
  PastDue = 'STATUS_PASTDUE',
  Unpaid = 'STATUS_UNPAID',
}

export const BILLING_STATUS_TO_NAME = Object.freeze({
  [BillingStatus.Active]: 'Active',
  [BillingStatus.Canceled]: 'Canceled',
  [BillingStatus.Paid]: 'Paid',
  [BillingStatus.PastDue]: 'Past Due',
  [BillingStatus.Unpaid]: 'Unpaid',
});

export enum BillingItemType {
  Credit = 'ITEM_TYPE_CREDIT',
  Debit = 'ITEM_TYPE_DEBIT',
  Description = 'ITEM_TYPE_DESCRIPTION',
  Discount = 'ITEM_TYPE_DISCOUNT',
  Product = 'ITEM_TYPE_PRODUCT',
}

export enum BillingInterval {
  Dates = 'INTERVAL_DATES',
  Day = 'INTERVAL_DAY',
  Month = 'INTERVAL_MONTH',
  Week = 'INTERVAL_WEEK',
  Year = 'INTERVAL_YEAR',
}

export const BillingIntervalLabelMap: Record<BillingInterval.Month | BillingInterval.Year, string> = {
  [BillingInterval.Month]: 'Month',
  [BillingInterval.Year]: 'Year',
};

export const BILLING_INTERVAL_TO_MONTH_COUNT = deepFreeze({
  [BillingInterval.Month]: 1,
  [BillingInterval.Year]: 12,
});

export type ApplicationFeatures = {
  [feature: string]: bigint | boolean | number | string | Record<string, unknown> | undefined;
};

export interface ProductApplicationGroup {
  group: string;
  features?: ApplicationFeatures;
  featuresActive?: ApplicationFeatures;
  featuresPaid?: ApplicationFeatures;
  featuresPastDue?: ApplicationFeatures;
}

export type ProductModel = {
  code: string;
  active: boolean;
  description: string;
  monthly_cost: number;
  interval_cost: {
    [BillingInterval.Month]: number;
    [BillingInterval.Year]: number;
  };
  public: boolean;
  name: string;

  readonly created_at: Date;
  readonly updated_at: Date;
} & (
  | ProductApplicationGroup
  | {
      applications: Array<ProductApplicationGroup>;
    }
);

export type BillingItemCredit = BillingItemBase<
  BillingItemType.Credit,
  {
    amount: number;
    description: string;
  }
> &
  PerpetualItem;

export type BillingItemDebit = BillingItemBase<
  BillingItemType.Debit,
  {
    amount: number;
    description: string;
  }
> &
  PerpetualItem;

export type BillingItemDescription = BillingItemBase<
  BillingItemType.Description,
  {
    applies_to_items?: Array<string>;
    description: string;
  }
> &
  PerpetualItem;

export type BillingItemDiscount = BillingItemBase<
  BillingItemType.Discount,
  {
    applies_to_items?: Array<string>;
    description: string;
  } & (
    | {
        amount: number;
        percent?: never;
      }
    | {
        amount?: never;
        percent: number;
      }
  )
>;

export type BillingItemProduct = BillingItemBase<
  BillingItemType.Product,
  {
    description: string;
    monthly_cost: number;
    interval_cost: number;
    product_code: string;
    product_name: string;
  } & (
    | {
        product_group: string;
      }
    | {
        applicationGroups?: Array<string>;
      }
  )
>;

interface BillingItemBase<T extends BillingItemType, D> {
  subscription_id: string;
  id: string;
  started_at: Date;
  ended_at?: Date;

  type: T;
  data: D;

  created_at?: Date;
  updated_at?: Date;
}

interface PerpetualItem {
  ended_at?: never;
}

interface ItemDocument {
  readonly created_at: Date;
  readonly updated_at: Date;
}

export type BillingItem =
  | BillingItemCredit
  | BillingItemDebit
  | BillingItemDescription
  | BillingItemDiscount
  | BillingItemProduct;

export type BillingItemModel = ItemDocument & BillingItem;

export interface InvoiceItem {
  description: string;
  cost: number;
  item?: BillingItemProduct | BillingItemDiscount;
}

export enum InvoicePurpose {
  Subscription = 'InvoicePurposeSubscription',
  Manual = 'InvoicePurposeManual',
}

export const InvoicePurposeToName = Object.freeze({
  [InvoicePurpose.Manual]: 'Manual',
  [InvoicePurpose.Subscription]: 'Subscription',
});

export type InvoiceModel = {
  subscription_id: string;
  id: string;
  items: Array<InvoiceItem>;
  total: number;
  next_charge_at?: Date;
  status_override?: BillingStatus;
  purpose?: InvoicePurpose;

  period_started_at: Date;
  period_ended_at: Date;

  readonly status?: BillingStatus;

  readonly created_at: Date;
  readonly updated_at: Date;
};

export type PaymentModel = {
  subscription_id: string;
  id: string;
  invoice_id: string;
  rebelpay_charge_id: string;
  rebelpay_charge_message: string;

  total: number;
  status: BillingStatus;

  source_type: string;
  source_institution_name: string;
  source_account_name: string;
  source_last_few: string;
  source_expire_month: string;
  source_expire_year: string;

  refund_of: string;

  readonly created_at: Date;
  readonly updated_at: Date;
};

export type SubscriptionModel = {
  church_id: string;
  id: string;

  started_at: Date;
  ended_at?: Date;
  interval: BillingInterval.Year | BillingInterval.Month;
  next_invoice_at?: Date;
  next_settlement_at?: Date;
  trial_ended_at?: Date;
  status_override?: BillingStatus;

  rebelpay_source_id?: string;
  source_type?: string;
  source_institution_name?: string;
  source_account_name?: string;
  source_last_few?: string;
  source_expire_month?: string;
  source_expire_year?: string;

  marked_expired?: Date;
  marked_expiring?: Date;

  platform?: Platform;

  nucleus_one_stripe_subscription_id?: string;
  nucleus_one_linked?: boolean;

  readonly created_at: Date;
  readonly updated_at: Date;
};

export const NUCLEUS_ONE_PRODUCT_CODES = Object.freeze([
  'webclassic',
  'sermonsclassic',
  'mediaclassic',
  'socialclassic',
  'customizationsclassic',
  'autopostingclassic',
]);

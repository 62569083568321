import React from 'react';
import { Route, Routes } from 'react-router-dom';
// import { CreateManaged } from './Create';
import { EditManaged } from './Edit';
import { ListManaged } from './List';

export const ManagedRoutes = () => {
  return (
    <Routes>
      <Route path={'/new'} element={<EditManaged />} />
      <Route path={'/edit/:policyName'} element={<EditManaged />} />
      <Route path="/" element={<ListManaged />} />
    </Routes>
  );
};

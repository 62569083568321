import _keyBy from 'lodash/keyBy';
import _pick from 'lodash/pick';
import _reverse from 'lodash/reverse';
import _sortBy from 'lodash/sortBy';
import _uniqBy from 'lodash/uniqBy';
import {
  Action,
  ACTION_CREATE_IDENTITY_POLICY,
  ACTION_CREATE_MANAGED_POLICY,
  ACTION_CREATE_RESOURCE_POLICY,
  ACTION_DESTROY_IDENTITY_POLICY,
  ACTION_DESTROY_MANAGED_POLICY,
  ACTION_DESTROY_RESOURCE_POLICY,
  ACTION_LOAD_IDENTITY_POLICIES,
  ACTION_LOAD_IDENTITY_POLICY,
  ACTION_LOAD_MANAGED_POLICIES,
  ACTION_LOAD_MANAGED_POLICY,
  ACTION_LOAD_RESOURCE_POLICIES,
  ACTION_LOAD_RESOURCE_POLICY,
  ACTION_UPDATE_IDENTITY_POLICY,
  ACTION_UPDATE_MANAGED_POLICY,
  ACTION_UPDATE_RESOURCE_POLICY,
  frozenArray,
} from '../action/helper';

const initialState = Object.freeze({
  identity: Object.freeze({
    policies: frozenArray,
    cursor: undefined,
  }),
  managed: Object.freeze({
    policies: frozenArray,
    cursor: undefined,
  }),
  resource: Object.freeze({
    policies: frozenArray,
    cursor: undefined,
  }),
});

export const selectIdentityCanLoadMore = (state) => state.policy.identity.cursor !== undefined;
export const selectIdentityCursor = (state) => state.policy.identity.cursor;
export const selectIdentityPolicies = (state) => state.policy.identity.policies;

export const selectManagedCanLoadMore = (state) => state.policy.managed.cursor !== undefined;
export const selectManagedCursor = (state) => state.policy.managed.cursor;
export const selectManagedPolicies = (state) => state.policy.managed.policies;

export const selectResourceCanLoadMore = (state) => state.policy.resource.cursor !== undefined;
export const selectResourceCursor = (state) => state.policy.resource.cursor;
export const selectResourcePolicies = (state) => state.policy.resource.policies;

export const reducer = function (state = initialState, action: Action) {
  switch (action.type) {
    case ACTION_CREATE_IDENTITY_POLICY:
      return {
        ...state,
        identity: organizeIdentityPolicies(state.identity, [action.payload.policy]),
      };
    case ACTION_DESTROY_IDENTITY_POLICY:
      return {
        ...state,
        identity: {
          ...state.identity,
          policies: state.identity.policies.filter(
            (policy: any) =>
              policy.principle !== action.payload.principle ||
              policy.resource !== action.payload.resource
          ),
        },
      };
    case ACTION_LOAD_IDENTITY_POLICIES:
      return {
        ...state,
        identity: {
          ...organizeIdentityPolicies(state.identity, action.payload.policies),
          ..._pick(action.payload, 'cursor'), // This will only pull cursor from payload if it exists.
        },
      };
    case ACTION_LOAD_IDENTITY_POLICY:
    case ACTION_UPDATE_IDENTITY_POLICY:
      return {
        ...state,
        identity: organizeIdentityPolicies(state.identity, [action.payload.policy]),
      };
    case ACTION_CREATE_MANAGED_POLICY:
      return {
        ...state,
        managed: {
          ...state.managed,
          policies: organizeManagedPolicies([action.payload.policy, ...state.managed.policies]),
        },
      };
    case ACTION_DESTROY_MANAGED_POLICY:
      return {
        ...state,
        managed: {
          ...state.managed,
          policies: state.managed.policies.filter(
            (policy: any) => policy.name !== action.payload.policyName
          ),
        },
      };
    case ACTION_LOAD_MANAGED_POLICIES:
      return {
        ...state,
        managed: {
          ...state.managed,

          policies: organizeManagedPolicies([
            ...action.payload.policies,
            ...state.managed.policies,
          ]),
          cursor: action.payload.cursor,
        },
      };
    case ACTION_LOAD_MANAGED_POLICY:
    case ACTION_UPDATE_MANAGED_POLICY:
      return {
        ...state,
        managed: {
          ...state.managed,
          policies: organizeManagedPolicies([action.payload.policy, ...state.managed.policies]),
          cursor: action.payload.cursor,
        },
      };
    case ACTION_CREATE_RESOURCE_POLICY:
      return {
        ...state,
        resource: {
          ...state.resource,
          policies: organizeResourcePolicies([action.payload.policy, ...state.resource.policies]),
        },
      };
    case ACTION_DESTROY_RESOURCE_POLICY:
      return {
        ...state,
        resource: {
          ...state.resource,
          policies: state.resource.policies.filter(
            (policy: any) => policy.resource !== action.payload.resourceNrn
          ),
        },
      };
    case ACTION_LOAD_RESOURCE_POLICIES:
      return {
        ...state,
        resource: {
          ...state.resource,
          policies: organizeResourcePolicies([
            ...action.payload.policies,
            ...state.resource.policies,
          ]),
          cursor: action.payload.cursor,
        },
      };
    case ACTION_LOAD_RESOURCE_POLICY:
    case ACTION_UPDATE_RESOURCE_POLICY:
      return {
        ...state,
        resource: {
          ...state.resource,
          policies: organizeResourcePolicies([action.payload.policy, ...state.resource.policies]),
        },
      };
    default:
      return state;
  }
};

function organizeIdentityPolicies(
  stateIdentity: typeof initialState.identity,
  policyList: Array<any>
): typeof initialState.identity {
  const policyKey = (policy: any) => `${policy.principle}${policy.resource}`;

  const keyedNewPolicies = _keyBy(policyList, policyKey);
  const updateExisting = stateIdentity.policies.map((policy: any) => {
    const key = policyKey(policy);
    const returnValue = keyedNewPolicies[key] ?? policy;
    keyedNewPolicies[key] = undefined;
    return returnValue;
  });

  const newPolicies = Object.values(keyedNewPolicies).filter((policy) => policy !== undefined);

  return {
    ...stateIdentity,
    policies: [...updateExisting, ...newPolicies],
  };
}

function organizeManagedPolicies(policyList: Array<any>) {
  return _sortBy(_uniqBy(policyList, 'name'), 'name');
}

function organizeResourcePolicies(policyList: Array<any>) {
  return _reverse(_sortBy(_uniqBy(policyList, 'resource'), 'updatedAt'));
}

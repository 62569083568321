import _isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Link } from 'react-router-dom';
import { isBoolean, isObject } from 'util';
import { WebsiteLink } from '../component/WebsiteLink';
import { AMOUNT_FORMATTER, DATE_FULL_FORMATTER, PHONE_FORMATTER } from './formatting';
import { prefixOfKey } from './key';
/**
 * The purpose of this file is to take in a value
 * and smartly determine what the output should look
 * like. Perhaps its a name that we return as a string;
 * perhaps its like an Id that we return as a link, etc.
 *
 **/

const GOOGLE_MAPS_PLACE_URL = 'https://www.google.com/maps/place/';
const EARLIEST_AUTO_DATE_VALUE = Date.parse('2015-01-01T00:00:00.000Z');

export const BLUEPAY_URL = (account_id) => {
  return `https://secure.bluepay.com/account/accountadmin?AID=${account_id}&IID=${account_id}`;
};

export const COPILOT_URL = (processorId) => {
  return `https://copilot.cardconnect.com/copilot/#/merchant/${processorId}/summary`;
};

export const ADDRESS_FORMATTER = (value) => {
  return <Address value={value} />;
};

export const BOOLEAN_FORMATTER = (value) => {
  if (value === true) {
    return 'true';
  }
  if (value === false) {
    return 'false';
  }
  return '';
};

export const BLUEPAY_FORMATTER = (value) => {
  const bluepayUrl = BLUEPAY_URL(value);
  return <WebsiteLink href={bluepayUrl} value={value} />;
};

export const COPILOT_FORMATTER = (value) => {
  const copilotUrl = COPILOT_URL(value);
  return <WebsiteLink href={copilotUrl} value={value} />;
};

export const DISPLAY_ADDRESS_FORMATTER = (value) => {
  if (typeof value === 'string') {
    return ADDRESS_FORMATTER(value);
  }

  if (value?.street_address !== undefined) {
    return (
      <>
        {value.street_address}
        <br />
        {value.city}, {value.state} {value.postal_code}
        <br />
        {value.country}
      </>
    );
  }
};

export const DISPLAY_DATE_FORMATTER = (value) => {
  const valueDate = new Date(value);
  const myDate = valueDate.toLocaleDateString();
  const myTime = valueDate.toLocaleTimeString();
  return <span title={value}>{`${myDate}  ${myTime}`}</span>;
};

export const EMAIL_FORMATTER = (value) => {
  return <Email value={value} />;
};

export const TEL_FORMATTER = (value) => {
  return <Phone value={PHONE_FORMATTER(value)} />;
};

export const LINK_FORMATTER = (value) => {
  return <LinkPrefixedId value={value} />;
};

export const CHURCH_MERCHANT_LINK_FORMATTER = (value) => {
  return (
    <Link style={{ textDecoration: 'underline' }} to={`/church/${value}/merchant`}>
      {value}
    </Link>
  );
};

export const WEBSITE_FORMATTER = (value) => {
  return <WebsiteLink href={value} value={value} />;
};

export const FORMAT_HINTS = Object.freeze({
  ADDRESS: 'ADDRESS',
  AMOUNT: 'AMOUNT',
  BLUEPAY: 'BLUEPAY',
  BOOLEAN: 'BOOLEAN',
  CHURCH_MERCHANT: 'CHURCH_MERCHANT',
  COPILOT: 'COPILOT',
  DATE_FULL: 'DATE_FULL',
  EMAIL: 'EMAIL',
  LINK: 'LINK',
  NONE: 'NONE',
  PHONE: 'PHONE',
  TEL: 'TEL',
  WEBSITE: 'WEBSITE',
});

export const FORMATTER_MAP = Object.freeze({
  ADDRESS: ADDRESS_FORMATTER,
  AMOUNT: AMOUNT_FORMATTER,
  BLUEPAY: BLUEPAY_FORMATTER,
  BOOLEAN: BOOLEAN_FORMATTER,
  CHURCH_MERCHANT: CHURCH_MERCHANT_LINK_FORMATTER,
  COPILOT: COPILOT_FORMATTER,
  DATE_FULL: DATE_FULL_FORMATTER,
  EMAIL: EMAIL_FORMATTER,
  LINK: LINK_FORMATTER,
  NONE: (value) => value,
  PHONE: PHONE_FORMATTER,
  TEL: TEL_FORMATTER,
  WEBSITE: WEBSITE_FORMATTER,
});

/**
 * Check an attribute name and value against
 * type hint or determine formatting based on if it
 * is an amount, date, email, id or phone number.
 *
 */
export const objectGridAttributeLibrary = (name, value, hint) => {
  if (React.isValidElement(value)) {
    return value;
  }

  if (value === undefined) {
    return '';
  }

  if (hint !== undefined) {
    return FORMATTER_MAP[hint](value);
  }

  if (isBoolean(value)) {
    return BOOLEAN_FORMATTER(value);
  }

  if (isObject(value)) {
    return JSON.stringify(value, null, 2);
  }

  if (_isEmpty(String(value).trim())) {
    return value;
  }

  // Dates: include time when formatted here. Use FORMAT.DATE for shorter date
  if (value >= EARLIEST_AUTO_DATE_VALUE) {
    return DATE_FULL_FORMATTER(value);
  }

  // Emails: attribute name contains email
  if (name.includes('email')) {
    return EMAIL_FORMATTER(value);
  }

  // Addresses: attribute name contains mailing
  if (name.includes('mailing')) {
    return ADDRESS_FORMATTER(value);
  }

  // Website: attribute name contains website
  if (name.includes('website')) {
    return WEBSITE_FORMATTER(value);
  }

  // Ids: attribute isPrefixedKey and name contains 'id'
  if (name.includes('id') && prefixOfKey(value) !== undefined) {
    return LINK_FORMATTER(value);
  }

  // Phones: by default, treat phone as tel
  if (name.includes('phone')) {
    return TEL_FORMATTER(value);
  }

  return value;
};

export const Address = (props) => {
  return (
    <a href={GOOGLE_MAPS_PLACE_URL + props.value.replace(' ', '+')} rel="noopener noreferrer" target="_blank">
      {props.value}
    </a>
  );
};

export const Email = (props) => {
  return (
    <a style={{ textDecoration: 'underline' }} href={'mailto:' + props.value}>
      {props.value}
    </a>
  );
};

export const LinkPrefixedId = (props) => {
  let internalLink = props.value;
  const prefix = prefixOfKey(props.value);

  switch (prefix) {
    case 'church':
      internalLink = `/church/${props.value}`;
      break;
    case 'person':
      internalLink = `/person/${props.value}`;
      break;
    /* case 'payment':
      internalLink = `payment/${props.value}`;
      break; */
    // etc, etc
    default:
      internalLink = undefined;
  }
  if (internalLink === undefined) {
    return props.value;
  }
  return (
    <Link style={{ textDecoration: 'underline' }} to={internalLink}>
      {props.value}
    </Link>
  );
};

export const Phone = (props) => {
  return (
    <a style={{ textDecoration: 'underline' }} href={'tel:' + props.value}>
      {props.value}
    </a>
  );
};

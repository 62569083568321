import { ButtonSecondary, Label } from '@foyyay/control-elements';
import React, { useEffect, useState } from 'react';
import { Link, useResolvedPath } from 'react-router-dom';
import { destroyIdentityPolicy, listIdentityPolicies } from '../../../../action/policy';
import { Layout } from '../../../../component/Layout';
import { ObjectsTable } from '../../../../component/ObjectsTable';
import { PanelButton } from '../../../../component/PanelButton';
import { Loading } from '../../../../component/Progress/Loading';
import { useAppDispatch } from '../../../../hooks/hooks';
import { useIdentityPolicies } from '../../../../hooks/policy';
import { FORMAT_HINTS } from '../../../../lib/objectGridAttributeLibrary';

function ListIdentityComponent() {
  const url = useResolvedPath('').pathname;

  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [policies, canLoadMore, cursor] = useIdentityPolicies();

  const loadPolicies = async () => {
    setLoading(true);
    setError(undefined);
    try {
      await dispatch(listIdentityPolicies(cursor));
    } catch (err: any) {
      setError(err.body ?? err.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadPolicies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showError = error !== undefined;

  const policyData = policies
    .map((policy) => {
      const principleName = policy.principle.split(':').slice(-1)[0];
      const resourceName = policy.resource.split(':').slice(-1)[0];
      const title = `${principleName} -> ${resourceName}`;
      policy = {
        ...policy,
        keyString: title,
        principleLink: (
          <Link title={title} to={`${url}/edit/${policy.principle}/${policy.resource}`}>
            {principleName}
          </Link>
        ),
        resourceLink: (
          <Link title={title} to={`${url}/edit/${policy.principle}/${policy.resource}`}>
            {resourceName}
          </Link>
        ),
        action: <DeleteButton policy={policy} />,
      };

      return policy;
    })
    .filter((policy) => policy !== undefined);

  const columns = ['principleLink', 'resourceLink', 'updatedAt', 'action'];

  return (
    <Layout.Panel>
      <Link to={`${url}/new`}>
        <ButtonSecondary>Add New Policy</ButtonSecondary>
      </Link>
      <ObjectsTable
        data={policyData}
        keys={columns}
        hints={{
          principleLink: FORMAT_HINTS.NONE,
          resourceLink: FORMAT_HINTS.NONE,
          action: FORMAT_HINTS.NONE,
        }}
        firstAttributes={columns}
        rowKey="keyString"
      />
      {canLoadMore && <PanelButton onClick={() => loadPolicies()}>Load More</PanelButton>}
      {loading && <Loading />}
      {showError && <Label error={showError}>{error}</Label>}
    </Layout.Panel>
  );
}

export const ListIdentity = ListIdentityComponent;

function DeleteButton({ policy }) {
  const dispatch = useAppDispatch();
  const [deleting, setDeleting] = useState(false);

  const doDeletePolicy = async () => {
    await dispatch(destroyIdentityPolicy(policy.principle, policy.resource));
  };

  const handleDeletePolicy = async () => {
    setDeleting(true);
    try {
      await doDeletePolicy();
    } catch (error) {
      console.error(error);
      setDeleting(false);
    }
  };

  const confirmDeletePolicy = () => {
    const doIt = window.confirm('Are you sure? This cannot be undone!');
    if (doIt !== true) {
      return;
    }
    handleDeletePolicy();
  };

  return (
    <ButtonSecondary onClick={confirmDeletePolicy} loading={deleting}>
      Delete
    </ButtonSecondary>
  );
}

import { Container, H1 } from '@foyyay/control-elements';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Header } from './Header';
import { Layout } from './Layout';
import { PageProps } from './Page';
import { L2 } from './Typography';

export interface LayoutPrimaryProps extends PageProps {
  layoutTitle?: string;
  layoutByline?: string;
}

export const LayoutPrimary: React.FC<PropsWithChildren<LayoutPrimaryProps>> = (props) => {
  return (
    <Layout.Page>
      <Header />
      <Main>
        <Container style={{ maxWidth: '95%' }}>
          <Layout.Group>
            <L2 style={{ opacity: 0.5, paddingLeft: '0.5rem' }}>{props.layoutByline}</L2>
            <H1>{props.layoutTitle}</H1>
          </Layout.Group>
          <Layout.Group>{props.children}</Layout.Group>
        </Container>
      </Main>
      <Footer />
    </Layout.Page>
  );
};

const Main = styled.main`
  margin: 0;
  padding: 0;
`;

const Footer = styled.footer`
  width: 100%;
  height: 20rem;
`;

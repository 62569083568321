import React from 'react';
import { Roles } from '../components/Roles';
import { Policies } from '../components/Policies';
import { ChurchConnections } from '../components/ChurchConnections';

export const Churches = () => {
  return (
    <>
      <Policies />
      <Roles />
      <ChurchConnections />
    </>
  );
};

import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { useChurch } from '../../../../hooks/useChurch';
import { Platform, entityPlatform } from '../../../../lib/platform';
import { SubscriptionList } from './components/SubscriptionList';
import { SubscriptionRoutes } from './Subscription/routes';

export const SubscriptionsRoutes = () => {
  const params = useParams();
  const { data: church } = useChurch(params.churchId);

  let platform: Platform | undefined;
  if (church !== undefined) {
    platform = entityPlatform(church);
  }

  return (
    <Routes>
      <Route path={'/:subscriptionId/*'} element={<SubscriptionRoutes />} />
      <Route path={'/'} element={<SubscriptionList church={church} platform={platform} />} />
    </Routes>
  );
};

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { CreateResource } from './Create';
import { EditResource } from './Edit';
import { ListResource } from './List';

export const ResourceRoutes = () => {
  return (
    <Routes>
      <Route path={'/new'} element={<CreateResource />} />
      <Route path={'/edit/:resource'} element={<EditResource />} />
      <Route path="/" element={<ListResource />} />
    </Routes>
  );
};

import { useGiftSchedules } from './useGiftSchedules';
import { usePerson, usePersonConnections } from './usePerson';
import { useSources } from './useSources';
import { useTransactions } from './useTransactions';

export const usePrefetchPersonData = (personId?: string) => {
  const query = usePerson(personId);
  usePersonConnections(personId);
  useSources(personId);
  useGiftSchedules(personId);
  useTransactions(personId);
  return query;
};

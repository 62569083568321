import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  keyframes,
  ThemedCssFunction,
  ThemedStyledProps,
} from 'styled-components';

export enum MediaSize {
  TinyOnly = 'tinyOnly',
  SmallPhoneAndUp = 'smallPhoneAndUp',
  SmallPhoneAndDown = 'smallPhoneAndDown',
  LargePhoneAndUp = 'largePhoneAndUp',
  PhoneOnly = 'phoneOnly',
  TabletPortraitAndUp = 'tabletPortraitAndUp',
  TabletPortraitAndDown = 'tabletPortraitAndDown',
  TabletLandscapeAndUp = 'tabletLandscapeAndUp',
  LargeTabletAndUp = 'largeTabletAndUp',
  SmallDesktopAndUp = 'smallDesktopAndUp',
  DesktopAndUp = 'desktopAndUp',
  MediumDesktopAndUp = 'mediumDesktopAndUp',
  LargeDesktopAndUp = 'largeDesktopAndUp',
  HugeDesktopAndUp = 'hugeDesktopAndUp',
  Print = 'print',
}

const reactMediaFunction = (queryString: string, args: Record<string, any>): Record<string, any> => ({
  [`@media ${queryString}`]: {
    ...args,
  },
});

export const reactMedia: Record<MediaSize, (args: Record<string, any>) => Record<string, any>> = {
  [MediaSize.TinyOnly]: (args: Record<string, any>) => reactMediaFunction('(max-width: 349px)', args),
  [MediaSize.SmallPhoneAndUp]: (args: Record<string, any>) => reactMediaFunction('(min-width: 350px)', args),
  [MediaSize.SmallPhoneAndDown]: (args: Record<string, any>) => reactMediaFunction('(max-width: 449px)', args),
  [MediaSize.LargePhoneAndUp]: (args: Record<string, any>) => reactMediaFunction('(min-width: 450px)', args),
  [MediaSize.PhoneOnly]: (args: Record<string, any>) => reactMediaFunction('(max-width: 599px)', args),
  [MediaSize.TabletPortraitAndUp]: (args: Record<string, any>) => reactMediaFunction('(min-width: 600px)', args),
  [MediaSize.TabletPortraitAndDown]: (args: Record<string, any>) => reactMediaFunction('(max-width: 799px)', args),
  [MediaSize.TabletLandscapeAndUp]: (args: Record<string, any>) => reactMediaFunction('(min-width: 800px)', args),
  [MediaSize.LargeTabletAndUp]: (args: Record<string, any>) => reactMediaFunction('(min-width: 950px)', args),
  [MediaSize.SmallDesktopAndUp]: (args: Record<string, any>) => reactMediaFunction('(min-width: 1020px)', args),
  [MediaSize.DesktopAndUp]: (args: Record<string, any>) => reactMediaFunction('(min-width: 1200px)', args),
  [MediaSize.MediumDesktopAndUp]: (args: Record<string, any>) => reactMediaFunction('(min-width: 1400px)', args),
  [MediaSize.LargeDesktopAndUp]: (args: Record<string, any>) => reactMediaFunction('(min-width: 1800px)', args),
  [MediaSize.HugeDesktopAndUp]: (args: Record<string, any>) => reactMediaFunction('(min-width: 2400px)', args),
  [MediaSize.Print]: (args: Record<string, any>) => reactMediaFunction('print', args),
};

const mediaFunction =
  (media: string): ThemedCssFunction<DefaultTheme> =>
  (first: any, ...interpolations: any[]) =>
    css`
      @media ${media} {
        ${css(first, ...interpolations)};
      }
    `;

export const media: Record<MediaSize, ThemedCssFunction<DefaultTheme>> = {
  [MediaSize.TinyOnly]: mediaFunction('(max-width: 349px)'),
  [MediaSize.SmallPhoneAndUp]: mediaFunction('(min-width: 350px)'),
  [MediaSize.SmallPhoneAndDown]: mediaFunction('(max-width: 449px)'),
  [MediaSize.LargePhoneAndUp]: mediaFunction('(min-width: 450px)'),
  [MediaSize.PhoneOnly]: mediaFunction('(max-width: 599px)'),
  [MediaSize.TabletPortraitAndUp]: mediaFunction('(min-width: 600px)'),
  [MediaSize.TabletPortraitAndDown]: mediaFunction('(max-width: 799px)'),
  [MediaSize.TabletLandscapeAndUp]: mediaFunction('(min-width: 800px)'),
  [MediaSize.LargeTabletAndUp]: mediaFunction('(min-width: 950px)'),
  [MediaSize.SmallDesktopAndUp]: mediaFunction('(min-width: 1020px)'),
  [MediaSize.DesktopAndUp]: mediaFunction('(min-width: 1200px)'),
  [MediaSize.MediumDesktopAndUp]: mediaFunction('(min-width: 1400px)'),
  [MediaSize.LargeDesktopAndUp]: mediaFunction('(min-width: 1800px)'),
  [MediaSize.HugeDesktopAndUp]: mediaFunction('(min-width: 2400px)'),
  [MediaSize.Print]: mediaFunction('print'),
};

export const HideOnMobile = styled.span`
  ${media.tabletPortraitAndDown`
    display: none;
  `}
`;

export const ShowOnMobile = styled.span`
  ${media.tabletLandscapeAndUp`
    display: none;
  `}
`;

/**
 * Builds a styled helper function that returns css if truthy prop[key] is truthy, otherwise returns fallback function if provided
 * @param {*} key [string]
 * @returns [function]
 */
export const booleanPropHelperFactory =
  <K extends string>(key: K) =>
  <P extends { [A in K]?: boolean }>(
    rules: FlattenInterpolation<ThemedStyledProps<P, DefaultTheme>>,
    fallbackRules?: FlattenInterpolation<ThemedStyledProps<P, DefaultTheme>>
  ): FlattenInterpolation<ThemedStyledProps<P, DefaultTheme>> =>
    css<P>`
      ${(props) => {
        if (props[key]) {
          return rules;
        } else {
          return fallbackRules;
        }
      }}
    `;

/**
 * Builds a styled helper function that return css if the result of the function argument is truthy, otherwise returns fallback function if provided
 * @param {*} key [string]
 * @returns [function]
 */
export const conditionalHelperFactory =
  <P extends Record<string, unknown>>(func: (props: P) => boolean) =>
  (
    rules: FlattenInterpolation<ThemedStyledProps<any, DefaultTheme>>,
    fallbackRules?: FlattenInterpolation<ThemedStyledProps<any, DefaultTheme>>
  ): FlattenInterpolation<ThemedStyledProps<P, DefaultTheme>> =>
    css<P>`
      ${(props) => {
        if (func(props)) {
          return rules;
        } else {
          return fallbackRules;
        }
      }}
    `;

const BASE_FONT_SIZE = 10;
const BASE_SIZE_UNIT = 6;
const SIZE_MULTIPLIERS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 16, 18, 20, 22];

export const toRem = (value: number): number => value / BASE_FONT_SIZE;
const toEm = (value: number, fontSize: number) => value / fontSize;

export const remToPixelNum = (value: string): number =>
  parseFloat(value) * parseInt(getComputedStyle(document.documentElement).fontSize);
export const remToPixel = (value: string): string => remToPixelNum(value) + 'px';

const sizes: Array<string> = [];
SIZE_MULTIPLIERS.forEach((multiplier) => (sizes[multiplier] = `${toRem(BASE_SIZE_UNIT * multiplier)}rem`));

export const SIZE = Object.freeze(sizes);

type ReactFont = {
  fontSize: string;
  fontWeight?: number;
  letterSpacing?: string;
  lineHeight: string;
};

export const reactFont = (
  fontSize: number,
  fontWeight?: keyof typeof WEIGHTS,
  letterSpacing?: number,
  lineHeight?: number
): ReactFont => {
  return {
    fontSize: fontSize ? toRem(fontSize) + 'rem' : '1em',
    fontWeight: fontWeight ? WEIGHTS[fontWeight] : undefined,
    letterSpacing: letterSpacing ? toEm(letterSpacing, fontSize) + 'em' : undefined,
    lineHeight: lineHeight ? toEm(lineHeight, fontSize) + 'em' : '1em',
  };
};

/** See "reactFont" function for the calculations applied for this function. */
export const font = (
  fontSize: number,
  fontWeight?: keyof typeof WEIGHTS,
  letterSpacing?: number,
  lineHeight?: number
): ReturnType<ThemedCssFunction<DefaultTheme>> => {
  const font = reactFont(fontSize, fontWeight, letterSpacing, lineHeight);

  return css`
    font-size: ${font.fontSize};
    font-weight: ${font.fontWeight};
    letter-spacing: ${font.letterSpacing};
    line-height: ${font.lineHeight};
  `;
};

const WEIGHTS = {
  ExtraLight: 100,
  UltraLight: 100,
  Light: 200,
  Thin: 200,
  Book: 300,
  Demi: 300,
  Normal: 400,
  Regular: 400,
  Medium: 500,
  Semibold: 600,
  Demibold: 600,
  Bold: 700,
  Black: 800,
  ExtraBold: 800,
  Heavy: 800,
  ExtraBlack: 900,
  Fat: 900,
  Poster: 900,
  UltraBlack: 900,
};

enum ColorClass {
  Default = 'Default',
  Link = 'Link',
  Visited = 'Visited',
  Hover = 'Hover',
  Focus = 'Focus',
  Active = 'Active',
  Error = 'Error',
  Disabled = 'Disabled',
}

const ORDERED_COLOR_CLASSES = [
  ColorClass.Default,
  ColorClass.Link,
  ColorClass.Visited,
  ColorClass.Hover,
  ColorClass.Focus,
  ColorClass.Active,
  ColorClass.Error,
  ColorClass.Disabled,
];

interface ColorObject {
  Foreground?: string;
  Background?: string;
  Shadow?: string;
}

export const colorStyles = (
  colorConfig: Partial<Record<ColorClass, ColorObject>>
): ReturnType<ThemedCssFunction<DefaultTheme>> | undefined => {
  if (colorConfig === undefined) {
    return;
  }

  return ORDERED_COLOR_CLASSES.map((key: ColorClass) => {
    const classConfig = colorConfig[key];
    if (classConfig === undefined) {
      return undefined;
    }

    let begin = ':' + key.toLowerCase() + '{';
    let end = '}';

    if (key === ColorClass.Default) {
      begin = '';
      end = '';
    }

    if (key === ColorClass.Active || key === ColorClass.Hover) {
      begin = ':' + key.toLowerCase() + ':not([disabled]) {';
    }

    return css`
      ${begin}
      color: ${classConfig.Foreground};
      background: ${classConfig.Background};
      box-shadow: ${classConfig.Shadow};
      ${end}
    `;
  }).filter((e) => e !== undefined);
};

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const STANDARD_SPRING_TRANSITION = {
  type: 'spring',
  delay: 0,
  stiffness: 1000,
  damping: 55,
  mass: 0.5,
};

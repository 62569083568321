import { useEffect, useRef } from 'react';

export function useInputFocusOnceRef() {
  const ref = useRef(null);

  useEffect(() => {
    try {
      ref.current.focus();
    } catch (error) {}
  }, []);

  return ref;
}

import { ButtonSecondary, Headline } from '@foyyay/control-elements';
import React from 'react';
import { CONTROL_CENTER_URL, NUCLEUS_DASHBOARD_URL } from '../../../../src/constant';
import { Layout } from '../../../component/Layout';
import { useCustomerSupportAdminMutation, useIsCustomerSupportAdmin } from '../../../hooks/useAdmins';
import { useChurch } from '../../../hooks/useChurch';

export const SelfInvite = (props: any) => {
  const { data: church } = useChurch(props.churchId);
  const isCustomerSupportAdmin = useIsCustomerSupportAdmin(church);
  const { mutate, isPending } = useCustomerSupportAdminMutation(church);

  const rebelGiveUrl = CONTROL_CENTER_URL + '/c/' + props.churchId.slice(7, 17);
  const nucleusUrl = NUCLEUS_DASHBOARD_URL + '/c/' + props.churchId.slice(7, 15);

  const renderVisitButtons = isCustomerSupportAdmin === true && isPending === false;
  const renderJoinButton = isCustomerSupportAdmin === false;
  const renderLeaveButton = isCustomerSupportAdmin === true;

  return (
    <>
      <Headline>Customer Support</Headline>
      <Layout.Header
        buttons={
          <>
            {renderVisitButtons && <VisitButton href={nucleusUrl}>Nucleus Dashboard</VisitButton>}
            {renderVisitButtons && <VisitButton href={rebelGiveUrl}>RebelGive Control Center</VisitButton>}
            {renderLeaveButton && (
              <ButtonSecondary loading={isPending} onClick={() => mutate(false)}>
                Leave Customer Support
              </ButtonSecondary>
            )}
            {renderJoinButton && (
              <ButtonSecondary loading={isPending} onClick={() => mutate(true)}>
                Join Customer Support
              </ButtonSecondary>
            )}
          </>
        }
      />
    </>
  );
};

const VisitButton = (props) => {
  return (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      <ButtonSecondary>{props.children}</ButtonSecondary>
    </a>
  );
};

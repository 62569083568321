import { useMutation, useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { Church } from '../../type/Church';
import {
  Admin,
  ChurchHttpApi,
  isLegacyRole,
  isNucleusPlatformAdminListResponse,
  isRebelGivePlatformRoleListResponse,
} from '../api/ChurchHttpApi';
import { useUser } from './useUser';

const getCacheKey = (church?: Church) => ['admins', church?.id];

export const useAdmins = (
  church?: Church,
  queryOptions?: UseQueryOptions<Admin[], Error, Admin[]>
): UseQueryResult<Admin[], Error> => {
  return useQuery({
    staleTime: 1000 * 60 * 5,
    ...queryOptions,
    queryKey: getCacheKey(church),
    queryFn: async () => {
      const response = await ChurchHttpApi.listAdmins(church!);

      if (isNucleusPlatformAdminListResponse(response)) {
        return response.administrators;
      }

      if (isRebelGivePlatformRoleListResponse(response)) {
        return response
          .map((admin): Admin | null => {
            if (!isLegacyRole(admin)) {
              return null;
            }

            return {
              id: admin.person.id,
              name: admin.name,
              email: admin.email,
            };
          })
          .filter((admin) => admin !== null) as Admin[];
      }

      return [];
    },
    enabled: church !== undefined,
  });
};

export const useIsCustomerSupportAdmin = (church?: Church) => {
  const currentUser = useUser();
  const { data: admins = [] } = useAdmins(church);
  return admins.find((admin) => admin.id === currentUser.id) !== undefined;
};

export const useCustomerSupportAdminMutation = (church?: Church) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (shouldJoin: boolean) => {
      if (church === undefined) {
        throw new Error('Church not found');
      }

      if (shouldJoin) {
        await ChurchHttpApi.joinAsAdmin(church);
      } else {
        await ChurchHttpApi.leaveAsAdmin(church);
      }
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: getCacheKey(church) }),
  });
};

import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SIZE } from './style-utils';
import { TypographyStyles } from './Typography';
import { media } from './style-utils';

interface BreadCrumbItem {
  title: string;
  to?: string;
}

const BreadCrumbContext = React.createContext<BreadCrumbItem[]>([]);

interface BreadCrumbProviderProps {
  children: React.ReactNode;
  items: BreadCrumbItem[];
}
export const BreadCrumbProvider = (props: BreadCrumbProviderProps): JSX.Element => {
  const parentContext = useContext(BreadCrumbContext);
  const nextContext = [...parentContext, ...props.items];
  return <BreadCrumbContext.Provider value={nextContext}>{props.children}</BreadCrumbContext.Provider>;
};

BreadCrumbProvider.propTypes = {
  items: PropTypes.array,
};

interface BreadcrumbsProps {
  items?: Array<BreadCrumbItem>;
}
export const Breadcrumbs = (props: BreadcrumbsProps): JSX.Element | null => {
  const contextItems = useContext(BreadCrumbContext);
  const items = props.items ?? contextItems;

  if (items.length < 1) {
    return null;
  }

  return (
    <Bread aria-label="Breadcrumb">
      <ol>
        {items.map((item, index) => (
          <Crumb key={item.title} title={item.title} to={item.to} isLast={index === items.length - 1} />
        ))}
      </ol>
    </Bread>
  );
};

Breadcrumbs.propTypes = {
  items: PropTypes.array,
};

const Bread = styled.nav`
  margin-bottom: ${SIZE[1]};
  > ol {
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  ${media.print`
    display: none;
  `}
`;

interface CrumbProps extends BreadCrumbItem {
  isLast: boolean;
}
const Crumb = (props: CrumbProps): JSX.Element => {
  if (props.isLast !== true && props.to !== undefined) {
    return (
      <Item>
        <Link to={props.to}>{props.title}</Link>
      </Item>
    );
  }

  return <Item>{props.title}</Item>;
};

Crumb.propTypes = {
  isLast: PropTypes.bool,
  title: PropTypes.string,
  to: PropTypes.string,
};

const Item = styled.li`
  ${TypographyStyles.B1}
  ::before {
    content: '/';
    margin: 0 4px;
  }

  :first-child::before {
    content: '';
    margin: 0;
  }

  a {
    color: ${({ theme }) => theme.Breadcrumb.Color.Link.Foreground};

    :hover,
    :active {
      /* color: ${({ theme }) => theme.Breadcrumb.Color.Hover.Foreground}; */
      text-decoration: underline;
    }
  }
`;

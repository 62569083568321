export enum Platform {
  Nuclues = 'nucleus',
  RebelGive = 'rebelgive',
}

export const entityPlatform = (item: any): Platform => {
  const valid = Object.values(Platform);
  if (valid.includes(item?.platform) === true) {
    return item.platform;
  }
  return Platform.RebelGive;
};

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import React, { useContext } from 'react';
import { Layout } from '../../../../../../../component/Layout';
import { Attribute, GridColumns } from '../../../../../../../component/LayoutParts';
import { useRpMerchant } from '../../../../../../../hooks/useRpMerchant';
import { FORMAT_HINTS, objectGridAttributeLibrary } from '../../../../../../../lib/objectGridAttributeLibrary';
import { MerchantContext } from '../../context/MerchantContext';

export const DataBluechex = (props: any) => {
  const { merchant } = useContext(MerchantContext);

  const { data: rpmerchant, isLoading } = useRpMerchant(merchant.rebelpay_merchant_id);

  if (isLoading) {
    return null;
  }

  return (
    <>
      {rpmerchant.bluechex_data && (
        <BluechexData
          data={{
            ...rpmerchant.bluechex_data,
            ..._get(rpmerchant.bluechex_data, 'cardconnect_mid', {}),
          }}
        />
      )}
    </>
  );
};

const BluechexData = (props: any) => {
  const bluechex = props.data || {};
  const bluechexFormatHints = {
    merchantId: FORMAT_HINTS.COPILOT,
    account_id: FORMAT_HINTS.BLUEPAY,
  };
  const statement = '';
  const hideAttributes: Array<string> = ['cardconnect_mid'];

  const bluechexKeys = getObjectKeys(bluechex, ['account_id'], undefined, hideAttributes);

  return (
    <Layout.Panel>
      <Layout.Header headline={'Bluechex Data'} statement={statement} />
      <GridColumns>
        {bluechexKeys.map((key: string, index: number) => (
          <div key={`bluechex-account_id-${index}`} style={{ maxWidth: '60rem', overflow: 'hidden' }}>
            <Attribute
              key={`bluechex-account_id-${index}`}
              // add space in camel case key names, and replace underscores with spaces
              name={key.replace(/([a-z])([A-Z])/g, `$1 $2`).replace(/_/g, ' ')}
            >
              {objectGridAttributeLibrary(key, bluechex[key], bluechexFormatHints[key])}
            </Attribute>
          </div>
        ))}
      </GridColumns>
    </Layout.Panel>
  );
};

const getObjectKeys = (data, firstKeys, lastKeys, hideKeys) => {
  let objectKeys = [...Object.keys(data)].sort();
  const hasData = objectKeys.length > 0;
  if (firstKeys !== undefined && !_isEmpty(firstKeys) && hasData) {
    // sort the first keys
    firstKeys.forEach((key: string, index: number) => {
      if (objectKeys.includes(key)) {
        objectKeys.splice(index, 0, objectKeys.splice(objectKeys.indexOf(key), 1)[0]);
      }
    });
  }

  if (lastKeys !== undefined && !_isEmpty(lastKeys) && hasData) {
    // sort the last keys
    lastKeys.forEach((key: string) => {
      if (objectKeys.includes(key)) {
        objectKeys.splice(objectKeys.length, 0, objectKeys.splice(objectKeys.indexOf(key), 1)[0]);
      }
    });
  }

  if (hideKeys !== undefined && !_isEmpty(hideKeys) && hasData) {
    // hide these keys
    hideKeys.forEach((key: string) => {
      if (objectKeys.includes(key)) {
        objectKeys.splice(objectKeys.indexOf(key), 1);
      }
    });
  }
  return objectKeys.filter((key) => key !== undefined && data[key] !== undefined);
};

import React from 'react';
import { DataList } from '../../../../component/DataList';
import { Layout } from '../../../../component/Layout';
import { AMOUNT_FORMATTER, FREQUENCY_FORMATTER, TIME_FORMATTER } from '../../../../lib/formatting';

function formatDate(date) {
  return date === undefined ? undefined : `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}

export const TransactionData = (props: { transaction: any }) => {
  const amount = AMOUNT_FORMATTER(props.transaction.amount);
  const fee = AMOUNT_FORMATTER(props.transaction.fee ?? 0);
  const givenAt = new Date(props.transaction.date_given);
  const showMemo = props.transaction.memo?.length > 0;

  return (
    <>
      <Layout.Group>
        <>
          <DataList inline>
            <DataList.Item>
              <DataList.Label>Date Given</DataList.Label>
              <DataList.Value title={givenAt.toString()}>{formatDate(givenAt)}</DataList.Value>
            </DataList.Item>
            <DataList.Item>
              <DataList.Label>Time Given</DataList.Label>
              <DataList.Value title={givenAt.toString()}>{TIME_FORMATTER(givenAt)}</DataList.Value>
            </DataList.Item>
          </DataList>

          <DataList inline>
            <DataList.Item>
              <DataList.Label>Amount</DataList.Label>
              <DataList.Value>{amount}</DataList.Value>
            </DataList.Item>
            {props.transaction.fee > 0 && (
              <DataList.Item>
                <DataList.Label>Covered Fee</DataList.Label>
                <DataList.Value>{fee}</DataList.Value>
              </DataList.Item>
            )}
            <DataList.Item>
              <DataList.Label>Method</DataList.Label>
              <DataList.Value>{props.transaction.method}</DataList.Value>
            </DataList.Item>
            <DataList.Item>
              <DataList.Label>Frequency</DataList.Label>
              <DataList.Value>{FREQUENCY_FORMATTER(props.transaction.schedule)}</DataList.Value>
            </DataList.Item>
            {props.transaction.schedule !== undefined && (
              <DataList.Item>
                <DataList.Label>Schedule</DataList.Label>
                <DataList.Value>{props.transaction.schedule}</DataList.Value>
              </DataList.Item>
            )}
          </DataList>

          <DataList inline>
            <DataList.Item>
              <DataList.Label>Fund</DataList.Label>
              <DataList.Value>{props.transaction.designation_name}</DataList.Value>
            </DataList.Item>
            {showMemo && (
              <DataList.Item>
                <DataList.Label>Memo</DataList.Label>
                <DataList.Value>{props.transaction.memo}</DataList.Value>
              </DataList.Item>
            )}
          </DataList>

          <DataList inline>
            <DataList.Item>
              <DataList.Label>Status</DataList.Label>
              <DataList.Value>{props.transaction.status}</DataList.Value>
            </DataList.Item>
          </DataList>
        </>
      </Layout.Group>
    </>
  );
};

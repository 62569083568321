import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { CreateIdentity } from './Create';
import { EditIdentity } from './Edit';
import { ListIdentity } from './List';

export const IdentityRoutes = () => {
  return (
    <Routes>
      <Route path={'/new'} element={<CreateIdentity />} />
      <Route path={'/edit/:principle/:resource'} element={<EditIdentity />} />
      <Route path="/" element={<ListIdentity />} />
    </Routes>
  );
};

import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { Layout } from '../../../../component/Layout';
import { LoadingSpinnerBlock } from '../../../../component/LoadingSpinner';
import { useChurch } from '../../../../hooks/useChurch';
import { useMerchants } from '../../../../hooks/useMerchant';
import { Platform, entityPlatform } from '../../../../lib/platform';
import { MerchantRoutes } from './Merchant/routes';
import { MerchantsContext } from './components/MerchantsContext';
import { MerchantsList } from './components/MerchantsList';

export const MerchantsRoutes = () => {
  const params = useParams();
  const { data: church, isLoading: churchIsLoading } = useChurch(params.churchId);
  const { data: merchants = [], isLoading } = useMerchants(params.churchId ?? '');

  let platform: Platform | undefined;
  if (church !== undefined) {
    platform = entityPlatform(church);
  }

  if (isLoading || churchIsLoading) {
    return (
      <Layout.Panel>
        <LoadingSpinnerBlock />
      </Layout.Panel>
    );
  }

  if (merchants.length === 0) {
    return (
      <Layout.Card.Message>
        <Layout.Header headline={'No Merchants Found'} />
      </Layout.Card.Message>
    );
  }

  return (
    <MerchantsContext.Provider value={{ church: church, merchants: merchants }}>
      <Routes>
        <Route path={'/:merchantId/*'} element={<MerchantRoutes />} />
        <Route path={'/'} element={<MerchantsList platform={platform} />} />
      </Routes>
    </MerchantsContext.Provider>
  );
};

import React from 'react';
import { Layout } from '../../../../../component/Layout';
import { TransactionTable } from './TransactionTable';

export const TransactionListPanel = (props) => {
  return (
    <Layout.Panel>
      <Layout.Group>
        <TransactionTable />
      </Layout.Group>
    </Layout.Panel>
  );
};

import { ButtonSecondary, InputText, Label } from '@foyyay/control-elements';
import _get from 'lodash/get';
import React, { useCallback, useState } from 'react';
import { useRpMerchantMutation } from '../../../../../../../hooks/useRpMerchant';

export const RpUpdateBluechex = (props: any) => {
  const rpmerchant = props.rpmerchant;
  const propsBluechex = props.rpmerchant.bluechex_data || {};
  const propsCardconnect = _get(props.rpmerchant.bluechex_data, 'cardconnect_mid', {});

  const { mutateAsync: updateRpMerchant, isPending, isError, error } = useRpMerchantMutation(props.rpmerchant);

  const [bluechexDataAccountId, setBluechexDataAccountId] = useState(propsBluechex.account_id || '');
  const [bluechexDataAccountSecret, setBluechexDataAccountSecret] = useState(propsBluechex.account_secret || '');
  const [bluechexDataMerchantId, setBluechexDataMerchantId] = useState(propsBluechex.merchantId || '');
  const [cardconnectMidFrontEnd, setCardconnectMidFrontEnd] = useState(propsCardconnect.back_end || '');
  const [cardconnectMidBackEnd, setCardconnectMidBackEnd] = useState(propsCardconnect.front_end || '');
  const [cardconnectMidProcessorReporting, setCardconnectMidProcessorReporting] = useState(
    propsCardconnect.processor_reporting || ''
  );

  const twelveDigitsTitle = '12 Digits required';
  const twelveDigitString = '[0-9]{12}';
  const nineDigitString = '[0-9]{9}';
  const accountSecretString = '[A-Z0-9./]+';
  const twelveDigitRegex = new RegExp(`^${twelveDigitString}$`);
  const nineDigitRegex = new RegExp(`^${nineDigitString}$`);
  const accountSecretRegex = new RegExp(`${accountSecretString}`);

  const dispatchUpdate = useCallback(async () => {
    await updateRpMerchant({
      bluechex_data: {
        account_id: bluechexDataAccountId,
        account_secret: bluechexDataAccountSecret,
        merchantId: bluechexDataMerchantId,
        cardconnect_mid: {
          back_end: cardconnectMidBackEnd,
          front_end: cardconnectMidFrontEnd,
          processor_reporting: cardconnectMidProcessorReporting,
        },
      },
    });
  }, [
    updateRpMerchant,
    bluechexDataAccountId,
    bluechexDataAccountSecret,
    bluechexDataMerchantId,
    cardconnectMidBackEnd,
    cardconnectMidFrontEnd,
    cardconnectMidProcessorReporting,
  ]);

  const onSubmitUpdate = useCallback(
    async (event) => {
      event.preventDefault();
      if (
        twelveDigitRegex.test(bluechexDataAccountId) &&
        accountSecretRegex.test(bluechexDataAccountSecret) &&
        nineDigitRegex.test(bluechexDataMerchantId) &&
        cardconnectMidBackEnd !== bluechexDataMerchantId &&
        cardconnectMidBackEnd === cardconnectMidFrontEnd &&
        cardconnectMidFrontEnd === cardconnectMidProcessorReporting &&
        twelveDigitRegex.test(cardconnectMidBackEnd)
      ) {
        await dispatchUpdate();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      bluechexDataAccountId,
      bluechexDataAccountSecret,
      bluechexDataMerchantId,
      cardconnectMidBackEnd,
      cardconnectMidFrontEnd,
      cardconnectMidProcessorReporting,
      accountSecretRegex,
      nineDigitRegex,
      dispatchUpdate,
    ]
  );

  const onCardconnectMidBackEndChange = useCallback(
    (value) => {
      setCardconnectMidBackEnd(value);
      if (cardconnectMidFrontEnd.length === 0) {
        setCardconnectMidFrontEnd(value);
      }
      if (cardconnectMidProcessorReporting.length === 0) {
        setCardconnectMidProcessorReporting(value);
      }
    },
    [
      setCardconnectMidProcessorReporting,
      setCardconnectMidFrontEnd,
      setCardconnectMidBackEnd,
      cardconnectMidFrontEnd,
      cardconnectMidProcessorReporting,
    ]
  );
  return (
    <>
      <div key={`merchant-status-${rpmerchant.id}`}>
        <form onSubmit={onSubmitUpdate}>
          <Label htmlFor="bluechexDataMerchantId">Bluechex Data: MerchantId</Label>
          <InputText
            data-lpignore="true"
            name="bluechexDataMerchantId"
            value={bluechexDataMerchantId}
            onChange={setBluechexDataMerchantId}
            maxLength={9}
            pattern={nineDigitString}
            placeholder="123123123"
            title={'9 Digits required'}
            required
          />
          <Label htmlFor="cardconnectMidBackEnd">Bluechex MID: Back End</Label>
          <InputText
            data-lpignore="true"
            name="cardconnectMidBackEnd"
            value={cardconnectMidBackEnd}
            onChange={onCardconnectMidBackEndChange}
            maxLength={12}
            pattern={twelveDigitString}
            placeholder="123456789012"
            title={twelveDigitsTitle}
            required
          />
          <Label htmlFor="bluechexDataAccountId">Bluechex MID: Front End</Label>
          <InputText
            data-lpignore="true"
            name="cardconnectMidFrontEnd"
            value={cardconnectMidFrontEnd}
            onChange={setCardconnectMidFrontEnd}
            maxLength={12}
            pattern={twelveDigitString}
            placeholder="123456789012"
            title={twelveDigitsTitle}
            required
          />
          <Label htmlFor="bluechexDataAccountId">Bluechex MID: Processor Reporting</Label>
          <InputText
            data-lpignore="true"
            name="cardconnectMidProcessorReporting"
            value={cardconnectMidProcessorReporting}
            onChange={setCardconnectMidProcessorReporting}
            maxLength={12}
            pattern={twelveDigitString}
            placeholder="123456789012"
            title={twelveDigitsTitle}
            required
          />
          <Label htmlFor="bluechexDataAccountId">Bluechex Data: Account Id</Label>
          <InputText
            data-lpignore="true"
            name="bluechexDataAccountId"
            value={bluechexDataAccountId}
            onChange={setBluechexDataAccountId}
            maxLength={12}
            pattern={twelveDigitString}
            placeholder="120987654321"
            title={twelveDigitsTitle}
            required
          />
          <Label htmlFor="bluechexDataAccountSecret">Bluechex Data: Account Secret</Label>
          <InputText
            data-lpignore="true"
            name="bluechexDataAccountSecret"
            value={bluechexDataAccountSecret}
            onChange={setBluechexDataAccountSecret}
            maxLength={32}
            pattern={accountSecretString}
            placeholder={'32CHARSTRING.123ABC123ABC123ABCE'}
            title={'32 Character Alpha-Numeric string'}
            required
          />
          <ButtonSecondary loading={isPending}>Update Bluechex</ButtonSecondary>
          <Label error={isError}>{error}</Label>
        </form>
      </div>
    </>
  );
};

import React, { useContext } from 'react';
import { useResolvedPath } from 'react-router-dom';
import { RelativeDate } from '../../../../../component/Date';
import { Layout } from '../../../../../component/Layout';
import { Table } from '../../../../../component/Table';
import { DISPLAY_DATE_FORMATTER } from '../../../../../lib/objectGridAttributeLibrary';
import { Platform, entityPlatform } from '../../../../../lib/platform';
import { MerchantsContext } from './MerchantsContext';

export const MerchantsList = (props: { platform: Platform | undefined }) => {
  const url = useResolvedPath('').pathname;
  const { church, merchants } = useContext(MerchantsContext);

  let platform: Platform | undefined;
  if (church !== undefined) {
    platform = entityPlatform(church);
  }

  const isOpen = (merchant: any): string =>
    merchant?.boarded_at !== undefined && merchant?.closed_at === undefined ? 'Active' : 'Inactive';

  return (
    <Layout.Panel platform={platform}>
      <Table.OverflowAuto>
        <Table>
          <Table.Thead>
            <Table.Row>
              <Table.Header>Status</Table.Header>
              <Table.Header>Created</Table.Header>
              <Table.Header>Boarded</Table.Header>
              <Table.Header>Updated</Table.Header>
              <Table.Header>Closed</Table.Header>
            </Table.Row>
          </Table.Thead>
          <Table.Tbody>
            {merchants.map((merchant, index) => {
              return (
                <Table.Row key={merchant.id} to={`${url}/${merchant.id}`}>
                  <Table.Cell>{isOpen(merchant)}</Table.Cell>
                  <Table.Cell>{DISPLAY_DATE_FORMATTER(merchant.created_at)}</Table.Cell>
                  <Table.Cell>
                    <RelativeDate date={merchant.boarded_at} />
                  </Table.Cell>
                  <Table.Cell>
                    <RelativeDate date={merchant.updated_at} />
                  </Table.Cell>
                  <Table.Cell>
                    <RelativeDate date={merchant.closed_at} />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Tbody>
        </Table>
      </Table.OverflowAuto>
    </Layout.Panel>
  );
};

import {
  QueryOptions,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { Church } from '../../type/Church';
import { ChurchHttpApi } from '../api/ChurchHttpApi';
import { FeatureFlagHttpApi } from '../api/FeatureFlagHttpApi';

const getCacheKey = (churchId: string) => ['church', churchId];

export const useChurch = (
  churchId?: string,
  queryOptions?: QueryOptions<Church | undefined, Error, Church | undefined>
): UseQueryResult<Church | undefined, Error> => {
  return useQuery({
    staleTime: 1000 * 60 * 5,
    ...queryOptions,
    queryKey: getCacheKey(churchId!),
    queryFn: async () => await ChurchHttpApi.read(churchId!),
    enabled: churchId !== undefined,
  });
};

export const useChurchMutation = (church: Church): UseMutationResult<Church, unknown, object> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: getCacheKey(church.id),
    mutationFn: async (updates) => {
      return await ChurchHttpApi.update(church, updates);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(getCacheKey(church.id), data);
    },
  });
};

export const useFeatureFlagMutation = (churchId: string, featureFlag: string) => {
  const queryClient = useQueryClient();
  const { data: church } = useChurch(churchId);

  const result = useMutation({
    mutationKey: ['featureFlag', churchId, featureFlag],
    mutationFn: async (enabled: boolean) => {
      if (church === undefined) {
        throw new Error('Church not found');
      }
      if (enabled) {
        await FeatureFlagHttpApi.activate(church, featureFlag);
      } else {
        await FeatureFlagHttpApi.deactivate(church, featureFlag);
      }
    },
    onSuccess: (data) => {
      queryClient.setQueryData(getCacheKey(churchId), data);
    },
  });

  return {
    ...result,
    data: church?.features?.includes(featureFlag),
  };
};

/* eslint-disable @typescript-eslint/no-redeclare */
import { STATE_LOADING } from '../constants/state';
import { useAsyncState } from '../hooks/useAsyncState';
import { deepFreeze } from '../lib/deepFreeze';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { omit as _omit, throttle as _throttle } from 'lodash';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { CircleIconAngle, CircleIconFlipAngle } from './Icons';
import { LoadingSpinner } from './LoadingSpinner';
import { MenuDivider } from './Menu';
import { booleanPropHelperFactory, font, SIZE, STANDARD_SPRING_TRANSITION } from './style-utils';
import { TableOverflowContext } from './TableContext';
import { L1 } from './Typography';

export interface DropdownMenuProps {
  align?: 'left' | 'right';
  children?: React.ReactNode;
  className?: string;
  closeOnScroll?: boolean;
  enterAnimationEnabled?: boolean;
  exitAnimationEnabled?: boolean;
  isOpen?: boolean;
  items?: any[];
  onClose?: () => void;
  style?: React.CSSProperties;
  parentRef?: React.RefObject<HTMLElement>;
  deepLink?: string[];
  xPos?: number;
  yPos?: number;
  position?: string;
}

const defaultProps = {
  align: 'left',
  closeOnScroll: false,
  enterAnimationEnabled: true,
  exitAnimationEnabled: true,
  isOpen: false,
  onClose: () => {},
  position: 'relative',
};

interface Context {
  onClose?: () => void;
  pushMenu: (menu: JSX.Element) => void;
  popMenu: () => void;
  deepLink?: Array<string>;
  currentMenuIndex: number;
}

const DropdownMenuContext = React.createContext<Context>({} as Context);

const useConsumeDeepLinkWhenOpened = (
  deepLinkOriginal: Array<string> | undefined,
  isOpen: boolean,
  deepLinkingComplete: boolean
) => {
  const [deepLinkConsumed, setDeepLinkConsumed] = useState(false);
  const [deepLink, setDeepLink] = useState(deepLinkOriginal);

  useEffect(() => {
    if (deepLinkConsumed === true) {
      setDeepLink(undefined);
    }
    if (deepLinkConsumed !== true && isOpen === true && deepLinkingComplete === true) {
      setDeepLinkConsumed(true);
    }
    if (deepLinkConsumed === true && isOpen === false) {
      setDeepLinkConsumed(false);
      setDeepLink(deepLinkOriginal);
    }
  }, [deepLinkingComplete, deepLinkConsumed, isOpen, deepLinkOriginal]);

  return deepLink;
};

const deepLinkAnimationOverrides = {
  initial: false,
  exit: 'hide',
  transition: { duration: 0 },
};

const DropdownMenuComponent = Object.assign(
  React.forwardRef<HTMLDivElement, DropdownMenuProps & typeof defaultProps>((props, ref) => {
    const positionRef = useRef<HTMLDivElement | null>(null);
    const dropdownRef = useRef<HTMLUListElement | null>(null);
    const [position, setPosition] = useState<Position>({} as Position);
    const { isInsideTableOverflow } = useContext(TableOverflowContext);

    const [[currentMenuIndex, direction], setCurrentMenuIndex] = useState([0, 'none']);
    const [menus, setMenus] = useState<Array<any>>(['initial']);

    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);

    // Set size and position on mount and on resize
    useEffect(() => {
      if (props.isOpen !== true) {
        return;
      }

      const setSizeAndPosition = () => {
        const dropdownRect = dropdownRef.current?.getBoundingClientRect();
        let positionRect;
        let parentRect;
        if (props.xPos && props.yPos) {
          positionRect = {
            top: props.yPos,
            left: props.xPos,
            height: 0,
            width: 0,
          } as DOMRect;
        } else {
          positionRect = positionRef.current?.getBoundingClientRect();
          parentRect = props.parentRef?.current?.getBoundingClientRect();
        }
        if (positionRect !== undefined && dropdownRect !== undefined) {
          setWidth(dropdownRect.width);
          setHeight(dropdownRect.height);
          const newPosition = calculatePosition(
            props.align,
            isInsideTableOverflow === true || props.position === 'fixed',
            positionRect,
            dropdownRect,
            parentRect
          );

          setPosition(newPosition);
        }
      };

      const handleViewportEvent = () => {
        setSizeAndPosition();
      };

      setSizeAndPosition();

      return addEventListeners(['resize'], handleViewportEvent);
    }, [
      props.isOpen,
      currentMenuIndex,
      dropdownRef,
      positionRef,
      props.parentRef,
      isInsideTableOverflow,
      props.xPos,
      props.yPos,
      props.position,
      props.align,
    ]);

    // Close on scroll or resize
    useEffect(() => {
      if (props.isOpen !== true || (props.closeOnScroll !== true && isInsideTableOverflow === false)) {
        return;
      }

      const handleViewportEvent = _throttle(() => {
        props.onClose();
      }, 250);

      return addEventListeners(['scroll', 'wheel', 'resize', 'touchmove'], handleViewportEvent);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isOpen]);

    const panelAnimationProps = getAnimationProps(props.enterAnimationEnabled, props.exitAnimationEnabled);

    const pushMenu = useCallback(
      (menu: any) => {
        setMenus([...menus.slice(0, currentMenuIndex + 1), menu]);
        setCurrentMenuIndex([currentMenuIndex + 1, 'forward']);
      },
      [menus, currentMenuIndex]
    );

    const popMenu = useCallback(() => {
      setCurrentMenuIndex([Math.max(0, currentMenuIndex - 1), 'backward']);
    }, [currentMenuIndex]);

    const onClose = useCallback(() => {
      props.onClose();
    }, [props]);

    // reset menu to initial state when menu is closed
    useEffect(() => {
      if (props.isOpen !== true) {
        setCurrentMenuIndex([0, 'none']);
        setHeight(0);
        setWidth(0);
      }
    }, [props.isOpen]);

    const deepLinkingComplete = (props.deepLink === undefined || menus.length - 1 === props.deepLink?.length) === true;
    const deepLink = useConsumeDeepLinkWhenOpened(props.deepLink, props.isOpen, deepLinkingComplete);

    const context = {
      onClose: onClose,
      pushMenu: pushMenu,
      popMenu: popMenu,
      deepLink: deepLink,
      currentMenuIndex: currentMenuIndex,
    };

    return (
      <DropdownMenuContext.Provider value={context}>
        <AnimatePresence>
          {props.isOpen && (
            <PositionRelative style={props.style} className={props.className}>
              <PositionAbsolute align={props.align} ref={positionRef}>
                <DropdownPortal isFixed={isInsideTableOverflow || props.position === 'fixed'}>
                  <DropdownPosition position={position} ref={ref}>
                    <DropdownMenuWrapper>
                      <AnimatedDropdownPanel
                        width={width}
                        height={height}
                        {...panelAnimationProps}
                        hasDeepLink={deepLink !== undefined}
                      >
                        <AnimatePresence custom={{ direction, width }}>
                          <AnimatedDropdownList
                            ref={dropdownRef}
                            key={currentMenuIndex}
                            position={position}
                            custom={{ direction, width }}
                            hasDeepLink={deepLink !== undefined}
                            {...listEnterExitAnimation}
                            {...(deepLink ? deepLinkAnimationOverrides : {})}
                          >
                            {currentMenuIndex === 0 ? (
                              <>
                                {props.items && <Items items={props.items} />}
                                {props.children}
                              </>
                            ) : (
                              menus[currentMenuIndex]
                            )}
                          </AnimatedDropdownList>
                        </AnimatePresence>
                      </AnimatedDropdownPanel>
                    </DropdownMenuWrapper>
                  </DropdownPosition>
                </DropdownPortal>
              </PositionAbsolute>
            </PositionRelative>
          )}
        </AnimatePresence>
      </DropdownMenuContext.Provider>
    );
  }),
  {
    defaultProps: defaultProps,
  }
);

const VIEWPORT_PADDING = 12;

function addEventListeners(events: string[], callback: () => void, target = window, passive = true) {
  events.forEach((event) => target.addEventListener(event, callback, { passive: passive }));
  return () => events.forEach((event) => target.removeEventListener(event, callback));
}

interface Position {
  top: number;
  left: number;
  isOutsideViewportX: boolean;
  isOutsideViewportY: boolean;
}

const calculatePositionRelative = (
  alignment: 'left' | 'right',
  positionRect: DOMRect,
  dropdownRect: DOMRect,
  parentRect?: DOMRect
) => {
  const isOutsideViewportY = positionRect.top + dropdownRect.height > window.innerHeight;
  let isOutsideViewportX = false;
  let top = 0;
  let left = 0;

  if (isOutsideViewportY) {
    if (parentRect !== undefined) {
      top = Math.max(-positionRect.top, -(parentRect.height + dropdownRect.height));
    } else {
      top = Math.max(-positionRect.top, -dropdownRect.height);
    }
  }

  if (alignment === 'right') {
    const distanceFromRight = window.innerWidth - positionRect.right;
    isOutsideViewportX = distanceFromRight + dropdownRect.width > window.innerWidth;
    // if dropdown is overflowing the left side of the viewport, push it right so it fits
    if (isOutsideViewportX) {
      left = dropdownRect.width + distanceFromRight - window.innerWidth;
    }
  } else {
    isOutsideViewportX = positionRect.left + dropdownRect.width + VIEWPORT_PADDING > window.innerWidth;

    if (isOutsideViewportX) {
      // if dropdown is overflowing the right side of the viewport, push it left so it fits
      if (window.innerWidth >= dropdownRect.width + VIEWPORT_PADDING) {
        left = left - VIEWPORT_PADDING + (window.innerWidth - (positionRect.left + dropdownRect.width));
        // if the dropdown is wider than the viewport, push it all the way to the left but no further
      } else {
        left = -VIEWPORT_PADDING / 2;
      }
    }
  }

  return {
    top: top,
    left: left,
    isOutsideViewportX: isOutsideViewportX,
    isOutsideViewportY: isOutsideViewportY,
  };
};

const calculatePositionFixed = (positionRect: DOMRect, dropdownRect: DOMRect, parentRect?: DOMRect) => {
  const isOutsideViewportY = positionRect.top + dropdownRect.height > window.innerHeight;
  const isOutsideViewportX = positionRect.left + dropdownRect.width > window.innerWidth;

  let top = positionRect.top;
  let left = positionRect.left;

  if (isOutsideViewportY) {
    if (parentRect !== undefined) {
      top = positionRect.top - (parentRect.height + dropdownRect.height);
    } else {
      top = positionRect.top + (window.innerHeight - (positionRect.top + dropdownRect.height));
    }
  }

  if (isOutsideViewportX) {
    left = positionRect.left - VIEWPORT_PADDING + (window.innerWidth - (positionRect.left + dropdownRect.width));
  }

  const position = {
    top: Math.max(0, top),
    left: Math.max(0, left),
    isOutsideViewportX: isOutsideViewportX,
    isOutsideViewportY: isOutsideViewportY,
  };

  return position;
};

function calculatePosition(
  alignment: 'left' | 'right',
  portalPositionFixed: boolean,
  positionRect: DOMRect,
  dropdownRect: DOMRect,
  parentRect?: DOMRect
): Position {
  if (portalPositionFixed === true) {
    return calculatePositionFixed(positionRect, dropdownRect, parentRect);
  }

  return calculatePositionRelative(alignment, positionRect, dropdownRect, parentRect);
}

const DROPDOWN_MENU_VARIANTS = deepFreeze<Variants>({
  show: {
    opacity: 1,
    y: 0,
    pointerEvents: 'auto',
  },
  hide: {
    opacity: 0,
    y: 20,
    pointerEvents: 'none',
  },
});

function getAnimationProps(enterAnimationEnabled: boolean, exitAnimationEnabled: boolean) {
  if (enterAnimationEnabled === true && exitAnimationEnabled === true) {
    return enterExitAnimation;
  }
  if (enterAnimationEnabled === true) {
    return enterAnimation;
  }
  if (exitAnimationEnabled === true) {
    return exitAnimation;
  }
  return {};
}

const enterExitAnimation = {
  initial: 'show',
  animate: 'show',
  exit: 'hide',
  transition: STANDARD_SPRING_TRANSITION,
  variants: DROPDOWN_MENU_VARIANTS,
};

const enterAnimation = {
  initial: 'hide',
  animate: 'show',
  exit: 'show',
  transition: STANDARD_SPRING_TRANSITION,
  variants: DROPDOWN_MENU_VARIANTS,
};

const exitAnimation = {
  initial: 'show',
  animate: 'show',
  exit: 'hide',
  transition: STANDARD_SPRING_TRANSITION,
  variants: DROPDOWN_MENU_VARIANTS,
};

const DROPDOWN_MENU_LIST_VARIANTS = deepFreeze<Variants>({
  enter: (args: { direction: string; width: number }) => {
    return {
      x: args.direction === 'none' ? '0' : args.direction === 'forward' ? args.width : -args.width,
      opacity: 1,
      pointerEvents: 'none',
    };
  },
  center: {
    x: '0',
    opacity: 1,
    pointerEvents: 'auto',
  },
  exit: (args: { direction: string; width: number }) => {
    return {
      x: args.direction === 'forward' ? -args.width : args.width,
      opacity: 0,
      pointerEvents: 'none',
    };
  },
  hide: {
    opacity: 0,

    pointerEvents: 'none',
  },
});

const listEnterExitAnimation = {
  initial: 'enter',
  animate: 'center',
  exit: 'exit',
  transition: STANDARD_SPRING_TRANSITION,
  variants: DROPDOWN_MENU_LIST_VARIANTS,
};

const PositionRelative = styled.div`
  position: relative;
`;

const PositionAbsolute = styled.div<{ align?: 'left' | 'right' }>`
  position: absolute;
  top: 0;
  ${(props) => `${props.align ?? 'left'}`}: 0;
  min-width: 20rem;
`;

const positionFixed = booleanPropHelperFactory('isFixed');

const DropdownPortal = styled.div<{ isFixed: boolean }>`
  position: relative;
  z-index: 9000;
  pointer-events: none;

  ${positionFixed(css`
    position: fixed;
    top: 0;
    left: 0;
  `)}
`;

const DropdownPosition = styled.div.attrs<{ position: Position }>((props) => ({
  style: {
    top: `${props.position.top}px`,
    left: `${props.position.left}px`,
  },
}))<{ position: Position }>`
  position: relative;
`;

const DropdownMenuWrapper = styled.div`
  padding: ${VIEWPORT_PADDING / 2}px 0;
`;

const AnimatedDropdownPanel = styled(motion.div)<{ width?: number; height?: number; hasDeepLink: boolean }>`
  list-style: none;
  background: ${(props) => props.theme.Header.Color.Background};
  border-radius: ${SIZE[2]};
  box-shadow: 0.2rem 0.2rem 2rem 0 ${(props) => props.theme.Panel.Color.Default.BoxShadow};
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior-x: contain;
  overscroll-behavior-y: contain;
  margin: 0;
  position: relative;
  min-height: ${SIZE[8]};
  max-height: ${`calc(100vh - ${VIEWPORT_PADDING}px)`};
  height: ${(props) => (props.height !== 0 ? props.height + 'px' : 'auto')};
  width: ${(props) => (props.width !== 0 ? props.width + 'px' : 'auto')};
  ${(props) =>
    props.hasDeepLink === true
      ? css`
          transition:
            height 0.15s ease-out,
            width 0.15s ease-out;
        `
      : ''};
`;

const AnimatedDropdownList = styled(motion.ul)<{ position?: Position; hasDeepLink: boolean }>`
  box-sizing: border-box;
  list-style: none;
  position: absolute;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: ${SIZE[1]};
  white-space: ${(props) => ((props.position?.left ?? 0) > 0 ? 'nowrap' : 'normal')};
  overflow: hidden;
  min-width: 20rem;
  opacity: 1;
  ${(props) => props.hasDeepLink && `opacity: 0 !important`};
  transition: opacity 0.05s ease-out;

  > li {
    padding: 0.3rem 0;
  }

  > li:first-child {
    padding-top: 0;
  }

  > li:last-child {
    padding-bottom: 0;
  }
`;

interface Item extends ItemProps {
  title: string;
  show?: boolean;
  divider?: boolean;
}

interface ItemsProps {
  items: Item[];
  deepLink?: Array<string>;
}

const Items = (props: ItemsProps): JSX.Element => {
  const { deepLink, currentMenuIndex } = useContext(DropdownMenuContext);

  return (
    <>
      {props.items
        .filter((item) => item.show !== false)
        .map(({ title, ...item }) => {
          item = _omit(item, ['show']);
          if (item.divider === true) {
            return <MenuDivider key={title} />;
          }
          if (deepLink !== undefined && deepLink.length > 0 && title === deepLink[currentMenuIndex]) {
            item.activate = true;
          }
          return (
            <Item key={title} {...item}>
              {title}
            </Item>
          );
        })}
    </>
  );
};

interface ItemProps extends React.HTMLAttributes<HTMLButtonElement> {
  as?: string | React.ComponentType<any>;
  dropdown?: React.ReactNode;
  dropdownItems?: Array<Item>;
  dropdownBackLabel?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  href?: string;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  keepOpen?: boolean;
  onClick: () => void;
  to?: string;
  activate?: boolean;
  loading?: boolean;
  rel?: string;
  target?: string;
  type?: 'button' | 'submit' | 'reset';
}

const Item = (props: ItemProps): JSX.Element => {
  const [initialized, setInitialized] = useState(false);
  const [asyncState, call] = useAsyncState();
  const buttonProps = _omit(props, [
    'dropdown',
    'dropdownItems',
    'dropdownBackLabel',
    'keepOpen',
    'onClick',
    'activate',
    'loading',
  ]);

  const loading = props.loading || asyncState === STATE_LOADING;

  const Context = useContext(DropdownMenuContext);
  const onClose = Context?.onClose ?? (() => {});

  const hasDropdown = props.dropdown !== undefined || props.dropdownItems !== undefined;

  if (buttonProps.to !== undefined) {
    buttonProps.as = Link;
  }

  if (buttonProps.href !== undefined) {
    buttonProps.as = 'a';
  }

  if (hasDropdown === true) {
    buttonProps.as = undefined;
    buttonProps.to = undefined;
    buttonProps.href = undefined;
  }

  const handleClick = async (e?: any) => {
    if (e !== undefined) {
      e.stopPropagation();
    }

    if (loading === true) {
      return;
    }

    if (hasDropdown === true) {
      const dropdown = props.dropdown ?? <Items items={props.dropdownItems ?? []} />;
      Context.pushMenu(<NestedMenu backLabel={props.dropdownBackLabel}>{dropdown}</NestedMenu>);
      return;
    }

    try {
      await call(props.onClick);
    } catch (error) {
      return;
    }

    if (props.keepOpen !== true) {
      onClose();
    }
  };

  useEffect(() => {
    if (initialized === false && props.activate === true) {
      handleClick();
      setInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized, props.activate]);

  return (
    <li>
      <DropdownItemButton {...buttonProps} onClick={handleClick} role="menuitem" tabIndex={-1}>
        {props.iconLeft && <ItemIconLeft>{props.iconLeft}</ItemIconLeft>}
        {props.children}
        {props.iconRight && <ItemIconRight>{props.iconRight}</ItemIconRight>}
        {!props.iconRight && hasDropdown && (
          <ItemIconRight>
            <CircleIconAngle direction="right" />
          </ItemIconRight>
        )}
        {loading && <LoadingSpinner width={24} strokeWidth={3} />}
      </DropdownItemButton>
    </li>
  );
};

Item.defaultProps = {
  onClick: () => {},
};

const NestedMenu = (props: any) => {
  const Context = useContext(DropdownMenuContext);
  const onBack = Context.popMenu;

  return (
    <>
      <Item iconLeft={<CircleIconAngle direction="left" />} onClick={onBack} keepOpen={true}>
        <L1>{props.backLabel}</L1>
      </Item>
      {props.children}
    </>
  );
};

NestedMenu.defaultProps = {
  backLabel: 'Back',
};

export const DropdownItemButton = styled.button.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['active'].includes(prop) && defaultValidatorFn(prop),
})`
  position: relative;
  display: flex;
  align-items: center;
  height: ${SIZE[8]};
  text-decoration: none;
  padding: 0 ${SIZE[2]};
  margin-right: ${SIZE[1]};
  ${font(16, 'Bold', -0.5)};
  color: ${({ theme }) => theme.HeaderNav.Color.Default.Foreground};
  cursor: pointer;
  transition:
    transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.1),
    box-shadow 0.1s ease-out;
  white-space: nowrap;

  outline: none;
  appearance: none;
  width: 100%;
  background: none;

  &:last-child {
    margin-bottom: 0;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 0.8rem;
    background: currentColor;
    opacity: 0;
    transition: opacity 25ms cubic-bezier(0, 0, 1, 1);
  }
  @media (hover: hover) {
    &:hover {
      &:before {
        opacity: 0.1;
        transition-duration: 0s;
      }
    }
  }
  &:active {
    &:before {
      opacity: 0.2;
      transition-duration: 0s;
    }
  }

  &.active {
    color: ${({ theme }) => theme.HeaderNav.Color.Active.Foreground};
    &:before {
      opacity: 0.15;
    }
    @media (hover: hover) {
      &:hover {
        &:before {
          opacity: 0.2;
          transition-duration: 0s;
        }
      }
    }
    &:active {
      &:before {
        opacity: 0.3;
        transition-duration: 0s;
      }
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  &:not([disabled]) {
    &:active {
      transform: scale(0.97);
    }
  }

  ${LoadingSpinner} {
    margin-left: auto;
    position: relative;
    top: unset;
    left: unset;
    transform: unset;
  }
`;

const ItemIconRight = styled.div`
  width: ${SIZE[4]};
  margin-left: auto;
`;

const ItemIconLeft = styled.div`
  width: ${SIZE[4]};
  margin-right: ${SIZE[2]};
`;

const Indicator = styled((props) => {
  const direction = props.isOpen ? 'up' : 'down';
  return <CircleIconFlipAngle direction={direction} {...props} />;
})``;

const TextItem = (props: { children?: React.ReactNode }): JSX.Element => {
  return (
    <li>
      <TextItemContainer>{props.children}</TextItemContainer>
    </li>
  );
};

const TextItemContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-height: ${SIZE[8]};
  text-decoration: none;
  padding: 0 ${SIZE[2]};
  margin-right: ${SIZE[1]};
  ${font(16, 'Demi', -0.5)};
  color: ${({ theme }) => theme.Typography.Color.Default.Heading};
  width: 100%;
  background: none;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const DropdownMenu = Object.assign(DropdownMenuComponent, {
  Items: Items,
  Item: Item,
  TextItem: TextItem,
  Indicator: Indicator,
  Divider: MenuDivider,
});

import { css } from 'styled-components';

export const media = {
  tinyAndUp: (...args) => css(['@media (min-width:0){', ';}'], css(...args)),
  phoneAndUp: (...args) => css(['@media (min-width:450px){', ';}'], css(...args)),
  tabletPortraitAndUp: (...args) => css(['@media (min-width:600px){', ';}'], css(...args)),
  tabletLandscapeAndUp: (...args) => css(['@media (min-width:900px){', ';}'], css(...args)),
  desktopAndUp: (...args) => css(['@media (min-width:1200px){', ';}'], css(...args)),
  largeDesktopAndUp: (...args) => css(['@media (min-width:1600px){', ';}'], css(...args)),
  hugeDesktopAndUp: (...args) => css(['@media (min-width:2400px){', ';}'], css(...args)),
  print: (...args) => css(['@media print{', ';}'], css(...args)),
};

export const font = (fontSize, fontWeight, letterSpacing, lineHeight) =>
  css(
    ['font-size:', ';font-weight:', ';letter-spacing:', ';line-height:', ';'],
    fontSize ? toRem(fontSize) + 'rem' : '1em',
    fontWeight ? WEIGHTS[fontWeight] : undefined,
    letterSpacing ? toEm(letterSpacing, fontSize) + 'em' : undefined,
    lineHeight ? toEm(lineHeight, fontSize) + 'em' : undefined
  );

const toRem = value => value / 10;

const toEm = (value, fontSize) => value / fontSize;

const WEIGHTS = {
  'Extra Light': 100,
  'Ultra Light': 100,
  Light: 200,
  Thin: 200,
  Book: 300,
  Demi: 300,
  Normal: 400,
  Regular: 400,
  Medium: 500,
  Semibold: 600,
  Demibold: 600,
  Bold: 700,
  Black: 800,
  'Extra Bold': 800,
  Heavy: 800,
  'Extra Black': 900,
  Fat: 900,
  Poster: 900,
  'Ultra Black': 900,
};

export const colorStyles = ColorObj =>
  css(
    ['', ''],
    Object.keys(ColorObj).map(key => {
      let begin = ':' + key + '{';
      let end = '}';

      if (key === 'Default') {
        begin = '';
        end = '';
      }

      if (key === 'Active' || key === 'Hover') {
        begin = ':' + key + ':not([disabled]) {';
      }

      return css(
        ['', ' color:', ';background-color:', ';', ''],
        begin,
        ColorObj[key].Foreground,
        ColorObj[key].Background,
        end
      );
    })
  );

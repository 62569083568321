import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from '../../../../../../component/Layout';
import { LoadingSpinnerBlock } from '../../../../../../component/LoadingSpinner';
import { SubscriptionControllerContext } from '../state/context';
import { selectItems, selectLoading, selectSubscription } from '../state/reducer';
import { CreditDebitList } from './components/CreditDebitList';
import { SubscriptionDiscounts } from './components/DiscountList';
import { SubscriptionProducts } from './components/ProductList';
import { SubscriptionActions } from './components/SubscriptionActions';
import { SubscriptionDetail } from './components/SubscriptionDetail';

export const OverviewRoutes = () => {
  return (
    <Routes>
      <Route path={'/*'} element={<Overview />} />
    </Routes>
  );
};

const Overview = (props) => {
  const { useSelector } = useContext(SubscriptionControllerContext);
  const loading = useSelector(selectLoading);
  const subscription = useSelector(selectSubscription);
  const items = useSelector(selectItems);

  if (loading === true && items.length < 1) {
    return (
      <Layout.Panel>
        <LoadingSpinnerBlock />
      </Layout.Panel>
    );
  }

  if (subscription === undefined) {
    return (
      <Layout.Panel>
        <Layout.Card.Message>
          <Layout.Header headline="Subscription Not Found" />
        </Layout.Card.Message>
      </Layout.Panel>
    );
  }

  return (
    <>
      <SubscriptionDetail />
      <SubscriptionActions />
      <SubscriptionProducts />
      <SubscriptionDiscounts />
      <CreditDebitList />
    </>
  );
};

import { Header as HeaderElement, media, Nav } from '@foyyay/control-elements';
import { useIsFetching } from '@tanstack/react-query';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import FortIcon from './FortIcon';
import { LOADING_SPINNER_SIZES, LoadingSpinnerBlock } from './LoadingSpinner';

export const Header = function () {
  return (
    <HeaderElement>
      <HeaderElement.Branding>
        <LogoPadding>
          <StyledLogo />
        </LogoPadding>
      </HeaderElement.Branding>

      <HeaderElement.Collapsable>
        <HeaderTopNav />
        <SignOutNav />
      </HeaderElement.Collapsable>
    </HeaderElement>
  );
};

const HEADER_NAV = [
  {
    to: '/churches',
    label: 'Churches',
  },
  {
    to: '/persons',
    label: 'Persons',
  },
  {
    to: '/policies',
    label: 'Policies',
  },
  /*{
    to: '/merchants',
    label: 'Merchants',
  },*/
];

const HeaderTopNav = (props: any) => {
  return (
    <Nav>
      {HEADER_NAV.map((item) => {
        let LinkComponent = (linkProps: any) => <NavLink to={item.to} {...linkProps} />;

        return (
          <Nav.Item key={item.label}>
            <LinkComponent>
              <Nav.Item.Label>{item.label}</Nav.Item.Label>
            </LinkComponent>
          </Nav.Item>
        );
      })}
    </Nav>
  );
};

const SignOutNav = () => {
  const userEmail = useSelector((state: any) => state.user.sessionData.login);
  const isFetching = useIsFetching();
  return (
    <RightNav>
      <Nav>
        {isFetching ? (
          <Nav.Item>
            <LoadingSpinnerBlock {...LOADING_SPINNER_SIZES.small} />
          </Nav.Item>
        ) : null}
        <Nav.Item key={'logged-in-as'}>
          <Nav.Item.Label>(Signed-in as {userEmail})</Nav.Item.Label>
        </Nav.Item>
        <Nav.Item key={'signout'}>
          <NavLink to={'/sign-out'}>
            <Nav.Item.Label>Sign Out</Nav.Item.Label>
          </NavLink>
        </Nav.Item>
      </Nav>
    </RightNav>
  );
};

const RightNav = styled.div`
  ${media.tabletLandscapeAndUp`
    float: right;
    margin-right: 6rem;
`}
`;

const LogoPadding = styled.div`
  padding: 1.2rem 3rem 1.4rem;

  ${media.tabletPortraitAndUp`
    padding: 1.5rem 3rem 1.4rem;
  `}
`;

const StyledLogo = styled(FortIcon)`
  display: block;
  height: 4rem;

  @media (min-width: 900px) {
    height: 4.6rem;
  }
`;

import React from 'react';
import { useParams } from 'react-router';
import { Navigate, Route, Routes, useResolvedPath } from 'react-router-dom';
import { LayoutPrimary } from '../../component/LayoutPrimary';
import { MemorandumsController } from '../../component/Memorandums/MemorandumsController';
import { MemorandumsList } from '../../component/Memorandums/MemorandumsList';
import { MemorandumsMain, MemorandumsSidebar, MemorandumsWrapper } from '../../component/MemorandumsGrid';
import { PillNav } from '../../component/PillNav';
import { usePrefetchChurchData } from '../../hooks/usePrefetchChurchData';
import { Admins } from './route/Admins';
import { Domains } from './route/Domains';
import { Features } from './route/Features';
import { Makeover } from './route/Makeover';
import { MerchantsRoutes } from './route/Merchants/routes';
import { Overview } from './route/Overview';
import { SubscriptionsRoutes } from './route/Subscriptions/routes';

const headline = 'Church';

export function Church() {
  const params = useParams<{ churchId: string | undefined }>();
  const url = useResolvedPath('').pathname;
  const { data: church } = usePrefetchChurchData(params.churchId);

  const navList = [
    {
      title: 'Overview',
      to: `${url}/overview`,
    },
    {
      title: 'Admins',
      to: `${url}/admins`,
      component: Admins,
    },
    {
      title: 'Subscriptions',
      to: `${url}/subscriptions`,
    },
    {
      title: 'Domains',
      to: `${url}/domains`,
    },
    {
      title: 'Merchants',
      to: `${url}/merchants`,
    },
    {
      title: 'Features',
      to: `${url}/features`,
    },
    {
      title: 'Makeover',
      to: `${url}/makeover`,
    },
  ];

  const byline = church?.name || '[no name]';

  return (
    <LayoutPrimary layoutTitle={headline} layoutByline={`${byline.toUpperCase()}: ${params.churchId}`}>
      <PillNav navList={navList} />
      <MemorandumsWrapper>
        <MemorandumsMain>
          <Routes>
            <Route path={'/overview/*'} element={<Overview />} />
            <Route path={'/admins/*'} element={<Admins />} />
            <Route path={'/subscriptions/*'} element={<SubscriptionsRoutes />} />
            <Route path={'/domains/*'} element={<Domains />} />
            <Route path={'/merchants/*'} element={<MerchantsRoutes />} />
            <Route path={'/features/*'} element={<Features />} />
            <Route path={'/makeover/*'} element={<Makeover />} />
            <Route path={'/*'} element={<Navigate to={navList[0].to} replace={true} />} />
          </Routes>
        </MemorandumsMain>
        <MemorandumsSidebar>
          <MemorandumsController subjectId={church?.id}>
            <MemorandumsList />
          </MemorandumsController>
        </MemorandumsSidebar>
      </MemorandumsWrapper>
    </LayoutPrimary>
  );
}

import { ButtonPrimary, InputText, Label, Section } from '@foyyay/control-elements';
import React, { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Layout } from '../../component/Layout';
import { LayoutPrimary } from '../../component/LayoutPrimary';
import { LoadingSpinnerBlock } from '../../component/LoadingSpinner';
import { ObjectsTable } from '../../component/ObjectsTable';
import { useEmails } from '../../hooks/useEmails';
import { useLocationEffect } from '../../hooks/useLocationEffect';

const byline = 'Platform';
const headline = 'Persons';

export const Persons = () => {
  const location = useLocation();
  const history = useNavigate();
  const q = decodeURIComponent((new URLSearchParams(location.search).get('q') || '').trim());
  const [query, setQuery] = useState(q);

  const { data: searchResults = [], isLoading } = useEmails(q);

  const onSearchSubmit = useCallback(
    (event) => {
      event.preventDefault();
      history(`/persons/search?q=${encodeURIComponent(query)}`);
    },
    [history, query]
  );

  // Listen for history change and dispatch search like location changes.
  useLocationEffect(() => {
    decodeURIComponent((new URLSearchParams(location.search).get('q') || '').trim());
  });

  const showResult = !isLoading && query.length > 0 && query === q;

  const onInputChange = useCallback(
    (value: string) => {
      setQuery(value);
    },
    [setQuery]
  );

  const renderEmptyCard = searchResults.length === 0 && showResult;
  const renderResult = searchResults.length > 0 && showResult;

  return (
    <LayoutPrimary layoutTitle={headline} layoutByline={byline}>
      <Section style={{ height: '3rem' }} />
      <Layout.Panel>
        <form onSubmit={onSearchSubmit}>
          <Label> Enter a Person Email</Label>
          <InputText name="q" value={decodeURIComponent(query)} onChange={onInputChange} />
          <ButtonPrimary>Go</ButtonPrimary>
        </form>
      </Layout.Panel>

      {isLoading && (
        <Layout.Panel>
          <LoadingSpinnerBlock />
        </Layout.Panel>
      )}

      {renderEmptyCard && (
        <Layout.Panel>
          <Layout.Card.Message>
            <Layout.Header headline={`No results for "${query}"`} />
          </Layout.Card.Message>
        </Layout.Panel>
      )}

      {renderResult && (
        <Layout.Panel>
          <Layout.Header headline="Search Results" />
          <ObjectsTable
            statement={`Results for "${query}"`}
            data={searchResults}
            keys={['address', 'person_id', 'created_at', 'verified']}
            firstAttributes={['address']}
            lastAttributes={['login']}
            rowKey={'address'}
          />
        </Layout.Panel>
      )}
    </LayoutPrimary>
  );
};

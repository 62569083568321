import { Church } from '../../type/Church';
import { ChurchPerson } from '../../type/ChurchPerson';
import { Person } from '../../type/Person';
import { Source } from '../../type/Sources';
import { GIVING_API_URL, SUPPORT_API_URL } from '../constant';
import { apiCall } from '../lib/fetch';

const read = async (id: string): Promise<Person | undefined> => {
  return await apiCall('POST', SUPPORT_API_URL, '/person/read', { person: { id: id } });
};

export interface ConnectionsResponse {
  churchpersons: ChurchPerson[];
  ownedChurches: Church[];
}

const connections = async (id: string): Promise<ConnectionsResponse> => {
  return await apiCall('GET', SUPPORT_API_URL, `/person/${id}/churches`);
};

export interface ListSourcesResponse {}

const listSources = async (personId: string): Promise<Source[]> => {
  return await apiCall('GET', GIVING_API_URL, `/source/list?includeexpired=true&personid=${personId}`);
};

const listGiftSchedules = async (personId: string): Promise<any[]> => {
  return await apiCall(
    'GET',
    GIVING_API_URL,
    // TODO: make this query string parameter camelCase
    `/giftschedule/list?person_id=${personId}`
  );
};

export interface ListTransactionsResponse {
  columns: any[];
  rows: any[];
}

const listTransactions = async (personId: string): Promise<ListTransactionsResponse> => {
  return await apiCall('POST', GIVING_API_URL, `/transaction/list`, { person: { id: personId } });
};

export const PersonHttpApi = {
  read: read,
  connections: connections,
  listSources: listSources,
  listGiftSchedules: listGiftSchedules,
  listTransactions: listTransactions,
};

import React from 'react';
import { useParams } from 'react-router';
import { Layout } from '../../../component/Layout';
import { LoadingSpinnerBlock } from '../../../component/LoadingSpinner';
import { ObjectsTable } from '../../../component/ObjectsTable';
import { useSources } from '../../../hooks/useSources';

export const PaymentMethods = () => {
  const params = useParams<{ personId: string }>();
  const { data: paymentMethods = [], isLoading } = useSources(params.personId);

  if (isLoading) {
    return (
      <Layout.Panel>
        <LoadingSpinnerBlock />
      </Layout.Panel>
    );
  }

  if (paymentMethods.length === 0) {
    return (
      <Layout.Card.Message>
        <Layout.Header headline={'No Payment Methods'} />
      </Layout.Card.Message>
    );
  }

  return (
    <>
      {paymentMethods.length > 0 && (
        <Layout.Panel>
          <ObjectsTable
            headline={`Payment Methods`}
            statement={params.personId}
            data={paymentMethods}
            keys={['id', 'institution_name', 'account_name', 'type', 'account_last_few']}
          />
        </Layout.Panel>
      )}
    </>
  );
};

import React from 'react';
import { Button } from '../../../../../../../component/Button';
import { useMerchants, useRefreshMerchant } from '../../../../../../../hooks/useMerchant';
import { useRpMerchantMutation } from '../../../../../../../hooks/useRpMerchant';

export const SetFeeButtons = (props: any) => {
  const { data: merchants = [] } = useMerchants(props.church.id);
  const merchant = merchants.find((merchant) => merchant.rebelpay_merchant_id === props.rpmerchant.id);

  const { mutateAsync: updateRpMerchant, isPending: updatePending } = useRpMerchantMutation(props.rpmerchant);
  const { mutateAsync: refreshMerchant, isPending: refreshPending } = useRefreshMerchant(props.church, merchant);

  const isLoading = updatePending || refreshPending;

  if (merchant === undefined) {
    return null;
  }

  const doUpdateRPMerchant = async (fee: number) => {
    await updateRpMerchant({
      fee_override: {
        ach_debit: {
          processorFixed: fee,
        },
      },
    });
  };

  const doSetAchFee = async (fee: number) => {
    await doUpdateRPMerchant(fee);
    await refreshMerchant();
  };

  const handleSetToZeroClicked = () => {
    doSetAchFee(0);
  };

  const handleSetTo25Clicked = () => {
    doSetAchFee(25);
  };

  return (
    <>
      {props.rpmerchant.fee_structure.ach_debit.processorFixed === 0 ? (
        <Button
          shape="squared"
          size="small"
          variant="secondary"
          onClick={handleSetTo25Clicked}
          loading={isLoading}
          disabled={isLoading === true}
          style={{ marginLeft: '2rem', padding: '1rem' }}
        >
          Set ACH to $0.25
        </Button>
      ) : (
        <Button
          shape="squared"
          size="small"
          variant="secondary"
          onClick={handleSetToZeroClicked}
          loading={isLoading}
          disabled={isLoading === true}
          style={{ marginLeft: '2rem', padding: '0.5rem' }}
        >
          Set ACH to $0
        </Button>
      )}
    </>
  );
};

export function withStopPropagation(func) {
  return function (e, ...args) {
    e.stopPropagation();
    func(...args);
  };
}

export function withPreventDefault(func) {
  return function (e, ...args) {
    e.preventDefault();
    func(...args);
  };
}

export function addEventListeners(events, callback, target = window, passive = true) {
  events.forEach((event) => target.addEventListener(event, callback, { passive: passive }));
  return () => events.forEach((event) => target.removeEventListener(event, callback));
}

import { Headline, Statement } from '@foyyay/control-elements';
import _orderBy from 'lodash/orderBy';
import React, { MouseEventHandler, useContext, useState } from 'react';
import { Clickable } from '../../../../../../../../component/Button';
import { RelativeDate } from '../../../../../../../../component/Date';
import { Panel } from '@foyyay/control-elements';
import { Table } from '../../../../../../../../component/Table';
import {
  BILLING_STATUS_TO_NAME,
  InvoiceModel,
  PaymentModel,
  SOURCE_TYPE_TO_NAME,
} from '../../../../../../../../constant';
import { AMOUNT_FORMATTER } from '../../../../../../../../lib/formatting';
import { entityPlatform } from '../../../../../../../../lib/platform';
import { SubscriptionControllerContext } from '../../../state/context';
import {
  collectInvoice,
  invoiceIsCollectableSelector,
  paymentsByInvoiceSelector,
  refundPayment,
  selectBalance,
  selectSubscription,
} from '../../../state/reducer';
import { SIZE } from '../../../../../../../../component/style-utils';
import { Layout } from '../../../../../../../../component/Layout';

interface PaymentPanelProps {
  invoice: InvoiceModel;
}

export const PaymentPanel = (props: PaymentPanelProps) => {
  const { useSelector } = useContext(SubscriptionControllerContext);

  const payments = useSelector(paymentsByInvoiceSelector(props.invoice));
  const charges = _orderBy(
    payments.filter((payment) => payment.refund_of === undefined),
    ['created_at'],
    ['asc']
  );
  const refunds = _orderBy(
    payments.filter((payment) => payment.refund_of !== undefined),
    ['created_at'],
    ['asc']
  );

  if (charges.length < 1) {
    return null;
  }
  // TODO: sort charges and return payment/refund in different rows
  return (
    <Layout.Panel>
      <Headline>Payments</Headline>
      <Table.OverflowAuto>
        <Table>
          <Table.Thead>
            <Table.Row>
              <Table.Header>Status</Table.Header>
              <Table.Header>Total</Table.Header>
              <Table.Header>Date</Table.Header>
              <Table.Header>Source</Table.Header>
              <Table.Header>Name</Table.Header>
              <Table.Header>Account</Table.Header>
              <Table.Header>Expires</Table.Header>
              <Table.Header>Message</Table.Header>
            </Table.Row>
          </Table.Thead>
          <Table.Tbody>
            {charges.map((charge: PaymentModel) => {
              const chargesRefunded = refunds.filter((refund) => refund.refund_of === charge.id);
              return (
                <>
                  <ChargeRow charge={charge} refunds={refunds} />
                  {chargesRefunded.map((refund: PaymentModel) => (
                    <RefundRow refund={refund} />
                  ))}
                </>
              );
            })}
          </Table.Tbody>
        </Table>
      </Table.OverflowAuto>
    </Layout.Panel>
  );
};

// TODO: separate the paid rows from the refund rows
const ChargeRow = (props) => {
  const { dispatch, useSelector } = useContext(SubscriptionControllerContext);
  const subscription = useSelector(selectSubscription);

  const doRefundPayment = async (refundAmount: number) => {
    await dispatch(
      refundPayment({
        subscription: subscription,
        payment: { subscription_id: props.charge.subscription_id, id: props.charge.id },
        refundAmount: refundAmount,
      })
    );
  };

  const handleAmountClicked: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    const refund = getAmount(props.charge.total);
    if (refund === undefined) {
      return;
    }
    doRefundPayment(refund.amount);
  };

  return (
    <Table.Row platform={entityPlatform(subscription)} key={props.charge.id}>
      <Table.Cell>{getBillingStatus(props.charge, props.refunds)}</Table.Cell>
      <Table.Cell>
        <Clickable onClick={handleAmountClicked}>{AMOUNT_FORMATTER(props.charge.total)}</Clickable>
      </Table.Cell>
      <Table.Cell>
        <RelativeDate date={props.charge.created_at} />
      </Table.Cell>
      <Table.Cell>{props.charge.source_type}</Table.Cell>
      <Table.Cell>
        {[props.charge.source_account_name, props.charge.source_institution_name]
          .filter((value) => value !== undefined && value.length > 1)
          .join(' / ')}
      </Table.Cell>
      <Table.Cell>
        {props.charge.source_last_few !== undefined && '*'}
        {props.charge.source_last_few}
      </Table.Cell>
      <Table.Cell>
        {[props.charge.source_expire_month, props.charge.source_expire_year]
          .filter((item) => item !== undefined)
          .join('/')}
      </Table.Cell>
      <Table.Cell>{props.charge.rebelpay_charge_message}</Table.Cell>
    </Table.Row>
  );
};

const getBillingStatus = (charge: PaymentModel, refunds: Array<PaymentModel>) => {
  const refundsApplied = refunds.filter((refund) => refund.refund_of === charge.id && refund.status === 'STATUS_PAID');
  const refundedAmount = refundsApplied.reduce((acc: number, next: PaymentModel) => acc + next.total, 0);

  if (refundedAmount === charge.total) {
    return 'Refunded';
  } else if (refundsApplied.length > 0) {
    return 'Partially Refunded';
  } else {
    return BILLING_STATUS_TO_NAME[charge.status];
  }
};

const getAmount = (amount: number): { amount: number } | undefined => {
  const currentAmount = amount as number;
  const answer = window.prompt(['Enter the amount to refund from this payment:'].join('\n'), `${currentAmount / 100}`);
  if (answer === null) {
    return;
  }

  const newAmount = parseFloat(answer) * 100;
  if (isNaN(newAmount)) {
    window.alert(`${answer} is not a number`);
    return;
  }

  if (newAmount < 100) {
    window.alert('Amount must be at least $1');
    return;
  }

  if (currentAmount < newAmount) {
    return;
  }

  return { amount: newAmount };
};

const RefundRow = (props) => {
  const { useSelector } = useContext(SubscriptionControllerContext);
  const subscription = useSelector(selectSubscription);

  return (
    <Table.Row platform={entityPlatform(subscription)} key={props.refund.id}>
      <Table.Cell>{BILLING_STATUS_TO_NAME[props.refund.status]}</Table.Cell>
      <Table.Cell>{AMOUNT_FORMATTER(props.refund.total)}</Table.Cell>
      <Table.Cell>
        <RelativeDate date={props.refund.created_at} />
      </Table.Cell>
      <Table.Cell></Table.Cell>
      <Table.Cell></Table.Cell>
      <Table.Cell></Table.Cell>
      <Table.Cell></Table.Cell>
      <Table.Cell>{props.refund.rebelpay_charge_message}</Table.Cell>
    </Table.Row>
  );
};

interface CollectionPanelProps {
  invoice: InvoiceModel;
}

export const CollectionPanel = (props: CollectionPanelProps) => {
  const { useSelector, dispatch } = useContext(SubscriptionControllerContext);
  const [collecting, setCollecting] = useState(false);
  const subscription = useSelector(selectSubscription);
  const balance = useSelector(selectBalance);
  const isPayable = useSelector(invoiceIsCollectableSelector(props.invoice));
  const credit = Math.max(balance * -1, 0);

  const hasPaymentSource = subscription.rebelpay_source_id !== undefined;
  const paymentSourceName = SOURCE_TYPE_TO_NAME[subscription.source_type || ''];

  if (isPayable !== true) {
    return null;
  }

  const customerCanPay = credit >= props.invoice.total || hasPaymentSource === true;

  const actionMessage = `Collect ${AMOUNT_FORMATTER(props.invoice.total)}`;

  const message = ['Paying for this invoice will use'];
  if (credit > 0) {
    if (credit > props.invoice.total) {
      message.push(`${AMOUNT_FORMATTER(props.invoice.total)} of`);
    } else {
      message.push('all of');
    }
    message.push(`their ${AMOUNT_FORMATTER(credit)} balance`);
    if (credit < props.invoice.total) {
      message.push(`and the remaining ${AMOUNT_FORMATTER(props.invoice.total - credit)} will be collected using`);
    }
  }

  if (credit < props.invoice.total) {
    message.push(`their ${paymentSourceName}`);
  }

  const doCollectInvoice = async () => {
    setCollecting(true);
    try {
      await dispatch(collectInvoice({ invoice: props.invoice, subscription: subscription, balance: balance }));
    } finally {
      setCollecting(false);
    }
  };

  const guardCollectInvoice = () => {
    if (collecting === true) {
      return;
    }

    if (isPayable !== true) {
      return;
    }

    if (customerCanPay !== true) {
      return;
    }

    if (credit < props.invoice.total) {
      const areYouSure = window.confirm(
        [
          'Are you sure?',
          `This will attempt to charge the customers ${paymentSourceName}`,
          `${AMOUNT_FORMATTER(props.invoice.total - credit)} rignt now`,
        ].join('\n')
      );
      if (areYouSure !== true) {
        return;
      }
    }

    doCollectInvoice();
  };

  const handleCollectClicked = () => {
    guardCollectInvoice();
  };

  const willTryCollecting = props.invoice.next_charge_at !== undefined && isPayable === true;

  const renderNextChargeDate = willTryCollecting === true;
  const renderCollectButton = isPayable === true && customerCanPay === true;
  const renderCollectionMessage = renderCollectButton === true;
  const renderCannotCollectMessage = isPayable === true && customerCanPay !== true;

  return (
    <Panel
      dirty={renderCollectButton}
      dirtyMessage={actionMessage}
      onButtonClick={handleCollectClicked}
      loading={collecting}
      style={{ marginTop: SIZE[2] }}
    >
      <Headline>Collection</Headline>
      {renderNextChargeDate && (
        <Statement>
          Invoice will be <strong>auto collected</strong> after{' '}
          <strong>
            <RelativeDate date={props.invoice.next_charge_at} />
          </strong>
        </Statement>
      )}
      {renderCollectionMessage && <Statement>{message.join(' ')}</Statement>}
      {renderCannotCollectMessage && <Statement>This is no way to collect payment for this invoice.</Statement>}
    </Panel>
  );
};

import React, { useContext } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { Layout } from '../../../../../../../component/Layout';
import { LoadingSpinnerBlock } from '../../../../../../../component/LoadingSpinner';
import { SubscriptionControllerContext } from '../../state/context';
import { invoiceByIdSelector, selectLoading } from '../../state/reducer';
import { InvoicePanel } from './components/Invoice';
import { CollectionPanel, PaymentPanel } from './components/Payment';

export const InvoiceRoutes = () => {
  const params = useParams();
  const { useSelector } = useContext(SubscriptionControllerContext);
  const invoice = useSelector(invoiceByIdSelector(params.invoiceId ?? ''));

  return (
    <Routes>
      <Route path={'/'} element={<Invoice invoice={invoice} />} />
    </Routes>
  );
};

const Invoice = (props) => {
  const { useSelector } = useContext(SubscriptionControllerContext);
  const loading = useSelector(selectLoading);

  if (loading === true) {
    return (
      <Layout.Panel>
        <LoadingSpinnerBlock />
      </Layout.Panel>
    );
  }

  if (props.invoice === undefined) {
    return (
      <Layout.Panel>
        <Layout.Card.Message>
          <Layout.Header headline="Invoice Not Found" />
        </Layout.Card.Message>
      </Layout.Panel>
    );
  }

  return (
    <>
      <InvoicePanel invoice={props.invoice} />
      <CollectionPanel invoice={props.invoice} />
      <PaymentPanel invoice={props.invoice} />
    </>
  );
};

import { omit as _omit } from 'lodash';
import { Church } from '../../type/Church';
import { CustomProcess } from '../../type/CustomProcess';
import { Nullable } from '../../type/utility';
import { NUCLEUS_PLATFORM_API_URL } from '../constant';
import { apiCall } from '../lib/fetch';

export const CustomProcessHttpApi = {
  read: async (
    church: Pick<Church, 'id'>,
    process: string,
    entityId: string,
    isSetupMode: boolean | undefined
  ): Promise<{ globals: CustomProcess }> => {
    let queryString = '';
    if (isSetupMode === false) {
      queryString = '?setup=false';
    }
    return await apiCall(
      'GET',
      NUCLEUS_PLATFORM_API_URL,
      `/custom-process/${church.id}/process/${process}/entity/${entityId}${queryString}`
    );
  },
  update: async (
    church: Pick<Church, 'id'>,
    process: string,
    entityId: string,
    updates: Omit<CustomProcess, 'features'>
  ): Promise<{ globals: CustomProcess }> => {
    const body: { globals: Nullable<Partial<CustomProcess>> } = {
      globals: {
        ..._omit(updates, ['features']),
      },
    };
    return await apiCall(
      'PATCH',
      NUCLEUS_PLATFORM_API_URL,
      `/custom-process/${church.id}/process/${process}/entity/${entityId}`,
      body
    );
  },
};

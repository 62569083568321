import { createAction, createAsyncThunk, createReducer, PayloadAction } from '@reduxjs/toolkit';
import _intersectionBy from 'lodash/intersectionBy';
import _sortBy from 'lodash/sortBy';
import {
  BillingInterval,
  BillingItem,
  BillingItemCredit,
  BillingItemDebit,
  BillingItemDiscount,
  BillingItemModel,
  BillingItemProduct,
  BillingItemType,
  BillingStatus,
  InvoiceModel,
  NUCLEUS_ONE_PRODUCT_CODES,
  PaymentModel,
  ProductModel,
  SubscriptionModel,
  SUPPORT_API_URL,
} from '../../../../../../constant';
import { apiCall } from '../../../../../../lib/fetch';
import { NullableKeys, OnlyKeysRequired } from '../../../../../../../type/utility';
import _keyBy from 'lodash/keyBy';

interface State {
  loading: boolean;
  loadError?: string;
  subscription: SubscriptionModel;
  items?: Array<BillingItemModel>;
  invoices?: Array<InvoiceModel & { status?: BillingStatus }>;
  payments?: Array<PaymentModel>;
  costs?: {
    invoiceTotal: number;
    productTotal: number;
    discountTotal: number;
  };
  nextInvoice?: InvoiceModel;
  loadingProducts: boolean;
  loadingProductsError?: any;
  products?: Array<ProductModel>;
  highlightedDiscountedItemsOf?: BillingItemDiscount;
}

// Actions.
interface ListSubscriptionArgs {
  churchId: string;
  subscriptionId: string;
}

interface ListSubscriptionResponse {
  subscription: SubscriptionModel;
  items: Array<BillingItemModel>;
  invoices: Array<InvoiceModel>;
  payments: Array<any>;
  costs: {
    invoiceTotal: number;
    productTotal: number;
    discountTotal: number;
  };
  nextInvoice: InvoiceModel;
}
export const readSubscription = createAsyncThunk<ListSubscriptionResponse, ListSubscriptionArgs>(
  'subscription/read',
  async (args) => {
    const response = await apiCall(
      'GET',
      SUPPORT_API_URL,
      `/billing/subscription/${args.churchId}/${args.subscriptionId}`
    );

    return {
      subscription: response.subscription,
      items: response.items,
      invoices: response.invoices,
      payments: response.payments,
      costs: response.costs,
      nextInvoice: response.nextInvoice,
    };
  }
);

interface UpdateSubscriptionArgs {
  subscription: SubscriptionModel;
  updates: {
    ended_at?: Date | null;
    interval?: BillingInterval;
    next_invoice_at?: Date | null;
    next_settlement_at?: Date | null;
    status_override?: BillingStatus | null;
    nucleus_one_stripe_subscription_id?: string | null;
  };
}

interface UpdateSubscriptionResponse {
  subscription: SubscriptionModel;
}

export const updateSubscription = createAsyncThunk<UpdateSubscriptionResponse, UpdateSubscriptionArgs>(
  'subscription/update',
  async (args, thunkAPI) => {
    const response = await apiCall(
      'POST',
      SUPPORT_API_URL,
      `/billing/subscription/${args.subscription.church_id}/${args.subscription.id}`,
      {
        subscription: args.updates,
      }
    );
    thunkAPI.dispatch(
      readSubscription({ churchId: args.subscription.church_id, subscriptionId: args.subscription.id })
    );
    return { subscription: response.subscription };
  }
);

interface RunSubscriptionActionArgs {
  subscription: SubscriptionModel;
  action: string;
}

export const runSubscriptionAction = createAsyncThunk<void, RunSubscriptionActionArgs>(
  'subscription/runSubscriptionAction',
  async (args) => {
    await apiCall(
      'POST',
      SUPPORT_API_URL,
      `/billing/subscription/${args.subscription.church_id}/${args.subscription.id}/actions/${args.action}`
    );
  }
);

export const runLifecycleHooks = (args: Omit<RunSubscriptionActionArgs, 'action'>) =>
  runSubscriptionAction({ ...args, action: 'run-lifecycle-hooks' });

export const linkNucleusOne = (args: Omit<RunSubscriptionActionArgs, 'action'>) =>
  runSubscriptionAction({ ...args, action: 'link-nucleus-one' });

export const unlinkNucleusOne = (args: Omit<RunSubscriptionActionArgs, 'action'>) =>
  runSubscriptionAction({ ...args, action: 'unlink-nucleus-one' });

interface LoadProductsResponse {
  products: Array<any>;
}

export const loadProducts = createAsyncThunk<LoadProductsResponse>('subscription/products/load', async () => {
  const response = await apiCall('GET', SUPPORT_API_URL, '/billing/products');
  return {
    products: response.products,
  };
});

interface CreateItemArgs {
  item: Partial<BillingItem>;
}

interface CreateItemResponse {
  item: BillingItemModel;
}

export const createItem = createAsyncThunk<CreateItemResponse, CreateItemArgs>(
  'subscription/item/create',
  async (args, thunkAPI) => {
    const subscription = selectSubscription(thunkAPI.getState() as State);
    const response = await apiCall('POST', SUPPORT_API_URL, `/billing/items/${subscription.id}`, { item: args.item });
    thunkAPI.dispatch(readSubscription({ churchId: subscription.church_id, subscriptionId: subscription.id }));
    return { item: response.item };
  }
);

interface UpdateItemArgsBase<U> {
  item: BillingItem;
  updates: U;
}

type UpdateProductItemArgs = UpdateItemArgsBase<{ ended_at?: Date | null }>;
type UpdateDiscountItemArgs = UpdateItemArgsBase<{
  ended_at?: Date | null;
  data?: Omit<BillingItemDiscount['data'], 'applies_to_items' | 'description'>;
}>;
type UpdateItemArgs = UpdateProductItemArgs | UpdateDiscountItemArgs;

interface UpdateItemResponse {
  item: BillingItemModel;
}

export const updateItem = createAsyncThunk<UpdateItemResponse, UpdateItemArgs>(
  'subscription/item/update',
  async (args, thunkAPI) => {
    const subscription = selectSubscription(thunkAPI.getState() as State);
    const response = await apiCall('POST', SUPPORT_API_URL, `/billing/items/${subscription.id}/${args.item.id}`, {
      item: args.updates,
    });
    thunkAPI.dispatch(readSubscription({ churchId: subscription.church_id, subscriptionId: subscription.id }));
    return { item: response.item };
  }
);

interface CreateManualInvoiceArgs {
  invoice: Partial<InvoiceModel>;
}
interface CreateManualInvoiceResponse {
  invoice: InvoiceModel;
}

export const createManualInvoice = createAsyncThunk<CreateManualInvoiceResponse, CreateManualInvoiceArgs>(
  'subscription/invoice/create/manual',
  async (args, thunkAPI) => {
    const subscription = selectSubscription(thunkAPI.getState() as State);
    const response = await apiCall('POST', SUPPORT_API_URL, `/billing/invoices/${subscription.id}`, {
      invoice: args.invoice,
    });
    thunkAPI.dispatch(readSubscription({ churchId: subscription.church_id, subscriptionId: subscription.id }));
    return {
      invoice: response.invoice,
    };
  }
);

interface RefundPaymentArgs {
  subscription: Pick<SubscriptionModel, 'church_id' | 'id'>;
  payment: Pick<PaymentModel, 'id' | 'subscription_id'>;
  refundAmount: number;
}
interface RefundPaymentResponse {
  refund: PaymentModel;
}

export const refundPayment = createAsyncThunk<RefundPaymentResponse, RefundPaymentArgs>(
  'subscription/payment/refund',
  async (args, thunkAPI) => {
    if (args.subscription.id !== args.payment.subscription_id) {
      throw new Error('Payment and subscription do not match');
    }
    let response = { payment: {} as PaymentModel };
    try {
      response = await apiCall(
        'POST',
        SUPPORT_API_URL,
        `/billing/payments/${args.subscription.id}/${args.payment.id}/refund`,
        {
          refundAmount: args.refundAmount,
        }
      );
    } catch (error: any) {
      console.error(error?.message);
    }

    // TODO: do we need to reload this - or can we just modify the payaments array in state?
    thunkAPI.dispatch(
      readSubscription({ churchId: args.subscription.church_id, subscriptionId: args.subscription.id })
    );
    return {
      refund: response.payment,
    };
  }
);

interface UpdateInvoiceArgs {
  subscription: Pick<SubscriptionModel, 'church_id' | 'id'>;
  invoice: Pick<InvoiceModel, 'id' | 'subscription_id'>;
  updateValues: NullableKeys<
    Partial<
      Pick<InvoiceModel, 'total' | 'next_charge_at' | 'status_override' | 'period_started_at' | 'period_ended_at'>
    >,
    'total' | 'next_charge_at' | 'status_override'
  >;
}
interface UpdateInvoiceResponse {
  invoice: InvoiceModel;
}

export const updateInvoice = createAsyncThunk<UpdateInvoiceResponse, UpdateInvoiceArgs>(
  'subscription/invoice/create/manual',
  async (args, thunkAPI) => {
    if (args.subscription.id !== args.invoice.subscription_id) {
      throw new Error('Invoice and subscription do not match');
    }

    const response = await apiCall(
      'POST',
      SUPPORT_API_URL,
      `/billing/invoices/${args.invoice.subscription_id}/${args.invoice.id}`,
      {
        updateValues: args.updateValues,
      }
    );
    thunkAPI.dispatch(
      readSubscription({ churchId: args.subscription.church_id, subscriptionId: args.subscription.id })
    );
    return {
      invoice: response.invoice,
    };
  }
);

interface CollectInvoiceArgs {
  invoice: OnlyKeysRequired<InvoiceModel, 'id'>;
  subscription: OnlyKeysRequired<SubscriptionModel, 'id' | 'church_id'>;
  balance: number;
}
interface CollectInvoiceResponse {
  invoice: InvoiceModel;
  items: Array<BillingItemModel>;
  payment: PaymentModel;
}

export const collectInvoice = createAsyncThunk<CollectInvoiceResponse, CollectInvoiceArgs>(
  'subscription/invoice/collect',
  async (args, thunkAPI) => {
    if (args.invoice.status === undefined) {
      throw new Error('Cannot collect an invoice with no status');
    }

    const response = await apiCall(
      'POST',
      SUPPORT_API_URL,
      `/billing/invoices/${args.subscription.id}/${args.invoice.id}/collect`,
      {
        sourceId: args.subscription.rebelpay_source_id,
        accountBalance: args.balance,
        invoiceStatus: args.invoice.status,
      }
    );

    thunkAPI.dispatch(
      readSubscription({ churchId: args.subscription.church_id, subscriptionId: args.subscription.id })
    );
    return {
      invoice: response.invoice,
      items: response.items,
      payment: response.payment,
    };
  }
);

export const highlightDiscountProduct = createAction<BillingItem>('subscription/discount/highlightAppliesTo');

// Selectors.
export const selectLoading = (state: State) => state.loading === true;

export const selectLoadError = (state: State) => state.loadError;

export const selectSubscription = (state: State) => state.subscription;

export const selectSubscriptionIsEnding = (state: State) =>
  state.subscription.ended_at !== undefined && new Date(state.subscription.ended_at) > new Date();

export const selectSubscriptionHasEnded = (state: State) =>
  state.subscription.ended_at !== undefined && new Date(state.subscription.ended_at) < new Date();

export const selectItems = (state: State) => state.items ?? [];

export const selectActiveItems = (state: State) =>
  selectItems(state).filter((item) => item.ended_at === undefined || new Date(item.ended_at) > new Date());

export const itemsByTypeSelector =
  (type: BillingItemType) =>
  (state: State): Array<BillingItemModel> =>
    selectItems(state).filter((item) => item.type === type);

export const selectProductItems = (state: State): Array<BillingItemProduct> => {
  let products = itemsByTypeSelector(BillingItemType.Product)(state) as Array<BillingItemProduct>;

  products = _sortBy(products, 'data.product_name').reverse();
  products = _sortBy(products, 'ended_at').reverse();

  return products;
};

export const selectActiveProductItems = (state: State) =>
  itemIntersection(selectProductItems(state), selectActiveItems(state));

export const selectNucleusOneProductItems = (state: State) =>
  selectProductItems(state).filter((productItem) => NUCLEUS_ONE_PRODUCT_CODES.includes(productItem.data.product_code));

export const selectActiveNucleusOneProductItems = (state: State) =>
  itemIntersection(selectNucleusOneProductItems(state), selectActiveItems(state));

export const selectPaidItems = (state: State) => selectProductItems(state).filter((item) => item.data.monthly_cost > 0);

export const selectActivePaidItems = (state: State) =>
  itemIntersection(selectPaidItems(state), selectActiveItems(state));

export const selectDiscountItems = (state: State): Array<BillingItemDiscount> =>
  _sortBy(itemsByTypeSelector(BillingItemType.Discount)(state) as Array<BillingItemDiscount>, 'started_at').reverse();

export const selectDebitsAndCredits = (state: State): Array<BillingItemCredit | BillingItemDebit> =>
  _sortBy(
    [
      ...itemsByTypeSelector(BillingItemType.Debit)(state),
      ...itemsByTypeSelector(BillingItemType.Credit)(state),
    ] as Array<BillingItemCredit | BillingItemDebit>,
    'started_at'
  ).reverse();

export const selectBalance = (state: State): number => {
  const debitItems = itemsByTypeSelector(BillingItemType.Debit)(state) as Array<BillingItemDebit>;
  const creditItems = itemsByTypeSelector(BillingItemType.Credit)(state) as Array<BillingItemCredit>;
  const debitBalance = debitItems.reduce((sum, item) => item.data.amount + sum, 0);
  const creditBalance = creditItems.reduce((sum, item) => item.data.amount + sum, 0);
  return debitBalance - creditBalance;
};

export const selectInvoices = (state: State) => _sortBy(state.invoices, 'created_at').reverse();

export const invoiceByIdSelector =
  (invoiceId: string) =>
  (state: State): InvoiceModel | undefined => {
    if (invoiceId === 'next') {
      return state.nextInvoice;
    }
    return selectInvoices(state).find((invoice) => invoice.id === invoiceId);
  };

export const invoiceIsCollectableSelector =
  (invoice?: InvoiceModel) =>
  (state: State): boolean => {
    if (invoice?.status === undefined) {
      return false;
    }

    // Active and unpaid can always be collected.
    if ([BillingStatus.Active, BillingStatus.Unpaid].includes(invoice.status) === true) {
      return true;
    }

    // The only remaining status that can be collected is past due.
    if (BillingStatus.PastDue !== invoice.status) {
      return false;
    }

    // A past due invoice can only be collected if it's the most recent invoice. This is because the balance of
    // the past due invoice was moved to the account and then added to the newest invoice (most likely);
    if (selectInvoices(state)[0]?.id !== invoice.id) {
      return false;
    }

    return true;
  };

export const selectLastInvoice = (state: State): InvoiceModel | undefined => selectInvoices(state)[0];

export const selectNextInvoice = (state: State): InvoiceModel | undefined => state.nextInvoice;

export const selectNextInvoices = (state: State): Array<InvoiceModel> =>
  [state.nextInvoice].filter((invoice): invoice is InvoiceModel => invoice !== undefined);

export const selectPayments = (state: State) => _sortBy(state.payments, 'created_at').reverse();

export const paymentsByInvoiceSelector = (invoice: InvoiceModel) => (state: State) =>
  selectPayments(state).filter((payment) => payment.invoice_id === invoice.id);

export const selectCosts = (state: State) => state.costs;

export const selectProductsLoading = (state: State) => state.loadingProducts === true;

export const selectProducts = (state: State) => state.products ?? [];

export const selectAddableProducts = (state: State) => {
  const allProducts = selectProducts(state);
  const activeProductCodes = selectActiveProductItems(state).map((item) => item.data.product_code);
  let addableProducts = allProducts.filter((product) => activeProductCodes.includes(product.code) !== true);

  addableProducts = _sortBy(addableProducts, 'monthly_cost');
  addableProducts = _sortBy(addableProducts, 'name').reverse();
  addableProducts = _sortBy(addableProducts, 'public');
  addableProducts = _sortBy(addableProducts, 'active').reverse();

  return addableProducts;
};

export const itemIsHighlightedSelector = (item: BillingItem) => (state: State) => {
  const highlightedIds = state.highlightedDiscountedItemsOf?.data.applies_to_items ?? [];
  return highlightedIds.includes(item.id) === true;
};

const itemIntersection = <T>(listA: Array<T>, listB): Array<T> => _intersectionBy(listA, listB, 'id');

// Reducer.
export const subscriptionReducerInit = ({ subscription }): State => {
  return {
    loading: false,
    subscription: subscription,
    loadingProducts: false,
  };
};

export const subscriptionReducer = createReducer<State>({} as State, {
  [readSubscription.pending.type]: (state: State): State => ({
    ...state,
    loading: true,
  }),
  [readSubscription.rejected.type]: (state: State, action): State => ({
    ...state,
    loading: false,
    loadError: action.error.message,
  }),
  [readSubscription.fulfilled.type]: (state: State, action: PayloadAction<ListSubscriptionResponse>): State => ({
    ...state,
    loading: false,
    subscription: action.payload.subscription,
    costs: action.payload.costs,
    invoices: action.payload.invoices,
    items: action.payload.items,
    nextInvoice: action.payload.nextInvoice,
    payments: action.payload.payments,
  }),
  [loadProducts.pending.type]: (state: State): State => ({
    ...state,
    loadingProducts: true,
  }),
  [loadProducts.rejected.type]: (state: State, action): State => ({
    ...state,
    loadingProducts: false,
    loadingProductsError: action.error.message,
  }),
  [loadProducts.fulfilled.type]: (state: State, action: PayloadAction<LoadProductsResponse>): State => ({
    ...state,
    loadingProducts: false,
    products: action.payload.products,
  }),
  [createItem.fulfilled.type]: (state: State, action: PayloadAction<CreateItemResponse>): State => ({
    ...state,
    items: [action.payload.item, ...selectItems(state)],
  }),
  [highlightDiscountProduct.type]: (state: State, action: PayloadAction<BillingItemDiscount>): State => {
    if (
      state.highlightedDiscountedItemsOf === undefined ||
      state.highlightedDiscountedItemsOf.id !== action.payload.id
    ) {
      return {
        ...state,
        highlightedDiscountedItemsOf: action.payload,
      };
    }

    return {
      ...state,
      highlightedDiscountedItemsOf: undefined,
    };
  },
  [createManualInvoice.fulfilled.type]: (state: State, action: PayloadAction<CreateManualInvoiceResponse>): State => {
    return {
      ...state,
      invoices: mergeBy('id', state.invoices, [action.payload.invoice]),
    };
  },
  [collectInvoice.fulfilled.type]: (state: State, action: PayloadAction<CollectInvoiceResponse>) => {
    return {
      ...state,
      invoices: mergeBy('id', state.invoices, [action.payload.invoice]),
      items: mergeBy('id', state.items, action.payload.items),
      payments: mergeBy('id', state.payments, [action.payload.payment]),
    };
  },
});

const mergeBy = <T>(attribute: string, leftList: Array<T> | undefined, rightList: Array<T | undefined>): Array<T> => {
  return Object.values({
    ..._keyBy(
      (leftList || []).filter((item) => item !== undefined),
      attribute
    ),
    ..._keyBy(rightList.filter((item) => item !== undefined) as Array<T>, attribute),
  });
};

import _get from 'lodash/get';
import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { loadSession } from '../../action/user';
import { REDIRECT_PARAM_KEY, ROLE } from '../../constant';
import { toBase64 } from '../../lib/encoding';
import { useLocationEffect } from '../../hooks/useLocationEffect';
import { AnyAction } from 'redux';
import { LoadingSpinner } from '../../component/LoadingSpinner';

export const RequireAuth = () => {
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const onRouteChanged = async () => {
    const sessionRequest = dispatch(loadSession() as unknown as AnyAction);
    let userSession;
    try {
      userSession = await sessionRequest;
    } catch (error) {
      userSession = undefined;
    }

    let patformRoles = _get(userSession, ['static_roles', 'platform'], []);

    if (!patformRoles.includes(ROLE.CustomerSupport)) {
      const redirectTo = toBase64(location);
      navigate(`/sign-in?${REDIRECT_PARAM_KEY}=${redirectTo}`);
      return;
    }

    if (loaded !== true) {
      setLoaded(true);
    }
  };

  // called after function has been rendered and inserted into the dom
  useLocationEffect(onRouteChanged);

  const stillLoading = loaded !== true;
  if (stillLoading) {
    return (
      <Fragment>
        <LoadingSpinner />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Outlet />
    </Fragment>
  );
};

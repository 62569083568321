import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { SIZE } from './style-utils';

export const MenuPortal = (props: { children: React.ReactNode }): React.ReactPortal => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return ReactDOM.createPortal(props.children, document.getElementById('menu-root')!);
};

export const MenuDivider = styled.hr`
  box-sizing: border-box;
  appearance: none;
  border: 0;
  border-top: 2px solid ${(props) => props.theme.MenuDivider.Color.Default.Background};
  border-radius: 2px;
  width: ${`calc(100% - ${SIZE[1]})`};
  padding: 0;
  margin: 0.3rem auto;
`;

import { upperFirst as _upperFirst } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Layout } from '../../../component/Layout';
import { LoadingSpinnerBlock } from '../../../component/LoadingSpinner';
import { Table } from '../../../component/Table';
import { usePerson } from '../../../hooks/usePerson';
import { LINK_FORMATTER } from '../../../lib/objectGridAttributeLibrary';

export const Roles = () => {
  const params = useParams<{ personId: string }>();
  const personId = params.personId ?? '';
  const { data: personData, isLoading } = usePerson(personId);

  if (isLoading) {
    return (
      <Layout.Panel>
        <LoadingSpinnerBlock />
      </Layout.Panel>
    );
  }

  return (
    <>
      {personData.roles?.length > 0 && (
        <Layout.Panel platform="rebelgive">
          <Layout.Header headline="Roles" />
          <Layout.Group>
            <Table.OverflowAuto>
              <Table>
                <Table.Thead>
                  <Table.Row>
                    <Table.Header>Church</Table.Header>
                    <Table.Header>Role</Table.Header>
                    <Table.Header>Active</Table.Header>
                  </Table.Row>
                </Table.Thead>
                <Table.Tbody>
                  {personData.roles.map((role) => {
                    return (
                      <Table.Row key={role.object}>
                        <Table.Cell>{LINK_FORMATTER(role.object)}</Table.Cell>
                        <Table.Cell>{formatRole(role.name)}</Table.Cell>
                        <Table.Cell>{role.active === true ? 'Active' : 'Inactive'}</Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Tbody>
              </Table>
            </Table.OverflowAuto>
          </Layout.Group>
        </Layout.Panel>
      )}
    </>
  );
};

const formatRole = (name) => {
  const parts = name.split(':');
  const length = parts.length;
  return _upperFirst(parts[length - 1].replace('_', ' '));
};

import React from 'react';

export const WebsiteLink = (props: any) => {
  let link = props.href;
  if (!link.includes('http')) {
    link = 'https://' + link;
  }
  return (
    <a
      style={{ textDecoration: 'underline' }}
      href={link}
      rel="noopener noreferrer"
      target="_blank"
    >
      {props.value}
    </a>
    // website link (outside of app)
  );
};

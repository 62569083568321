import { useInfiniteQuery } from '@tanstack/react-query';
import { MakeoverHttpApi } from '../api/MakeoverHttpApi';
import { withToQueryResult } from '../lib/toQueryResult';

export const useMakeoversInfiniteQuery = () => {
  return useInfiniteQuery({
    queryKey: ['makeovers'],
    queryFn: async ({ pageParam }) => {
      return await MakeoverHttpApi.list({ cursor: pageParam });
    },
    initialPageParam: undefined as string | undefined,
    getNextPageParam: (lastPage) => lastPage.cursor,
  });
};

export const useMakeovers = withToQueryResult(useMakeoversInfiniteQuery, (response) => response.makeovers);

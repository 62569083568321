import React, { useContext } from 'react';
import { Navigate, Route, Routes, useParams, useResolvedPath } from 'react-router-dom';
import { PillNav } from '../../../../../component/PillNav';
import { MerchantsContext } from '../components/MerchantsContext';
import { MerchantContext } from './context/MerchantContext';
import { ProcessorData } from './routes/ProcessorData';
import { Merchant } from './routes/Merchant';
import { RpMerchant } from './routes/RpMerchant';

export const MerchantRoutes = () => {
  const url = useResolvedPath('').pathname;
  const params = useParams();
  const merchantId = params?.merchantId;
  const merchants = useContext(MerchantsContext)?.merchants;
  const merchant = merchants?.find((merchant) => merchant.id === merchantId);

  const navList: Array<{ title: string; to: string }> = [];

  const hasRebelpay = merchant.rebelpay_merchant_id !== undefined;
  if (hasRebelpay === true) {
    navList.push(
      {
        title: 'Overview',
        to: `merchant`,
      },
      {
        title: 'Rebel Pay',
        to: `rpmerchant`,
      },
      {
        title: 'Processor Data',
        to: `processor-data`,
      }
    );
  }

  return (
    <MerchantContext.Provider value={{ merchant: merchant }}>
      <PillNav navList={navList} />
      <Routes>
        <Route path={'/merchant'} element={<Merchant />} />
        <Route path={'/rpmerchant'} element={<RpMerchant />} />
        <Route path={'/processor-data'} element={<ProcessorData />} />
        <Route path="/*" element={<Navigate to={`${url}/merchant`} replace={true} />} />
      </Routes>
    </MerchantContext.Provider>
  );
};

import styled from 'styled-components';
import { SIZE } from './style-utils';
import { B1 } from './Typography';

export const Byline = styled(B1)`
  margin-bottom: ${SIZE[1]};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 30rem;
`;

import React from 'react';
import { Navigate, Route, Routes, useResolvedPath } from 'react-router-dom';
import { LayoutPrimary } from '../../component/LayoutPrimary';
import { PillNav } from '../../component/PillNav';
import { useChurches } from '../../hooks/useChurches';
import { List } from './route/List';
import { Makeovers } from './route/Makeovers';
import { Search } from './route/Search';
import { Unboarded } from './route/Unboarded';

export const Churches = () => {
  const url = useResolvedPath('').pathname;
  const headline = 'Churches';
  const byline = 'Platform';

  useChurches();

  const navList = [
    {
      title: 'Search',
      to: `${url}/search`,
    },
    {
      title: 'Unboarded',
      to: `${url}/unboarded`,
    },
    {
      title: 'Makeovers',
      to: `${url}/makeovers`,
    },
  ];

  return (
    <LayoutPrimary layoutTitle={headline} layoutByline={byline}>
      <PillNav navList={navList} />
      <Routes>
        <Route path={'/search'} element={<Search />} />
        <Route path={'/unboarded'} element={<Unboarded />} />
        <Route path={'/makeovers'} element={<Makeovers />} />
        <Route path={'/list'} element={<List />} />
        <Route path="/*" element={<Navigate to={`${url}/search`} replace={true} />} />
      </Routes>
    </LayoutPrimary>
  );
};

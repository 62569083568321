import styled from 'styled-components';
import { P } from './Typography';
import { SIZE } from './style-utils';

export const Statement = styled(P)`
  max-width: 47.5rem;
  margin-top: ${SIZE[1]};
  opacity: 0.75;

  :first-child {
    margin-top: 0;
  }
`;

import {
  BillingInterval,
  BillingItemModel,
  BillingStatus,
  InvoiceModel,
  SubscriptionModel,
  SUPPORT_API_URL,
} from '../constant';
import { apiCall } from '../lib/fetch';

export interface ReadSubscriptionResponse {
  subscription: SubscriptionModel;
  items: Array<BillingItemModel>;
  invoices: Array<InvoiceModel>;
  payments: Array<any>;
  costs: {
    invoiceTotal: number;
    productTotal: number;
    discountTotal: number;
  };
  nextInvoice: InvoiceModel;
}

const readSubscription = async (churchId: string, subscriptionId: string): Promise<ReadSubscriptionResponse> => {
  return await apiCall('GET', SUPPORT_API_URL, `/billing/subscription/${churchId}/${subscriptionId}`);
};

interface UpdateSubscriptionArgs {
  subscription: SubscriptionModel;
  updates: {
    ended_at?: Date | null;
    interval?: BillingInterval;
    next_invoice_at?: Date | null;
    next_settlement_at?: Date | null;
    status_override?: BillingStatus | null;
    nucleus_one_stripe_subscription_id?: string | null;
  };
}

interface UpdateSubscriptionResponse {
  subscription: SubscriptionModel;
}

const updateSubscription = async (
  subscription: SubscriptionModel,
  update: UpdateSubscriptionArgs
): Promise<UpdateSubscriptionResponse> => {
  return await apiCall('POST', SUPPORT_API_URL, `/billing/subscription/${subscription.church_id}/${subscription.id}`, {
    subscription: update,
  });
};

interface ListSubscriptionsResponse {
  subscriptions: SubscriptionModel[];
}

const listSubscriptions = async (churchId: string): Promise<ListSubscriptionsResponse> => {
  return await apiCall('GET', SUPPORT_API_URL, `/billing/subscription/${churchId}`);
};

export const SubscriptionHttpApi = {
  read: readSubscription,
  update: updateSubscription,
  list: listSubscriptions,
};

import _orderBy from 'lodash/orderBy';
import React from 'react';
import { Layout } from '../../../component/Layout';
import { ObjectsTable } from '../../../component/ObjectsTable';
import { FORMAT_HINTS } from '../../../lib/objectGridAttributeLibrary';

export const ChurchesList = (props: any) => {
  const headline = props.headline || 'Church List';
  const churches = props.data;
  const keys = props.keys || ['id', 'name'];
  const firstAttributes = props.firstAttributes || ['name'];
  const hints = props.hints || {
    name: FORMAT_HINTS.NONE,
  };

  return (
    <Layout.Panel>
      <ObjectsTable
        headline={headline}
        statement={props.statement}
        data={_orderBy(churches, 'applied_at', 'desc')}
        keys={keys}
        firstAttributes={firstAttributes}
        rowKey={'id'}
        hints={hints}
      />
    </Layout.Panel>
  );
};

import { safeToArray } from '../lib/shape';

const BLOCK_VALUES = Object.freeze([
  // These values in requires will cause the fallback item to render.
  undefined,
  false,
]);

export const RenderBlocker = (props: RenderBlockerProps) => {
  const require = safeToArray(props.require);

  if (require.some((item) => BLOCK_VALUES.includes(item))) {
    return props.fallback;
  }
  return props.children;
};

type RequiredValue = boolean | undefined;

interface RenderBlockerProps {
  require: RequiredValue | Array<RequiredValue>;
  fallback: JSX.Element;
  children: JSX.Element;
}

RenderBlocker.defaultProps = {
  require: [],
  fallback: null,
};

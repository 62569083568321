import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { TransactionDetail } from './routes/TransactionDetail';
import { TransactionList } from './routes/TransactionList';

export const Transactions = () => {
  return (
    <Routes>
      <Route path={'/:transactionId/*'} element={<TransactionDetail />} />
      <Route path="/" element={<TransactionList />} />
    </Routes>
  );
};

import React from 'react';
import { UseSelector } from 'react-redux/es/hooks/useSelector';

interface MemorandumContext {
  dispatch: any;
  subjectId: string;
  useSelector: UseSelector;
}

export const MemorandumsContext = React.createContext<MemorandumContext>({} as MemorandumContext);

import React from 'react';
import { Button } from './Button';

export const ButtonConsoleLog = (props: { data: any }) => {
  return (
    <Button shape="pill" size="small" variant="tertiary" onClick={() => console.log(props.data)}>
      Log all data to browser console
    </Button>
  );
};

import { truncate as _truncate } from 'lodash';
import React, { useContext } from 'react';
import { Link, useParams, useResolvedPath } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '../../../../../component/Button';
import { Layout } from '../../../../../component/Layout';
import { LoadingSpinnerBlock } from '../../../../../component/LoadingSpinner';
import { Table } from '../../../../../component/Table';
import { SOURCE_TYPE_NAME_MAP } from '../../../../../constant';
import { DEFAULT_ACTIVE_COLUMNS_ARRAY, TRANSACTION_COLUMNS } from '../../../../../constants/giving';
import { useTransactions } from '../../../../../hooks/useTransactions';
import { AMOUNT_FORMATTER, DATE_FORMATTER } from '../../../../../lib/formatting';

export const TransactionTable = () => {
  const params = useParams<{ personId: string }>();
  const { data: transactions = [], isLoading } = useTransactions(params.personId);
  const columnOptions = TRANSACTION_COLUMNS.filter((column) => DEFAULT_ACTIVE_COLUMNS_ARRAY.includes(column.key));

  if (isLoading) {
    return (
      <Layout.Card.Message>
        <LoadingSpinnerBlock />
      </Layout.Card.Message>
    );
  }

  if (transactions?.length < 1) {
    return (
      <Layout.Card.Message>
        <Layout.Header headline="No Transactions to display" />
      </Layout.Card.Message>
    );
  }

  return (
    <Table.OverflowAuto>
      <Table>
        <TableHead columns={columnOptions} />
        {transactions ? <TableBody rows={transactions} columns={columnOptions} /> : <Table.LoadingTbody rowSpan={5} />}
      </Table>
    </Table.OverflowAuto>
  );
};

const TableHead = (props) => {
  return (
    <Table.Thead>
      <Table.Row>
        {props.columns.map((column) => {
          return <Table.Header key={column.key}>{column.label}</Table.Header>;
        })}
        <Table.Header align="right" />
      </Table.Row>
    </Table.Thead>
  );
};

const TableBody = (props: { rows: Array<any>; columns: Array<any> }) => {
  return (
    <Table.Tbody>
      {props.rows.map((transaction) => (
        <TransactionRow key={transaction.id} transaction={transaction} columns={props.columns} />
      ))}
    </Table.Tbody>
  );
};

const RowContext = React.createContext({} as { viewUrl: string; transaction: any });

const TransactionRow = (props: { transaction: any; columns: Array<any> }) => {
  const viewUrl = `${useResolvedPath('').pathname}/${props.transaction.id}`;

  const context = {
    transaction: props.transaction,
    viewUrl: viewUrl,
  };

  const formatMap = {
    designation_id: (value) => value.slice(12, 24).toUpperCase(),
    fee_processor: (value) => AMOUNT_FORMATTER(value),
    date_given: (value) => (value?.length > 0 ? DATE_FORMATTER(value) : value),
    memo: (value) => _truncate(value, { length: 45 }),
    net_amount: (value) => AMOUNT_FORMATTER(value),
    amount: (value) => AMOUNT_FORMATTER(value),
    paidout_at: (value) => (value?.length > 0 ? DATE_FORMATTER(value) : value),
    receipt_email: (value) => _truncate(value, { length: 45 }),
    recurring: (value) => (value ? 'Recurring' : 'One-Time'),
    source_type: (value) => SOURCE_TYPE_NAME_MAP?.[value] ?? 'Unknown',
  };

  function formatter(key, value) {
    if (typeof formatMap[key] === 'function') {
      return formatMap[key](value);
    }
    return value;
  }

  return (
    <RowContext.Provider key={props.transaction.id} value={context}>
      <Table.Row key={props.transaction.id} to={viewUrl}>
        {props.columns.map((column) => {
          if (column.active) {
            return (
              <Table.Cell key={uuidv4()} align="left">
                {formatter(column.key, props.transaction?.[column.key])}
              </Table.Cell>
            );
          }
          return undefined;
        })}
        <Table.Cell maxWidth="100%" align="right" type="buttons">
          <ViewButton />
        </Table.Cell>
      </Table.Row>
    </RowContext.Provider>
  );
};

const ViewButton = () => {
  const { viewUrl } = useContext(RowContext);
  return (
    <Button shape="pill" size="small" variant="secondary" to={viewUrl} forwardedAs={Link}>
      View
    </Button>
  );
};

import { ButtonSecondary, InputText, Label } from '@foyyay/control-elements';
import React, { useCallback, useState } from 'react';
import { useRpMerchantMutation } from '../../../../../../../hooks/useRpMerchant';

export const RpUpdateProcessor = (props: any) => {
  const { mutateAsync: updateRpMerchant, isPending, isError, error } = useRpMerchantMutation(props.rpmerchant);

  const [processorId, setProcessorId] = useState('');

  const dispatchUpdate = useCallback(async () => {
    await updateRpMerchant({ processor_id: processorId });
  }, [updateRpMerchant, processorId]);

  const nineDigitTitle = '9 Digits required';
  const nineDigitString = '[0-9]{9}';
  const nineDigitRegex = new RegExp(`^${nineDigitString}$`);

  const onSubmitUpdate = useCallback(
    async (event) => {
      event.preventDefault();
      if (nineDigitRegex.test(processorId)) {
        await dispatchUpdate();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [processorId, dispatchUpdate]
  );

  return (
    <div>
      <form onSubmit={onSubmitUpdate}>
        <Label htmlFor="processorId"></Label>
        <InputText
          data-lpignore="true"
          name="processorId"
          value={processorId}
          onChange={setProcessorId}
          maxLength={9}
          pattern={nineDigitString}
          placeholder="123123123"
          title={nineDigitTitle}
          required
        />
        <ButtonSecondary loading={isPending}>Update Processor</ButtonSecondary>
        <Label error={isError}>{error}</Label>
      </form>
    </div>
  );
};

import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useResolvedPath } from 'react-router-dom';
import { useThunkReducer } from '../../../../../../hooks/useThunkReducer';
import { SubscriptionControllerContext } from './context';
import { loadProducts, readSubscription, subscriptionReducer, subscriptionReducerInit } from './reducer';

export const SubscriptionController = (props) => {
  const url = useResolvedPath('').pathname;

  const [, dispatch, useSelector] = useThunkReducer(subscriptionReducer, {
    devToolsName: 'Subscription',
    initializer: subscriptionReducerInit,
    initializerArg: {},
  });

  const doLoadSubscription = useCallback(async () => {
    dispatch(readSubscription({ churchId: props.churchId, subscriptionId: props.subscriptionId }));
  }, [dispatch, props.churchId, props.subscriptionId]);

  const doLoadProducts = useCallback(async () => {
    dispatch(loadProducts());
  }, [dispatch]);

  useEffect(() => {
    doLoadSubscription();
  }, [doLoadSubscription]);

  useEffect(() => {
    doLoadProducts();
  }, [doLoadProducts]);

  const context: SubscriptionControllerContext = {
    dispatch: dispatch,
    useSelector: useSelector,
    overviewUrl: url,
    getInvoiceUrl: (invoice) => `${url}/invoices/${invoice.id}`,
  };

  return (
    <SubscriptionControllerContext.Provider value={context}>{props.children}</SubscriptionControllerContext.Provider>
  );
};

SubscriptionController.propTypes = {
  children: PropTypes.node,
  churchId: PropTypes.string.isRequired,
  relativeUrl: PropTypes.func,
  subscriptionId: PropTypes.string.isRequired,
};

SubscriptionController.defaultProps = {
  children: null,
};

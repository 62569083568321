import { SUPPORT_API_URL } from '../constant';
import { apiCall } from '../lib/fetch';

export type EmailSearchResponse = {
  address: string;
  created_at: string;
  person_id: string;
  updated_at: string;
  verified: boolean;
}[];

const search = async (query: string): Promise<EmailSearchResponse> => {
  return await apiCall('GET', SUPPORT_API_URL, `/email/search?q=${encodeURIComponent(query.trim())}`);
};

export const EmailHttpApi = {
  search: search,
};

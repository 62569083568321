import React from 'react';
import { Navigate, Route, Routes, useParams, useResolvedPath } from 'react-router-dom';
import { PillNav } from '../../../../../component/PillNav';
import { InvoicesRoutes } from './Invoices/routes';
import { OverviewRoutes } from './Overview/routes';
import { SubscriptionController } from './state/controller';

export const SubscriptionRoutes = () => {
  const params = useParams();
  const url = useResolvedPath('').pathname;

  const navList = [
    {
      title: 'Subscription',
      to: `overview`,
    },
    {
      title: 'Invoices',
      to: `invoices`,
    },
  ];

  return (
    <SubscriptionController churchId={params.churchId ?? ''} subscriptionId={params.subscriptionId ?? ''}>
      <PillNav navList={navList} />
      <Routes>
        <Route path={'/overview'} element={<OverviewRoutes />} />
        <Route path={'/invoices/*'} element={<InvoicesRoutes />} />
        <Route path="/*" element={<Navigate to={`${url}/overview`} replace={true} />} />
      </Routes>
    </SubscriptionController>
  );
};

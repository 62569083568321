import React, { useState } from 'react';
import styled from 'styled-components';
import { useClickCopy } from '../hooks/useCopyClick';
import { TextLink } from './TextLink';
import { L3 } from './Typography';

export const ButtonCopy = (props: { data: any; label?: string }) => {
  const [open, setOpen] = useState(false);
  const clickCopy = useClickCopy(props.data);
  const copyClick = () => {
    setOpen(true);
    clickCopy();
    setTimeout(() => setOpen(false), 750);
  };
  const label = props.label ?? 'Copy';

  return (
    <>
      <StyledDialog open={open}>
        <p>Copied to clipboard!</p>
      </StyledDialog>
      <L3>
        <TextLink onClick={copyClick}>{label}</TextLink>
      </L3>
    </>
  );
};

const StyledDialog = styled.dialog`
  background-color: ${(props) => props.theme.background}
  opacity: 1;
  transform: scaleY(1);
  padding 0.5rem 0.5rem;
  border-radius: 0.5rem;
`;

import { useState } from 'react';

const useDeprecatedPropHook = (props, propName, validValues = []) => {
  const [logged, setLogged] = useState(false);

  if (logged) {
    return;
  }

  const propValue = props[propName];
  if (validValues.includes(propValue)) {
    return;
  }

  if (validValues.length > 0) {
    const error = new Error(`The value of ${propName} is deprecated. "${propValue}"`);
    console.warn(error);
    setLogged(true);
    return;
  }

  if (propValue !== undefined) {
    const error = new Error(`The ${propName} prop is deprecated.`);
    console.warn(error);
    setLogged(true);
    return;
  }
};

export const useDeprecatedProp = process.env.NODE_ENV === 'development' ? useDeprecatedPropHook : () => {};

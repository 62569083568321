import { Church } from '../../type/Church';
import { MakeoverCustomProcess } from '../../type/MakeoverCustomProcess';
import { Nullable } from '../../type/utility';
import { NUCLEUS_PLATFORM_API_URL } from '../constant';
import { apiCall } from '../lib/fetch';
import { CustomProcessHttpApi } from './CustomProcessHttpApi';

export type MakeoverStatus = 'purchased' | 'submitted' | 'approved' | 'rejected' | 'completed' | 'delivered';

type ListResult<T> = T & {
  churchId: string;
  status: MakeoverStatus;
};

export type MakeoverListResponse = {
  makeovers: ListResult<MakeoverCustomProcess>[];
  cursor?: string;
};

export const MakeoverHttpApi = {
  create: async (church: Pick<Church, 'id'>): Promise<{ makeoverCustomProcess: MakeoverCustomProcess }> => {
    return apiCall('POST', NUCLEUS_PLATFORM_API_URL, `/onboarding-setup/makeover`, { churchId: church.id });
  },
  read: async (church: Pick<Church, 'id'>): Promise<MakeoverCustomProcess> => {
    return await CustomProcessHttpApi.read(church, 'makeover', church.id, false);
  },
  update: async (church: Pick<Church, 'id'>, updates: Nullable<MakeoverCustomProcess>) => {
    const response = await CustomProcessHttpApi.update(church, 'makeover', church.id, updates);
    return response.globals;
  },
  list: async (options?: { cursor?: string }): Promise<MakeoverListResponse> => {
    return apiCall(
      'GET',
      NUCLEUS_PLATFORM_API_URL,
      `/onboarding-setup/makeover${options?.cursor ? `?cursor=${options.cursor}` : ''}`
    );
  },
};

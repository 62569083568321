import { QueryOptions, useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { RpMerchant } from '../../type/Merchant';
import { RpMerchantHttpApi } from '../api/RpMerchantHttpApi';

const getCacheKey = (rpMerchantId?: string) => ['rpmerchant', rpMerchantId];

export const useRpMerchant = (
  rpMerchantId?: string,
  queryOptions?: QueryOptions<RpMerchant, Error, RpMerchant>
): UseQueryResult<RpMerchant, Error> => {
  return useQuery({
    staleTime: 1000 * 60 * 5,
    ...queryOptions,
    queryKey: getCacheKey(rpMerchantId),
    queryFn: async () => {
      if (rpMerchantId === undefined) {
        throw new Error('RpMerchant ID is required');
      }
      const response = await RpMerchantHttpApi.getRpMerchant({ id: rpMerchantId });
      return response.data.rpmerchant;
    },
    enabled: rpMerchantId !== undefined,
  });
};

export const useRefreshRpMerchant = (rpMerchantId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => await RpMerchantHttpApi.refreshRebelpayMerchant({ id: rpMerchantId }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: getCacheKey(rpMerchantId) }),
  });
};

export const useRpMerchantMutation = (rpMerchant: RpMerchant) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['updateRpMerchant', rpMerchant.id],
    mutationFn: async (data: any) => await RpMerchantHttpApi.updateRebelpayMerchant(rpMerchant, data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: getCacheKey(rpMerchant.id) }),
  });
};

export const useRpMerchantSubmit = (rpMerchant: RpMerchant) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['submitRpMerchant', rpMerchant.id],
    mutationFn: async () => await RpMerchantHttpApi.submitRebelpayMerchant(rpMerchant),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: getCacheKey(rpMerchant.id) }),
  });
};

import { Table, TextAreaLimitedChar } from '@foyyay/control-elements';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Memorandum } from '../../../type/memorandum';
import { useAsyncCallback } from '../../hooks/useAsyncCallback';
import { Button } from '../Button';
import { ButtonGroup } from '../ButtonGroup';
import { XRounded } from '../Icons';
import { Loading } from '../Progress/Loading';
import { MemorandumsContext } from './MemorandumsContext';
import { MemorandumEntity, createMemorandum, updateMemorandum } from './MemorandumsState';
import { SIZE } from '../style-utils';
import _isEqual from 'lodash/isEqual';
import { Layout } from '../Layout';
import { TextLink } from '../TextLink';
import { DATE_FORMATTER, DATE_FULL_FORMATTER } from '../../lib/formatting';
import { Field, FieldLabel, FieldValue } from '../LayoutParts';
import { MenuDivider } from '../Menu';
import { useUser } from '../../hooks/useUser';

interface TableContext {
  rows: MemorandumEntity[];
  loaded: boolean;
  renderForm: boolean;
  setRenderForm: (val: boolean) => void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const TableContext = React.createContext<TableContext>({} as TableContext);

export const MemorandumsTable = (props: {
  rows: MemorandumEntity[];
  loaded: boolean;
  renderForm: boolean;
  setRenderForm: (val: boolean) => void;
}): JSX.Element => {
  if (props.loaded !== true) {
    return <Loading />;
  }

  return (
    <TableContext.Provider value={props}>
      <Table>
        <TableHead />
        <TableBody />
      </Table>
    </TableContext.Provider>
  );
};

const TableHead = (): JSX.Element => {
  return (
    <Table.Thead>
      <Table.Row>
        <Table.Header></Table.Header>
      </Table.Row>
    </Table.Thead>
  );
};

const TableBody = (): JSX.Element => {
  const { rows, renderForm } = useContext(TableContext);

  return (
    <Table.Tbody>
      {rows.length === 0 && renderForm === false && <Layout.Message statement="There are no notes." />}
      {renderForm === true && <MemorandumRow key="new" />}
      {rows.map((entity) => (
        <MemorandumRow key={entity.id} entity={entity} />
      ))}
    </Table.Tbody>
  );
};

const MemorandumRow = ({ entity }: { entity?: MemorandumEntity }): JSX.Element => {
  const memorandum: Memorandum | undefined = entity?.memorandum;
  const user = useUser();
  const mayIUpdate = memorandum?.id === undefined || user?.id === memorandum?.createdBy;
  const memorandumCreator =
    [memorandum?.createdByFirstName, memorandum?.createdByLastName?.slice(0, 1)].join(' ').trim() ||
    memorandum?.createdByEmail?.trim() ||
    memorandum?.createdBy;
  const { dispatch, subjectId } = useContext(MemorandumsContext);
  const { setRenderForm } = useContext(TableContext);

  const [input, setInput] = useState(memorandum?.body ?? '');
  const [isRowEditing, setIsRowEditing] = useState(memorandum?.id === undefined);

  const ref = useRef<HTMLElement>();

  useEffect(() => {
    if (memorandum?.id === undefined) {
      ref.current?.focus();
    }
  }, [ref, memorandum]);

  const setEditing = (value: boolean) => {
    if (mayIUpdate !== true) {
      return;
    }
    setIsRowEditing(value);

    if (value === false) {
      setRenderForm(false);
    }
  };

  const doUpdate = async () => {
    if (_isEqual(input, memorandum?.body)) {
      return memorandum;
    }

    await dispatch(
      updateMemorandum({
        subjectId: subjectId ?? '',
        memorandumId: memorandum?.id ?? '',
        options: { body: input },
      })
    );
  };

  const doCreate = async () => {
    await dispatch(createMemorandum({ subjectId: subjectId ?? '', options: { body: input } }));
  };

  const saveDisabled = input.length < 1;

  const doSave = async () => {
    if (saveDisabled === true) {
      return;
    }
    if (memorandum?.id !== undefined) {
      await doUpdate();
    } else {
      await doCreate();
    }
    setEditing(false);

    return;
  };

  const [handleSave, asyncState] = useAsyncCallback(doSave);

  const handleChange = (value) => {
    setInput(value);
  };

  const handleClick = () => {
    setEditing(true);
  };

  const handleCancelClick = () => {
    setEditing(false);
    setInput(memorandum?.body ?? '');
  };

  return (
    <Table.Row active={false}>
      <Table.Cell style={{ width: '100%', padding: SIZE[1], background: 'none' }}>
        <MenuDivider style={{ marginTop: SIZE[2], marginBottom: SIZE[2] }} />

        {isRowEditing === false && (
          <>
            <Field style={{ maxWidth: '43rem', whiteSpace: 'normal', overflowWrap: 'break-word' }}>
              <FieldLabel
                style={{ marginTop: SIZE[2], userSelect: 'text' }}
                title={`Last updated on ${DATE_FULL_FORMATTER(memorandum?.updatedAt)} by ${memorandum?.createdByEmail}`}
              >{`${memorandumCreator}. on ${DATE_FORMATTER(memorandum?.createdAt)}`}</FieldLabel>
              <FieldValue style={{ marginTop: SIZE[2], userSelect: 'text' }}>
                {memorandum?.body} {mayIUpdate === true && <TextLink onClick={() => setEditing(true)}>Edit</TextLink>}
              </FieldValue>
            </Field>
          </>
        )}

        {isRowEditing === true && (
          <>
            <TextAreaLimitedChar
              placeholder={memorandum?.id === undefined ? 'Enter note here' : 'Update note here'}
              value={input}
              onChange={handleChange}
              onClick={handleClick}
              ref={ref}
              style={{ width: '100%', borderStyle: 'none', minHeight: '100%' }}
            />
            <ButtonGroup style={{ justifyContent: 'center', paddingTop: SIZE[1] }}>
              <Button
                size="small"
                shape="pill"
                onClick={handleSave}
                disabled={saveDisabled}
                loading={asyncState === 'pending'}
                style={{ marginRight: SIZE[1] }}
              >
                Save
              </Button>
              <Button size="small" shape="circle" variant="secondary" icon={<XRounded />} onClick={handleCancelClick} />
            </ButtonGroup>
          </>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

import * as dateFns from 'date-fns';
import React, { MouseEventHandler, useContext, useState } from 'react';
import styled from 'styled-components';
import { RelativeDate } from '../../../../../../../component/Date';
import { LoadingSpinnerInline } from '../../../../../../../component/LoadingSpinner';
import { Table } from '../../../../../../../component/Table';
import { BillingInterval, BillingIntervalLabelMap, SubscriptionModel } from '../../../../../../../constant';
import { useSubscriptions } from '../../../../../../../hooks/useSubscription';
import { AMOUNT_FORMATTER } from '../../../../../../../lib/formatting';
import { entityPlatform } from '../../../../../../../lib/platform';
import { formatPaymentMethod, getSubscriptionStatusName } from '../../../../../../../lib/subscription';
import { SubscriptionControllerContext } from '../../state/context';
import {
  linkNucleusOne,
  readSubscription,
  selectActiveNucleusOneProductItems,
  selectActiveProductItems,
  selectBalance,
  selectCosts,
  selectLastInvoice,
  selectNextInvoice,
  selectPaidItems,
  selectSubscription,
  selectSubscriptionHasEnded,
  selectSubscriptionIsEnding,
  unlinkNucleusOne,
  updateSubscription,
} from '../../state/reducer';
import { getNewDate } from './input';

export const SubscriptionDetail = () => {
  return (
    <Space>
      <Table.OverflowAuto>
        <Table>
          <Table.Tbody>
            <SummaryRow />
            <LastInvoiceRow />
            <NextInvoiceRow />
            <AccountStateRow />
            <StripeRow />
          </Table.Tbody>
        </Table>
      </Table.OverflowAuto>
    </Space>
  );
};

const Space = styled.div`
  padding-top: 1rem;
  padding-bottom: 3rem;
`;

const SummaryRow = () => {
  const { dispatch, useSelector } = useContext(SubscriptionControllerContext);
  const subscription = useSelector(selectSubscription);
  const subscriptionHasEnded = useSelector(selectSubscriptionHasEnded);
  const subscriptionIsEnding = useSelector(selectSubscriptionIsEnding);
  const costs = useSelector(selectCosts);
  const activeProducts = useSelector(selectActiveProductItems);
  const lastInvoice = useSelector(selectLastInvoice);

  const { refetch } = useSubscriptions(subscription.church_id);

  const doUpdateSubscriptionList = async () => {
    await refetch();
  };

  const doCancelSubscription = async (asOf: Date | null) => {
    await dispatch(
      updateSubscription({ subscription: subscription, updates: { ended_at: asOf, next_invoice_at: null } })
    );
    await doUpdateSubscriptionList();
  };

  const doRestartSubscription = async (nextInvoiceDate: Date | null) => {
    await dispatch(
      updateSubscription({ subscription: subscription, updates: { ended_at: null, next_invoice_at: nextInvoiceDate } })
    );
    await doUpdateSubscriptionList();
  };

  const handleGetNextBillingStatus = () => {
    const now = new Date();

    const message: Array<string> = [];
    let recommendedDate = now;
    let action;

    if (lastInvoice !== undefined) {
      let months = 1;
      if (subscription.interval === BillingInterval.Year) {
        months = 12;
      }

      const invoicePeriodStart = new Date(lastInvoice.period_started_at ?? lastInvoice.created_at);
      const invoicePeriodEnd = new Date(lastInvoice.period_ended_at ?? dateFns.addMonths(invoicePeriodStart, months));
      message.push(
        `The last invoice covers ${dateFns.format(invoicePeriodStart, 'P')} through ${dateFns.format(
          invoicePeriodEnd,
          'P'
        )}`
      );
      if (invoicePeriodEnd > recommendedDate) {
        recommendedDate = invoicePeriodEnd;
      }
    }

    if (subscriptionHasEnded === true || subscriptionIsEnding === true) {
      message.unshift('What should the new billing date be?');
      action = doRestartSubscription;
    } else {
      message.unshift('When do you want this subscription to end?');
      action = doCancelSubscription;
    }

    const nextDate = getNewDate(message.join('\n'), recommendedDate, now);
    if (nextDate === undefined) {
      return;
    }

    action(nextDate);
  };

  const handleStatusClicked: MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleGetNextBillingStatus();
  };

  return (
    <Table.Row platform={entityPlatform(subscription)}>
      <Table.Cell onClick={handleStatusClicked}>
        <RelativeDate date={subscription.ended_at}>{getSubscriptionStatusName(subscription)}</RelativeDate>
      </Table.Cell>
      <Table.Cell>
        {AMOUNT_FORMATTER(costs?.invoiceTotal)}/<Interval subscription={subscription} />
      </Table.Cell>
      <Table.Cell>{`${activeProducts.length} Products`}</Table.Cell>
      <Table.Cell>{formatPaymentMethod(subscription)}</Table.Cell>
    </Table.Row>
  );
};

interface IntervalProps {
  subscription: SubscriptionModel;
}
const Interval = ({ subscription }: IntervalProps): JSX.Element => {
  const { dispatch } = useContext(SubscriptionControllerContext);
  const [inputActive, setInputActive] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const doUpdateInterval = async (e) => {
    const interval = e.target.value === BillingInterval.Year ? BillingInterval.Year : BillingInterval.Month;
    setLoading(true);
    await dispatch(updateSubscription({ subscription: subscription, updates: { interval: interval } }));
    setInputActive(false);
    setLoading(false);
  };

  const handleToggleClicked: MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setInputActive(!inputActive);
  };

  if (loading === true) {
    return (
      <LoadingSpinnerInline style={{ display: 'inline-block', width: '2rem', height: '2rem', marginLeft: '.6rem' }} />
    );
  }

  if (inputActive === true) {
    const selectedValue = (interval) => (interval === subscription.interval ? { selected: true } : {});

    return (
      <select onChange={doUpdateInterval}>
        {Object.keys(BillingIntervalLabelMap).map((key) => (
          <option key={key} value={key} {...selectedValue(key)}>
            {BillingIntervalLabelMap[key]}
          </option>
        ))}
      </select>
    );
  }

  return <a onClick={handleToggleClicked}>{BillingIntervalLabelMap[subscription.interval]}</a>;
};

const LastInvoiceRow = () => {
  const { useSelector, getInvoiceUrl } = useContext(SubscriptionControllerContext);
  const subscription = useSelector(selectSubscription);
  const lastInvoice = useSelector(selectLastInvoice);

  if (lastInvoice === undefined) {
    return null;
  }

  return (
    <Table.Row platform={entityPlatform(subscription)} to={getInvoiceUrl(lastInvoice)}>
      <Table.Cell>Last Invoice was</Table.Cell>
      <Table.Cell>{AMOUNT_FORMATTER(lastInvoice.total)}</Table.Cell>
      <Table.Cell>
        <RelativeDate date={lastInvoice.period_started_at ?? lastInvoice.created_at} />
      </Table.Cell>
      <Table.Cell></Table.Cell>
    </Table.Row>
  );
};

const AccountStateRow = () => {
  const { useSelector } = useContext(SubscriptionControllerContext);
  const subscription = useSelector(selectSubscription);
  const balance = useSelector(selectBalance);

  return (
    <Table.Row platform={entityPlatform(subscription)}>
      <Table.Cell>Balance</Table.Cell>
      <Table.Cell>{AMOUNT_FORMATTER(balance * -1)}</Table.Cell>
      <Table.Cell></Table.Cell>
      <Table.Cell></Table.Cell>
    </Table.Row>
  );
};

const NextInvoiceRow = () => {
  const { dispatch, useSelector, getInvoiceUrl } = useContext(SubscriptionControllerContext);
  const subscription = useSelector(selectSubscription);
  const subscriptionHasEnded = useSelector(selectSubscriptionHasEnded);
  const subscriptionIsEnding = useSelector(selectSubscriptionIsEnding);
  const paidProducts = useSelector(selectPaidItems);
  const lastInvoice = useSelector(selectLastInvoice);
  const nextInvoice = useSelector(selectNextInvoice);

  const { refetch } = useSubscriptions(subscription.church_id);

  const doUpdateSubscriptionList = async () => {
    await refetch();
  };

  const doUpdateBillingDate = async (nextDate: Date | null) => {
    await dispatch(updateSubscription({ subscription: subscription, updates: { next_invoice_at: nextDate } }));
    await doUpdateSubscriptionList();
  };

  const handleGetNextBillingDate = () => {
    const subscriptionStartedAt = new Date(subscription.started_at ?? subscription.created_at);

    // Try to come up with a date that makes sense.
    const possibleDates = [
      new Date(lastInvoice?.period_ended_at ?? new Date()),
      ...paidProducts.map((product) => new Date(product?.started_at ?? product.created_at)),
      subscriptionStartedAt,
      new Date(),
    ];

    const nextDate = getNewDate(
      'What should the next invoice date be?',
      possibleDates.sort(dateFns.compareDesc)[0],
      subscriptionStartedAt
    );
    if (nextDate === undefined) {
      return;
    }
    if (nextInvoice?.period_started_at !== undefined) {
      if (new Date(nextInvoice.period_started_at).getTime() === nextDate.getTime()) {
        return;
      }
    }

    doUpdateBillingDate(nextDate);
  };

  const handleNextBillingDateClicked: MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleGetNextBillingDate();
  };

  if (nextInvoice !== undefined) {
    return (
      <Table.Row platform={entityPlatform(subscription)} to={getInvoiceUrl({ id: 'next' })}>
        <Table.Cell>Next Invoice will be</Table.Cell>
        <Table.Cell>{AMOUNT_FORMATTER(nextInvoice.total)}</Table.Cell>
        <Table.Cell onClick={handleNextBillingDateClicked}>
          <RelativeDate date={nextInvoice.period_started_at} />
        </Table.Cell>
        <Table.Cell></Table.Cell>
      </Table.Row>
    );
  }

  if (subscriptionHasEnded === false && subscriptionIsEnding === false) {
    return (
      <Table.Row platform={entityPlatform(subscription)}>
        <Table.Cell>BILLING IS PAUSED</Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell onClick={handleNextBillingDateClicked}>Add a billing date</Table.Cell>
        <Table.Cell></Table.Cell>
      </Table.Row>
    );
  }

  return null;
};

const StripeRow = () => {
  const { dispatch, useSelector } = useContext(SubscriptionControllerContext);
  const subscription = useSelector(selectSubscription);
  const activeNucleusOneProducts = useSelector(selectActiveNucleusOneProductItems);
  const hasStripeId = subscription.nucleus_one_stripe_subscription_id !== undefined;
  const isLinkedToNucleusOne = hasStripeId && subscription.nucleus_one_linked === true;
  const [linkingState, setLinkingState] = useState<string | undefined>(undefined);

  if (activeNucleusOneProducts.length < 1 && hasStripeId === false) {
    return null;
  }

  const doUpdateStripeId = async (stripeId: string | null) => {
    await dispatch(
      updateSubscription({ subscription: subscription, updates: { nucleus_one_stripe_subscription_id: stripeId } })
    );
  };

  const doLinkNucleusOne = async () => {
    setLinkingState('Linking...');
    await dispatch(linkNucleusOne({ subscription: subscription }));
    await dispatch(readSubscription({ churchId: subscription.church_id, subscriptionId: subscription.id }));
    setLinkingState(undefined);
  };

  const doUnlinkNucleusOne = async () => {
    setLinkingState('Unlinking...');
    await dispatch(unlinkNucleusOne({ subscription: subscription }));
    await dispatch(readSubscription({ churchId: subscription.church_id, subscriptionId: subscription.id }));
    setLinkingState(undefined);
  };

  const handleGetStripeId = () => {
    const message = ['Enter the Stripe subscription ID of the Nucleus 1 account'];
    if (hasStripeId === true) {
      message.push('', 'Type "delete" to remove the ID');
    }
    const promptDefault = subscription.nucleus_one_stripe_subscription_id ?? 'sub_';

    const answer = window.prompt(message.join('\n'), promptDefault);

    if (answer === null) {
      return;
    }

    if (answer === subscription.nucleus_one_stripe_subscription_id) {
      return;
    }

    if (answer.toLowerCase() === 'delete') {
      doUpdateStripeId(null);
      return;
    }

    if (answer.match(/^sub_[a-zA-Z0-9]+$/) === null) {
      window.alert("This doesn't look like a Stripe Subscription ID.");
      handleGetStripeId();
      return;
    }

    doUpdateStripeId(answer);
  };

  const handleLinkNucleusOne = (input?: string) => {
    const message = [
      `This will link the Nucleus 1 account connected to the Stripe subscription ${subscription.nucleus_one_stripe_subscription_id} to this Nucleus 2 subscription.`,
      'Changes in Stripe will no longer affect the Nucleus 1 account',
      '',
      'No changes will be made to Stripe. Please go cancel the subscription.',
      'This can be undone.',
      '',
      'Type "link" to confirm this is what you want to do.',
    ];

    const answer = window.prompt(message.join('\n'), input);

    if (answer === null) {
      return;
    }

    if (answer.toLowerCase() !== 'link') {
      handleLinkNucleusOne(input);
      return;
    }

    doLinkNucleusOne();
  };

  const handleUnlinkNucleusOne = (input?: string) => {
    const message = [
      `This will unlink the Nucleus 1 account and give control back to Stripe subscription ${subscription.nucleus_one_stripe_subscription_id}.`,
      'Changes in Stripe will again affect the Nucleus 1 account',
      '',
      'No changes will be made to Stripe.',
      '',
      'Type "unlink" to confirm this is what you want to do.',
    ];

    const answer = window.prompt(message.join('\n'), input);

    if (answer === null) {
      return;
    }

    if (answer.toLowerCase() !== 'unlink') {
      handleUnlinkNucleusOne(input);
      return;
    }

    doUnlinkNucleusOne();
  };

  const handleStripIdClicked: MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleGetStripeId();
  };

  const handleStripeLinkClicked: MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isLinkedToNucleusOne) {
      handleUnlinkNucleusOne();
    } else {
      handleLinkNucleusOne();
    }
  };

  const stripeDashboardLink = `https://dashboard.stripe.com/subscriptions/${subscription.nucleus_one_stripe_subscription_id}`;
  const renderStripeDashboardLink = hasStripeId === true;

  const linkLabel = [linkingState, isLinkedToNucleusOne === true && 'Linked', 'Not Linked'].find(
    (label): label is string => typeof label === 'string'
  );

  const stripeIdClicked = isLinkedToNucleusOne === true ? undefined : handleStripIdClicked;

  return (
    <Table.Row platform={entityPlatform(subscription)}>
      <Table.Cell>Stripe Subscription</Table.Cell>
      <Table.Cell>{renderStripeDashboardLink && <a href={stripeDashboardLink}>Stripe Dashboard</a>}</Table.Cell>
      <Table.Cell onClick={handleStripeLinkClicked}>{renderStripeDashboardLink && linkLabel}</Table.Cell>
      <Table.Cell onClick={stripeIdClicked}>
        {subscription.nucleus_one_stripe_subscription_id ?? 'Add Stripe ID'}
      </Table.Cell>
    </Table.Row>
  );
};

// NOTE: this is an abbreviated version of the nucleus-platform level key file

const JOIN_ON = '_';

export function isPrefixedKey(prefix, value) {
  if (value?.split === undefined) {
    return false;
  }
  let parts = value.split(JOIN_ON);
  if (parts.length !== 2) {
    return false;
  }

  if (prefix !== undefined && parts[0] !== prefix) {
    return false;
  }

  return true;
}

export function prefixOfKey(value) {
  if (!isPrefixedKey(undefined, value)) {
    return undefined;
  }

  return value.split(JOIN_ON)[0];
}

export function valueOfKey(value) {
  if (!isPrefixedKey(undefined, value)) {
    return undefined;
  }

  return value.split(JOIN_ON)[1];
}

export function isPrefixedKeyGenerator(prefix) {
  return (value) => isPrefixedKey(prefix, value);
}

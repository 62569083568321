import { isEmpty as _isEmpty, upperFirst as _upperFirst } from 'lodash';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../component/Button';
import { ButtonConsoleLog } from '../../../component/ButtonConsoleLog';
import { ButtonCopy } from '../../../component/ButtonCopy';
import { DataList } from '../../../component/DataList';
import { Layout } from '../../../component/Layout';
import { LoadingSpinnerBlock } from '../../../component/LoadingSpinner';
import { H3 } from '../../../component/Typography';
import { SIZE } from '../../../component/style-utils';
import { useChurch } from '../../../hooks/useChurch';
import { PHONE_FORMATTER } from '../../../lib/formatting';
import {
  DISPLAY_ADDRESS_FORMATTER,
  DISPLAY_DATE_FORMATTER,
  EMAIL_FORMATTER,
  LINK_FORMATTER,
  Phone,
  WEBSITE_FORMATTER,
} from '../../../lib/objectGridAttributeLibrary';
import { Platform, entityPlatform } from '../../../lib/platform';

export const Overview = () => {
  const params = useParams<{ churchId: string | undefined }>();
  const { data: church, isLoading } = useChurch(params.churchId);

  const displayAddress = _isEmpty(church?.address_mailing_postal)
    ? church?.address_mailing
    : church?.address_mailing_postal;

  let platform: Platform | undefined;

  if (church !== undefined) {
    platform = entityPlatform(church);
  }

  if (isLoading) {
    return (
      <Layout.Panel>
        <LoadingSpinnerBlock />
      </Layout.Panel>
    );
  }

  if (_isEmpty(church)) {
    return (
      <Layout.Card.Message>
        <Layout.Header headline={'Could not load church details'} />
      </Layout.Card.Message>
    );
  }

  return (
    <Layout.Panel platform={String(platform)}>
      <Layout.Header
        headline={church.name ?? '[No Name]'}
        buttons={
          <>
            <ButtonEdit churchId={church.id} />
          </>
        }
      />
      <Layout.Card>
        <Layout.Header headline="Contact Information" />
        <Layout.Group>
          <Layout.Flex wrap={true} gap={0}>
            <DataList inline>
              <DataList.Item>
                <DataList.Label>Website</DataList.Label>
                <DataList.Value>{WEBSITE_FORMATTER(church.address_website ?? '')}</DataList.Value>
              </DataList.Item>
              <DataList.Item>
                <DataList.Label>Email</DataList.Label>
                <DataList.Value>{EMAIL_FORMATTER(church.address_email)}</DataList.Value>
              </DataList.Item>
              <DataList.Item>
                <DataList.Label>Phone</DataList.Label>
                <DataList.Value>
                  <Phone value={PHONE_FORMATTER(church.phone)} />
                </DataList.Value>
              </DataList.Item>
            </DataList>
            <DataList inline>
              <DataList.Item>
                <DataList.Label>Address</DataList.Label>
                <DataList.Value>{DISPLAY_ADDRESS_FORMATTER(displayAddress)}</DataList.Value>
              </DataList.Item>
            </DataList>
          </Layout.Flex>
        </Layout.Group>
      </Layout.Card>

      <Layout.Group>
        <Layout.Header />
        <H3>Settings</H3>
        <DataList inline labelWidth="20rem">
          <Layout.Group>
            <DataList.Item>
              <DataList.Label>Church Setup</DataList.Label>
              <DataList.Value>{church.status_church_setup === true ? 'Yes' : 'No'}</DataList.Value>
            </DataList.Item>
            <DataList.Item>
              <DataList.Label>Created At</DataList.Label>
              <DataList.Value>{DISPLAY_DATE_FORMATTER(church.created_at)}</DataList.Value>
            </DataList.Item>
            <DataList.Item>
              <DataList.Label>Person Count</DataList.Label>
              <DataList.Value>{church.person_count || 'Not Reported'}</DataList.Value>
            </DataList.Item>
            <DataList.Item>
              <DataList.Label>Platform</DataList.Label>
              <DataList.Value>{_upperFirst(church.platform)}</DataList.Value>
            </DataList.Item>
            <DataList.Item>
              <DataList.Label>Timezone</DataList.Label>
              <DataList.Value>{church.timezone || 'Not Set'}</DataList.Value>
            </DataList.Item>
            <DataList.Item>
              <DataList.Label>Updated At</DataList.Label>
              <DataList.Value>{DISPLAY_DATE_FORMATTER(church.updated_at)}</DataList.Value>
            </DataList.Item>
          </Layout.Group>
        </DataList>
      </Layout.Group>

      <Layout.Footer>
        <Layout.Card style={{ borderRadius: SIZE[1] }}>
          <Layout.Header headline="Identification" />
          <Layout.Group>
            <DataList inline labelWidth="20rem">
              <DataList.Item>
                <DataList.Label>Id</DataList.Label>
                <DataList.Value>{church.id}</DataList.Value>
              </DataList.Item>
              <DataList.Item>
                <DataList.Label></DataList.Label>
                <DataList.Value>
                  <ButtonCopy data={church.id} />
                </DataList.Value>
              </DataList.Item>
              <DataList.Item>
                <DataList.Label>Owner Id</DataList.Label>
                <DataList.Value>{LINK_FORMATTER(church.owner_id)}</DataList.Value>
              </DataList.Item>
            </DataList>
          </Layout.Group>
        </Layout.Card>
        <Layout.Group>
          <Layout.Flex justifyContent="center">
            <ButtonConsoleLog data={church} />
          </Layout.Flex>
        </Layout.Group>
      </Layout.Footer>
    </Layout.Panel>
  );
};

const ButtonEdit = (props: { churchId: string }) => {
  const navigate = useNavigate();
  return (
    <Button shape="pill" size="small" variant="secondary" onClick={() => navigate(`/entity?key=${props.churchId}`)}>
      Editor
    </Button>
  );
};

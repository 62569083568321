import { ButtonCancel, ButtonPrimary, InputRow } from '@foyyay/control-elements';
import { unwrapResult } from '@reduxjs/toolkit';
import _intersection from 'lodash/intersection';
import _uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import React, { ReactElement, useContext, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../../../../../../component/Button';
import { Layout } from '../../../../../../../component/Layout';
import { Table } from '../../../../../../../component/Table';
import {
  BILLING_INTERVAL_TO_MONTH_COUNT,
  BillingInterval,
  BillingIntervalLabelMap,
  BillingItemProduct,
  BillingItemType,
  ProductModel,
} from '../../../../../../../constant';
import { AMOUNT_FORMATTER } from '../../../../../../../lib/formatting';
import { SubscriptionControllerContext } from '../../state/context';
import { createItem, selectAddableProducts, selectProductsLoading, selectSubscription } from '../../state/reducer';

export const ProductAdd = (props) => {
  const { dispatch, useSelector } = useContext(SubscriptionControllerContext);
  const loading = useSelector(selectProductsLoading);
  const addableProducts = useSelector(selectAddableProducts);
  const subscription = useSelector(selectSubscription);
  const [showPrivate, setShowPrivate] = useState(false);
  const [creating, setCreating] = useState(false);
  const [selectedProductCodes, setSelectedProductCodes] = useState<Array<ProductModel['code']>>([]);

  const addableProductCodes = addableProducts.map((product) => product.code);
  const addingProductCodes = _intersection(addableProductCodes, selectedProductCodes);

  const doClose = () => props.onClose();
  const doSelectProduct = (product: ProductModel) =>
    setSelectedProductCodes(_uniq([product.code, ...selectedProductCodes]));
  const doDeselectProduct = (product: ProductModel) =>
    setSelectedProductCodes(selectedProductCodes.filter((code) => code !== product.code));

  const doCreateItems = async () => {
    setCreating(true);

    const productsToAdd = addableProducts.filter((product) => addingProductCodes.includes(product.code));

    try {
      for (const product of productsToAdd) {
        const item: Partial<BillingItemProduct> = {
          type: BillingItemType.Product,
          data: {
            applicationGroups: 'group' in product ? [product.code] : product.applications.map((app) => app.group),
            description: product.description,
            interval_cost: product.interval_cost[BillingInterval.Month],
            monthly_cost: product.monthly_cost,
            product_code: product.code,
            product_name: product.name,
          },
        };
        await dispatch(createItem({ item: item })).then(unwrapResult);
      }
    } catch (error) {
      console.error(error);
      setCreating(false);
      return;
    }

    doClose();
  };

  const handleCloseClicked = () => {
    if (creating === true) {
      return;
    }
    doClose();
  };

  const handleSaveClicked = () => {
    if (creating === true) {
      return;
    }
    doCreateItems();
  };

  const handleProductClicked = (event, product: ProductModel) => {
    if (creating === true) {
      return;
    }

    if (addingProductCodes.includes(product.code)) {
      doDeselectProduct(product);
    } else {
      doSelectProduct(product);
    }
  };

  const isCreatable = addingProductCodes.length > 0;
  const createDisabled = isCreatable !== true || creating === true;

  const publicAddableProducts = addableProducts.filter((product) => product.public === true);
  const privateAddableProducts = addableProducts.filter((product) => product.public === false);

  const renderLoading = loading === true;
  const renderCreateButton = loading === false;
  const renderPrivate = renderLoading === false && showPrivate === true;

  return (
    <Layout.Panel>
      <Layout.Header
        headline="Add products"
        buttons={<ButtonCancel onClick={handleCloseClicked}>Cancel</ButtonCancel>}
      />
      <Table.OverflowAuto>
        <Table>
          <Table.Thead>
            <Table.Row>
              <Table.Header></Table.Header>
              <Table.Header>Name</Table.Header>
              <Table.Header>$/{BillingIntervalLabelMap[subscription.interval]}</Table.Header>
              <Table.Header>Description</Table.Header>
              <Table.Header>Applications</Table.Header>
            </Table.Row>
          </Table.Thead>
          <Table.Tbody>
            {renderLoading && (
              <Table.Row>
                <Table.Cell colSpan={99}>Loading</Table.Cell>
              </Table.Row>
            )}
            {publicAddableProducts.map((product) => (
              <ProductRow
                key={product.code}
                product={product}
                onClick={handleProductClicked}
                checked={selectedProductCodes.includes(product.code)}
              />
            ))}
            <Table.Row>
              <Table.Cell colSpan={99} style={{ background: 'transparent' }}>
                <Button
                  size="small"
                  width="100%"
                  variant="tertiary"
                  onClick={() => setShowPrivate((showPrivate) => !showPrivate)}
                >
                  {showPrivate ? 'Hide' : 'Show'} private products
                </Button>
              </Table.Cell>
            </Table.Row>
            {renderPrivate && (
              <>
                {renderLoading && (
                  <Table.Row>
                    <Table.Cell colSpan={99}>Loading</Table.Cell>
                  </Table.Row>
                )}
                {privateAddableProducts.map((product) => (
                  <ProductRow
                    key={product.code}
                    product={product}
                    onClick={handleProductClicked}
                    checked={selectedProductCodes.includes(product.code)}
                  />
                ))}
              </>
            )}
          </Table.Tbody>
        </Table>
      </Table.OverflowAuto>
      {renderCreateButton && (
        <EditRow>
          <ButtonPrimary disabled={createDisabled} onClick={handleSaveClicked} loading={creating}>
            Add
          </ButtonPrimary>
        </EditRow>
      )}
    </Layout.Panel>
  );
};

interface ProductAddProps {
  checked: boolean;
  onClick: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, product: ProductModel) => void;
  product: ProductModel;
}
const ProductRow = ({ checked, onClick, product }: ProductAddProps): ReactElement => {
  const { useSelector } = useContext(SubscriptionControllerContext);
  const subscription = useSelector(selectSubscription);

  return (
    <Table.Row key={product.code} onClick={(event) => onClick(event, product)}>
      <Table.Cell>
        <input type="checkbox" checked={checked} readOnly />
      </Table.Cell>
      <Table.Cell>{product.name}</Table.Cell>
      <Table.Cell>
        {AMOUNT_FORMATTER(product.monthly_cost * BILLING_INTERVAL_TO_MONTH_COUNT[subscription.interval])}
      </Table.Cell>
      <Table.Cell>{product.description}</Table.Cell>
      <Table.Cell>
        {'group' in product
          ? product.group
          : product.applications.map((application) => (
              <React.Fragment key={application.group}>
                {application.group}
                <br />
              </React.Fragment>
            ))}
      </Table.Cell>
    </Table.Row>
  );
};

ProductAdd.propTypes = {
  onClose: PropTypes.func,
};

ProductAdd.defaultProps = {
  onClose: () => {},
};

const EditRow = styled(InputRow)`
  justify-content: space-between;
`;

import _upperFirst from 'lodash/upperFirst';
import React, { useContext, useState } from 'react';
import { prefixOfKey } from '../../lib/key';
import { Button } from '../Button';
import { Layout } from '../Layout';
import { MemorandumsContext } from './MemorandumsContext';
import { selectAll, selectAsyncState } from './MemorandumsState';
import { MemorandumsTable } from './MemorandumsTable';

export const MemorandumsList = (): JSX.Element => {
  const { useSelector, subjectId } = useContext(MemorandumsContext);
  const state = useSelector(selectAsyncState);
  const loaded = state === 'fulfilled';
  const memorandums = useSelector(selectAll);
  const [renderForm, setRenderForm] = useState<boolean>(false);
  const toggleRenderForm = () => setRenderForm(!renderForm);
  const headline = [_upperFirst(prefixOfKey(subjectId)), 'Notes'].join(' ');

  return (
    <>
      <Layout.Header
        headline={headline}
        buttons={<CreateButton disabled={renderForm === true} onClick={toggleRenderForm} />}
      />
      <Layout.Group>
        <MemorandumsTable rows={memorandums} loaded={loaded} renderForm={renderForm} setRenderForm={setRenderForm} />
      </Layout.Group>
    </>
  );
};

const CreateButton = (props: { disabled: boolean; onClick: () => void }): JSX.Element => {
  return (
    <Button variant="secondary" shape="pill" size="small" onClick={props.onClick} disabled={props.disabled}>
      + Add New
    </Button>
  );
};

import React from 'react';
import styled, { keyframes } from 'styled-components';
import { defaultTheme } from '../constant';

const rotate = keyframes`
  0%, 5% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0%, 4% {
    stroke-dasharray: 157;
    stroke-dashoffset: 157;
  }
  52% {
    stroke-dasharray: 157;
    stroke-dashoffset: 39.75;
  }
  100% {
    stroke-dasharray: 157;
    stroke-dashoffset: -157;
  }
`;

const Spinner = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const SpinnerTrack = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  stroke: ${defaultTheme.Palette.Light1};
`;

const SpinnerProgress = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  svg {
    stroke: ${defaultTheme.Palette.Light3};
    animation: ${rotate} 2s ease-in-out infinite;
    transform-origin: center center;
  }

  circle {
    animation: ${dash} 2s ease-in-out infinite;
    stroke-linecap: round;
  }
`;

export const SimpleLoadingSpinner = (props) => {
  return (
    <Spinner>
      <SpinnerTrack>
        <svg width="54" height="54">
          <circle cx="27" cy="27" r="25" fill="transparent" strokeWidth="2" />
        </svg>
      </SpinnerTrack>
      <SpinnerProgress>
        <svg width="54" height="54">
          <circle
            id="rg-spinner__loading-circle"
            r="25"
            cx="27"
            cy="27"
            fill="transparent"
            strokeDasharray="157"
            strokeDashoffset="0"
            style={{ strokeDashoffset: '39.75' }}
            strokeWidth="3"
            strokeLinecap="round"
          />
        </svg>
      </SpinnerProgress>
    </Spinner>
  );
};

const LoadingSpacer = styled.div`
  position: relative;
  padding-top: 7.5rem;
`;

export const LoadingSpinner = () => {
  return (
    <LoadingSpacer>
      <SimpleLoadingSpinner />
    </LoadingSpacer>
  );
};

import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { PersonHttpApi } from '../api/PersonHttpApi';

export const useTransactions = (personId?: string): UseQueryResult<any[], Error> => {
  return useQuery({
    staleTime: 1000 * 60 * 5,
    queryKey: ['person', 'transactions', personId],
    queryFn: async () => {
      if (personId === undefined) {
        throw new Error('personId is required');
      }
      const response = await PersonHttpApi.listTransactions(personId);
      return response.rows;
    },
    enabled: personId !== undefined,
  });
};

import type { Memorandum } from '../../../type/memorandum';
import { createAsyncThunk, createEntityAdapter, createReducer, EntityState } from '@reduxjs/toolkit';
import { MemorandumApi } from '../../action/memorandum';

export interface MemorandumEntity {
  id: string;
  memorandum: Memorandum;
}

export interface MemorandumsState extends EntityState<MemorandumEntity> {
  asyncState: 'pending' | 'fulfilled' | 'rejected';
}

export const createMemorandum = createAsyncThunk(
  'memorandum/create',
  async (args: { subjectId: string; options: Partial<Memorandum> }) => {
    const response = await MemorandumApi.create(args.subjectId, args.options);
    return { id: response.memorandum?.id, ...response };
  }
);

export const updateMemorandum = createAsyncThunk(
  'memorandum/update',
  async (args: { subjectId: string; memorandumId: string; options: Partial<Memorandum> }) => {
    const response = await MemorandumApi.update(args.subjectId, args.memorandumId, args.options);
    return { id: response.memorandum?.id, ...response };
  }
);

export const listMemorandum = createAsyncThunk('memorandum/list', async (args: { subjectId: string }) => {
  const response = await MemorandumApi.list(args.subjectId);
  return response.map((result) => ({ id: result.memorandum.id, ...result }));
});

/** https://github.com/reduxjs/redux-toolkit/blob/v1.4.0/docs/api/createEntityAdapter.md */
const memorandumsAdapter = createEntityAdapter<MemorandumEntity>({
  sortComparer: (a, b) => (b.memorandum?.createdAt ?? '').localeCompare(a.memorandum?.createdAt ?? ''),
});

export const selectAll = memorandumsAdapter.getSelectors().selectAll;
export const selectMemorandums = (state: MemorandumsState): MemorandumEntity[] =>
  selectAll(state).filter((entity) => entity.memorandum.archivedAt === undefined);

export const memorandumEntityByIdSelector =
  (id: string) =>
  (state: MemorandumsState): MemorandumEntity | undefined =>
    memorandumsAdapter.getSelectors().selectById(state, id);

export const memorandumByIdSelector =
  (id: string) =>
  (state: MemorandumsState): Memorandum | undefined =>
    memorandumsAdapter.getSelectors().selectById(state, id)?.memorandum;

export const selectAsyncState = (state: MemorandumsState): MemorandumsState['asyncState'] => state.asyncState;

export const memorandumsInit = (): MemorandumsState => {
  return memorandumsAdapter.getInitialState({
    asyncState: 'pending',
  });
};

export const nextIdSelector =
  (currentId: string) =>
  (state: MemorandumsState): string | undefined => {
    const entities = memorandumsAdapter.getSelectors().selectAll(state);
    const index = entities?.findIndex((memorandum) => memorandum.id === currentId);
    let nextId;
    if (index > -1) {
      nextId = entities[index + 1] ? entities[index + 1]['id'] : undefined;
    }
    return nextId;
  };

export const previousIdSelector =
  (currentId: string) =>
  (state: MemorandumsState): string | undefined => {
    const entities = memorandumsAdapter.getSelectors().selectAll(state);
    const index = entities?.findIndex((memorandum) => memorandum.id === currentId);
    let previousId;
    if (index > 0) {
      previousId = entities[index - 1] ? entities[index - 1]['id'] : undefined;
    }
    return previousId;
  };

export const memorandumsReducer = createReducer({} as MemorandumsState, {
  [listMemorandum.pending.type]: (state: MemorandumsState): MemorandumsState => ({
    ...state,
    asyncState: 'pending',
  }),
  [listMemorandum.fulfilled.type]: (
    state: MemorandumsState,
    action: { payload: MemorandumEntity[] }
  ): MemorandumsState => {
    return memorandumsAdapter.setAll(
      {
        ...state,
        asyncState: 'fulfilled',
      },
      action.payload
    );
  },
  [listMemorandum.rejected.type]: (state: MemorandumsState): MemorandumsState => ({
    ...state,
    asyncState: 'rejected',
  }),
  [createMemorandum.fulfilled.type]: (
    state: MemorandumsState,
    action: { payload: MemorandumEntity }
  ): MemorandumsState => {
    return memorandumsAdapter.addOne(state, action.payload);
  },
  [updateMemorandum.fulfilled.type]: (
    state: MemorandumsState,
    action: ReturnType<typeof updateMemorandum.fulfilled>
  ): MemorandumsState => {
    return memorandumsAdapter.setOne(state, action.payload);
  },
});

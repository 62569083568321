import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonCircleX } from './ButtonCircleX';
import { Button } from './Button';

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: center;
  margin: 0;
  width: 100%;

  ${Button} {
    margin: 0;
  }
`;

export const ButtonGroupColumn = styled.div`
  flex: 1 1 auto;

  ${Button} + ${Button} {
    margin-top: 1rem;
    width: 100%;
  }
`;

export const ButtonGroupCircleX = styled(ButtonCircleX)`
  flex: 0 0 auto;
  height: 6rem;
  width: 6rem;
  margin-right: 1.6rem;

  svg {
    width: 1.6rem;
    height: 1.6rem;
  }
`;

export const OptionalGoBackButton = (props) => {
  if (props.onClick === undefined) {
    return props.children;
  }

  return (
    <ButtonGroup>
      <ButtonGroupCircleX onClick={props.onClick} />
      <ButtonGroupColumn>{props.children}</ButtonGroupColumn>
    </ButtonGroup>
  );
};

OptionalGoBackButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

import { UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { CustomProcessHttpApi } from '../api/CustomProcessHttpApi';
import { CustomProcess } from '../../type/CustomProcess';
import { Nullable } from '../../type/utility';

const getCacheKey = (churchId: string, process: string, entityId: string) => [
  'customProcess',
  churchId,
  process,
  entityId,
];

export { getCacheKey as getCustomProcessCacheKey };

export const useCustomProcess = (
  church: { id: string },
  process: string,
  entityId: string
): UseQueryResult<CustomProcess, unknown> => {
  return useQuery({
    queryKey: getCacheKey(church.id, process, entityId),
    queryFn: async () => {
      const response = await CustomProcessHttpApi.read(church, process, entityId, true);
      return response.globals;
    },
  });
};

export const useCustomProcessUpdate = (
  church: { id: string },
  process: string,
  entityId: string
): UseMutationResult<CustomProcess, unknown, Nullable<CustomProcess>> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: getCacheKey(church.id, process, entityId),
    mutationFn: async (updates) => {
      const response = await CustomProcessHttpApi.update(church, process, entityId, updates);
      return response.globals;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(getCacheKey(church.id, process, entityId), data);
    },
  });
};

export const useInvalidateCustomProcessQuery = (
  church: { id: string },
  process: string,
  entityId: string
): (() => Promise<void>) => {
  const queryClient = useQueryClient();

  return useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: getCacheKey(church.id, process, entityId) });
  }, [church.id, entityId, process, queryClient]);
};

import { unwrapResult } from '@reduxjs/toolkit';
import React, { useContext, useState } from 'react';
import { entityPlatform, Platform } from '../../../../../../../lib/platform';
import { SubscriptionControllerContext } from '../../state/context';
import { runLifecycleHooks, selectSubscription } from '../../state/reducer';
import { SubscriptionAction } from './SubscriptionAction';

export const SubscriptionLifecycleRunner = () => {
  const { dispatch, useSelector } = useContext(SubscriptionControllerContext);
  const subscription = useSelector(selectSubscription);
  const [lifecycleRunning, setLifecycleRunning] = useState(false);

  const doRunLifecycle = async () => {
    setLifecycleRunning(true);
    try {
      await dispatch(runLifecycleHooks({ subscription: subscription })).then(unwrapResult);
      alert('Complete!');
    } catch (error) {
      console.error(error);
      alert((error as Error).message);
    }
    setLifecycleRunning(false);
  };

  const handleRunLifecycle = (event) => {
    if (lifecycleRunning === true) {
      return;
    }
    doRunLifecycle();
  };

  let buttonLabel = 'Run Application Lifecycle Hooks';
  if (lifecycleRunning === true) {
    buttonLabel = 'Running Application Lifecycle...';
  }

  if (entityPlatform(subscription) === Platform.RebelGive) {
    return null;
  }

  return <SubscriptionAction onClick={handleRunLifecycle}>{buttonLabel}</SubscriptionAction>;
};

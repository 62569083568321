import Auth from '@aws-amplify/auth';
import { connect } from 'react-redux';
import { unloadSession } from '../../action/user';
import { useNavigate } from 'react-router-dom';

interface SignOutProps {
  onSignOut: Function;
}

const SignOutComponent = (props: SignOutProps) => {
  const navigate = useNavigate();
  Auth.signOut()
    .then(() => navigate('/sign-in', { replace: true }))
    .catch((e) => {
      console.error('error signing out...', e);
      window.location.reload();
      return null;
    });

  props.onSignOut();

  return null;
};

function mapDispatchToProps(dispatch: Function) {
  return {
    onSignOut: () => dispatch(unloadSession()),
  };
}

export const SignOut = connect(undefined, mapDispatchToProps)(SignOutComponent);

import React from 'react';
import { useParams } from 'react-router';
import { AddFeature, ExistingFeatures } from '../../../component/Features';
import { Layout } from '../../../component/Layout';
import { Loading } from '../../../component/Progress/Loading';
import { RenderBlocker } from '../../../component/RenderBlocker';
import { useChurch } from '../../../hooks/useChurch';
import { Platform, entityPlatform } from '../../../lib/platform';

export const Features = () => {
  const params = useParams<{ churchId: string }>();
  const churchId = params.churchId ?? '';

  const { data: church, refetch } = useChurch(churchId);

  const reloadChurch = () => refetch();
  const showAddFeatures = churchId !== undefined;
  const showFeatures = church?.features?.length > 0;

  let platform: Platform | undefined;
  if (church !== undefined) {
    platform = entityPlatform(church);
  }

  if (church === undefined) {
    return (
      <Layout.Card.Message>
        <Layout.Header headline={'No Features Found'} />
      </Layout.Card.Message>
    );
  }

  return (
    <>
      <Layout.Panel platform={platform}>
        <RenderBlocker require={[church, showAddFeatures]} fallback={<Loading />}>
          <AddFeature name={church?.name} owner={{ id: churchId }} onAddedCallback={reloadChurch} />
        </RenderBlocker>
      </Layout.Panel>

      <RenderBlocker require={[church, showFeatures]}>
        <Layout.Panel>
          <ExistingFeatures features={church?.features} onRemovedCallback={reloadChurch} owner={{ id: churchId }} />
        </Layout.Panel>
      </RenderBlocker>
    </>
  );
};

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Job } from '../../type/job';
import { JobHttpApi } from '../api/JobHttpApi';

const getCacheKey = (job: Job) => ['job', job.id];

export const useJobStart = <T extends Job = Job>() => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (job: Partial<T>) => {
      const response = await JobHttpApi.start(job);
      return response.job;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(getCacheKey(data), data);
    },
  });
};

export const useJobStatus = <T extends Job = Job>(job: T) => {
  const [polling, setPolling] = useState(true);
  return useQuery({
    queryKey: getCacheKey(job),
    queryFn: async () => {
      const response = await JobHttpApi.status(job);
      setPolling(['pending', 'running'].includes(response.job.status));
      return response.job;
    },
    enabled: polling,
    refetchInterval: 200,
  });
};

export const useJobAbort = <T extends Job = Job>() => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (job: Pick<T, 'id'>) => {
      const response = await JobHttpApi.abort(job);
      return response.job;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(getCacheKey(data), data);
    },
  });
};

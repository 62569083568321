import { ButtonSecondary, Label } from '@foyyay/control-elements';
import { isEmpty as _isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams, useResolvedPath } from 'react-router-dom';
import { destroyIdentityPolicy, listIdentityPoliciesByPrinciple } from '../../../action/policy';
import { Button } from '../../../component/Button';
import { Layout } from '../../../component/Layout';
import { ObjectsTable } from '../../../component/ObjectsTable';
import { PanelButton } from '../../../component/PanelButton';
import { Loading } from '../../../component/Progress/Loading';
import { useAppDispatch } from '../../../hooks/hooks';
import { useIdentityPolicies } from '../../../hooks/policy';
import { isPrefixedKey } from '../../../lib/key';
import { FORMAT_HINTS, LINK_FORMATTER } from '../../../lib/objectGridAttributeLibrary';

export const Policies = () => {
  const url = useResolvedPath('/policies/identity').pathname;
  const params = useParams<{ personId: string }>();
  const personId = params.personId ?? '';

  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const loadPolicies = useCallback(async () => {
    setLoading(true);
    setError(undefined);
    try {
      await dispatch(listIdentityPoliciesByPrinciple(personId));
    } catch (err: any) {
      setError(err.body ?? err.message);
    }
    setLoading(false);
  }, [dispatch, personId]);

  useEffect(() => {
    loadPolicies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personId]);

  const [policies, canLoadMore] = useIdentityPolicies();

  const showError = error !== undefined;

  const policyData = policies
    .map((policy) => {
      const principleName = policy.principle?.split(':').slice(-1)[0];
      const resourceName = policy.resource.split(':').slice(-1)[0];

      if (principleName !== personId) {
        return undefined;
      }

      if (isPrefixedKey('church', resourceName) === false) {
        return undefined;
      }

      const title = `${principleName} -> ${resourceName}`;
      policy = {
        ...policy,
        keyString: title,
        church: LINK_FORMATTER(resourceName),
        policyActions: (
          <Layout.ButtonGroup>
            <EditButton to={`${url}/edit/${policy.principle}/${policy.resource}`}>View Policy</EditButton>
            <DeleteButton policy={policy} />
          </Layout.ButtonGroup>
        ),
      };

      return policy;
    })
    .filter((policy) => policy !== undefined);

  const columns = ['church', 'policyActions'];
  const showEmpty = loading === false && _isEmpty(policyData);

  return (
    <Layout.Panel platform="nucleus">
      <Layout.Header headline="Policies" />
      <Link to={`${url}/new`}>
        <ButtonSecondary>Add New Policy</ButtonSecondary>
      </Link>
      {!loading && (
        <ObjectsTable
          data={policyData}
          hints={{ policyActions: FORMAT_HINTS.NONE, church: FORMAT_HINTS.NONE }}
          keys={columns}
          rowKey="keyString"
        />
      )}
      {canLoadMore && <PanelButton onClick={() => loadPolicies()}>Load More</PanelButton>}
      {loading && <Loading />}
      {showEmpty && (
        <Layout.Card.Message>
          <Layout.Header headline="No Policies found." />
        </Layout.Card.Message>
      )}
      {showError && <Label error={showError}>{error}</Label>}
    </Layout.Panel>
  );
};

function DeleteButton({ policy }) {
  const dispatch = useAppDispatch();
  const [deleting, setDeleting] = useState(false);

  const doDeletePolicy = async () => {
    await dispatch(destroyIdentityPolicy(policy.principle, policy.resource));
  };

  const handleDeletePolicy = async () => {
    setDeleting(true);
    try {
      await doDeletePolicy();
    } catch (error) {
      console.error(error);
      setDeleting(false);
    }
  };

  const confirmDeletePolicy = () => {
    const doIt = window.confirm('Are you sure? This cannot be undone!');
    if (doIt !== true) {
      return;
    }
    handleDeletePolicy();
  };

  return (
    <Button shape="pill" size="small" variant="secondary" onClick={confirmDeletePolicy} loading={deleting}>
      Delete
    </Button>
  );
}

const EditButton = (props) => {
  return (
    <Button shape="pill" size="small" variant="secondary" to={props.to}>
      View / Edit
    </Button>
  );
};

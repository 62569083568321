import { InfiniteData, UseInfiniteQueryResult, UseQueryResult } from '@tanstack/react-query';

/**
 * Converts the result of an infinite query to a modified query result.
 * @param result
 * @param callback
 * @returns The modified query result.
 */
export const toQueryResult = <T, R>(
  result: UseInfiniteQueryResult<InfiniteData<T>>,
  callback: (response: T) => R[]
): UseQueryResult<R> & Omit<UseInfiniteQueryResult<T>, 'data'> => {
  return {
    ...result,
    data: result.data?.pages.flatMap(callback) ?? [],
  } as any;
};

type ExtractUseInfiniteQueryResult<T> = T extends (...args: any[]) => UseInfiniteQueryResult<InfiniteData<infer R>>
  ? R
  : never;

export const withToQueryResult = <T extends (...args: any[]) => UseInfiniteQueryResult<any>, R>(
  useCustomHook: T,
  callback: (response: ExtractUseInfiniteQueryResult<T>) => R[]
): ((...args: Parameters<T>) => UseQueryResult<R[]> & Omit<UseInfiniteQueryResult<T>, 'data'>) =>
  ((...args: Parameters<T>) => toQueryResult(useCustomHook(...args), callback)) as any;

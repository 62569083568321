import { ButtonSecondary } from '@foyyay/control-elements';
import React from 'react';
import { useRpMerchantSubmit } from '../../../../../../../hooks/useRpMerchant';

export const RpSubmitMerchant = (props: any) => {
  const rpmerchant = props.rpmerchant;

  const { mutate: submit, isPending } = useRpMerchantSubmit(rpmerchant);

  return (
    <div key={`merchant-submit-${rpmerchant.id}`}>
      <ButtonSecondary loading={isPending} onClick={submit}>
        Submit Signature
      </ButtonSecondary>
    </div>
  );
};

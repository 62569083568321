import { BodyStyle, ButtonCircleArrow, Container, LabelStep, Step, StepInput, media } from '@foyyay/control-elements';
import Auth from '@aws-amplify/auth';
import React, { useState, FormEvent } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { REDIRECT_PARAM_KEY, EMAIL_REGEX } from '../../constant';
import { fromBase64 } from '../../lib/encoding';
import styled from 'styled-components';
import FortBrandmark from './../../component/FortBrandmark';
import { useInputFocusOnceRef } from '../../hooks/useInputFocusOnceRef';

export const SignIn = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useInputFocusOnceRef();
  const [signinError, setSigninError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [processing, setProcessing] = useState(false);

  const attemptSignIn = async (event: FormEvent) => {
    setSigninError('');
    event?.preventDefault();

    if (EMAIL_REGEX.test(email) !== true) {
      setSigninError('An email is required');
      return;
    }
    if (password === '') {
      setSigninError('A password is required');
      return;
    }

    setProcessing(true);
    let cognitoUser;
    try {
      cognitoUser = await Auth.signIn(email.toLowerCase(), password);
    } catch (error: any) {
      setProcessing(false);
      if (error.message !== undefined) {
        setSigninError(error.message);
      } else {
        setSigninError('Unknowns error');
      }
      return;
    }

    if (cognitoUser['challengeName'] !== undefined) {
      setSigninError('Your account requires attention. Please manage in the Control Center.');
      setProcessing(false);
      return;
    }

    setSigninError('');

    let params = new URLSearchParams(location.search);
    let redirectTo = '/';

    try {
      redirectTo = fromBase64(params.get(REDIRECT_PARAM_KEY));
    } catch (err) {
      // Do nothing.
    }
    navigate(redirectTo);
  };

  let label = 'Login';
  if (processing) {
    label = 'Logging in...';
  }

  return (
    <>
      <BodyStyle />
      <div>
        <Container maxWidth="58rem" marginTop="10rem">
          <FortBrandmark />
          <div style={{ height: '10rem' }} />
          <Step>
            <form onSubmit={attemptSignIn}>
              <LabelStep absolute error={signinError}>
                {label}
              </LabelStep>
              <LayoutStep>
                <FieldWrapper>
                  <form name="signin" autoComplete="on" onSubmit={attemptSignIn}>
                    <StepInput
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="username"
                      placeholder="email@example.com"
                      spellCheck="false"
                      value={email}
                      onChange={(value: string) => {
                        setEmail(value);
                        setSigninError('');
                      }}
                      error={signinError}
                      ref={ref}
                    />
                    <StepInput
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      minLength="8"
                      maxLength="99"
                      placeholder="password"
                      value={password}
                      onChange={(value: string) => {
                        setPassword(value);
                        setSigninError('');
                      }}
                      onEnter={attemptSignIn}
                      error={signinError}
                    />
                  </form>
                </FieldWrapper>
                <ButtonCircleArrow onClick={attemptSignIn} loading={processing} />
              </LayoutStep>
            </form>
          </Step>
        </Container>
      </div>
    </>
  );
};

const FieldWrapper = styled.div`
  padding-top: 1rem;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const LayoutStep = styled.div`
  align-items: center;
  display: flex;
  height: 12rem;
  justify-content: space-between;
  transform: translateY(-0.1rem);

  ${media.tabletPortraitAndUp`
    height: 15rem;
  `}
`;

import { ButtonSecondary, Label } from '@foyyay/control-elements';
import React, { useEffect, useState } from 'react';
import { Link, useResolvedPath } from 'react-router-dom';
import { destroyResourcePolicy, listResourcePolicies } from '../../../../action/policy';
import { Layout } from '../../../../component/Layout';
import { ObjectsTable } from '../../../../component/ObjectsTable';
import { PanelButton } from '../../../../component/PanelButton';
import { Loading } from '../../../../component/Progress/Loading';
import { useAppDispatch } from '../../../../hooks/hooks';
import { useResourcePolicies } from '../../../../hooks/policy';
import { FORMAT_HINTS } from '../../../../lib/objectGridAttributeLibrary';

function ListResourceComponent() {
  const url = useResolvedPath('').pathname;
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [policies, canLoadMore, cursor] = useResourcePolicies();

  const loadPolicies = async () => {
    setLoading(true);
    setError(undefined);
    try {
      await dispatch(listResourcePolicies(cursor));
    } catch (err: any) {
      setError(err.body ?? err.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadPolicies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showError = error !== undefined;

  const policyData = policies.map((policy) => {
    policy = {
      ...policy,
      resourceLink: <Link to={`${url}/edit/${policy.resource}`}>{policy.resource}</Link>,
    };

    if (policy.editable === true) {
      policy.action = <DeleteButton resource={policy.resource} />;
    }

    return policy;
  });

  return (
    <Layout.Panel>
      <Link to={`${url}/new`}>
        <ButtonSecondary>Add New Policy</ButtonSecondary>
      </Link>
      <ObjectsTable
        data={policyData}
        keys={['resourceLink', 'updatedAt', 'action']}
        hints={{
          resourceLink: FORMAT_HINTS.NONE,
          action: FORMAT_HINTS.NONE,
        }}
        firstAttributes={['resourceLink']}
        rowKey="resource"
      />
      {canLoadMore && <PanelButton onClick={() => loadPolicies()}>Load More</PanelButton>}
      {loading && <Loading />}
      {showError && <Label error={showError}>{error}</Label>}
    </Layout.Panel>
  );
}

export const ListResource = ListResourceComponent;

function DeleteButton({ resource }) {
  const dispatch = useAppDispatch();
  const [deleting, setDeleting] = useState(false);

  const doDeletePolicy = async () => {
    await dispatch(destroyResourcePolicy(resource));
  };

  const handleDeletePolicy = async () => {
    setDeleting(true);
    await doDeletePolicy();
  };

  const confirmDeletePolicy = () => {
    const doIt = window.confirm('Are you sure? This cannot be undone!');
    if (doIt !== true) {
      return;
    }
    handleDeletePolicy();
  };

  return (
    <ButtonSecondary onClick={confirmDeletePolicy} loading={deleting}>
      Delete
    </ButtonSecondary>
  );
}

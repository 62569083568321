import React from 'react';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { OnlyKeysRequired } from '../../../../../../../type/utility';
import { InvoiceModel } from '../../../../../../constant';

export type SubscriptionControllerContext = {
  dispatch: ThunkDispatch<void, unknown, AnyAction>;
  getInvoiceUrl: (invoice: OnlyKeysRequired<InvoiceModel, 'id'>) => string;
  overviewUrl: string;
  useSelector: <S, R>(selector: (state: S) => R) => R;
};

export const SubscriptionControllerContext = React.createContext<SubscriptionControllerContext>(
  {} as SubscriptionControllerContext
);

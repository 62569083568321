import React, { StrictMode } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppRoutes } from './routes/AppRoutes';
import { Theme as ThemeOld } from '@foyyay/control-elements';
import { Theme } from './component/Theme';
import { defaultTheme, defaultThemeOld } from './constant';

import './css/normalize.css';
import './css/fonts.css';
import './css/index.css';

export const App = () => (
  <StrictMode>
    <ThemeOld.Provider value={defaultThemeOld}>
      <Theme.Provider value={defaultTheme}>
        <Router>
          <AppRoutes />
        </Router>
      </Theme.Provider>
    </ThemeOld.Provider>
  </StrictMode>
);

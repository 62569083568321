import React from 'react';
import { Navigate, Route, Routes, useParams, useResolvedPath } from 'react-router-dom';
import { LayoutPrimary } from '../../component/LayoutPrimary';
import { MemorandumsController } from '../../component/Memorandums/MemorandumsController';
import { MemorandumsList } from '../../component/Memorandums/MemorandumsList';
import { MemorandumsMain, MemorandumsSidebar, MemorandumsWrapper } from '../../component/MemorandumsGrid';
import { PillNav } from '../../component/PillNav';
import { usePrefetchPersonData } from '../../hooks/usePrefetchPersonData';
import { Churches } from './route/Churches';
import { Features } from './route/Features';
import { Giftschedules } from './route/Giftschedules/Giftschedules';
import { Overview } from './route/Overview';
import { PaymentMethods } from './route/PaymentMethods';
import { Transactions } from './route/Transactions/Transactions';

const headline = 'Person';
const byline = 'The Fort';

export const Person = (props: any) => {
  const url = useResolvedPath('').pathname;
  const { personId } = useParams<{ personId: string }>();

  usePrefetchPersonData(personId);

  const navList = [
    {
      title: 'Overview',
      to: `${url}/overview`,
    },
    {
      title: 'Churches',
      to: `${url}/churches`,
    },
    {
      title: 'Payments',
      to: `${url}/payment-methods`,
    },
    {
      title: 'Schedules',
      to: `${url}/schedules`,
    },
    {
      title: 'Transactions',
      to: `${url}/transactions`,
    },
    {
      title: 'Features',
      to: `${url}/features`,
    },
  ];

  return (
    <LayoutPrimary layoutTitle={headline} layoutByline={`${byline.toUpperCase()}: ${personId}`}>
      <PillNav navList={navList} />
      <MemorandumsWrapper>
        <MemorandumsMain>
          <Routes>
            <Route path={'/overview'} element={<Overview />} />
            <Route path={'/churches'} element={<Churches />} />
            <Route path={'/payment-methods'} element={<PaymentMethods />} />
            <Route path={'/schedules/*'} element={<Giftschedules />} />
            <Route path={'/transactions/*'} element={<Transactions />} />
            <Route path={'/features'} element={<Features />} />
            <Route path={'/*'} element={<Navigate to={`${url}/overview`} replace={true} />} />
          </Routes>
        </MemorandumsMain>
        <MemorandumsSidebar>
          <MemorandumsController subjectId={personId ?? ''}>
            <MemorandumsList />
          </MemorandumsController>
        </MemorandumsSidebar>
      </MemorandumsWrapper>
    </LayoutPrimary>
  );
};

export const STATE_CREATING = 'creating';
export const STATE_DEFAULT = 'default';
export const STATE_DELETING = 'deleting';
export const STATE_EDITING = 'editing';
export const STATE_ERROR = 'error';
export const STATE_LOADED = 'loaded';
export const STATE_LOADING = 'loading';
export const STATE_SUCCESS = 'success';

export const REDUX_STATE_KEY = '__nucleusdashboard_redux_state';
export const REDUX_STATE_VERSION = 1; // Advance me when required to invalidate bad state or changes to format are required.

export const STATE_IDLE = 'idle';
export const STATE_PENDING = 'pending';
export const STATE_FULFILLED = 'fulfilled';
export const STATE_REJECTED = 'rejected';

import styled from 'styled-components';
import { SIZE } from './style-utils';
import { Toggle } from './Toggle';
import { FontStyles } from './Typography';

export const Headline = styled.h1`
  ${FontStyles.H1};
  max-width: 76rem;
  margin: 0;
  color: ${({ theme }) => theme.Typography.Color.Default.Heading};

  ${Toggle} {
    margin-left: ${SIZE[4]};
    margin-right: ${SIZE[4]};
    vertical-align: text-top;
  }
`;

import {
  ButtonCancel,
  ButtonPrimary,
  InputCurrencySmall,
  InputRow,
  InputText,
  RadioGroup,
  RadioOption,
  Select,
} from '@foyyay/control-elements';
import { unwrapResult } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Layout } from '../../../../../../../component/Layout';
import { BillingItemType } from '../../../../../../../constant';
import { SubscriptionControllerContext } from '../../state/context';
import { createItem } from '../../state/reducer';

const DESCRIPTIONS = Object.freeze(['RebelGive Credit', 'Nucleus 1 Stripe Credit']);

type CreditDebitItemType = BillingItemType.Credit | BillingItemType.Debit;

export const CreditDebitAdd = (props) => {
  const { dispatch } = useContext(SubscriptionControllerContext);

  const [itemType, setItemType] = useState<CreditDebitItemType>(BillingItemType.Credit);
  const [itemAmount, setItemAmount] = useState(1);
  const [itemDescription, setItemDescription] = useState('');
  const [saving, setSaving] = useState(false);

  const doClose = () => props.onClose();
  const doSaveItem = async () => {
    setSaving(true);
    try {
      await dispatch(
        createItem({
          item: {
            type: itemType,
            data: {
              description: itemDescription,
              amount: itemAmount,
            },
          },
        })
      ).then(unwrapResult);
    } catch (error) {
      console.error(error);
      setSaving(false);
      return;
    }
    doClose();
  };

  const handleCloseClicked = () => {
    if (saving === true) {
      return;
    }
    doClose();
  };

  const handleTypeChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (saving === true) {
      return;
    }
    setItemType(event.target.value as CreditDebitItemType);
  };

  const handleAmountChanged = (input: string) => {
    if (saving === true) {
      return;
    }
    const match = Array.from(input.matchAll(/[-0-9.]/g)).join('');
    const amount = Math.round(parseFloat(match) * 100);
    if (isNaN(amount)) {
      setItemAmount(0);
    } else {
      setItemAmount(amount);
    }
  };

  const handleDescriptionChanged = (description: string) => {
    if (saving === true) {
      return;
    }
    setItemDescription(description);
  };

  const handleSaveClicked = () => {
    if (saving === true) {
      return;
    }
    doSaveItem();
  };

  const descriptionPresetValue = DESCRIPTIONS.includes(itemDescription) === true ? itemDescription : '';
  const amountError = itemAmount <= 0;

  const isSavable = itemAmount > 0 && itemDescription.length > 2;
  const saveDisabled = isSavable !== true || saving === true;

  return (
    <Layout.Panel>
      <Layout.Header
        headline="Add an adjustment"
        buttons={<ButtonCancel onClick={handleCloseClicked}>Cancel</ButtonCancel>}
      />

      <EditRow>
        <TypeSelect style={{ display: 'inline-block' }} value={itemType} onChange={handleTypeChanged}>
          <option value={BillingItemType.Credit}>Credit</option>
          <option value={BillingItemType.Debit}>Debit</option>
        </TypeSelect>
      </EditRow>
      <EditRow>
        <Amount placeholder="Amount" onChange={handleAmountChanged} error={amountError} />
      </EditRow>

      <EditRow>
        <RadioGroupList value={descriptionPresetValue} onChange={handleDescriptionChanged}>
          {DESCRIPTIONS.map((preset) => (
            <RadioOption key={preset} value={preset} label={preset} />
          ))}
          <RadioOption value={''} label="Custom" />
        </RadioGroupList>
      </EditRow>

      <EditRow>
        <Description placeholder="Description" value={itemDescription} onChange={handleDescriptionChanged} />
      </EditRow>

      <EditRow>
        <ButtonPrimary disabled={saveDisabled} onClick={handleSaveClicked} loading={saving}>
          Save
        </ButtonPrimary>
      </EditRow>
    </Layout.Panel>
  );
};

CreditDebitAdd.propTypes = {
  onClose: PropTypes.func,
};

CreditDebitAdd.defaultProps = {
  onClose: () => {},
};

const EditRow = styled(InputRow)`
  justify-content: space-between;
`;

const TypeSelect = styled(Select)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const Amount = styled(InputCurrencySmall)`
  margin-top: 1rem;
  margin-left: 2rem;
  margin-bottom: 1rem;
`;

const Description = styled(InputText)`
  margin-top: 1rem;
  margin-left: 2rem;
  margin-bottom: 1rem;
`;

const RadioGroupList = styled(RadioGroup)`
  flex-direction: column;
`;

import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { PersonHttpApi } from '../api/PersonHttpApi';

export const useGiftSchedules = (personId?: string): UseQueryResult<any[], Error> => {
  return useQuery({
    staleTime: 1000 * 60 * 5,
    queryKey: ['person', 'giftSchedules', personId],
    queryFn: async () => {
      if (personId === undefined) {
        throw new Error('personId is required');
      }
      return await PersonHttpApi.listGiftSchedules(personId);
    },
    enabled: personId !== undefined,
  });
};

import { BILLING_STATUS_TO_NAME, SOURCE_TYPE_TO_NAME } from '../constant';

export const formatPaymentMethod = (subscription) => {
  if (subscription.rebelpay_source_id === undefined) {
    return 'No payment method';
  }

  return [SOURCE_TYPE_TO_NAME[subscription.source_type], `*${subscription.source_last_few}`].join(' ');
};

export const getSubscriptionStatusName = (subscription) => {
  if (subscription.ended_at !== undefined) {
    if (new Date(subscription.ended_at) > new Date()) {
      return 'Ending';
    } else {
      return 'Ended';
    }
  }

  return BILLING_STATUS_TO_NAME[subscription.status];
};

export const subscriptionStartDate = (subscription) => subscription.started_at ?? subscription.created_at;

export const nextSubscriptionSettlementDate = (subscription) =>
  [subscription.next_settlement_at, subscription.next_invoice_at].filter((date) => date !== undefined).sort()[0];

import { Headline, Statement } from '@foyyay/control-elements';
import React, { MouseEventHandler, useContext } from 'react';
import { Clickable } from '../../../../../../../../component/Button';
import { RelativeDate } from '../../../../../../../../component/Date';
import { Table } from '../../../../../../../../component/Table';
import { BillingStatus, BILLING_STATUS_TO_NAME, InvoiceItem, InvoiceModel } from '../../../../../../../../constant';
import { AMOUNT_FORMATTER } from '../../../../../../../../lib/formatting';
import { entityPlatform } from '../../../../../../../../lib/platform';
import { SubscriptionControllerContext } from '../../../state/context';
import { selectSubscription, updateInvoice } from '../../../state/reducer';
import { Layout } from '../../../../../../../../component/Layout';

interface InvoicePanelProps {
  invoice: InvoiceModel;
}

export const InvoicePanel = (props: InvoicePanelProps) => {
  const { dispatch, useSelector } = useContext(SubscriptionControllerContext);
  const subscription = useSelector(selectSubscription);

  const doUpdateInvoiceStatus = async (status: BillingStatus | null) => {
    await dispatch(
      updateInvoice({
        subscription: subscription,
        invoice: props.invoice,
        updateValues: {
          status_override: status,
        },
      })
    );
  };

  const handleStatusClicked: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    const newStatus = getNewStatus();
    if (newStatus === undefined) {
      return;
    }
    if (newStatus === props.invoice.status_override) {
      return;
    }
    doUpdateInvoiceStatus(newStatus);
  };

  const startDate = props.invoice.period_started_at || props.invoice.created_at;
  const endDate = props.invoice.period_ended_at;

  const renderPeriodEnd = endDate !== undefined;

  return (
    <Layout.Panel>
      <Headline>
        <Clickable onClick={handleStatusClicked}>{invoiceStatus(props.invoice)}</Clickable> Invoice Details
      </Headline>
      <Statement>
        Invoice covers{' '}
        <strong>
          <RelativeDate date={startDate} />
        </strong>
        {renderPeriodEnd && (
          <>
            {' '}
            to{' '}
            <strong>
              <RelativeDate date={endDate} />
            </strong>
          </>
        )}
      </Statement>
      <Table.OverflowAuto>
        <Table>
          <Table.Thead>
            <Table.Row>
              <Table.Header>Item</Table.Header>
              <Table.Header>Amount</Table.Header>
            </Table.Row>
          </Table.Thead>
          <Table.Tbody>
            {props.invoice.items.map((invoiceItem: InvoiceItem) => {
              return (
                <Table.Row platform={entityPlatform(subscription)} key={invoiceItem.description}>
                  <Table.Cell>{invoiceItem.description}</Table.Cell>
                  <Table.Cell>{AMOUNT_FORMATTER(invoiceItem.cost)}</Table.Cell>
                </Table.Row>
              );
            })}
            <Table.Row />
            <Table.Row platform={entityPlatform(subscription)}>
              <Table.Cell>Total</Table.Cell>
              <Table.Cell>{AMOUNT_FORMATTER(props.invoice.total)}</Table.Cell>
            </Table.Row>
          </Table.Tbody>
        </Table>
      </Table.OverflowAuto>
    </Layout.Panel>
  );
};

const invoiceStatus = (invoice: InvoiceModel): string => {
  const status = invoice.status_override ?? invoice.status;

  if (status === undefined) {
    return 'Preview';
  }

  return BILLING_STATUS_TO_NAME[status];
};

const InputToStatusMap: { [key: string]: BillingStatus | null } = Object.freeze({
  active: BillingStatus.Active,
  canceled: BillingStatus.Canceled,
  clear: null,
  paid: BillingStatus.Paid,
  pastdue: BillingStatus.PastDue,
  'past due': BillingStatus.PastDue,
  unpaid: BillingStatus.Unpaid,
  a: BillingStatus.Active,
  l: null,
  c: BillingStatus.Canceled,
  p: BillingStatus.Paid,
  d: BillingStatus.PastDue,
  u: BillingStatus.Unpaid,
});

const getNewStatus = (input?: string): BillingStatus | null | undefined => {
  const message = [
    `Pick a new status for this invoice:`,
    ``,
    `(A)ctive`,
    `(C)anceled`,
    `(P)aid`,
    `Past (D)ue`,
    `(U)npaid`,
    ``,
    `To clear any override use`,
    `C(l)ear`,
  ].join('\n');

  const answer = window.prompt(message, input);
  if (answer === null) {
    return;
  }

  const status = InputToStatusMap[answer.trim().toLowerCase()];
  if (status === undefined) {
    return getNewStatus(answer);
  }

  return status;
};

import { useSelector } from 'react-redux';
import {
  selectIdentityCanLoadMore,
  selectIdentityCursor,
  selectIdentityPolicies,
  selectManagedCanLoadMore,
  selectManagedCursor,
  selectManagedPolicies,
  selectResourceCanLoadMore,
  selectResourceCursor,
  selectResourcePolicies,
} from '../reducer/policy';

export const useIdentityPolicies = () => {
  return [
    useSelector(selectIdentityPolicies),
    useSelector(selectIdentityCanLoadMore),
    useSelector(selectIdentityCursor),
  ];
};

export const useManagedPolicies = () => {
  return [
    useSelector(selectManagedPolicies),
    useSelector(selectManagedCanLoadMore),
    useSelector(selectManagedCursor),
  ];
};

export const useResourcePolicies = () => {
  return [
    useSelector(selectResourcePolicies),
    useSelector(selectResourceCanLoadMore),
    useSelector(selectResourceCursor),
  ];
};

import { NUCLEUS_PLATFORM_API_URL } from '../constant';
import { apiCall } from '../lib/fetch';
import {
  ACTION_CREATE_IDENTITY_POLICY,
  ACTION_CREATE_MANAGED_POLICY,
  ACTION_CREATE_RESOURCE_POLICY,
  ACTION_DESTROY_IDENTITY_POLICY,
  ACTION_DESTROY_MANAGED_POLICY,
  ACTION_DESTROY_RESOURCE_POLICY,
  ACTION_LOAD_IDENTITY_POLICIES,
  ACTION_LOAD_IDENTITY_POLICY,
  ACTION_LOAD_MANAGED_POLICIES,
  ACTION_LOAD_MANAGED_POLICY,
  ACTION_LOAD_RESOURCE_POLICIES,
  ACTION_LOAD_RESOURCE_POLICY,
  ACTION_UPDATE_IDENTITY_POLICY,
  ACTION_UPDATE_MANAGED_POLICY,
  ACTION_UPDATE_RESOURCE_POLICY,
  buildAction,
} from './helper';

interface Policy {
  createdAt: string;
  policyContent: object;
  policyType: string;
  resource: string;
  updatedAt: string;
}

interface PolicyResult {
  policy: Policy;
}

interface PoliciesResult {
  policies: Array<Policy>;
  cursor?: string;
}

export function createIdentityPolicy(
  principleId: string,
  resourceId: string,
  policyContent: object
) {
  return async function (dispatch: Function): Promise<PolicyResult> {
    const path = `/admin/policy/identity`;

    const result = await apiCall('PUT', NUCLEUS_PLATFORM_API_URL, path, {
      principleId: principleId,
      resourceId: resourceId,
      policyContent: policyContent,
    });

    dispatch(
      buildAction(ACTION_CREATE_IDENTITY_POLICY, {
        policy: result.policy,
      })
    );

    return result;
  };
}

interface ListIdentityPoliciesByOptions {
  principle?: string;
  resource?: string;
  cursor?: string;
}

function listIdentityPoliciesWith(options: ListIdentityPoliciesByOptions) {
  return async function (dispatch: Function): Promise<PoliciesResult> {
    const policies: PoliciesResult['policies'] = [];
    let cursor = options.cursor;
    const onlyAPage = options.principle === undefined && options.resource === undefined;

    do {
      const params = Object.entries({
        principle: options.principle,
        resource: options.resource,
        cursor: cursor,
      })
        .map(([key, value]) => {
          if (value === undefined) {
            return undefined;
          }
          return [key, value].join('=');
        })
        .filter((item) => item !== undefined)
        .join('&');

      const path = ['/admin/policy/identity', params].filter((item) => item.length > 0).join('?');

      const result = await apiCall('GET', NUCLEUS_PLATFORM_API_URL, path);

      policies.push(...result.policies);
      cursor = result.cursor;
    } while (cursor !== undefined && onlyAPage === false);

    const payload: PoliciesResult = {
      policies: policies,
      cursor: cursor,
    };

    dispatch(buildAction(ACTION_LOAD_IDENTITY_POLICIES, payload));

    return payload;
  };
}

export function listIdentityPoliciesByPrinciple(principle: string) {
  return listIdentityPoliciesWith({ principle: principle });
}

export function listIdentityPoliciesByResource(resource: string) {
  return listIdentityPoliciesWith({ resource: resource });
}

export function listIdentityPolicies(cursor?: string) {
  return listIdentityPoliciesWith({ cursor: cursor });
}

export function readIdentityPolicy(principle: string, resource: string): any {
  return async function (dispatch: Function): Promise<PolicyResult> {
    const path = `/admin/policy/identity/${principle}/${resource}`;

    const result = await apiCall('GET', NUCLEUS_PLATFORM_API_URL, path);

    dispatch(
      buildAction(ACTION_LOAD_IDENTITY_POLICY, {
        policy: result.policy,
      })
    );

    return result;
  };
}

export function updateIdentityPolicy(
  principle: string,
  resource: string,
  policyContent: object
): any {
  return async function (dispatch: Function): Promise<PolicyResult> {
    const path = `/admin/policy/identity/${principle}/${resource}`;

    const result = await apiCall('POST', NUCLEUS_PLATFORM_API_URL, path, {
      policyContent: policyContent,
    });

    dispatch(
      buildAction(ACTION_UPDATE_IDENTITY_POLICY, {
        policy: result.policy,
      })
    );

    return result;
  };
}

export function destroyIdentityPolicy(principle: string, resource: string) {
  return async function (dispatch: Function): Promise<void> {
    const path = `/admin/policy/identity/${principle}/${resource}`;

    await apiCall('DELETE', NUCLEUS_PLATFORM_API_URL, path);

    dispatch(
      buildAction(ACTION_DESTROY_IDENTITY_POLICY, {
        principle: principle,
        resource: resource,
      })
    );
  };
}

export function createManagedPolicy(policyName: string, policyContent: object) {
  return async function (dispatch: Function): Promise<PolicyResult> {
    const path = `/admin/policy/managed`;

    const result = await apiCall('PUT', NUCLEUS_PLATFORM_API_URL, path, {
      policyName: policyName,
      policyContent: policyContent,
    });

    dispatch(
      buildAction(ACTION_CREATE_MANAGED_POLICY, {
        policy: result.policy,
      })
    );

    return result;
  };
}

export function listManagedPolicies(cursor: string) {
  return async function (dispatch: Function): Promise<Policy[]> {
    let path = `/admin/policy/managed`;
    if (cursor !== undefined) {
      path = `${path}?cursor=${cursor}`;
    }

    const result = await apiCall('GET', NUCLEUS_PLATFORM_API_URL, path);

    dispatch(
      buildAction(ACTION_LOAD_MANAGED_POLICIES, {
        policies: result.policies,
        cursor: result.cursor,
      })
    );

    return result;
  };
}

export function readManagedPolicy(policyName: string): any {
  return async function (dispatch: Function): Promise<PolicyResult> {
    let path = `/admin/policy/managed/${policyName}`;

    const result = await apiCall('GET', NUCLEUS_PLATFORM_API_URL, path);

    dispatch(
      buildAction(ACTION_LOAD_MANAGED_POLICY, {
        policyName: policyName,
        policy: result.policy,
      })
    );

    return result;
  };
}

export function updateManagedPolicy(policyName: string, policyContent: object): any {
  return async function (dispatch: Function): Promise<PolicyResult> {
    let path = `/admin/policy/managed/${policyName}`;

    const result = await apiCall('POST', NUCLEUS_PLATFORM_API_URL, path, {
      policyContent: policyContent,
    });

    dispatch(
      buildAction(ACTION_UPDATE_MANAGED_POLICY, {
        policyName: policyName,
        policy: result.policy,
      })
    );

    return result;
  };
}

export function destroyManagedPolicy(policyName: string): any {
  return async function (dispatch: Function): Promise<void> {
    let path = `/admin/policy/managed/${policyName}`;

    await apiCall('DELETE', NUCLEUS_PLATFORM_API_URL, path);

    dispatch(
      buildAction(ACTION_DESTROY_MANAGED_POLICY, {
        policyName: policyName,
      })
    );
  };
}

export function createResourcePolicy(resourceId: string, policyContent: string) {
  return async function (dispatch: Function): Promise<Policy> {
    const path = `/admin/policy/resource`;

    const result = await apiCall('PUT', NUCLEUS_PLATFORM_API_URL, path, {
      resourceId: resourceId,
      policyContent: policyContent,
    });

    dispatch(
      buildAction(ACTION_CREATE_RESOURCE_POLICY, {
        policy: result.policy,
      })
    );

    return result;
  };
}

export function listResourcePolicies(cursor: string) {
  return async function (dispatch: Function): Promise<Policy[]> {
    let path = `/admin/policy/resource`;
    if (cursor !== undefined) {
      path = `${path}?cursor=${cursor}`;
    }

    const result = await apiCall('GET', NUCLEUS_PLATFORM_API_URL, path);

    dispatch(
      buildAction(ACTION_LOAD_RESOURCE_POLICIES, {
        policies: result.policies,
        cursor: result.cursor,
      })
    );

    return result;
  };
}

export function readResourcePolicy(resourceNrn: string): any {
  return async function (dispatch: Function): Promise<Policy> {
    let path = `/admin/policy/resource/${resourceNrn}`;

    const result = await apiCall('GET', NUCLEUS_PLATFORM_API_URL, path);

    dispatch(
      buildAction(ACTION_LOAD_RESOURCE_POLICY, {
        resourceNrn: resourceNrn,
        policy: result.policy,
      })
    );

    return result;
  };
}

export function updateResourcePolicy(resourceNrn: string, policyContent: string): any {
  return async function (dispatch: Function): Promise<Policy> {
    let path = `/admin/policy/resource/${resourceNrn}`;

    const result = await apiCall('POST', NUCLEUS_PLATFORM_API_URL, path, {
      policyContent: policyContent,
    });

    dispatch(
      buildAction(ACTION_UPDATE_RESOURCE_POLICY, {
        resourceNrn: resourceNrn,
        policy: result.policy,
      })
    );

    return result;
  };
}

export function destroyResourcePolicy(resourceNrn: string): any {
  return async function (dispatch: Function): Promise<void> {
    let path = `/admin/policy/resource/${resourceNrn}`;

    await apiCall('DELETE', NUCLEUS_PLATFORM_API_URL, path);

    dispatch(
      buildAction(ACTION_DESTROY_RESOURCE_POLICY, {
        resourceNrn: resourceNrn,
      })
    );
  };
}

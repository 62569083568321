import React from 'react';
import { LayoutPrimary } from '../component/LayoutPrimary';

export const Overview = () => {
  return (
    <LayoutPrimary
      layoutTitle="Overview"
      layoutByline="The Nucleus/RebelGive Laboratory"
    ></LayoutPrimary>
  );
};

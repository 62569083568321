import React from 'react';
import { Navigate, Route, Routes, useResolvedPath } from 'react-router-dom';
import { LayoutPrimary } from '../../component/LayoutPrimary';
import { PillNav } from '../../component/PillNav';
import { IdentityRoutes } from './route/Identity/Routes';
import { ManagedRoutes } from './route/Managed/Routes';
import { ResourceRoutes } from './route/Resource/Routes';

export const Policies = (props) => {
  const url = useResolvedPath('').pathname;

  const navList = [
    {
      title: 'Identity',
      to: `${url}/identity`,
    },
    {
      title: 'Managed',
      to: `${url}/managed`,
    },
    {
      title: 'Resource',
      to: `${url}/resource`,
    },
  ];

  return (
    <LayoutPrimary layoutTitle="Policy Management" layoutByline="Platform">
      <PillNav navList={navList} />
      <Routes>
        <Route path={'/identity/*'} element={<IdentityRoutes />} />
        <Route path={'/managed/*'} element={<ManagedRoutes />} />
        <Route path={'/resource/*'} element={<ResourceRoutes />} />
        <Route path="/" element={<Navigate to={`${url}/identity`} replace={true} />} />
      </Routes>
    </LayoutPrimary>
  );
};

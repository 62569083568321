import { SubNav } from '@foyyay/control-elements';
import React from 'react';
import { NavLink } from 'react-router-dom';

interface NavItemProps {
  title: string;
  to: string;
}

interface PillNavProps {
  navList: Array<NavItemProps>;
}

export const PillNav = (props: PillNavProps) => {
  return (
    <SubNav>
      {props.navList.map((item) => (
        <SubNav.Item key={item.title}>
          <NavLink to={item.to}>{item.title}</NavLink>
        </SubNav.Item>
      ))}
    </SubNav>
  );
};

import React from 'react';
import { useParams } from 'react-router';
import { Person } from '../../../component/Person';

export const Overview = () => {
  const params = useParams<{ personId: string }>();
  const personId = params.personId;

  return <Person personId={personId} />;
};

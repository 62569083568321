import { H2, P } from '@foyyay/control-elements';
import styled from 'styled-components';
import { font, media } from '../lib/style';
import React from 'react';

export const GridColumns = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  grid-column-gap: 3.6rem;
  grid-row-gap: 3.1rem;
  margin-top: 2.7rem;
  padding-left: 0.6rem;

  ${media.tabletPortraitAndUp`
    padding-left: 1rem;
  `}
`;

export const Field = styled.div``;

export const FieldLabel = styled(H2)`
  ${font(14, 'Gray', 2.96, 18)}
  text-transform: uppercase;
`;

export const FieldValue = styled(P)`
  ${font(20, 'Light', -0.2, 30)}
  margin: 0;
`;

export const Attribute = (props: { name: string; to?: string; children?: any; entityKey?: string }) => {
  const renderEditLink = props.entityKey !== undefined;
  return (
    <Field>
      <FieldLabel>{props.name}</FieldLabel>
      <FieldValue>{props.children}</FieldValue>
      {renderEditLink && <a href={`/entity?key=${props.entityKey}`}>edit</a>}
    </Field>
  );
};

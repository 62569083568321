import { ButtonSecondary } from '@foyyay/control-elements';
import _upperFirst from 'lodash/upperFirst';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { LayoutPrimary } from '../../../component/LayoutPrimary';
import { LoadingSpinner } from '../../../component/LoadingSpinner';
import { SUPPORT_API_URL, defaultTheme } from '../../../constant';
import { apiCall } from '../../../lib/fetch';
import { prefixOfKey } from '../../../lib/key';
import { EditableArea } from '../EditableArea';

export const ViewEntity = (props: any) => {
  const [entity, setEntity] = useState<any>(undefined);
  const location = useLocation();
  const params = splitQuery(location.search);

  const goBackUrl = (entityId): string | undefined => {
    if (prefixOfKey(entityId) === 'church') {
      const base = location.pathname.split('/').slice(0, -2).join('/');
      return `${base}/church/${entityId}/overview`;
    }
    // No other entity uses this editor right now
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    try {
      const response = await apiCall('GET', SUPPORT_API_URL, `/entity/read?key=${params.key}`);
      setEntity(response);
    } catch (e) {
      console.error(e);
    }
  };

  if (entity === undefined) {
    return <LoadingSpinner />;
  }

  return (
    <LayoutPrimary layoutTitle={`Edit ${_upperFirst(entity.entityType)}`} layoutByline={`${entity?.entity?.name}`}>
      <StyledDiv>{<EditableArea entity={entity} key={params?.key} />}</StyledDiv>
      <>
        <ButtonSecondary onClick={() => window.location.replace(location.pathname + location.search)}>
          Reload
        </ButtonSecondary>{' '}
        <ButtonSecondary as={Link} to={goBackUrl(params?.key)}>
          Go Back
        </ButtonSecondary>
      </>
    </LayoutPrimary>
  );
};

function splitQuery(query) {
  const trimmed = query.split('?')[1];
  const array1 = trimmed.split('&');
  const array2 = array1.map((row) => row.split('='));
  return Object.fromEntries(array2);
}

const StyledDiv = styled.div`
  border-color: ${() => defaultTheme.Palette.Light4};
  border-style: solid;
  border-width: 1px;
  margin: 2rem 0;
  padding: 2rem 1.2rem;
  border-radius: 1.2rem;
`;

import { uniqBy as _uniqBy } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Layout } from '../../../component/Layout';
import { LoadingSpinnerBlock } from '../../../component/LoadingSpinner';
import { Person } from '../../../component/Person';
import { useAdmins } from '../../../hooks/useAdmins';
import { useChurch } from '../../../hooks/useChurch';
import { Platform, entityPlatform } from '../../../lib/platform';
import { SelfInvite } from '../component/SelfInvite';

export const Admins = () => {
  const params = useParams<{ churchId: string }>();
  const { data: church } = useChurch(params.churchId);
  const { data: admins = [], isLoading } = useAdmins(church);

  const ownerAdmin = admins.find((admin) => admin?.id === church?.owner_id);

  const displayAdmins = _uniqBy(
    [ownerAdmin, ...admins].filter((admin) => admin !== undefined),
    'id'
  );

  let platform: Platform | undefined;
  if (church !== undefined) {
    platform = entityPlatform(church);
  }

  if (isLoading) {
    return (
      <Layout.Panel>
        <LoadingSpinnerBlock />
      </Layout.Panel>
    );
  }

  if (church === undefined && admins.length === 0) {
    return (
      <Layout.Card.Message>
        <Layout.Header headline={'No Admins Found'} />
      </Layout.Card.Message>
    );
  }

  return (
    <>
      {church === undefined && (
        <Layout.Group>
          <Layout.Card.Message>
            <Layout.Header headline="Church Not Loaded" statement="Showing any admins based on legacy roles" />
          </Layout.Card.Message>
        </Layout.Group>
      )}

      {church !== undefined && (
        <Layout.Panel platform={platform}>
          <SelfInvite churchId={church.id} />
        </Layout.Panel>
      )}

      {displayAdmins.map((admin: any, index: number) => {
        if (admin !== undefined && admin !== undefined) {
          return (
            <Person
              key={admin.id}
              isChurchOwner={admin?.id === ownerAdmin?.id}
              personId={admin.id}
              name={admin.name}
              adminData={admin}
            />
          );
        } else {
          return '';
        }
      })}
    </>
  );
};

import { Church } from '../../type/Church';
import { BILLING_API_URL, PLATFORM_API_URL, SUPPORT_API_URL } from '../constant';
import { apiCall } from '../lib/fetch';

const read = async (id: string): Promise<Church | undefined> => {
  return await apiCall('GET', PLATFORM_API_URL, `/church/${id}`);
};

const update = async (church: Church, params: object) => {
  return await await apiCall('POST', PLATFORM_API_URL, `/church/${church.id}`, params);
};

export type ChurchListResponse = {
  id: string;
  name: string;
  address_email: string;
  address_website: string;
  phone: string;
}[];

const list = async (): Promise<ChurchListResponse> => {
  return await apiCall('GET', SUPPORT_API_URL, '/church/list');
};

export type Admin = {
  id: string;
  name: string;
  email: string;
};

type LegacyRole = {
  active: boolean;
  created_at: string;
  email: string;
  name: string;
  person: {
    id: string;
  };
  role: string;
};

export const isLegacyRole = (role: LegacyRole | LegacyRoleInvite): role is LegacyRole => {
  return (role as LegacyRole).person !== undefined;
};

type LegacyRoleInvite = {
  church_id: string;
  created_at: string;
  email: string;
  name: string;
  role: string;
  send_to: string;
};

export const isLegacyRoleInvite = (role: LegacyRole | LegacyRoleInvite): role is LegacyRoleInvite => {
  return (role as LegacyRoleInvite).send_to !== undefined;
};

type NucleusPlatformAdminListResponse = { administrators: Admin[] };
type RebelGivePlatformRoleListResponse = (LegacyRole | LegacyRoleInvite)[];

export type AdminListResponse = NucleusPlatformAdminListResponse | RebelGivePlatformRoleListResponse;

export const isNucleusPlatformAdminListResponse = (
  response: AdminListResponse
): response is NucleusPlatformAdminListResponse => {
  return (response as NucleusPlatformAdminListResponse).administrators !== undefined;
};

export const isRebelGivePlatformRoleListResponse = (
  response: AdminListResponse
): response is RebelGivePlatformRoleListResponse => {
  return (response as RebelGivePlatformRoleListResponse).length > 0;
};

const listAdmins = async (church: Church): Promise<AdminListResponse> => {
  return await apiCall(
    'GET',
    PLATFORM_API_URL,
    `/administrator/${church.id}${church.platform ? `?platform=${church.platform}` : ''}`
  );
};

const getSubscription = async (church: Church): Promise<any> => {
  return await apiCall('GET', BILLING_API_URL, `/subscription/${church.id}`);
};

const joinAsAdmin = async (church: Church): Promise<any> => {
  return await apiCall('POST', SUPPORT_API_URL, '/church/join', { church: { id: church.id } });
};

const leaveAsAdmin = async (church: Church): Promise<any> => {
  return await await apiCall('POST', SUPPORT_API_URL, '/church/leave', { church: { id: church.id } });
};

export const ChurchHttpApi = {
  read: read,
  update: update,
  list: list,
  listAdmins: listAdmins,
  getSubscription: getSubscription,
  joinAsAdmin: joinAsAdmin,
  leaveAsAdmin: leaveAsAdmin,
};

import { Action, ACTION_UNLOAD_SESSION, frozenObject, ACTION_LOAD_SESSION } from '../action/helper';
import { USER_SESSION_TIMEOUT } from '../constant';

const initialState = frozenObject;

export const reducer = function (state = initialState, action: Action) {
  switch (action.type) {
    case ACTION_LOAD_SESSION:
      return {
        ...state,
        sessionData: action.payload,
        sessionExpiresAt: Date.now() + USER_SESSION_TIMEOUT,
      };
    case ACTION_UNLOAD_SESSION:
      return initialState;
    default:
      return state;
  }
};

export const selectCurrentUser = (state): { id: string; login: string; firstNameLastInitial: string } => ({
  id: state.user?.sessionData?.id,
  login: state.user?.sessionData?.login,
  firstNameLastInitial: [
    state.user?.sessionData?.profile.first_name,
    state.user?.sessionData?.profile?.lastName?.slice(0, 1),
  ]
    .join(' ')
    .trim(),
});

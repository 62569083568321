import { useMountSafeState } from '../hooks/useMountSafe';
import { useOnClickOutside } from '../hooks/useOnClickOutside';
import { omit as _omit, pick as _pick } from 'lodash';
import React, { MouseEvent, useCallback, useImperativeHandle, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import styled, { css, DefaultTheme, FlattenInterpolation, ThemedStyledProps } from 'styled-components';
import { DropdownMenu } from './DropdownMenu';
import { LoadingSpinner, LOADING_SPINNER_SIZES } from './LoadingSpinner';
import { booleanPropHelperFactory, colorStyles, font, media, SIZE } from './style-utils';

export const Clickable = styled.button`
  color: inherit;
  background: none;
  border: none;
  font: inherit;
  padding: 0px;
  cursor: pointer;
  text-decoration: underline;
`;

const ButtonContent = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
`;

const Label = styled.span<{ visible: boolean }>`
  display: inline-block;
  white-space: nowrap;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  flex-shrink: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Description = styled.span<{ visible?: boolean }>`
  display: block;
  white-space: nowrap;
  flex-shrink: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Icon = styled.span<{ visible?: boolean }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  opacity: ${(props) => (props.visible ? 1 : 0)};

  & > * {
    width: 100%;
  }
`;

const IconRight = styled.span<{ visible?: boolean }>`
  flex-shrink: 0;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.visible ? 1 : 0)};
`;

const IconLeft = styled.span<{ visible?: boolean }>`
  opacity: ${(props) => (props.visible ? 1 : 0)};
`;

const ButtonStyles = {
  base: css<{ disabled?: boolean }>`
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: auto;
    max-width: 100%;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    outline: none;
    text-decoration: none !important;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
    transition:
      transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.1),
      background-color 0.1s cubic-bezier(0, 0, 1, 1);
    appearance: none;

    @media (hover: hover) {
      :hover {
        transition:
          transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.1),
          background-color 0s cubic-bezier(0, 0, 1, 1);
      }
    }

    :active:not([disabled]) {
      transform: scale(0.97);
      transition:
        transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.1),
        background-color 0s cubic-bezier(0, 0, 1, 1);
    }

    position: relative;
    :after {
      content: '';
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      background: ${(props) => props.theme.Button.Color.ActiveHighlight};
      pointer-events: none;
      transition: opacity 0.1s cubic-bezier(0, 0, 1, 1);
      opacity: 0;
    }

    :active:not([disabled]),
    :focus-visible {
      :after {
        opacity: 1;
        transition: opacity 0s cubic-bezier(0, 0, 1, 1);
      }
    }

    :disabled {
      cursor: default;
      opacity: 0.35;
    }

    ${(props) => {
      if (props.disabled === true) {
        return css`
          pointer-events: none;
          opacity: 0.35;
        `;
      }
    }}
  `,
};

const ButtonWidthByMode = {
  auto: css`
    width: auto;
    max-width: 100%;
  `,
  full: css`
    width: 100%;
    max-width: 100%;
  `,
  fullAuto: css`
    width: 100%;
    max-width: 100%;

    ${media.tabletPortraitAndUp`
      width: auto;
    `}
  `,
};

type ButtonSizeProps = ThemedStyledProps<{ textSize?: TextSize }, DefaultTheme>;

const ButtonSize = {
  small: css<ButtonSizeProps>`
    height: ${SIZE[6]};

    :active:not([disabled]) {
      transform: scale(0.96);
    }

    ${Label} {
      ${(props) => {
        switch (props.textSize) {
          case 'small':
            return css`
              ${font(14, 'Bold', -0.25, 20)}
            `;
          case 'large':
            return css`
              ${font(18, 'Bold', -0.5, 20)}
            `;
          case 'medium':
          default:
            return css`
              ${font(16, 'Bold', -0.25, 20)}
            `;
        }
      }}
      padding-left: ${SIZE[1]};
      padding-right: ${SIZE[1]};

      &:first-child {
        padding-left: ${SIZE[4]};
      }

      &:last-child {
        padding-right: ${SIZE[4]};
      }
    }

    ${IconRight}, ${IconLeft} {
      padding: ${SIZE[1]};

      & > * {
        height: ${SIZE[4]};
      }
    }
  `,
  medium: css<ButtonSizeProps>`
    height: ${SIZE[8]};

    :active:not([disabled]) {
      transform: scale(0.97);
    }

    ${Label} {
      ${(props) => {
        switch (props.textSize) {
          case 'small':
            return css`
              ${font(18, 'Bold', -0.5, 20)}
            `;
          case 'large':
            return css`
              ${font(22, 'Bold', -1, 26)}
            `;
          case 'medium':
          default:
            return css`
              ${font(20, 'Bold', -0.5, 24)}
            `;
        }
      }}

      &:first-child {
        padding-left: ${SIZE[4]};
      }

      &:last-child {
        padding-right: ${SIZE[4]};
      }
    }

    ${IconRight}, ${IconLeft} {
      padding: ${SIZE[2]};

      & > * {
        height: ${SIZE[4]};
        width: ${SIZE[4]};
      }
    }
  `,
  large: css<ButtonSizeProps>`
    height: ${SIZE[10]};

    :active:not([disabled]) {
      transform: scale(0.98);
    }

    ${Label} {
      ${(props) => {
        switch (props.textSize) {
          case 'small':
            return css`
              ${font(18, 'Bold', -0.5, 20)}
            `;
          case 'large':
            return css`
              ${font(22, 'Bold', -1, 26)}
            `;
          case 'medium':
          default:
            return css`
              ${font(20, 'Bold', -0.5, 24)}
            `;
        }
      }}

      &:first-child {
        padding-left: ${SIZE[4]};
      }

      &:last-child {
        padding-right: ${SIZE[4]};
      }
    }

    ${IconRight}, ${IconLeft} {
      padding: ${SIZE[2]};

      & > * {
        height: ${SIZE[6]};
      }
    }
  `,
  xlarge: css<ButtonSizeProps>`
    height: ${SIZE[16]};

    :active:not([disabled]) {
      transform: scale(0.98);
    }

    ${Label} {
      ${(props) => {
        switch (props.textSize) {
          case 'small':
            return css`
              ${font(20, 'Bold', -0.5, 24)}
            `;
          case 'large':
            return css`
              ${font(24, 'Bold', -1.25, 28)}
            `;
          case 'medium':
          default:
            return css`
              ${font(22, 'Bold', -1, 26)}
            `;
        }
      }}

      &:first-child {
        padding-left: ${SIZE[8]};
      }

      &:last-child {
        padding-right: ${SIZE[8]};
      }
    }

    ${Description} {
      ${font(16, 'Book', -0.5, 18)}
      opacity: 0.8;
    }

    ${IconRight}, ${IconLeft} {
      padding: ${SIZE[6]};

      & > * {
        height: ${SIZE[8]};
      }
    }
  `,
};

type ButtonShapeProps = ThemedStyledProps<{ size?: Size }, DefaultTheme>;

const ButtonShape = {
  circle: css<ButtonShapeProps>`
    ${(props) => {
      switch (props.size) {
        case 'small':
          return css`
            width: ${SIZE[6]};
            border-radius: ${SIZE[6]};
          `;
        case 'large':
          return css`
            width: ${SIZE[10]};
            border-radius: ${SIZE[10]};
          `;
        case 'medium':
        default:
          return css`
            width: ${SIZE[8]};
            border-radius: ${SIZE[8]};
          `;
      }
    }}
  `,
  square: css<ButtonShapeProps>`
    ${(props) => {
      switch (props.size) {
        case 'small':
          return css`
            width: ${SIZE[6]};
            border-radius: ${SIZE[1]};
          `;
        case 'large':
          return css`
            width: ${SIZE[10]};
            border-radius: ${SIZE[1]};
          `;
        case 'medium':
        default:
          return css`
            width: ${SIZE[8]};
            border-radius: ${SIZE[1]};
          `;
      }
    }}
  `,
  pill: css<ButtonShapeProps>`
    ${(props) => {
      switch (props.size) {
        case 'small':
          return css`
            border-radius: ${SIZE[6]};
          `;
        case 'large':
          return css`
            border-radius: ${SIZE[10]};
          `;
        case 'medium':
        default:
          return css`
            border-radius: ${SIZE[8]};
          `;
      }
    }}
  `,
  rounded: css<ButtonShapeProps>`
    ${(props) => {
      switch (props.size) {
        case 'small':
          return css`
            border-radius: ${SIZE[1]};
          `;
        case 'large':
        case 'medium':
        default:
          return css`
            border-radius: ${SIZE[2]};
          `;
      }
    }}
  `,
  squared: css<ButtonShapeProps>`
    border-radius: ${SIZE[1]};
  `,
  sharp: css<ButtonShapeProps>`
    border-radius: none;
  `,
};

type ThemeKeys =
  | 'ButtonPrimary'
  | 'ButtonPrimaryGradient'
  | 'ButtonSecondary'
  | 'ButtonTertiary'
  | 'ButtonAlertPrimary'
  | 'ButtonBreakoutNav'
  | 'ButtonOverlay';

const Variants: { variant: Variant; themeKey: ThemeKeys }[] = [
  { variant: 'primary', themeKey: 'ButtonPrimary' },
  { variant: 'primary-gradient', themeKey: 'ButtonPrimaryGradient' },
  { variant: 'secondary', themeKey: 'ButtonSecondary' },
  { variant: 'tertiary', themeKey: 'ButtonTertiary' },
  { variant: 'alert-primary', themeKey: 'ButtonAlertPrimary' },
  { variant: 'breakout-nav', themeKey: 'ButtonBreakoutNav' },
  { variant: 'overlay', themeKey: 'ButtonOverlay' },
];

type VariantProps = ThemedStyledProps<{ error?: any }, DefaultTheme>;

const ButtonVariant: Record<Variant, FlattenInterpolation<VariantProps>> = Variants.reduce(
  (acc, { variant, themeKey }: { variant: Variant; themeKey: ThemeKeys }) => ({
    ...acc,
    [variant]: css<VariantProps>`
      ${(props) => colorStyles(props.theme[themeKey].Color)}

      &.activated, &.active {
        ${(props) => colorStyles(props.theme[themeKey].Color.Activated)}
      }

      &:focus-visible {
        outline: ${(props) => props.theme['ButtonPrimary'].Color.Default.Background} solid 3px;
        outline-offset: 2px;
      }

      ${(props) =>
        props.error &&
        css`
          background: ${props.theme[themeKey].Color.Error.Background};
          color: ${props.theme[themeKey].Color.Error.Foreground};

          &:hover:not([disabled]),
          &:active:not([disabled]) {
            background: ${props.theme[themeKey].Color.Error.Background};
            color: ${props.theme[themeKey].Color.Error.Foreground};
          }
        `}

      ${LoadingSpinner} {
        color: currentColor;
      }
    `,
  }),
  {} as Record<Variant, FlattenInterpolation<VariantProps>>
);

const StyledButton = styled.button
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => {
      return !['shape'].includes(prop) && defaultValidatorFn(prop);
    },
  })
  .attrs(() => ({
    onTouchStart: () => true,
  }))<{
  disabled?: boolean;
  shape: Shape;
  size: Size;
  variant: Variant;
  width: number;
  widthMode: WidthMode;
}>`
  ${ButtonStyles.base}
  ${(props) => ButtonWidthByMode[props.widthMode]}
  ${(props) => ButtonSize[props.size]}
  ${(props) => ButtonShape[props.shape]}
  ${(props) => ButtonVariant[props.variant]}
  width: ${(props) => props.width};
`;

type Shape = 'circle' | 'square' | 'pill' | 'rounded' | 'squared' | 'sharp';
type Size = 'small' | 'medium' | 'large' | 'xlarge';
type TextSize = 'small' | 'medium' | 'large';
type Variant = 'primary' | 'secondary' | 'tertiary' | 'primary-gradient' | 'alert-primary' | 'breakout-nav' | 'overlay';
type WidthMode = 'auto' | 'full' | 'fullAuto';

const ButtonContainer = styled.div`
  position: relative;
`;

export const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -${SIZE[2]};
  margin-bottom: -${SIZE[2]};

  & > * {
    flex: 1 0 auto;
    margin-left: ${SIZE[2]};
    margin-bottom: ${SIZE[2]};
    max-width: 100%;

    &:first-child {
      flex: 1 0 60%;
    }
  }

  ${media.print`
    display: none;
  `}
`;

export const ButtonBase = styled(
  React.forwardRef((props: ButtonBaseProps, ref) => {
    const buttonRef = useRef(null);
    // https://reactjs.org/docs/hooks-reference.html#useimperativehandle
    useImperativeHandle(ref, () => buttonRef.current);

    const showText = props.loading !== true;
    const showSpinner = props.loading === true;
    const showIcon = props.icon !== undefined;
    const showIconLeft = props.iconLeft !== undefined;
    const showIconRight = props.iconRight !== undefined;

    const buttonProps = _omit(props, ['icon', 'iconLeft', 'iconRight', 'children', 'loading']);

    if (buttonProps.to !== undefined) {
      buttonProps.as = NavLink;
    }

    if (buttonProps.href !== undefined) {
      buttonProps.as = 'a';
    }

    const loadingSpinnerSize = LOADING_SPINNER_SIZES[props.size];

    return (
      <StyledButton {...buttonProps} ref={buttonRef}>
        <ButtonContent>
          {showIcon && <Icon visible={showText}>{props.icon}</Icon>}
          {showIconLeft && <IconLeft visible={showText}>{props.iconLeft}</IconLeft>}
          {props.children && (
            <Label visible={showText}>
              {props.children}
              {props.description && <Description>{props.description}</Description>}
            </Label>
          )}
          {showIconRight && <IconRight visible={showText}>{props.iconRight}</IconRight>}
        </ButtonContent>
        {showSpinner && (
          <LoadingSpinner width={loadingSpinnerSize.width} strokeWidth={loadingSpinnerSize.strokeWidth} />
        )}
      </StyledButton>
    );
  })
)``;

const DropdownButton = styled(
  React.forwardRef((props: ButtonProps, ref): JSX.Element => {
    const initialState = {
      isOpen: false,
    };
    const [state, setState] = useMountSafeState(initialState);
    const isOpen = state.isOpen === true;
    const containerRef = useRef(null);
    const buttonRef = useRef(null);
    // https://reactjs.org/docs/hooks-reference.html#useimperativehandle
    useImperativeHandle(ref, () => buttonRef.current);

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setState((prevState) => ({
        ...prevState,
        isOpen: prevState.isOpen !== true,
      }));
      if (typeof props.onClick === 'function') {
        props.onClick(e);
      }
    };

    const closeDropdown = useCallback(() => {
      setState((prevState) => ({
        ...prevState,
        isOpen: false,
      }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useOnClickOutside([containerRef], closeDropdown, isOpen);

    const otherProps = _omit(props, ['alignDropdown', 'children', 'className', 'dropdownItems', 'onClick', 'style']);

    // We need to add the dropdown indicator props to the button base.
    const dropdownIndicatorProps: Partial<ButtonProps> = {};
    // If the user has passed an icon, we don't want to overlap it with the dropdown indicator.
    if (props.icon === undefined) {
      const indicatorIconProp = props.dropdownIndicatorPosition === 'left' ? 'iconLeft' : 'iconRight';
      dropdownIndicatorProps[indicatorIconProp] = <DropdownMenu.Indicator isOpen={isOpen} />;
    }

    return (
      <ButtonContainer className={props.className} style={props.style} ref={containerRef}>
        <ButtonBase
          className={isOpen ? 'activated' : ''}
          onClick={handleClick}
          {...dropdownIndicatorProps}
          {...otherProps}
          ref={buttonRef}
        >
          {props.children}
        </ButtonBase>
        <DropdownMenu
          isOpen={isOpen}
          items={props.dropdownItems}
          align={props.alignDropdown}
          onClose={closeDropdown}
          parentRef={containerRef}
        >
          {props.dropdown}
        </DropdownMenu>
      </ButtonContainer>
    );
  })
)``;

/**
 * The ubiquitous Nucleus Button component. Supports various sizes, shapes, and variants.
 */
const ButtonComponent = styled(
  React.forwardRef((props: ButtonProps, ref): JSX.Element => {
    const hasDropdown =
      props.dropdown !== undefined || (props.dropdownItems !== undefined && props.dropdownItems.length > 0);

    const { as, ...rest } = props;

    if (hasDropdown) {
      return <DropdownButton {...rest} forwardedAs={as} ref={ref} />;
    }

    return <ButtonBase {...rest} forwardedAs={as} ref={ref} />;
  })
)``;

interface ButtonBaseProps extends React.HTMLAttributes<HTMLButtonElement> {
  as?: string | React.ComponentType<any>;
  children?: React.ReactNode;
  className?: string;
  description?: React.ReactNode;
  disabled?: boolean;
  error?: any;
  href?: string;
  icon?: React.ReactNode;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  isOpen?: boolean;
  loading?: boolean;
  rel?: HTMLAnchorElement['rel'];
  shape: Shape;
  size: Size;
  style?: React.CSSProperties;
  target?: HTMLAnchorElement['target'];
  to?: string;
  type?: 'button' | 'submit' | 'reset';
  variant: Variant;
  width?: string;
  widthMode: WidthMode;
}

export interface ButtonProps extends ButtonBaseProps {
  dropdown?: React.ReactNode;
  dropdownItems?: Array<any>;
  alignDropdown?: 'left' | 'right';
  dropdownIndicatorPosition?: 'left' | 'right';
}

const ButtonDefaultProps = {
  variant: 'primary',
  size: 'medium',
  textSize: 'medium',
  shape: 'rounded',
  widthMode: 'auto',
  onClick: () => {},
  dropdownItems: [],
  dropdownIndicatorPosition: 'right',
  type: 'button',
};

export const Button = Object.assign(ButtonComponent, {
  defaultProps: ButtonDefaultProps,
});

export const BareButton = styled.button`
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  line-height: normal;
  text-align: inherit;
  overflow: visible;
  padding: 0;
  margin: 0;
  appearance: none;

  // Press-able styles
  transition:
    transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.1),
    opacity 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.1),
    box-shadow 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.1),
    background-color 0.1s cubic-bezier(0, 0, 1, 1);

  @media (hover: hover) {
    :hover {
      transition:
        transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.1),
        opacity 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.1),
        box-shadow 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.1),
        background-color 0s cubic-bezier(0, 0, 1, 1);
    }
  }

  :active:not([disabled]) {
    transform: scale(0.97);
    transition:
      transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.1),
      opacity 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.1),
      box-shadow 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.1),
      background-color 0s cubic-bezier(0, 0, 1, 1);
  }
`;

const TileButtonInner = styled.div`
  position: relative;
  z-index: 1;
  background: #ffffff;
  color: #520099;
  border-radius: calc(var(--border-radius) - var(--border-size));
  padding: ${SIZE[2]} ${SIZE[3]};
  height: 100%;
`;

const TileButtonSelectedMark = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  z-index: 1;
`;

const TitleGradientBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: linear-gradient(to bottom right, #cc70fa 0%, #9d3df2 50% 100%);
`;

const isSelected = booleanPropHelperFactory('selected');

const StyledTileButton = styled(BareButton)<{ disabled?: boolean }>`
  --border-size: 3px;
  --border-radius: 1.2rem;
  --selected-outline-transition-in: opacity 0s cubic-bezier(0.175, 0.885, 0.32, 1.1);
  --selected-outline-transition-out: opacity 0s cubic-bezier(0.175, 0.885, 0.32, 1.1);
  --selected-mark-transition-in: opacity 0s cubic-bezier(0.175, 0.885, 0.32, 1.1);
  --selected-mark-transition-out: opacity 0s cubic-bezier(0.175, 0.885, 0.32, 1.1);
  --outline-transition-in: opacity 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.1);
  --outline-transition-out: opacity 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.1);
  --box-shadow-transition-in: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.1);
  --box-shadow-transition-out: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.1);

  display: inline-block;
  position: relative;
  max-width: 100%;
  background: #ffffff;
  box-shadow: -0.1rem 0.7rem 0.7rem 0 rgba(82, 0, 153, 0.08);

  border-radius: var(--border-radius);
  padding: var(--border-size);

  transition: var(--box-shadow-transition-out);

  ${TileButtonSelectedMark} {
    opacity: 0;
    transition: var(--selected-mark-transition-out);
  }

  ${isSelected(css`
    ${TileButtonSelectedMark} {
      opacity: 1;
      transition: var(--selected-mark-transition-in);
    }
  `)}

  ${TitleGradientBackground} {
    opacity: 0;
    transition: var(--outline-transition-out);
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        cursor: pointer;
        box-shadow: 1.2rem 2.4rem 3.6rem 0 rgba(82, 0, 153, 0.18);
        transition: var(--box-shadow-transition-in);

        ${TitleGradientBackground} {
          opacity: 1;
          transition: var(--outline-transition-in);
        }
      }
    `}
`;

export const TileButton = (props: {
  disabled?: boolean;
  onClick?: () => void;
  href?: string;
  to?: string;
  target?: string;
  selectedMark?: React.ReactNode;
  children?: React.ReactNode;
  as?: string | React.ComponentType<any>;
  className?: string;
  style?: React.CSSProperties;
}): JSX.Element => {
  const buttonProps = _pick(props, ['to', 'href', 'target', 'as']);

  if (buttonProps.to !== undefined) {
    buttonProps.as = Link;
  }

  if (buttonProps.href !== undefined) {
    buttonProps.as = 'a';
  }

  return (
    <StyledTileButton {...props} {...buttonProps}>
      <TitleGradientBackground />
      <TileButtonInner>{props.children}</TileButtonInner>
      {props.selectedMark && <TileButtonSelectedMark>{props.selectedMark}</TileButtonSelectedMark>}
    </StyledTileButton>
  );
};

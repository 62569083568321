import React from 'react';
import styled from 'styled-components';
import { SubscriptionLifecycleRunner } from './LifecycleRunner';

export const SubscriptionActions = () => {
  return (
    <Space>
      <SubscriptionLifecycleRunner />
    </Space>
  );
};

const Space = styled.div`
  padding-bottom: 2rem;
`;

import React from 'react';
import { Button } from '../../../../../../../component/Button';
import { useMerchantActivation } from '../../../../../../../hooks/useMerchant';

export const MerchantActivate = (props: any) => {
  const church = props.church;
  const merchant = props.merchant;
  const { mutate: activate, isPending } = useMerchantActivation(church, merchant);

  const merchantIsClosed = merchant.closed_at !== undefined;
  const merchantIsActive = merchant.boarded_at !== undefined && merchantIsClosed === false;
  const buttonText = merchantIsClosed ? 'Reactivate Merchant' : 'Activate Merchant';

  return (
    <Button
      shape="pill"
      size="small"
      variant="secondary"
      loading={isPending}
      onClick={() => activate(true)}
      disabled={merchantIsActive}
    >
      {buttonText}
    </Button>
  );
};

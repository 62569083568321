import { Table as CETable } from '@foyyay/control-elements';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { SIZE } from './style-utils';

const Cell = styled(CETable.Cell)`
  height: 4rem !important;

  ${(props) => {
    if (props.onClick !== undefined) {
      return css`
        cursor: pointer;
        text-decoration: underline !important;
      `;
    }
  }}
  a {
    font-size: inherit;
    font-weight: inherit;
    text-decoration: underline;
  }
`;

const RowComponent = styled(CETable.Row)`
  ${(props) => {
    if (props.onClick !== undefined) {
      return css`
        cursor: pointer;
      `;
    }
  }}

  ${Cell} {
    ${(props) => {
      let result = css`
        padding-top: ${SIZE[1]};
        padding-bottom: ${SIZE[1]};
      `;
      if (props.highlight === true) {
        result = css`
          ${result}
          background: rgb(255 235 224);
        `;
      }

      if (props.platform !== undefined) {
        result = css`
          ${result}
          a {
            color: inherit;
          }
        `;
      }

      if (props.platform === 'rebelgive') {
        result = css`
          ${result}
          background: rgb(11 186 230 / 24%);
          color: rgb(22 100 230);
        `;
        if (props.highlight === true) {
          result = css`
            ${result}
            background: rgb(11 186 230 / 36%);
          `;
        }
      } else if (props.platform === 'nucleus') {
        result = css`
          ${result}
          background: rgb(82 0 153 / 12%);
          color: rgb(82 0 153);
        `;
        if (props.highlight === true) {
          result = css`
            ${result}
            background: rgb(82 0 153 / 24%);
          `;
        }
      }

      if (props.strikethrough === true) {
        result = css`
          ${result}
          text-decoration: line-through;
        `;
      }

      if (props.strong === true) {
        result = css`
          ${result}
          font-weight: 900;
        `;
      }

      if (props.emphasize === true) {
        result = css`
          ${result}
          font-style: italic;
        `;
      }

      return result;
    }}
  }

  :hover {
    ${Cell} {
      ${(props) => {
        if (props.platform === 'rebelgive') {
          return css`
            background: rgb(11 186 230 / 36%) !important;
          `;
        } else if (props.platform === 'nucleus') {
          return css`
            background: rgb(82 0 153 / 24%) !important;
          `;
        }
      }}
    }
  }
`;

const Row = (props) => {
  const navigate = useNavigate();
  const { to, ...otherProps } = props;

  if (to !== undefined) {
    otherProps.onClick = (event) => {
      event.preventDefault();
      navigate(to);
    };
  }

  return <RowComponent {...otherProps}>{props.children}</RowComponent>;
};

const Tbody = styled(CETable.Tbody)``;

const Thead = styled(CETable.Thead)``;

const Header = styled(CETable.Header)``;

const MainTable = styled(CETable)`
  border-collapse: separate;
  border-spacing: 0 0.5rem;
  user-select: auto;
`;

export const Table = Object.assign(MainTable, {
  Thead: Thead,
  Row: Row,
  Header: Header,
  Tbody: Tbody,
  Cell: Cell,
});

import { orderBy as _orderBy } from 'lodash';
import React from 'react';
import { ButtonConsoleLog } from '../../../../../../../component/ButtonConsoleLog';
import { DataList } from '../../../../../../../component/DataList';
import { Layout } from '../../../../../../../component/Layout';
import { LoadingSpinnerBlock } from '../../../../../../../component/LoadingSpinner';
import { H3 } from '../../../../../../../component/Typography';
import { SIZE } from '../../../../../../../component/style-utils';
import { useRpMerchant } from '../../../../../../../hooks/useRpMerchant';
import { AMOUNT_FORMATTER } from '../../../../../../../lib/formatting';
import { COPILOT_FORMATTER, DISPLAY_DATE_FORMATTER } from '../../../../../../../lib/objectGridAttributeLibrary';
import { RpRefreshMerchant } from './RpRefreshMerchant';
import { SetFeeButtons } from './SetFeeButtons';

export const RpMerchant = (props: any) => {
  const { data: rpmerchant, isLoading } = useRpMerchant(props.rpMerchantId);

  if (isLoading) {
    return (
      <Layout.Panel>
        <LoadingSpinnerBlock />
      </Layout.Panel>
    );
  }

  const canRefresh = rpmerchant.id !== undefined;

  return (
    <>
      <Layout.Panel>
        <Layout.Header
          headline={'Rebel Pay Merchant'}
          buttons={<>{canRefresh && <RpRefreshMerchant church={props.church} rpmerchant={rpmerchant} />}</>}
        />

        <Layout.Card>
          <Layout.Header headline="Status" />
          <DataList inline labelWidth="25rem">
            <DataList.Item>
              <DataList.Label>Token Failues</DataList.Label>
              <DataList.Value>{(rpmerchant.token_authentication_failures ?? []).length}</DataList.Value>
            </DataList.Item>
            {(rpmerchant.token_authentication_failures ?? []).length > 0 && (
              <DataList.Item>
                <DataList.Label>Last Token Failure</DataList.Label>
                <DataList.Value>
                  {DISPLAY_DATE_FORMATTER(
                    _orderBy(rpmerchant.token_authentication_failures, (val) => new Date(val).valueOf(), 'desc')[0]
                  )}
                </DataList.Value>
              </DataList.Item>
            )}
            {rpmerchant.require_captcha_until && (
              <DataList.Item>
                <DataList.Label>Require Captcha Until</DataList.Label>
                <DataList.Value>{DISPLAY_DATE_FORMATTER(rpmerchant.require_captcha_until)}</DataList.Value>
              </DataList.Item>
            )}
            <DataList.Item>
              <DataList.Label>Created At</DataList.Label>
              <DataList.Value>{DISPLAY_DATE_FORMATTER(rpmerchant.created_at)}</DataList.Value>
            </DataList.Item>
            <DataList.Item>
              <DataList.Label>Updated At</DataList.Label>
              <DataList.Value>{DISPLAY_DATE_FORMATTER(rpmerchant.updated_at)}</DataList.Value>
            </DataList.Item>
            <DataList.Item>
              <DataList.Label>Updated Up</DataList.Label>
              <DataList.Value>{DISPLAY_DATE_FORMATTER(rpmerchant.updated_up)}</DataList.Value>
            </DataList.Item>
          </DataList>
        </Layout.Card>

        <Layout.Group>
          <Layout.Header />
          <H3>Fee Structure</H3>
          <DataList inline labelWidth="20rem">
            <DataList.Item>
              <DataList.Label>ACH Debit</DataList.Label>
              <DataList.Value>
                {AMOUNT_FORMATTER(rpmerchant.fee_structure?.ach_debit.processorFixed)}{' '}
                <SetFeeButtons church={props.church} rpmerchant={rpmerchant} />
              </DataList.Value>
            </DataList.Item>
            <DataList.Item>
              <DataList.Label>Credit Card</DataList.Label>
              <DataList.Value>{rpmerchant.fee_structure?.card.processorPercent * 100 + '%'}</DataList.Value>
            </DataList.Item>
          </DataList>
        </Layout.Group>

        <Layout.Footer>
          <Layout.Card style={{ borderRadius: SIZE[1] }}>
            <Layout.Header headline="Identification" />
            <Layout.Group>
              <DataList inline labelWidth="18rem">
                <DataList.Item>
                  <DataList.Label>RP Account Id</DataList.Label>
                  <DataList.Value>{rpmerchant.account_id}</DataList.Value>
                </DataList.Item>

                <DataList.Item>
                  <DataList.Label>RP Merchant Id</DataList.Label>
                  <DataList.Value>{rpmerchant.id}</DataList.Value>
                </DataList.Item>
                <DataList.Item>
                  <DataList.Label>Processor Id</DataList.Label>
                  <DataList.Value>{COPILOT_FORMATTER(rpmerchant.processor_id)}</DataList.Value>
                </DataList.Item>
              </DataList>
            </Layout.Group>
          </Layout.Card>
          <Layout.Group>
            <Layout.Flex justifyContent="center">
              <ButtonConsoleLog data={rpmerchant} />
            </Layout.Flex>
          </Layout.Group>
        </Layout.Footer>
      </Layout.Panel>
    </>
  );
};

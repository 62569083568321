import React from 'react';
import { Layout } from '../../../component/Layout';
import { LoadingSpinnerBlock } from '../../../component/LoadingSpinner';
import { useUnBoardedMerchants } from '../../../hooks/useMerchant';
import { FORMAT_HINTS } from '../../../lib/objectGridAttributeLibrary';
import { ChurchesList } from '../component/ChurchesList';

export const Unboarded = () => {
  const headline = 'Unboarded Merchants';
  const byline = 'Merchant applications have been submitted for these Churches.';
  const hints = { church_id: FORMAT_HINTS.CHURCH_MERCHANT, applied_at: FORMAT_HINTS.DATE_FULL };
  const { data: churches = [], isLoading } = useUnBoardedMerchants();

  if (isLoading) {
    return (
      <Layout.Panel>
        <LoadingSpinnerBlock />
      </Layout.Panel>
    );
  }

  return (
    <ChurchesList
      headline={headline}
      statement={byline}
      data={churches}
      hints={hints}
      keys={['church_id', 'church_name', 'applied_at']}
    />
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import * as dateFns from 'date-fns';

const SecondsInADay = 24 * 60 * 60;

const formatRelative = (date: Date) => {
  return dateFns.formatRelative(date, new Date());
};

export const Dated = (props) => {
  if (props.date === undefined) {
    return props.children;
  }

  const date = new Date(props.date);
  if (isNaN(date.getTime())) {
    console.warn(`formatRelative date prop is invalid. ${props.date}`);
    return props.children;
  }

  const childFormatted = formatDate(props.format, date);
  const titleFormatted = formatDate(props.titleFormat, date);

  return (
    <span title={titleFormatted}>
      {props.children}
      {childFormatted}
    </span>
  );
};

Dated.propTypes = {
  children: PropTypes.node,
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  format: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  titleFormat: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

Dated.defaultProps = {
  children: null,
  format: 'PPP',
  titleFormat: 'PPPPpppp',
};

export const RelativeDate = (props) => {
  if (props.date === undefined) {
    return props.children;
  }

  const asOf = new Date();
  const date = new Date(props.date);

  // Delta is positive when date is greater than asOf.
  const secondsDelta = dateFns.differenceInSeconds(date, asOf);
  const isRelative =
    secondsDelta < props.relativeDaysBefore * SecondsInADay && secondsDelta > props.relativeDaysAfter * -SecondsInADay;

  const childFormatted = isRelative === true ? props.formatRelative(date) : formatDate(props.format, date);
  const titleFormatted = formatDate(props.titleFormat, date);

  return (
    <span title={titleFormatted}>
      {props.children} {childFormatted}
    </span>
  );
};

RelativeDate.propTypes = {
  children: PropTypes.node,
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  format: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  formatRelative: PropTypes.func,
  relativeDaysBefore: PropTypes.number,
  relativeDaysAfter: PropTypes.number,
  titleFormat: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

RelativeDate.defaultProps = {
  children: null,
  format: 'P',
  formatRelative: formatRelative,
  relativeDaysBefore: 3,
  relativeDaysAfter: 0.5,
  titleFormat: 'PPPPpppp',
};

const formatDate = (format: string | ((d: Date) => string), date: Date) => {
  if (typeof format === 'string') {
    const formatString = format as string;
    format = (value) => dateFns.format(value, formatString);
  } else if (typeof format === 'function') {
    // Good to go.
  } else {
    throw new TypeError('Format function is not valid');
  }

  return format(date);
};

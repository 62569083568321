import React, { useState } from 'react';
import { MakeoverStatus } from '../../../api/MakeoverHttpApi';
import { Button } from '../../../component/Button';
import { CircleIconXRounded } from '../../../component/Icons';
import { Layout } from '../../../component/Layout';
import { LoadingSpinnerBlock } from '../../../component/LoadingSpinner';
import { ObjectsTable } from '../../../component/ObjectsTable';
import { NUCLEUS_DASHBOARD_URL } from '../../../constant';
import { useCustomerSupportAdminMutation, useIsCustomerSupportAdmin } from '../../../hooks/useAdmins';
import { useChurch } from '../../../hooks/useChurch';
import { useMakeovers } from '../../../hooks/useMakeovers';
import { FORMAT_HINTS } from '../../../lib/objectGridAttributeLibrary';

export const Makeovers = () => {
  const [filter, setFilter] = useState<MakeoverStatus | null>(null);
  const { data, isLoading, hasNextPage, fetchNextPage } = useMakeovers();

  if (isLoading) {
    return (
      <Layout.Panel>
        <LoadingSpinnerBlock />
      </Layout.Panel>
    );
  }

  const filteredData = data?.filter((item) => filter === null || item.status === filter);

  return (
    <Layout.Panel>
      <Layout.Header
        headline="Makeover Applications"
        right={
          <>
            Filter:{' '}
            <select onChange={(e) => setFilter(e.target.value === '' ? null : (e.target.value as MakeoverStatus))}>
              <option value="">All</option>
              <option value="purchased">Purchased</option>
              <option value="submitted">Submitted</option>
              <option value="approved">Approved</option>
              <option value="rejected">Rejected</option>
              <option value="completed">Completed</option>
              <option value="delivered">Delivered</option>
            </select>
          </>
        }
      />

      <Layout.Group>
        <ObjectsTable
          data={filteredData?.map((item) => ({
            id: item.churchId,
            churchName: item.applicationData?.churchName,
            churchWebsiteUrl: item.applicationData?.churchWebsiteUrl,
            status: item.status.toUpperCase(),
            created: item.applicationCreatedAt?.split('T')[0],
            actions: <Actions churchId={item.churchId} />,
          }))}
          rowKey="id"
          keys={[
            //
            'churchName',
            'id',
            'churchWebsiteUrl',
            'status',
            'created',
            'actions',
          ]}
          firstAttributes={['churchName']}
          lastAttributes={['status', 'actions']}
          hints={{
            id: FORMAT_HINTS.LINK,
            churchWebsiteUrl: FORMAT_HINTS.WEBSITE,
          }}
        />
        {hasNextPage && (
          <Button style={{ width: '100%' }} size="large" shape="pill" onClick={() => fetchNextPage()}>
            Load More
          </Button>
        )}
      </Layout.Group>
    </Layout.Panel>
  );
};

const Actions = (props: { churchId: string }) => {
  return (
    <div style={{ display: 'flex', gap: '10px' }}>
      <ViewSubmissionButton churchId={props.churchId} />
      <JoinChurchButton churchId={props.churchId} />
    </div>
  );
};

export { Actions as MakeoverActions };

const JoinChurchButton = (props: { churchId: string }) => {
  const { data: church } = useChurch(props.churchId);
  const isCustomerSupportAdmin = useIsCustomerSupportAdmin(church);
  const { mutate, isPending } = useCustomerSupportAdminMutation(church);

  if (isCustomerSupportAdmin) {
    return (
      <Button
        title="Leave Church"
        style={{ height: '3.6rem', width: '3.6rem' }}
        icon={<CircleIconXRounded style={{ height: '3.6rem', width: '3.6rem' }} />}
        size="small"
        variant="primary"
        shape="pill"
        loading={isPending}
        onClick={() => mutate(false)}
      ></Button>
    );
  }

  return (
    <Button size="small" variant="secondary" shape="pill" loading={isPending} onClick={() => mutate(true)}>
      Join Church
    </Button>
  );
};

const ViewSubmissionButton = (props: { churchId: string }) => {
  const { data: church } = useChurch(props.churchId);
  const isCustomerSupportAdmins = useIsCustomerSupportAdmin(church);

  if (!isCustomerSupportAdmins) {
    return null;
  }

  return (
    <a
      href={`${NUCLEUS_DASHBOARD_URL}/c/${props.churchId.slice(7, 15)}/makeover/submission`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Button size="small" variant="primary" shape="pill">
        View Submission
      </Button>
    </a>
  );
};

import { STATE_DEFAULT, STATE_ERROR, STATE_LOADING, STATE_SUCCESS } from '../constants/state';
import { useCallback, useRef } from 'react';
import { useMountSafeState } from './useMountSafe';

type State = 'default' | 'loading' | 'error' | 'success';

export function useAsyncState(
  initialState: State = STATE_DEFAULT
): [State, (action: any, resetTimeout?: any) => Promise<any>] {
  const [state, setState] = useMountSafeState<State>(initialState);

  const timeout = useRef<NodeJS.Timeout>();

  const call = useCallback(async (action, resetTimeout = 3000) => {
    if (timeout.current !== undefined) {
      clearTimeout(timeout.current);
    }
    setState(STATE_LOADING);

    let result;

    try {
      result = await action();
    } catch (error) {
      setState(STATE_ERROR);
      throw error;
    }

    setState(STATE_SUCCESS);

    timeout.current = setTimeout(() => setState(STATE_DEFAULT), resetTimeout);

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [state, call];
}

import React from 'react';
import { Layout } from '../../../../../component/Layout';
import { GiftschedulesTable } from './GiftschedulesTable';

export const GiftschedulesListPanel = (props) => {
  return (
    <Layout.Panel>
      <Layout.Group>
        <GiftschedulesTable />
      </Layout.Group>
    </Layout.Panel>
  );
};

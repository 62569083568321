import React, { useContext } from 'react';
import { MerchantsContext } from '../../components/MerchantsContext';
import { MerchantContext } from '../context/MerchantContext';
import { RpMerchant } from './rpmerchant/RpMerchant';

export const RpMerchantDetail = (props: any) => {
  const { church } = useContext(MerchantsContext);
  const { merchant } = useContext(MerchantContext);
  const hasRebelpay = merchant.rebelpay_merchant_id !== undefined;

  return <>{hasRebelpay && <RpMerchant church={church} rpMerchantId={merchant.rebelpay_merchant_id} />}</>;
};

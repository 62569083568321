import { QueryOptions, useQueries, useQuery, UseQueryResult } from '@tanstack/react-query';
import { Person } from '../../type/Person';
import { ConnectionsResponse, PersonHttpApi } from '../api/PersonHttpApi';

const getCacheKey = (personId?: string) => ['person', personId];

export const usePerson = (
  personId?: string,
  queryOptions?: QueryOptions<Person | undefined, Error, Person | undefined>
): UseQueryResult<Person | undefined, Error> => {
  return useQuery({
    staleTime: 1000 * 60 * 5,
    ...queryOptions,
    queryKey: getCacheKey(personId),
    queryFn: async () => {
      if (personId === undefined) {
        throw new Error('personId is required');
      }
      return await PersonHttpApi.read(personId);
    },
    enabled: personId !== undefined,
  });
};

export const usePersons = (personIds: string[]) => {
  return useQueries({
    queries: personIds.map((personId) => ({
      staleTime: Infinity,
      queryKey: getCacheKey(personId),
      queryFn: async () => await PersonHttpApi.read(personId),
    })),
  });
};

export const usePersonConnections = (
  personId?: string,
  queryOptions?: QueryOptions<ConnectionsResponse, Error, ConnectionsResponse>
) => {
  return useQuery({
    staleTime: 1000 * 60 * 5,
    ...queryOptions,
    queryKey: ['person', 'connections', personId],
    queryFn: async () => {
      if (personId === undefined) {
        throw new Error('personId is required');
      }
      return await PersonHttpApi.connections(personId);
    },
    enabled: personId !== undefined,
  });
};

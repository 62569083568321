import React from 'react';
import { useParams } from 'react-router-dom';
import { ButtonConsoleLog } from '../../../../component/ButtonConsoleLog';
import { Layout } from '../../../../component/Layout';
import { GiftscheduleData } from './GiftscheduleData';
import { useGiftSchedules } from '../../../../hooks/useGiftSchedules';

export const GiftscheduleDetailPanel = () => {
  const params = useParams<{ giftscheduleId: string; personId: string }>();
  const { data: giftschedules = [] } = useGiftSchedules(params.personId);
  const giftschedule = giftschedules.find((schedule: any) => schedule.id === params.giftscheduleId);

  if (giftschedule === undefined) {
    return null;
  }

  return (
    <Layout.Panel>
      <Layout.Header headline={`${giftschedule.receipt_email ?? '(no receipt email)'}`} />
      <GiftscheduleData giftschedule={giftschedule} />

      <Layout.Footer>
        <Layout.Footer.Card>
          <Layout.Footer.Headline>Schedule ID</Layout.Footer.Headline>
          <Layout.Footer.Body>{giftschedule?.id}</Layout.Footer.Body>

          {giftschedule?.transfer_id !== undefined && (
            <>
              <Layout.Footer.Headline>Transfer ID</Layout.Footer.Headline>
              <Layout.Footer.Body>{giftschedule.transfer_id}</Layout.Footer.Body>
            </>
          )}
        </Layout.Footer.Card>
        <Layout.Group>
          <Layout.Flex justifyContent="center">
            <ButtonConsoleLog data={giftschedule} />
          </Layout.Flex>
        </Layout.Group>
      </Layout.Footer>
    </Layout.Panel>
  );
};

import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LoadingSpinner } from '../component/LoadingSpinner';
import { RequireAuth } from './Auth/RequireAuth';
import { SignIn } from './Auth/SignIn';
import { SignOut } from './Auth/SignOut';
import { Church } from './Church/Church';
import { Churches } from './Churches/Churches';
import { ViewEntity } from './Entities/route/ViewEntity';
import { Overview } from './Overview';
import { Person } from './Person/Person';
import { Persons } from './Persons/Persons';
import { Policies } from './Policies/Policies';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/sign-out" element={<SignOut />} />
      <Route path="/" element={<RequireAuth />} errorElement={<LoadingSpinner />}>
        <Route path="church/:churchId/*" element={<Church />} errorElement={<LoadingSpinner />} />
        <Route path="churches/*" element={<Churches />} />
        <Route path="person/:personId/*" element={<Person />} />
        <Route path="persons/search/*" element={<Persons />} />
        <Route path="persons/*" element={<Persons />} />
        <Route path="policies/*" element={<Policies />} />
        <Route path="overview/*" element={<Overview />} />
        <Route path="entity/*" element={<ViewEntity />} />
        <Route path="/" element={<Navigate to="/churches" replace={true} />} />
      </Route>
      <Route path="*" element={<Navigate to="/sign-in" replace={true} />} />
    </Routes>
  );
};

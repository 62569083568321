import { Church } from '../../type/Church';
import { Merchant } from '../../type/Merchant';
import { GIVING_API_URL, SUPPORT_API_URL } from '../constant';
import { apiCall } from '../lib/fetch';

const listMerchants = async (church: Church): Promise<Merchant[]> => {
  return await apiCall('GET', GIVING_API_URL, `/merchants?church_id=${church.id}`);
};

type UnBoardedResponse = {
  church_id: string;
  church_name: string;
  created_at: string;
  applied_at: string;
}[];

const listUnBoarded = async (): Promise<UnBoardedResponse> => {
  return await apiCall('GET', SUPPORT_API_URL, '/church/list/unboarded');
};

const activateMerchant = async (church: Church, merchant: Merchant): Promise<Merchant> => {
  return await apiCall('POST', SUPPORT_API_URL, '/merchant/activate', {
    church_id: church.id,
    merchant_id: merchant.id,
  });
};

const deactivateMerchant = async (church: Church, merchant: Merchant): Promise<{ merchant: Merchant }> => {
  return await apiCall('POST', SUPPORT_API_URL, '/merchant/deactivate', {
    church: { id: church.id },
    merchant: { id: merchant.id },
  });
};

const refreshMerchant = async (church: Church, merchant: Merchant): Promise<Merchant> => {
  return await apiCall('POST', SUPPORT_API_URL, '/merchant/refresh', {
    church: { id: church.id },
    merchant: { id: merchant.id },
  });
};

export const MerchantHttpApi = {
  listMerchants: listMerchants,
  listUnBoarded: listUnBoarded,
  activateMerchant: activateMerchant,
  deactivateMerchant: deactivateMerchant,
  refreshMerchant: refreshMerchant,
};

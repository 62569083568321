import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { LoadingSpinner } from './LoadingSpinner';
import { booleanPropHelperFactory, font, SIZE } from './style-utils';

export const Toggle = styled((props) => {
  const { checked, disabled, name, onChange, label, reverse, loading, ...otherProps } = props;

  const handleChange = (e) => {
    onChange(e.target.checked);
  };

  return (
    <Wrapper className={props.className} reverse={reverse}>
      <PositionRelative>
        <StyledCheckbox
          checked={checked}
          name={name}
          onChange={handleChange}
          loading={loading}
          disabled={disabled}
          {...otherProps}
        />
        <Slider reverse={reverse} disabled={disabled}>
          <SliderKnob>{loading && <LoadingSpinner width={20} strokeWidth={4} />}</SliderKnob>
        </Slider>
      </PositionRelative>
      <Label reverse={reverse} checked={checked}>
        {label}
      </Label>
    </Wrapper>
  );
})``;

Toggle.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  reverse: PropTypes.bool,
};

Toggle.defaultProps = {
  reverse: false,
  loading: false,
};

const isLoading = booleanPropHelperFactory('loading');

const SliderKnob = styled.div`
  position: absolute;
  left: ${SIZE[1]};
  bottom: ${SIZE[1]};
  width: ${SIZE[4]};
  height: ${SIZE[4]};
  background-color: ${(props) => props.theme.Toggle.Color.Default.Foreground};
  border-radius: 50%;
  transition: 0.4s;
`;

const Slider = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${(props) => {
    return props.reverse ? props.theme.Toggle.Color.Reverse.Background : props.theme.Toggle.Color.Default.Background;
  }};
  border-radius: ${SIZE[3]};
  color: ${(props) => props.theme.Toggle.Color.Default.Icon};
  cursor: pointer;
  transition: 0.4s;
  opacity: ${(props) => (props.disabled ? 0.35 : 1)};
`;

const StyledCheckbox = styled.input
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => {
      return !['loading'].includes(prop) && defaultValidatorFn(prop);
    },
  })
  .attrs({
    type: 'checkbox',
  })`
  appearance: none;
  opacity: 0;
  outline: none;
  -webkit-tap-highlight-color: transparent;

  :checked + ${Slider} {
    background: ${(props) => props.theme.Toggle.Color.Checked.Background};
  }

  :checked + ${Slider} ${SliderKnob} {
    transform: ${`translateX(${SIZE[4]})`};
  }

  ${isLoading(css`
    :checked + ${Slider} ${SliderKnob}, & + ${Slider} ${SliderKnob} {
      transform: ${`translateX(${SIZE[2]})`};
    }
  `)}
`;

const PositionRelative = styled.div`
  position: relative;
  width: ${SIZE[10]};
  height: ${SIZE[6]};
`;

const Label = styled.span`
  ${font(16, 'Bold', -0.5, 20)}
  font-family: ${({ theme }) => theme.Toggle.Font.Default};
  color: ${(props) => {
    return props.reverse && !props.checked ? props.theme.Toggle.Color.Reverse.Label : props.theme.Toggle.Color.Label;
  }};
  margin-left: ${SIZE[1]};
  margin-right: ${SIZE[1]};
  user-select: none;
`;

const Wrapper = styled.label`
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  justify-content: ${(props) => (props.reverse ? 'flex-end' : 'flex-start')};
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  > * {
    order: 1;
  }

  > ${Label} {
    order: ${(props) => (props.reverse ? '-1' : '1')};
  }
`;

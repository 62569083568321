import React from 'react';
import { useResolvedPath } from 'react-router-dom';
import styled from 'styled-components';
import { Church } from '../../../../../../type/Church';
import { RelativeDate } from '../../../../../component/Date';
import { Layout } from '../../../../../component/Layout';
import { Loading } from '../../../../../component/Progress/Loading';
import { Table } from '../../../../../component/Table';
import { BILLING_INTERVAL_TO_NAME } from '../../../../../constant';
import { useSubscriptions } from '../../../../../hooks/useSubscription';
import { Platform, entityPlatform } from '../../../../../lib/platform';
import {
  formatPaymentMethod,
  getSubscriptionStatusName,
  nextSubscriptionSettlementDate,
  subscriptionStartDate,
} from '../../../../../lib/subscription';

export const SubscriptionList = (props: { church: Church; platform: Platform | undefined }) => {
  const { data: subscriptions = [], isLoading, isError, error } = useSubscriptions(props.church?.id);
  const url = useResolvedPath('').pathname;

  if (isLoading) {
    return (
      <Layout.Panel>
        <Centered>
          <Loading />
        </Centered>
      </Layout.Panel>
    );
  }

  if (isError) {
    return (
      <Layout.Panel>
        <Centered>{error.message}</Centered>
      </Layout.Panel>
    );
  }

  if (subscriptions.length === 0) {
    return (
      <Layout.Card.Message>
        <Layout.Header headline={'No Subscriptions Found'} />
      </Layout.Card.Message>
    );
  }

  return (
    <Layout.Panel platform={props.platform}>
      <Table.OverflowAuto>
        <Table>
          <Table.Thead>
            <Table.Row>
              <Table.Header>Status</Table.Header>
              <Table.Header>Started</Table.Header>
              <Table.Header>Ended</Table.Header>
              <Table.Header>Interval</Table.Header>
              <Table.Header>Next Settlement</Table.Header>
              <Table.Header>Payment Method</Table.Header>
            </Table.Row>
          </Table.Thead>
          <Table.Tbody>
            {subscriptions.map((subscription, index) => {
              return (
                <Table.Row
                  key={subscription.id}
                  platform={entityPlatform(subscription)}
                  to={`${url}/${subscription.id}`}
                >
                  <Table.Cell>
                    <RelativeDate date={subscription.ended_at}>{getSubscriptionStatusName(subscription)}</RelativeDate>
                  </Table.Cell>
                  <Table.Cell>
                    <RelativeDate date={subscriptionStartDate(subscription)} />
                  </Table.Cell>
                  <Table.Cell>
                    <RelativeDate date={subscription.ended_at} />
                  </Table.Cell>
                  <Table.Cell>{BILLING_INTERVAL_TO_NAME[subscription.interval]}</Table.Cell>
                  <Table.Cell>
                    <RelativeDate date={nextSubscriptionSettlementDate(subscription)} />
                  </Table.Cell>
                  <Table.Cell>{formatPaymentMethod(subscription)}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Tbody>
        </Table>
      </Table.OverflowAuto>
    </Layout.Panel>
  );
};

const Centered = styled.div`
  min-height: 15rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

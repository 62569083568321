import styled from 'styled-components';
import { media } from '@foyyay/control-elements';

export const PanelButton = styled.div`
  position: static;

  ${media.tabletLandscapeAndUp`
    position: absolute;
    top: 5.3rem;
    right: 7.2rem;
  `}
`;

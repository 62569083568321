import { SUPPORT_API_URL } from '../constant';
import { apiCall } from '../lib/fetch';

type Owner = { id: string };

export const FeatureFlagHttpApi = {
  activate: async (owner: Owner, feature: string): Promise<void> => {
    await apiCall('POST', SUPPORT_API_URL, '/feature/activate', { owner: { id: owner.id }, feature: feature });
  },
  deactivate: async (owner: Owner, feature: string): Promise<void> => {
    await apiCall('POST', SUPPORT_API_URL, '/feature/deactivate', { owner: { id: owner.id }, feature: feature });
  },
};

import Color from 'color';
import ColorCast from 'colorcast';
import { get as _get } from 'lodash';
import { ThemeContext } from 'styled-components';

const primaryFont = 'LL Circular';
const secondaryFont = primaryFont;
const errorColor = '#B25151';

const PALETTE_CONFIG = {
  272: {
    Dark1: {
      saturation: 100,
      value: 60,
    },
    Dark2: {
      saturation: 100,
      value: 40,
    },
    Light1: {
      saturation: 2,
      value: 100,
    },
    Light2: {
      saturation: 6,
      value: 100,
    },
    Light3: {
      saturation: 12,
      value: 100,
    },
    Light4: {
      saturation: 24,
      value: 100,
    },
    Medium1: {
      saturation: 36,
      value: 100,
    },
    Vibrant1: {
      saturation: 75,
      value: 95,
    },
    Vibrant2: {
      saturation: 80,
      value: 88,
    },
    Vibrant3: {
      saturation: 90,
      value: 80,
    },
  },
};

export function createPalette(hexValue: string): Palette {
  const cast = new ColorCast(PALETTE_CONFIG);
  return cast.fromColor(hexValue);
}

interface Palette {
  Dark1: string;
  Dark2: string;
  Light1: string;
  Light2: string;
  Light3: string;
  Light4: string;
  Medium1: string;
  Vibrant1: string;
  Vibrant2: string;
  Vibrant3: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createTheme(palette: Palette) {
  const { Light1, Light2, Light3, Light4, Medium1, Vibrant1, Vibrant2, Vibrant3, Dark1, Dark2 } = palette;

  // These colors visually match Light1-4 from the palette, but are transparent for layering
  const AlphaLight1 = Color(Vibrant1).alpha(0.08).string();
  const AlphaLight2 = Color(Vibrant1).alpha(0.16).string();
  const AlphaLight3 = Color(Vibrant1).alpha(0.22).string();
  const AlphaLight4 = Color(Vibrant1).alpha(0.32).string();

  // Expanded palette colors
  const AlphaSuperLight1 = Color(Vibrant1).alpha(0.02).string();
  const AlphaSuperLight2 = Color(Vibrant1).alpha(0.04).string();
  const AlphaLight5 = Color(Vibrant1).alpha(0.38).string();

  return {
    Accounts: {
      Color: {
        Default: {
          Background: Light2,
          Foreground: Vibrant1,
        },
      },
    },
    Alert: {
      Color: {
        Error: {
          Background: errorColor,
          Foreground: '#fff',
        },
        Info: {
          Background: Light2,
          Foreground: Dark1,
        },
        Success: {
          Background: Vibrant1,
          Foreground: '#fff',
        },
      },
    },
    Auth: {
      Color: {
        Default: {
          Background: Light1,
          Body: Dark2,
          Footer: '#999999',
        },
        Error: {
          Foreground: errorColor,
        },
      },
    },
    Background: {
      Color: {
        Default: {
          Background: '#fff',
          Foreground: Vibrant1,
        },
      },
    },
    BadgePrimary: {
      Color: {
        Default: {
          Background: AlphaLight1,
          Border: Vibrant1,
          Foreground: Dark1,
          Icon: Vibrant1,
        },
      },
    },
    BadgeSecondary: {
      Color: {
        Default: {
          Background: 'transparent',
          Border: Dark1,
          Foreground: Dark1,
          Icon: Dark1,
        },
      },
    },
    BadgeTertiary: {
      Color: {
        Default: {
          Background: 'transparent',
          Border: AlphaLight2,
          Foreground: Dark1,
          Icon: Dark1,
        },
      },
    },
    Banner: {
      Color: {
        Error: {
          Background: errorColor,
          Foreground: '#fff',
        },
        Info: {
          Background: AlphaLight1,
          Foreground: Dark1,
        },
        Success: {
          Background: Vibrant1,
          Foreground: '#fff',
        },
      },
    },
    PublishingBlocker: {
      Body: {
        Color: {
          Default: {
            Background: Light1,
          },
        },
      },
      Option: {
        Color: {
          Default: {
            Background: '#fff',
            Border: '#fff',
            Foreground: Dark1,
            Shadow: Color(Dark1).alpha(0.08).string(),
          },
          Active: {
            Background: undefined,
            Border: Vibrant1,
            Foreground: Vibrant1,
            Shadow: Color(Vibrant1).alpha(0.16).string(),
          },
        },
      },
    },
    Breadcrumb: {
      Color: {
        Default: {
          Foreground: Dark2,
        },
        Hover: {
          Foreground: Color(Vibrant1).alpha(0.8).string(),
        },
        Link: {
          Foreground: Vibrant1,
        },
      },
    },
    Button: {
      Color: {
        ActiveHighlight: Color(Dark2).alpha(0.08).string(),
      },
    },
    ButtonCircleArrow: {
      Color: {
        Active: {
          Background: Color(Vibrant3).darken(0.2).string(),
          Foreground: undefined,
        },
        Default: {
          Background: Vibrant2,
          Foreground: '#ffffff',
        },
        Disabled: {
          Background: Light4,
          Foreground: undefined,
        },
        Hover: {
          Background: Vibrant3,
          Foreground: undefined,
        },
      },
    },
    ButtonCircleNumber: {
      Color: {
        Background: Vibrant1,
        Foreground: '#ffffff',
      },
    },
    ButtonCirclePlus: {
      Color: {
        Active: {
          Background: Color(Vibrant3).darken(0.2).string(),
          Foreground: undefined,
        },
        Default: {
          Background: Vibrant2,
          Foreground: '#ffffff',
        },
        Disabled: {
          Background: Light4,
          Foreground: undefined,
        },
        Hover: {
          Background: Vibrant3,
          Foreground: undefined,
        },
      },
    },
    ButtonPlaid: {
      Color: {
        Default: {
          Background: Vibrant2,
          Foreground: '#fff',
        },
        Hover: {
          Background: Vibrant3,
          Foreground: '#fff',
        },
        SmallText: Vibrant1,
      },
    },
    ButtonAlertPrimary: {
      Color: {
        Activated: {},
        Active: {
          Background: '#ffffff',
          Foreground: Dark1,
        },
        Default: {
          Background: '#ffffff',
          Foreground: Dark1,
        },
        Error: {
          Background: errorColor,
          Foreground: '#ffffff',
        },
        Hover: {
          Background: '#ffffff',
          Foreground: Vibrant1,
        },
      },
    },
    ButtonPrimary: {
      Color: {
        Activated: {},
        Active: {
          Background: Vibrant3,
          Foreground: undefined,
        },
        Default: {
          Background: Vibrant1,
          Foreground: '#ffffff',
        },
        Error: {
          Background: errorColor,
          Foreground: undefined,
        },
        Hover: {
          Background: Vibrant2,
          Foreground: undefined,
        },
      },
    },
    ButtonPrimaryGradient: {
      Color: {
        Activated: {},
        Active: {
          Background: undefined,
          Foreground: undefined,
        },
        Default: {
          Background: `linear-gradient(90deg, ${Vibrant1} 5%, ${Dark1} 100%)`,
          Foreground: '#ffffff',
        },
        Error: {
          Background: errorColor,
          Foreground: undefined,
        },
        Hover: {
          Background: undefined,
          Foreground: undefined,
        },
      },
    },
    ButtonResourcePrimary: {
      Color: {
        Active: {
          Background: Vibrant3,
          Foreground: undefined,
        },
        Default: {
          Background: Vibrant1,
          Foreground: '#ffffff',
        },
        Error: {
          Background: errorColor,
          Foreground: undefined,
        },
        Hover: {
          Background: Vibrant2,
          Foreground: undefined,
        },
      },
    },
    ButtonResourcePrimaryGradient: {
      Color: {
        Active: {
          Background: undefined,
          Foreground: undefined,
        },
        Default: {
          Background: `linear-gradient(90deg, ${Vibrant1} 5%, ${Dark1} 100%)`,
          Foreground: '#ffffff',
          Shadow: `3px 6px 18px 0 ${Color(Dark2).alpha(0.48).string()}`,
        },
        Error: {
          Background: errorColor,
          Foreground: undefined,
        },
        Hover: {
          Background: undefined,
          Foreground: undefined,
        },
      },
    },
    ButtonResourceSecondary: {
      Color: {
        Active: {
          Background: undefined,
          Foreground: undefined,
        },
        Default: {
          Background: '#ffffff',
          Foreground: Dark1,
          Foreground2: Dark2,
          Shadow: `3px 6px 18px 0 ${Color(Dark2).alpha(0.12).string()}`,
        },
        Error: {
          Background: Color(errorColor).alpha(0.35).string(),
          Foreground: errorColor,
        },
        Hover: {
          Background: undefined,
          Border: `linear-gradient(90deg, ${Medium1} 5%, ${Vibrant1} 100%)`,
          Foreground: Vibrant1,
          Foreground2: Dark1,
        },
      },
    },
    ButtonSecondary: {
      Color: {
        Activated: {
          Active: {
            Background: AlphaLight4,
            Foreground: undefined,
          },
          Default: {
            Background: AlphaLight2,
            Foreground: Vibrant1,
          },
          Hover: {
            Background: AlphaLight3,
            Foreground: undefined,
          },
        },
        Active: {
          Background: AlphaLight3,
          Foreground: undefined,
        },
        Default: {
          Background: AlphaLight1,
          Foreground: Dark1,
        },
        Error: {
          Background: Color(errorColor).alpha(0.35).string(),
          Foreground: errorColor,
        },
        Hover: {
          Background: AlphaLight2,
          Foreground: undefined,
        },
      },
    },
    ButtonTertiary: {
      Color: {
        Activated: {
          Active: {
            Background: AlphaLight5,
            Foreground: undefined,
          },
          Default: {
            Background: AlphaLight3,
            Foreground: Vibrant1,
          },
          Hover: {
            Background: AlphaLight4,
            Foreground: undefined,
          },
        },
        Active: {
          Background: AlphaLight4,
          Foreground: undefined,
        },
        Default: {
          Background: AlphaLight2,
          Foreground: Dark1,
        },
        Error: {
          Background: Color(errorColor).alpha(0.35).string(),
          Foreground: errorColor,
        },
        Hover: {
          Background: AlphaLight3,
          Foreground: undefined,
        },
      },
    },
    ButtonText: {
      Color: {
        Default: Vibrant1,
      },
    },
    ButtonBreakoutNav: {
      Color: {
        Activated: {
          Active: {
            Background: '#ffffff',
            Foreground: Vibrant1,
          },
          Default: {
            Background: '#ffffff',
            Foreground: Vibrant1,
          },
          Hover: {
            Background: AlphaLight1,
            Foreground: undefined,
          },
        },
        Active: {
          Background: AlphaLight2,
          Foreground: undefined,
        },
        Default: {
          Background: '#ffffff',
          Foreground: Dark1,
        },
        Error: {
          Background: Color(errorColor).alpha(0.35).string(),
          Foreground: errorColor,
        },
        Hover: {
          Background: AlphaLight1,
          Foreground: undefined,
        },
      },
    },
    ButtonOverlay: {
      Color: {
        Activated: {
          Active: {
            Background: '#ffffff',
            Foreground: Vibrant1,
          },
          Default: {
            Background: '#ffffff',
            Foreground: Vibrant1,
          },
          Hover: {
            Background: '#ffffff',
            Foreground: Vibrant1,
          },
        },
        Active: {
          Background: '#ffffff',
          Foreground: Vibrant1,
        },
        Default: {
          Background: '#ffffff',
          Foreground: Dark1,
        },
        Error: {
          Background: Color(errorColor).alpha(0.35).string(),
          Foreground: errorColor,
        },
        Hover: {
          Background: '#ffffff',
          Foreground: Vibrant1,
        },
      },
    },
    Card: {
      Color: {
        Default: {
          Background: AlphaLight1,
          Foreground: Dark2,
        },
        Hover: {
          Background: AlphaLight2,
          Foreground: undefined,
        },
      },
    },
    Checkbox: {
      Color: {
        Checked: {
          Active: {
            Background: AlphaLight4,
            Foreground: undefined,
          },
          Default: {
            Background: AlphaLight2,
            Foreground: Vibrant1,
            IndicatorBackground: Vibrant1,
            IndicatorForeground: '#fff',
          },
          Hover: {
            Background: AlphaLight3,
            Foreground: undefined,
          },
        },
        Default: {
          Background: AlphaLight1,
          Foreground: Dark1,
          IndicatorBackground: AlphaLight1,
        },
        Error: {
          Background: Color(errorColor).alpha(0.35).string(),
          Foreground: errorColor,
        },
        Hover: {
          Background: AlphaLight2,
          Foreground: undefined,
          IndicatorBackground: AlphaLight2,
        },
      },
    },
    ChecklistItem: {
      Color: {
        Checked: {
          Active: {
            Background: AlphaLight4,
            Foreground: undefined,
          },
          Default: {
            Background: AlphaSuperLight1,
            Foreground: undefined,
            IndicatorBackground: Vibrant1,
            IndicatorForeground: '#fff',
          },
          Hover: {
            Background: AlphaLight1,
            Foreground: undefined,
            IndicatorBackground: Vibrant1,
            IndicatorForeground: '#fff',
          },
        },
        Default: {
          Background: AlphaLight1,
          Foreground: Dark1,
          IndicatorBackground: AlphaLight1,
          IndicatorForeground: undefined,
        },
        Error: {
          Background: Color(errorColor).alpha(0.35).string(),
          Foreground: errorColor,
        },
        Hover: {
          Background: AlphaLight2,
          Foreground: undefined,
          IndicatorBackground: AlphaLight2,
        },
      },
    },
    CircleCheck: {
      Color: {
        Background: {
          Checked: Vibrant2,
          Disabled: Light2,
          Foreground: '#fff',
          Unchecked: Light3,
        },
        Default: {
          Background: undefined,
          Foreground: Vibrant2,
        },
        Disabled: {
          Background: undefined,
          Foreground: Light4,
        },
        Hover: {
          Background: Light2,
          Foreground: Vibrant3,
        },
      },
    },
    DatePicker: {
      Color: {
        Default: {
          Background: Light2,
          Day: Dark1,
          DayNumber: Dark2,
          DayNumberPassive: Color(Dark2).alpha(0.25).string(),
          Foreground: Vibrant2,
          Icon: Dark1,
          MonthName: Color(Dark2).alpha(0.5).string(),
        },
        Disabled: {
          Background: Light2,
          Foreground: Light4,
        },
        Hover: {
          Background: Light2,
          Foreground: Vibrant2,
          Icon: Dark1,
        },
      },
    },
    DateRangePicker: {
      Color: {
        Range: [Vibrant1, Dark1],
      },
    },
    Divider: {
      Color: {
        Default: {
          Foreground: Dark1,
          Background: AlphaLight1,
        },
      },
    },
    Dropdown: {
      Color: {
        BackButton: {
          Color: {
            Desktop: {
              Border: Light4,
              Default: {
                Background: '#fff',
                Foreground: Vibrant2,
              },
              Hover: {
                Background: Light2,
                Foreground: Vibrant3,
              },
            },
            Mobile: {
              Default: {
                Background: 'none',
                Border: Color(Light4).alpha(0.2).string(),
                Foreground: Light3,
              },
            },
          },
        },
        CardContainer: {
          Border: Light4,
          BoxShadow: Color(Dark1).alpha(0.1).string(),
        },
        Dropdown: {
          Active: {
            Background: Color(Light2).alpha(0.15).string(),
          },
          Default: {
            Background: Light2,
          },
          Hover: {
            Background: Color(Light2).alpha(0.15).string(),
          },
        },
        Label: {
          Foreground: Dark1,
        },
        Nav: {
          Color: {
            Default: {
              Background: '#fff',
              Foreground: Vibrant2,
            },
            Error: errorColor,
            Hover: {
              Background: Light2,
              Foreground: Vibrant3,
            },
          },
        },
        Profile: {
          Background: Medium1,
          Border: Dark1,
          Foreground: Color(Light2).alpha(0.75).string(),
          Notification: errorColor,
        },
      },
    },
    DropdownBackButton: {},
    DropdownCardContainer: {},
    DropdownLabel: {},
    DropdownProfile: {},
    EmbedCode: {
      Color: {
        Default: {
          Background: Light2,
          Foreground: Vibrant1,
        },
      },
      Font: secondaryFont,
    },
    EmojiPicker: {
      Color: {
        Default: {
          Foreground: Vibrant1,
        },
      },
    },
    FloatingButton: {
      Color: {
        Default: {
          Background: '#fff',
          BoxShadow: Color(Dark1).alpha(0.24).string(),
          Foreground: Dark1,
        },
      },
    },
    Footer: {
      Color: {
        Foreground: Dark1,
      },
    },
    Header: {
      Color: {
        Background: '#fff',
        BoxShadow: Color(Dark2).alpha(0.2).string(),
        Foreground: Vibrant1,
      },
    },
    HeaderNav: {
      Color: {
        Active: {
          Foreground: Vibrant1,
        },
        Default: {
          Foreground: Dark1,
        },
        Hover: {
          Foreground: Dark1,
        },
      },
    },
    HeaderToggle: {
      Color: {
        Active: {
          Background: Color(Light4).alpha(0.55).string(),
          Foreground: undefined,
        },
        Default: {
          Background: Color(Light4).alpha(0.3).string(),
          Foreground: '#fff',
        },
      },
    },
    Headings: {
      Color: {
        Default: {
          Background: Light2,
          Foreground: Vibrant1,
        },
      },
    },
    ImageUpload: {
      Color: {
        Default: {
          Background: AlphaLight1,
          Border: AlphaLight4,
          Foreground: Dark1,
          Headline: Dark1,
          Icon: Vibrant1,
        },
        Hover: {
          Background: AlphaLight2,
          Border: Vibrant1,
          Foreground: Vibrant1,
          Headline: Dark1,
          Icon: Vibrant1,
        },
      },
    },
    Input: {
      Color: {
        Default: {
          Background: AlphaLight1,
          Foreground: Dark1,
          Icon: Vibrant1,
        },
        Error: errorColor,
        Focus: {
          Background: Light1,
          Border: Vibrant1,
          Foreground: undefined,
          Icon: Vibrant1,
        },
        Hover: {
          Background: AlphaLight2,
          Foreground: undefined,
          Icon: Vibrant1,
        },
        Placeholder: Light4,
      },
      Font: {
        Default: secondaryFont,
      },
      Warning: {
        Default: {
          Background: Color(errorColor).alpha(0.2).string(),
          Foreground: errorColor,
        },
        Focus: {
          Background: Color(errorColor).alpha(0.35).string(),
          Border: errorColor,
          Foreground: undefined,
        },
        Hover: {
          Background: Color(errorColor).alpha(0.35).string(),
          Foreground: undefined,
        },
      },
    },
    InputAutoComplete: {
      Color: {
        Suggestion: {
          Active: {
            Background: Light2,
            Foreground: Vibrant1,
          },
          Default: {
            Background: undefined,
            Foreground: Vibrant2,
          },
          Hover: {
            Background: Light2,
            Foreground: Vibrant1,
          },
          Inactive: {
            Background: Light2,
            Foreground: Medium1,
          },
        },
      },
    },
    InputTextLimitedChar: {
      Color: {
        Counter: Color(Dark1).alpha(0.45).string(),
        Error: errorColor,
      },
    },
    InputSlider: {
      Color: {
        Default: {
          Track: AlphaLight2,
          TrackFilled: Vibrant1,
          Thumb: '#fff',
          ThumbValue: Dark1,
          ThumbShadow: Color(Dark1).alpha(0.18).string(),
        },
        Hover: {
          ThumbShadow: Color(Dark1).alpha(0.24).string(),
        },
      },
    },
    Label: {
      Color: {
        Default: {
          Foreground: Color(Dark2).alpha(0.5).string(),
        },
        Error: {
          Foreground: errorColor,
        },
        Focus: {
          Foreground: Dark1,
        },
      },
    },
    Layout: {
      Footer: {
        Banner: {
          Color: {
            Default: {
              Background: Dark1,
              Foreground: '#fff',
            },
          },
        },
      },
    },
    Manager: {
      Color: {
        Default: {
          Background: Light1,
          Foreground: Vibrant1,
        },
      },
    },
    Marketing: {
      Color: {
        Default: {
          Background: '#fff',
          Foreground: '#000',
        },
      },
    },
    MenuDivider: {
      Color: {
        Default: {
          Background: Color(Dark1).alpha(0.1).string(),
        },
      },
    },
    Overlay: {
      Color: {
        Default: {
          Background: Color(Light4).alpha(0.9).string(),
          BoxShadow: Color(Dark1).alpha(0.18).string(),
        },
      },
    },
    OverviewPage: {
      Color: {
        Default: {
          Background: Light1,
          Border: Light2,
        },
      },
    },
    Palette: {
      Error: errorColor,
      Dark1: Dark1,
      Dark2: Dark2,
      Light1: Light1,
      Light2: Light2,
      Light3: Light3,
      Light4: Light4,
      Medium1: Medium1,
      Vibrant1: Vibrant1,
      Vibrant2: Vibrant2,
      Vibrant3: Vibrant3,
      AlphaLight1: AlphaLight1,
      AlphaLight2: AlphaLight2,
      AlphaLight3: AlphaLight3,
      AlphaLight4: AlphaLight4,
    },
    Panel: {
      Color: {
        Default: {
          Background: '#fff',
          Border: Light4,
          BoxShadow: Color(Dark1).alpha(0.16).string(),
        },
        Empty: {
          Background: Light2,
          Color: Medium1,
        },
        Error: {
          Border: Color(errorColor).alpha(0.2).string(),
          Foreground: errorColor,
        },
      },
    },
    Parameter: {
      Color: {
        Default: {
          Background: Light2,
          Foreground: Dark1,
        },
      },
    },
    Preview: {
      Color: {
        Background: Light2,
        Foreground: Dark2,
        Icon: Dark1,
      },
    },
    Profile: {
      Color: {
        Default: {
          Background: Medium1,
          Border: Dark1,
          Foreground: Light3,
        },
      },
    },
    Progress: {
      Color: {
        Default: {
          Background: Light3,
          Foreground: Vibrant2,
        },
        Inverse: {
          Background: Color(Light4).alpha(0.65).string(),
          Foreground: Light1,
        },
      },
    },
    ProgressPill: {
      Color: {
        Default: {
          Background: Light3,
        },
        Active: {
          Background: Vibrant1,
        },
      },
    },
    ProgressCircle: {
      Color: {
        Default: {
          Foreground: Vibrant1,
        },
      },
    },
    RadioButton: {
      Color: {
        Checked: {
          Active: {
            Background: AlphaLight4,
            Foreground: undefined,
          },
          Default: {
            Background: AlphaLight2,
            Foreground: Vibrant1,
            IndicatorBackground: Vibrant1,
            IndicatorForeground: '#ffffff',
          },
          Hover: {
            Background: AlphaLight3,
            Foreground: undefined,
          },
        },
        Default: {
          Background: AlphaLight1,
          Foreground: Dark1,
          IndicatorBackground: AlphaLight1,
        },
        Error: {
          Background: Color(errorColor).alpha(0.35).string(),
          Foreground: errorColor,
        },
        Hover: {
          Background: AlphaLight2,
          Foreground: undefined,
          IndicatorBackground: AlphaLight2,
        },
      },
    },
    RadioGroup: {},
    RadioOption: {},
    SpecialRadioGroup: {
      Color: {
        Default: {
          Background: AlphaLight1,
          Foreground: Dark1,
        },
        Error: {
          Background: Color(errorColor).alpha(0.35).string(),
          Foreground: errorColor,
        },
      },
    },
    SpecialRadioOption: {
      Color: {
        Checked: {
          Active: {
            Background: Light4,
            Foreground: Vibrant1,
          },
          Default: {
            Background: AlphaLight2,
            Foreground: Vibrant1,
            IndicatorBackground: Vibrant1,
            IndicatorForeground: '#ffffff',
          },
          Hover: {
            Background: AlphaLight2,
            Foreground: Vibrant1,
          },
        },
        Default: {
          Background: AlphaLight1,
          Foreground: Dark1,
        },
        Error: {
          Background: Color(errorColor).alpha(0.35).string(),
          Foreground: errorColor,
        },
        Hover: {
          Background: AlphaLight2,
          Foreground: AlphaLight4,
        },
      },
    },
    RichText: {
      Color: {
        Default: {
          Background: '#fff',
          Border: AlphaLight2,
          Text: '#000',
        },
      },
    },
    Select: {
      Color: {
        Default: {
          Background: Light2,
          Border: Vibrant1,
          Foreground: Dark1,
          Icon: Vibrant1,
        },
      },
    },
    ShowMore: {
      Color: {
        Active: {
          Foreground: undefined,
        },
        Default: {
          Foreground: Vibrant1,
        },
        Hover: {
          Foreground: Vibrant1,
        },
      },
    },
    Step: {
      Color: {
        Default: {
          Background: '#fff',
          BoxShadow: Color(Dark1).alpha(0.16).string(),
          Foreground: Dark1,
        },
        Focus: {
          BoxShadow: Color(Dark1).alpha(0.24).string(),
        },
      },
    },
    StepInput: {},
    StepInputAutoComplete: {},
    StepOption: {
      Color: {
        Default: {
          Background: undefined,
          Foreground: Dark1,
        },
        Disabled: {
          Background: Light3,
          Foreground: undefined,
        },
        Hover: {
          Background: Light3,
          Foreground: undefined,
        },
      },
    },
    SubNav: {
      Color: {
        Active: {
          Background: Light4,
          Foreground: Dark1,
        },
        Default: {
          Background: Light2,
          Foreground: Dark1,
        },
        Hover: {
          Background: Light3,
          Foreground: Dark1,
        },
      },
    },
    Table: {
      Color: {
        Active: {
          Foreground: Vibrant1,
        },
        Default: {
          Background: AlphaLight1,
          Cell: Dark1,
          DragHandle: Dark1,
          Foreground: Vibrant1,
          Header: {
            Background: AlphaLight1,
            Foreground: Dark1,
          },
        },
        Hover: {
          Background: AlphaLight2,
        },
        Inactive: {
          Foreground: Dark2,
        },
        Muted: {
          Background: AlphaSuperLight2,
          Foreground: Light4,
          Text: Color(Dark1).alpha(0.5).string(),
        },
      },
      Font: {
        Default: secondaryFont,
        Heading: primaryFont,
      },
    },
    Toggle: {
      Color: {
        Checked: {
          Background: Vibrant2,
          Foreground: undefined,
        },
        Default: {
          Background: Light3,
          Foreground: '#ffffff',
          Icon: Vibrant1,
        },
        Label: Vibrant2,
        Reverse: {
          Background: Color(Dark1).alpha(0.2).string(),
          Label: Color(Dark1).alpha(0.4).string(),
        },
      },
      Font: {
        Default: primaryFont,
      },
    },
    ToolbarButton: {
      Color: {
        Active: {
          Default: {
            Background: Light3,
            Border: Vibrant1,
            Foreground: undefined,
          },
          Hover: {
            Background: Light4,
            Foreground: undefined,
          },
        },
        Default: {
          Background: Light2,
          Foreground: Dark1,
        },
        Hover: {
          Background: Light3,
          Foreground: undefined,
        },
      },
    },
    Typography: {
      Color: {
        Active: {
          Link: undefined,
        },
        Default: {
          Byline: Dark2,
          Field: Vibrant1,
          Heading: Dark2,
          Label: Dark1,
          Link: Vibrant1,
          Paragraph: Dark2,
          ParagraphLead: Dark1,
        },
        Hover: {
          Link: Vibrant1,
        },
      },
      Default: primaryFont,
      Paragraph: secondaryFont,
    },
    Unsubscribe: {
      Card: {
        Color: {
          Default: {
            Headline: Dark2,
            Subheading: Dark1,
          },
        },
      },
      Color: {
        Default: {
          Headline: Dark2,
        },
      },
    },
    Web: {
      Preview: {
        Color: {
          Paragraph: '#000000',
        },
      },
    },
    Wizard: {
      Header: {
        Color: {
          Default: {
            Label: Color(Dark2).alpha(0.5).string(),
          },
        },
      },
      Body: {
        Color: {
          Default: {
            Background: Light1,
          },
        },
      },
      Option: {
        Color: {
          Default: {
            Background: '#fff',
            Border: '#fff',
            Foreground: Dark1,
            Shadow: Color(Dark1).alpha(0.08).string(),
          },
          Active: {
            Background: undefined,
            Border: Vibrant1,
            Foreground: Vibrant1,
            Shadow: Color(Vibrant1).alpha(0.16).string(),
          },
        },
      },
    },
  };
}

export const Theme = ThemeContext;

export function getThemeValue(...paths: string[]) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return function (props: any) {
    return resolveObject(props.theme, ...paths);
  };
}

function resolveObject(object: any, ...paths: string[]) {
  for (const path of paths) {
    object = _get(object, path);
  }
  return object;
}

import React from 'react';
import { useParams } from 'react-router-dom';
import { ButtonConsoleLog } from '../../../../component/ButtonConsoleLog';
import { Layout } from '../../../../component/Layout';
import { useTransactions } from '../../../../hooks/useTransactions';
import { TransactionData } from './TransactionData';

export const TransactionDetailPanel = () => {
  const params = useParams<{ transactionId: string; personId: string }>();
  const { data: transactions = [] } = useTransactions(params.personId ?? '');
  const transaction = transactions.find((transaction: any) => transaction.id === params.transactionId);

  if (transaction === undefined) {
    return null;
  }

  return (
    <Layout.Panel>
      <TransactionData transaction={transaction} />
      <Layout.Footer>
        <Layout.Footer.Card>
          <Layout.Footer.Headline>Transaction ID</Layout.Footer.Headline>
          <Layout.Footer.Body>{transaction.id}</Layout.Footer.Body>
          {transaction.submission_id !== undefined && ( // will we ever have this from the endpoint?
            <>
              <Layout.Footer.Headline>Submission ID</Layout.Footer.Headline>
              <Layout.Footer.Body>{transaction.submission_id}</Layout.Footer.Body>
            </>
          )}
        </Layout.Footer.Card>
        <Layout.Group>
          <Layout.Flex justifyContent="center">
            <ButtonConsoleLog data={transaction} />
          </Layout.Flex>
        </Layout.Group>
      </Layout.Footer>
    </Layout.Panel>
  );
};

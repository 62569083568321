import { Church } from '../../type/Church';
import { NUCLEUS_PLATFORM_API_URL } from '../constant';
import { apiCall } from '../lib/fetch';

export interface SiteEngineListResponse {
  siteEngines: Array<any>;
  domains: Record<
    string,
    {
      preview: Array<string>;
      production: Array<string>;
    }
  >;
}

const list = async (church: Pick<Church, 'id'>): Promise<SiteEngineListResponse> => {
  return await apiCall('GET', NUCLEUS_PLATFORM_API_URL, `/site-engines/${church.id}`);
};

export const SiteEngineHttpApi = {
  list: list,
};

import { Section } from '@foyyay/control-elements';
import { cloneDeep as _cloneDeep, get as _get, isEmpty as _isEmpty } from 'lodash';
import React, { useState } from 'react';
import { usePerson } from '../hooks/usePerson';
import {
  DISPLAY_ADDRESS_FORMATTER,
  EMAIL_FORMATTER,
  LINK_FORMATTER,
  TEL_FORMATTER,
} from '../lib/objectGridAttributeLibrary';
import { Button } from './Button';
import { ButtonConsoleLog } from './ButtonConsoleLog';
import { ButtonCopy } from './ButtonCopy';
import { CircleProfileImage } from './CircleProfileImage';
import { DataList } from './DataList';
import { CircleIconXRounded, IconPreview } from './Icons';
import { Layout } from './Layout';
import { LoadingSpinnerBlock } from './LoadingSpinner';
import { ObjectsTable } from './ObjectsTable';
import { H3 } from './Typography';
import { SIZE } from './style-utils';

export const Person = (props: any) => {
  let { data: personData, isLoading } = usePerson(props.personId);
  personData = _cloneDeep(personData) || {};
  if (!_isEmpty(personData) && Object.keys(personData).length > 0) {
    personData.emails.forEach((email: any) => {
      email.login = email.address === personData.login ? true : false;
    });
  }

  const [showEmails, setShowEmails] = useState();

  const displayAddress = _isEmpty(personData.profile?.address_mailing_postal)
    ? personData.profile?.address_mailing
    : personData.profile?.address_mailing_postal;

  const thumbnail = _get(personData, 'profile.profile_file_url');

  if (isLoading) {
    return <LoadingSpinnerBlock />;
  }

  if (_isEmpty(personData?.emails)) {
    return (
      <Layout.Group>
        <Layout.Panel>
          <Layout.Card.Message>
            <Layout.Header headline="Person not found" statement={LINK_FORMATTER(personData.id)} />
          </Layout.Card.Message>
        </Layout.Panel>
      </Layout.Group>
    );
  }
  return (
    <Layout.Panel>
      <Layout.Header
        headline={
          <>
            {props.name || _get(personData, 'profile.full_name') || personData.login}
            {props.isChurchOwner === true ? ' (Owner) ' : ''}
          </>
        }
      />
      <Layout.Group>
        <Layout.Card style={{ background: 'transparent' }}>
          <Layout.Group>
            <Layout.Flex gap={SIZE[6]} wrap={true}>
              <Layout.Flex.Column>
                {thumbnail !== undefined && (
                  <CircleProfileImage style={{ height: '90px', width: '90px' }} src={thumbnail} />
                )}
              </Layout.Flex.Column>
              <Layout.Flex.Column>
                <DataList inline style={{ width: '100%' }}>
                  {personData.profile?.full_name?.length > 0 && (
                    <DataList.Item>
                      <DataList.Label>Full Name</DataList.Label>
                      <DataList.Value>{personData.profile.full_name}</DataList.Value>
                    </DataList.Item>
                  )}
                  {JSON.stringify(displayAddress)?.length > 0 && (
                    <DataList.Item>
                      <DataList.Label>Address</DataList.Label>
                      <DataList.Value>{DISPLAY_ADDRESS_FORMATTER(displayAddress)}</DataList.Value>
                    </DataList.Item>
                  )}
                  {personData.profile.phone?.length > 0 && (
                    <DataList.Item>
                      <DataList.Label>Phone</DataList.Label>
                      <DataList.Value>{TEL_FORMATTER(personData.profile?.phone ?? '')}</DataList.Value>
                    </DataList.Item>
                  )}
                  <DataList.Item>
                    <DataList.Label>Login</DataList.Label>
                    <DataList.Value>{EMAIL_FORMATTER(personData.login)}</DataList.Value>
                  </DataList.Item>
                  <DataList.Item>
                    <DataList.Label>Id</DataList.Label>
                    <DataList.Value>{LINK_FORMATTER(personData.id)}</DataList.Value>
                  </DataList.Item>
                  <DataList.Item>
                    <DataList.Label></DataList.Label>
                    <DataList.Value>
                      <ButtonCopy data={personData.id} />
                    </DataList.Value>
                  </DataList.Item>
                </DataList>
              </Layout.Flex.Column>
            </Layout.Flex>
          </Layout.Group>
        </Layout.Card>
      </Layout.Group>

      <Section key="person-data-emails">
        <Layout.Header
          headline={
            <Layout.Flex>
              <Layout.Flex.Column>
                <H3>Emails</H3>
              </Layout.Flex.Column>
              <Layout.Flex.Column>
                <Button
                  size="small"
                  shape="circle"
                  icon={showEmails === personData.id ? <CircleIconXRounded /> : <IconPreview />}
                  onClick={() => setShowEmails(showEmails === personData.id ? false : personData.id)}
                  style={{ height: '2.5rem', width: '2.5rem' }}
                />
              </Layout.Flex.Column>
            </Layout.Flex>
          }
        />
        {showEmails && (
          <ObjectsTable
            key="person-data-emails-table"
            rowKey={'address'}
            firstAttributes={['address']}
            data={personData.emails}
            keys={['address', 'verified', 'login']}
            lastAttributes={['login']}
          />
        )}
      </Section>

      {_isEmpty(props.adminData) === true && (
        <Layout.Footer>
          <Layout.Flex justifyContent="center">
            <ButtonConsoleLog data={personData} />
          </Layout.Flex>
        </Layout.Footer>
      )}
    </Layout.Panel>
  );
};

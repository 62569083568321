import { cloneDeep as _cloneDeep, truncate as _truncate } from 'lodash';
import { AMOUNT_FORMATTER, formatSchedule } from '../../../../../lib/formatting';
import { getFunction } from '../../../../../lib/functionMap';
import { GiftscheduleColumns } from '../constants/constants';

/** Non-Mutating formatter: returns deeply cloned giftschedules array */
export function useFormatGiftschedules(giftschedules: Array<any>): Array<any> {
  const getStatus = (value: boolean | string, item: any) => {
    if (item?.max_charges === 1 && item?.remaining_charges === 0) {
      return 'Completed';
    }
    return value ? 'Active' : 'Inactive';
  };

  const formatMap: Record<string, (value: string, item: any) => string> = {
    active: (value, item) => getStatus(value, item),
    net_amount: (value) => AMOUNT_FORMATTER(value),
    amount: (value) => AMOUNT_FORMATTER(value),
    recurring: (value, item) => (item.max_charges === 1 ? 'One-Time' : 'Recurring'),
    memo: (value) => _truncate(value, { length: 45 }),
    receipt_email: (value) => _truncate(value, { length: 45 }),
    receipt_first_name: (value, item) => [value, item.receipt_last_name].join(' ').trim(),
    schedule: (value, item) => _truncate(formatSchedule(item), { length: 65 }),
  };

  return _cloneDeep(giftschedules ?? []).map((giftschedule: any) => {
    GiftscheduleColumns.forEach((column) => {
      const formatter = getFunction(formatMap, column.key);
      giftschedule[column.key] = formatter(giftschedule?.[column.key], giftschedule);
    });
    return giftschedule;
  });
}

import { deepFreeze } from '../lib/deepFreeze';

// NOTE THESE ARE NOT THE SAME AS USED IN NUCLEUS DASHBOARD
export const TRANSACTION_COLUMNS = deepFreeze([
  {
    label: 'Amount',
    key: 'amount',
    active: true,
  },
  {
    label: 'Covered fee',
    key: 'fee',
    active: true,
  },
  {
    label: 'Date',
    key: 'date',
    active: true,
  },
  {
    label: 'Date Given',
    key: 'date_given',
    active: true,
  },
  {
    label: 'Date Paid Out',
    key: 'date_paidout',
    active: true,
  },
  {
    label: 'Fund',
    key: 'designation_name',
    active: true,
  },
  {
    label: 'Frequency',
    key: 'frequency',
    active: true,
  },
  {
    label: 'Schedule',
    key: 'schedule',
    active: true,
  },
  {
    label: 'Status',
    key: 'status',
    active: true,
  },
  {
    label: 'Fund Id',
    key: 'fund_id',
    active: true,
  },
  {
    label: 'Method',
    key: 'method',
    active: true,
  },
  {
    label: 'Memo',
    key: 'memo',
    active: true,
  },
  {
    label: 'Church',
    key: 'church',
    active: true,
  },
  {
    label: 'Fund',
    key: 'fund',
    active: true,
  },
  {
    label: 'Transaction ID',
    key: 'transaction_id',
    active: true,
  },
  {
    label: 'Member ID',
    key: 'member_id',
    active: true,
  },
  {
    label: 'Account ID',
    key: 'account_id',
    active: true,
  },
]);

export const DEFAULT_ACTIVE_COLUMNS_ARRAY = Object.freeze([
  'amount',
  'date_given',
  'church',
  'frequency',
  'status',
  'transaction_id',
]);

import React from 'react';
import { DataList } from '../../../../component/DataList';
import { Layout } from '../../../../component/Layout';
import {
  AMOUNT_FORMATTER,
  DATE_FORMATTER,
  DATE_FULL_FORMATTER,
  FREQUENCY_FORMATTER,
  formatSchedule,
} from '../../../../lib/formatting';

export const GiftscheduleData = (props: { giftschedule: any }) => {
  const amount = AMOUNT_FORMATTER(props.giftschedule.amount);
  const completed = props.giftschedule?.max_charges === 1 && props.giftschedule?.remaining_charges === 0;
  const netAmount = AMOUNT_FORMATTER(props.giftschedule.net_amount);
  const nextCharge =
    props.giftschedule.active === true ? DATE_FULL_FORMATTER(props.giftschedule.next_charge_at) : undefined;
  const schedule = formatSchedule(props.giftschedule);
  const showNextCharge = completed === false && props.giftschedule.active === true;
  const showReceiptEmail = nextCharge !== undefined && props.giftschedule.receipt_email;
  const showMemo = props.giftschedule.memo !== undefined;

  return (
    <>
      <Layout.Group>
        <DataList inline>
          <DataList.Item>
            <DataList.Label>Status</DataList.Label>
            <DataList.Value>{getStatus(props.giftschedule)}</DataList.Value>
          </DataList.Item>
          <DataList.Item>
            <DataList.Label>Created on</DataList.Label>
            <DataList.Value>{DATE_FORMATTER(props.giftschedule.created_at)}</DataList.Value>
          </DataList.Item>
        </DataList>

        <DataList inline>
          <DataList.Item>
            <DataList.Label>Amount</DataList.Label>
            <DataList.Value>{netAmount}</DataList.Value>
          </DataList.Item>
          <DataList.Item>
            <DataList.Label>Fund</DataList.Label>
            <DataList.Value>{props.giftschedule.designation_name}</DataList.Value>
          </DataList.Item>
          <DataList.Item>
            <DataList.Label>Church</DataList.Label>
            <DataList.Value>{props.giftschedule.church_name}</DataList.Value>
          </DataList.Item>
          {completed === true ? (
            <DataList.Item>
              <DataList.Label>Frequency</DataList.Label>
              <DataList.Value>{FREQUENCY_FORMATTER(props.giftschedule)}</DataList.Value>
            </DataList.Item>
          ) : (
            <DataList.Item>
              <DataList.Label>Schedule</DataList.Label>
              <DataList.Value>{schedule}</DataList.Value>
            </DataList.Item>
          )}
        </DataList>

        <DataList inline>
          <DataList.Item>
            <DataList.Label>Total</DataList.Label>
            <DataList.Value>{amount}</DataList.Value>
          </DataList.Item>
          <DataList.Item>
            <DataList.Label>Method</DataList.Label>
            <DataList.Value>{props.giftschedule.source_id}</DataList.Value>
          </DataList.Item>
          {showNextCharge && (
            <DataList.Item>
              <DataList.Label>Next Charge</DataList.Label>
              <DataList.Value>{nextCharge}</DataList.Value>
            </DataList.Item>
          )}
          {showReceiptEmail && (
            <DataList.Item>
              <DataList.Label>Receipt to</DataList.Label>
              <DataList.Value>{props.giftschedule?.receipt_email}</DataList.Value>
            </DataList.Item>
          )}
        </DataList>

        {showMemo && (
          <DataList inline>
            <DataList.Item>
              <DataList.Label>Memo</DataList.Label>
              <DataList.Value>{props.giftschedule.memo}</DataList.Value>
            </DataList.Item>
          </DataList>
        )}
      </Layout.Group>
    </>
  );
};

const getStatus = (giftschedule) => {
  if (giftschedule?.max_charges === 1 && giftschedule?.remaining_charges === 0) {
    return 'Completed';
  }
  return giftschedule.active ? 'Active' : 'Inactive';
};

import React from 'react';
import { Button } from '../../../../../../../component/Button';
import { useRefreshRpMerchant } from '../../../../../../../hooks/useRpMerchant';

export const RpRefreshMerchant = (props: any) => {
  const rpmerchant = props.rpmerchant;
  const { mutate: refresh, isPending } = useRefreshRpMerchant(rpmerchant.id);

  return (
    <Button shape="pill" size="small" variant="primary" loading={isPending} onClick={refresh}>
      Refresh Rebelpay
    </Button>
  );
};

import React, { useContext } from 'react';
import { Layout } from '../../../../../../component/Layout';
import { LoadingSpinnerBlock } from '../../../../../../component/LoadingSpinner';
import { useRpMerchant } from '../../../../../../hooks/useRpMerchant';
import { MerchantsContext } from '../../components/MerchantsContext';
import { MerchantContext } from '../context/MerchantContext';
import { DataBluechex } from './processordata/DataBluechex';
import { DataCardConnect } from './processordata/DataCardConnect';
import { RpMerchantUpdate } from './rpmerchant/RpMerchantUpdate';
import { RpRefreshMerchant } from './rpmerchant/RpRefreshMerchant';
import { RpSubmitMerchant } from './rpmerchant/RpSubmitMerchant';

export const ProcessorDataDetail = (props: any) => {
  const { church } = useContext(MerchantsContext);
  const { merchant } = useContext(MerchantContext);
  const { data: rpmerchant = {}, isLoading } = useRpMerchant(merchant?.rebelpay_merchant_id);

  const isBoarded = merchant.rebelpay_merchant_id !== undefined && merchant.boarded_at !== undefined;

  const canRefresh = rpmerchant.id !== undefined;
  const canSubmit = rpmerchant.signed_at === undefined;

  if (isLoading) {
    return (
      <Layout.Panel>
        <LoadingSpinnerBlock />
      </Layout.Panel>
    );
  }
  return (
    <>
      <Layout.Panel>
        <Layout.Header
          headline={'Processor Data'}
          buttons={
            <>
              {canRefresh && <RpRefreshMerchant church={props.church} rpmerchant={rpmerchant} />}
              {canSubmit && <RpSubmitMerchant church={props.church} rpmerchant={rpmerchant} />}
            </>
          }
        />
      </Layout.Panel>
      {isBoarded === true && (
        <>
          <DataCardConnect />
          <DataBluechex />
        </>
      )}
      {isBoarded === false && <RpMerchantUpdate church={church} rpMerchantId={merchant.rebelpay_merchant_id} />}
    </>
  );
};

import { ButtonPrimary, InputText, Label, TextAreaLimitedChar } from '@foyyay/control-elements';
import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createManagedPolicy, readManagedPolicy, updateManagedPolicy } from '../../../../action/policy';
import { Layout } from '../../../../component/Layout';

function EditManagedComponent() {
  const params = useParams<{ policyName?: string }>();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [policy, setPolicy] = useState({ editable: false });
  const [policyName, setPolicyName] = useState(params.policyName ?? '');
  const [policyContent, setPolicyContent] = useState('{}');
  const [isValid, setIsValid] = useState(true);
  const [error, setError] = useState('');

  const loadPolicy = async () => {
    setIsLoading(true);
    try {
      const result = await dispatch(readManagedPolicy(policyName));
      setPolicy(result.policy);
      setPolicyContent(JSON.stringify(result.policy.policyContent, null, 2));
    } catch (error: any) {
      setError(JSON.stringify(error.body ?? error.message, null, 2));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (policyName.length < 1) {
      return;
    }
    loadPolicy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.policyName]);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (isValid === false) {
      return;
    }

    if (isLoading === true) {
      return;
    }

    const dispatchAction = policy.editable === true ? updateManagedPolicy : createManagedPolicy;

    setError('');
    setIsLoading(true);
    try {
      const result = await dispatch(dispatchAction(policyName, JSON.parse(policyContent)));
      setPolicy(result.policy);
      setPolicyName(result.policy.name);
      setPolicyContent(JSON.stringify(result.policy.policyContent, null, 2));
    } catch (error: any) {
      setError(JSON.stringify(error.body ?? error.message, null, 2));
    }

    setIsLoading(false);
  };

  const updatePolicyContent = (value: string) => {
    setPolicyContent(value);

    try {
      JSON.parse(value);
      setIsValid(true);
      setError('');
    } catch (error) {
      setIsValid(false);
      setError('Not valid JSON');
    }
  };

  const updatePolicyName = (value: string) => {
    if (policy.editable === true) {
      return;
    }
    setPolicyName(value);
  };

  const buttonLabel = policy.editable === true ? 'Update Policy' : 'Create Policy';

  return (
    <Layout.Panel>
      <form onSubmit={onSubmit}>
        <Label htmlFor="resource">Managed ID</Label>
        <InputText
          data-lpignore="true"
          name="resource"
          value={policyName}
          onChange={updatePolicyName}
          title={'title string'}
          required
        />
        <Label htmlFor="content">Policy Content</Label>
        <TextAreaLimitedChar
          name="content"
          value={policyContent}
          onChange={updatePolicyContent}
          title={'title string'}
          required
        />
        <ButtonPrimary disabled={isValid === false} loading={isLoading}>
          {buttonLabel}
        </ButtonPrimary>
        <div>
          <Label error={error.length > 0}>{error}</Label>
        </div>
      </form>
    </Layout.Panel>
  );
}

export const EditManaged = EditManagedComponent;

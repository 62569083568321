import { QueryOptions, useQuery, UseQueryResult } from '@tanstack/react-query';
import { ReadSubscriptionResponse, SubscriptionHttpApi } from '../api/SubscriptionHttpApi';
import { SubscriptionModel } from '../constant';

const getCacheKey = (churchId?: string) => ['subscriptions', churchId];

export const useSubscriptions = (
  churchId?: string,
  queryOptions?: QueryOptions<SubscriptionModel[], Error, SubscriptionModel[]>
): UseQueryResult<SubscriptionModel[], Error> => {
  return useQuery({
    staleTime: 1000 * 60 * 5,
    ...queryOptions,
    queryKey: getCacheKey(churchId),
    queryFn: async () => {
      if (churchId === undefined) {
        throw new Error('Church id required');
      }
      const response = await SubscriptionHttpApi.list(churchId);
      return response.subscriptions;
    },
    enabled: churchId !== undefined,
  });
};

export const useSubscription = (
  churchId?: string,
  subscriptionId?: string
): UseQueryResult<ReadSubscriptionResponse, Error> => {
  return useQuery({
    staleTime: 1000 * 60 * 5,
    queryKey: ['subscription', subscriptionId],
    queryFn: async () => {
      if (churchId === undefined || subscriptionId === undefined) {
        throw new Error('Church and subscription id required');
      }
      return await SubscriptionHttpApi.read(churchId, subscriptionId);
    },
    enabled: churchId !== undefined && subscriptionId !== undefined,
  });
};

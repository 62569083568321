import {
  DAY_OF_THE_WEEK_TO_NAME_MAP,
  INTERVAL_MONTH,
  INTERVAL_WEEK,
  INTERVAL_DATES,
  SOURCE_TYPE_ACH_DEBIT,
  SOURCE_TYPE_CARD,
} from '../constant';
import * as dateFns from 'date-fns';

export const NUMBER_FILTER = (value) => ((value || '').match(/\d|•/g) || []).join('');
export const TAX_ID_FILTER = (value) => NUMBER_FILTER(value).slice(0, 9);
export const PHONE_FILTER = (value) => NUMBER_FILTER(value).slice(0, 10);

export const DATE_FILTER = (value) => {
  value = NUMBER_FILTER(value).slice(0, 8);
  if (value.length < 1) {
    return value;
  }

  let m = value.slice(0, 2);
  let d = value.slice(2, 4);
  let y = value.slice(4, 8);
  let parts = [m];
  if (d) {
    parts.push(d);
  }
  if (y) {
    parts.push(y);
  }
  return parts.join('/');
};

export const PHONE_FORMATTER = (value) => {
  value = NUMBER_FILTER(value).slice(0, 10);
  if (value.length < 1) {
    return value;
  }

  let a = value.slice(0, 3);
  let b = value.slice(3, 6);
  let c = value.slice(6, 10);
  let parts = [a];
  if (b) {
    parts.push(b);
  }
  if (c) {
    parts.push(c);
  }
  return parts.join('-');
};

export const AMOUNT_FORMATTER = (value) => {
  let sign = value < 0 ? '-' : '';
  let amount = (Math.abs(value) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  amount = amount.replace(/\.0$/, '');
  amount = amount.replace(/\.\d$/, '$&0');
  amount = `${sign}$${amount}`;
  return amount;
};

export const DATE_FORMATTER = (value) => {
  let date = new Date(value);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

export const DATE_FULL_FORMATTER = (value) => {
  // is it a date?
  let asDate = new Date(value);
  if (isNaN(asDate.getTime()) === false) {
    return dateFns.format(asDate, 'yyyy-MM-dd  HH:mm:ss xxx');
  }
  return value;
};

export const TIME_FORMATTER = (value) => {
  const date = new Date(value);
  return `${date.toLocaleTimeString()}`;
};

export const DATE_TIME_FORMATTER = (value) => {
  if (dateFns.isValid(new Date(value)) === false) {
    return value;
  } else {
    return dateFns.format(new Date(value), "MM/dd/yyyy 'at' hh:mmaaa");
  }
};

export const FREQUENCY_FORMATTER = (giftschedule) => {
  if (giftschedule === undefined || giftschedule.max_charges === 1) {
    return 'One Time';
  }
  return 'Recurring';
};

// NOTE: Schedule Formatting is copied from NucleusGivingApi format file.
export const SCHEDULE_FORMATTER = (intervalAnchor, intervalType, intervalCount, intervalDates = [], maxCharges) => {
  if (intervalType === undefined && intervalCount === undefined) {
    return 'Just this once';
  }

  let intervalName = {
    [INTERVAL_MONTH]: 'month',
    [INTERVAL_WEEK]: 'week',
    [INTERVAL_DATES]: 'regular',
  }[intervalType];

  let result = '';
  if (intervalCount > 1) {
    result = `Every ${intervalCount} ${intervalName}s on`;
  } else if (maxCharges === 1 && intervalDates.length <= 1) {
    // use 'scheduled' where max charges equals 1.
    result = 'On';
  } else {
    result = `${intervalName}ly on`;
  }

  let anchorDate = new Date(intervalAnchor);

  if (INTERVAL_MONTH === intervalType) {
    let day = String(anchorDate.getDate());
    result = `${result} the ${nth(day)}`;
  } else if (INTERVAL_WEEK === intervalType) {
    let day = DAY_OF_THE_WEEK_TO_NAME_MAP[anchorDate.getDay()];
    result = `${result} ${day}`;
  } else if (INTERVAL_DATES === intervalType) {
    if (intervalDates.length === 1) {
      result = `${result} the ${nth(intervalDates[0])} of the month`;
    }
    // if there are two dates combine them with and
    if (intervalDates.length === 2) {
      result = `${result} the ${nth(intervalDates[0])} and ${nth(intervalDates[1])} of the month`;
    }
    // if there are more than two valid numbers in the array... use commas, then and
    if (intervalDates.length > 2) {
      result = `${result} the`;
      intervalDates.forEach((day) => {
        if (intervalDates.indexOf(day) + 1 === intervalDates.length) {
          result = `${result} and ${nth(day)}`;
        } else {
          result = `${result} ${nth(day)},`;
        }
      });
      result = `${result} of the month`;
    }
  }

  return result[0].toUpperCase() + result.slice(1);
};

export const SOURCE_FORMATTER = (model) => {
  let { source_type, source_last_few } = model;

  if ([source_type, source_last_few].includes(undefined)) {
    return '';
  }

  return `${SOURCE_TYPE_TO_WORD[source_type]} ending in *${source_last_few}`;
};

export const SOURCE_TYPE_TO_WORD = {
  [SOURCE_TYPE_ACH_DEBIT]: 'Bank Account',
  [SOURCE_TYPE_CARD]: 'Credit Card',
};

// NOTE: nth Formatting is copied from NucleusGivingApi format file.
const nth = (num) => {
  // return non-numeric
  if (isNaN(num)) {
    return num;
  }
  // we're casting to string
  num = String(num);
  if (['1', '21', '31'].includes(num)) {
    num = num + 'st';
  } else if (['2', '22'].includes(num)) {
    num = num + 'nd';
  } else if (['3', '23'].includes(num)) {
    num = num + 'rd';
  } else {
    num = num + 'th';
  }
  return num;
};

const KEY_PREFIX_CHURCH = 'church';
const SEPARATOR = '_';
const PREFIX_START = KEY_PREFIX_CHURCH.length + SEPARATOR.length;
const PREFIX_LENGTH = 8;

export const generateChurchIdPrefix = (id) =>
  id ? id.substring(PREFIX_START, PREFIX_START + PREFIX_LENGTH) : undefined;
export const generateChurchIdPrefixBaseUrl = (id) => (id ? `/c/${generateChurchIdPrefix(id)}` : undefined);

export const relativeDate = (date) => {
  try {
    return dateFns.formatRelative(new Date(date), new Date());
  } catch (error) {
    return '';
  }
};

export const formatSchedule = (schedule) => {
  let result = SCHEDULE_FORMATTER(
    schedule.interval_anchor,
    schedule.interval_type,
    schedule.interval_count,
    schedule.interval_dates,
    schedule.max_charges
  );

  if (!schedule.active && schedule.max_charges !== 1) {
    result = `${result} (paused)`;
  }

  return result;
};

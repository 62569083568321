import React, { useContext, useState } from 'react';
import { RelativeDate } from '../../../../../../../component/Date';
import { Table } from '../../../../../../../component/Table';
import { BillingItemType } from '../../../../../../../constant';
import { AMOUNT_FORMATTER } from '../../../../../../../lib/formatting';
import { SubscriptionControllerContext } from '../../state/context';
import { selectDebitsAndCredits } from '../../state/reducer';
import { CreditDebitAdd } from './CreditDebitAdd';
import { Layout } from '../../../../../../../component/Layout';
import { Button } from '../../../../../../../component/Button';

export const CreditDebitList = () => {
  const { useSelector } = useContext(SubscriptionControllerContext);
  const debitsAndCreditItems = useSelector(selectDebitsAndCredits);
  const [renderAdd, setRenderAdd] = useState(false);

  const doShowAddPanel = () => setRenderAdd(true);
  const doHideAddPanel = () => setRenderAdd(false);

  const handleAddNewClicked = () => doShowAddPanel();

  const renderTable = debitsAndCreditItems.length > 0;

  return (
    <Layout.Panel>
      <Layout.Header
        headline={`Adjustments: ${debitsAndCreditItems.length}`}
        buttons={
          <Button shape="pill" size="small" variant="primary" onClick={handleAddNewClicked}>
            + Add Adjustment
          </Button>
        }
      />
      {renderAdd && <CreditDebitAdd onClose={doHideAddPanel} />}
      {renderTable && (
        <Table.OverflowAuto>
          <Table>
            <Table.Thead>
              <Table.Row>
                <Table.Header>Type</Table.Header>
                <Table.Header>$ amount</Table.Header>
                <Table.Header>Description</Table.Header>
                <Table.Header>Issued</Table.Header>
              </Table.Row>
            </Table.Thead>
            <Table.Tbody>
              {debitsAndCreditItems.map((item) => {
                return (
                  <Table.Row key={item.id}>
                    <Table.Cell>{nameOfType(item)}</Table.Cell>
                    <Table.Cell>{formatAmount(item)}</Table.Cell>
                    <Table.Cell>{item.data.description}</Table.Cell>
                    <Table.Cell>
                      <RelativeDate date={item.started_at} />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Tbody>
          </Table>
        </Table.OverflowAuto>
      )}
    </Layout.Panel>
  );
};

const formatAmount = (item) => {
  if (item.type === BillingItemType.Debit) {
    return AMOUNT_FORMATTER(item.data.amount * -1);
  }
  return AMOUNT_FORMATTER(item.data.amount);
};

const nameOfType = (item) => {
  if (item.type === BillingItemType.Credit) {
    return 'Credit';
  } else if (item.type === BillingItemType.Debit) {
    return 'Debit';
  }
  return '🤷🏼‍♂️';
};

import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from '../../../../../../component/Layout';
import { LoadingSpinnerBlock } from '../../../../../../component/LoadingSpinner';
import { SubscriptionControllerContext } from '../state/context';
import { selectLoading, selectSubscription } from '../state/reducer';
import { InvoiceRoutes } from './Invoice/routes';
import { InvoiceList } from './components/InvoiceList';

export const InvoicesRoutes = () => {
  return (
    <Routes>
      <Route path={'/:invoiceId/*'} element={<InvoiceRoutes />} />
      <Route path={'/'} element={<Invoices />} />
    </Routes>
  );
};

const Invoices = () => {
  const { useSelector } = useContext(SubscriptionControllerContext);
  const loading = useSelector(selectLoading);
  const subscription = useSelector(selectSubscription);

  if (loading === true) {
    return (
      <Layout.Panel>
        <LoadingSpinnerBlock />
      </Layout.Panel>
    );
  }

  if (subscription === undefined) {
    return (
      <Layout.Panel>
        <Layout.Card.Message>
          <Layout.Header headline="Subscription Not Found" />
        </Layout.Card.Message>
      </Layout.Panel>
    );
  }

  return <InvoiceList />;
};

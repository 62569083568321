import { Headline } from '@foyyay/control-elements';
import React, { MouseEventHandler, useContext, useState } from 'react';
import { Button } from '../../../../../../../component/Button';
import { RelativeDate } from '../../../../../../../component/Date';
import { Layout } from '../../../../../../../component/Layout';
import { Table } from '../../../../../../../component/Table';
import { BillingItemDiscount, BillingItemProduct } from '../../../../../../../constant';
import { AMOUNT_FORMATTER } from '../../../../../../../lib/formatting';
import { Platform, entityPlatform } from '../../../../../../../lib/platform';
import { SubscriptionControllerContext } from '../../state/context';
import {
  highlightDiscountProduct,
  selectDiscountItems,
  selectLastInvoice,
  selectProductItems,
  selectSubscription,
  selectSubscriptionHasEnded,
  updateItem,
} from '../../state/reducer';
import { DiscountAdd } from './DiscountAdd';
import { getNewDate } from './input';

type Update = {
  ended_at?: BillingItemDiscount['ended_at'] | null;
  data?: Pick<BillingItemDiscount['data'], 'percent' | 'amount'>;
};

export const SubscriptionDiscounts = () => {
  const { dispatch, useSelector } = useContext(SubscriptionControllerContext);
  const discountItems = useSelector(selectDiscountItems);
  const lastInvoice = useSelector(selectLastInvoice);
  const productItems = useSelector(selectProductItems);
  const subscription = useSelector(selectSubscription);
  const subscriptionHasEnded = useSelector(selectSubscriptionHasEnded);
  const [renderAddPanel, setRenderAddPanel] = useState(false);

  const appliesTo = (discountItem: BillingItemDiscount) => {
    if (discountItem.data.applies_to_items === undefined) {
      return 'Entire subscription';
    }

    const productCount = discountItem.data.applies_to_items
      .map((itemId) => productItems.find((item) => item.id === itemId))
      .filter((item) => item !== undefined).length;

    return `${productCount} products`;
  };

  const doUpdateDiscount = (discountItem: BillingItemDiscount, update: Update) => {
    console.info('Updating discount amount', update);
    dispatch(updateItem({ item: discountItem, updates: update }));
  };

  const doShowAddPanel = () => setRenderAddPanel(true);
  const doHideAddPanel = () => setRenderAddPanel(false);

  const handleStatusClicked = (event, discountItem: BillingItemDiscount) => {
    if (discountItem.ended_at !== undefined) {
      const activate = window.confirm('Re-activate this discount?');
      if (activate !== true) {
        return;
      }
      doUpdateDiscount(discountItem, { ended_at: null });
      return;
    }

    const message = 'When should this discount end?';
    const asOf = new Date();
    const newDate = getNewDate(message, asOf, asOf);
    if (newDate === undefined) {
      return;
    }
    doUpdateDiscount(discountItem, { ended_at: newDate });
  };

  const handleAmountClicked = (event, discountItem: BillingItemDiscount) => {
    const newValue = getDataUpdate(discountItem);
    if (newValue === undefined) {
      return;
    }
    doUpdateDiscount(discountItem, { data: newValue });
  };

  const handleAppliesToClicked = (event, discountItem: BillingItemDiscount) => {
    dispatch(highlightDiscountProduct(discountItem));
  };

  const handleAddNewClicked = () => doShowAddPanel();

  const renderTable = discountItems.length > 0;

  return (
    <Layout.Panel>
      <Layout.Header
        buttons={
          <Button shape="pill" size="small" variant="primary" onClick={handleAddNewClicked}>
            + Add Discount
          </Button>
        }
      />

      <Headline>Discounts: {discountItems.length}</Headline>
      {renderAddPanel && <DiscountAdd onClose={doHideAddPanel} />}
      {renderTable && (
        <Table.OverflowAuto>
          <Table>
            <Table.Thead>
              <Table.Row>
                <Table.Header>Description</Table.Header>
                <Table.Header>Amount</Table.Header>
                <Table.Header>Applies to</Table.Header>
                <Table.Header></Table.Header>
              </Table.Row>
            </Table.Thead>
            <Table.Tbody>
              {discountItems.map((discountItem) => {
                const strikethrough = isDiscountValid(discountItem, productItems) === false;

                const editable =
                  discountItem.ended_at === undefined ||
                  lastInvoice === undefined ||
                  new Date(discountItem.ended_at) > new Date(lastInvoice.period_started_at ?? lastInvoice.created_at);
                let onAmountClicked: MouseEventHandler | undefined;
                let onStatusClicked: MouseEventHandler | undefined;
                if (editable) {
                  onAmountClicked = (event) => handleAmountClicked(event, discountItem);
                  if (entityPlatform(subscription) !== Platform.RebelGive) {
                    onStatusClicked = (event) => handleStatusClicked(event, discountItem);
                  }
                }

                let onAppliesToClicked;
                if (discountItem.data.applies_to_items !== undefined) {
                  onAppliesToClicked = (event) => handleAppliesToClicked(event, discountItem);
                }

                return (
                  <Table.Row key={discountItem.id} strikethrough={strikethrough}>
                    <Table.Cell>{discountItem.data.description}</Table.Cell>
                    <Table.Cell onClick={onAmountClicked}>{amountFormatter(discountItem)}</Table.Cell>
                    <Table.Cell onClick={onAppliesToClicked}>{appliesTo(discountItem)}</Table.Cell>
                    <Table.Cell onClick={onStatusClicked}>{status(discountItem, subscriptionHasEnded)}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Tbody>
          </Table>
        </Table.OverflowAuto>
      )}
    </Layout.Panel>
  );
};

const amountFormatter = (item) => {
  if (item.data.amount !== undefined) {
    return AMOUNT_FORMATTER(item.data.amount);
  }
  if (item.data.percent !== undefined) {
    return `${item.data.percent}%`;
  }
  return 'Invalid';
};

const status = (item, subscriptionHasEnded) => {
  if (subscriptionHasEnded === true) {
    return '';
  }

  if (item.ended_at === undefined) {
    return 'Active';
  }

  if (new Date(item.ended_at) <= new Date()) {
    return <RelativeDate date={item.ended_at}>Ended</RelativeDate>;
  }

  return <RelativeDate date={item.ended_at}>Ending</RelativeDate>;
};

const isDiscountValid = (discountItem: BillingItemDiscount, productItems: Array<BillingItemProduct>) => {
  const appliesToProductIds = discountItem.data.applies_to_items ?? [];

  for (const appliesToProductId of appliesToProductIds) {
    const appliesToProduct = productItems.find((productItem) => productItem.id === appliesToProductId);
    if (appliesToProduct === undefined) {
      // Product no longer exists.
      return false;
    }

    if (appliesToProduct.ended_at === undefined) {
      // Product is here and it doesn't end.
      continue;
    }

    if (discountItem.ended_at === undefined) {
      // The discount is not ending, the product is.
      return false;
    }

    if (new Date(discountItem.ended_at) > new Date(appliesToProduct.ended_at)) {
      // The product and the discount are ending, but the discount ends later.
      return false;
    }
  }

  return true;
};

const getDataUpdate = (discountItem: BillingItemDiscount): Update['data'] | undefined => {
  if (discountItem.data.amount !== undefined) {
    return getNewAmount(discountItem);
  } else if (discountItem.data.percent) {
    return getNewPercent(discountItem);
  } else {
    window.alert('This discount is invalid');
    return;
  }
};

const getNewAmount = (discountItem: BillingItemDiscount): Update['data'] | undefined => {
  const currentAmount = discountItem.data.amount as number;
  const answer = window.prompt(['Enter a new amount for this discount:'].join('\n'), `${currentAmount / 100}`);
  if (answer === null) {
    return;
  }

  const newAmount = parseFloat(answer) * 100;
  if (isNaN(newAmount)) {
    window.alert(`${answer} is not a number`);
    return;
  }

  if (newAmount < 1) {
    window.alert('Amount must be at least $0.01');
    return;
  }

  if (currentAmount === newAmount) {
    return;
  }

  return { amount: newAmount };
};

const getNewPercent = (discountItem: BillingItemDiscount): Update['data'] | undefined => {
  const currentPercent = discountItem.data.percent as number;
  const answer = window.prompt(['Enter a new percentage (1-100) for this discount:'].join('\n'), `${currentPercent}`);
  if (answer === null) {
    return;
  }

  const newPercent = parseFloat(answer);
  if (isNaN(newPercent)) {
    window.alert(`${answer} is not a number`);
    return;
  }

  if (newPercent <= 0) {
    window.alert('Percentage has to be above 0%');
    return;
  }

  if (newPercent > 100) {
    window.alert('Percentage cannot be above 100%');
    return;
  }

  if (currentPercent === newPercent) {
    return;
  }

  return { percent: newPercent };
};

import copy from 'copy-to-clipboard';

export const useClickCopy = (copyText: string, successText?: string): (() => void) => {
  successText = successText ?? 'Copied to clipboard!';

  const doCopyClick = () => {
    copy(copyText);
  };

  return doCopyClick;
};

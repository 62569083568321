import { ButtonPrimary, ButtonSecondary, Headline, InputText, Label, Statement } from '@foyyay/control-elements';
import React, { FormEvent, useState } from 'react';
import styled from 'styled-components';
import { FeatureFlagHttpApi } from '../api/FeatureFlagHttpApi';
import { defaultTheme } from '../constant';

interface AddFeatureProps {
  owner: {
    id: string;
  };
  onAddedCallback: (feature: string) => void;
  name: React.ReactNode;
}

export const AddFeature = (props: AddFeatureProps) => {
  const [featureString, setFeatureString] = useState('');
  const [adding, setAdding] = useState(false);
  const [error, setError] = useState();

  const onAddSubmit = async (event: FormEvent) => {
    setError(undefined);
    event.preventDefault();
    if (adding === true) {
      return;
    }
    setAdding(true);

    try {
      await FeatureFlagHttpApi.activate({ id: props.owner.id }, featureString);
      await props.onAddedCallback(featureString);
      setFeatureString('');
    } catch (error: any) {
      setError(error.message);
      console.error(error);
    }

    setAdding(false);
  };

  const showError = error !== undefined;

  return (
    <>
      <Headline>Add a feature to {props.name ?? props.owner.id}</Headline>
      <form onSubmit={onAddSubmit}>
        <Label>Feature string</Label>
        <InputText value={featureString} onChange={setFeatureString} />
        {showError && (
          <div>
            <Label error={true}>{error}</Label>
          </div>
        )}
        <ButtonPrimary loading={adding}>Add</ButtonPrimary>
      </form>
    </>
  );
};

AddFeature.defaultProps = {
  name: 'this object',
  onAddedCallback: async (feature: string) => {},
};

interface ExistingFeaturesProps {
  features: Array<string>;
  owner: { id: string };
  onRemovedCallback: (feature: string) => void;
}

export const ExistingFeatures = (props: ExistingFeaturesProps) => {
  const [removingFeature, setRemovingFeature] = useState('');
  const [error, setError] = useState();

  const handleRemove = async (featureString: string) => {
    setError(undefined);
    if (removingFeature !== '') {
      return;
    }
    setRemovingFeature(featureString);

    try {
      await FeatureFlagHttpApi.deactivate({ id: props.owner.id }, featureString);
      await props.onRemovedCallback(featureString);
    } catch (error: any) {
      setError(error.message);
      console.error(error);
    }

    setRemovingFeature('');
  };

  const showError = error !== undefined;

  return (
    <>
      <Headline>Existing Features</Headline>
      {showError && (
        <div>
          <Label error={true}>{error}</Label>
        </div>
      )}
      {props.features.map((feature) => (
        <StyledDiv key={feature}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <Statement key={feature} style={{ paddingTop: '1.2rem', wordBreak: 'break-all' }}>
              {feature}
            </Statement>
            <ButtonSecondary
              loading={removingFeature === feature}
              onClick={() => handleRemove(feature)}
              style={{ alignSelf: 'right' }}
            >
              Remove
            </ButtonSecondary>
          </div>
        </StyledDiv>
      ))}
    </>
  );
};

ExistingFeatures.defaultProps = {
  features: [],
  onRemovedCallback: async (feature: string) => {},
};

const StyledDiv = styled.div`
  border-color: ${() => defaultTheme.Palette.Light4};
  border-style: solid;
  border-width: 0;
  border-bottom-width: 2px;
  margin-top: 1.2rem;
  padding-bottom: 0.5rem;
`;

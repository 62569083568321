import React from 'react';
import { useParams } from 'react-router';
import { AddFeature, ExistingFeatures } from '../../../component/Features';
import { Layout } from '../../../component/Layout';
import { Loading } from '../../../component/Progress/Loading';
import { RenderBlocker } from '../../../component/RenderBlocker';
import { usePerson } from '../../../hooks/usePerson';

export const Features = () => {
  const params = useParams<{ personId: string }>();
  const personId = params.personId ?? '';

  const { data: person, refetch } = usePerson(personId);

  const reloadPerson = () => refetch();
  const showAddFeatures = personId !== undefined;
  const showFeatures = person?.features?.length > 0;

  return (
    <>
      <Layout.Panel>
        <RenderBlocker require={[person, showAddFeatures]} fallback={<Loading />}>
          <AddFeature name={person?.profile?.first_name} owner={{ id: personId }} onAddedCallback={reloadPerson} />
        </RenderBlocker>
      </Layout.Panel>

      <RenderBlocker require={[person, showFeatures]}>
        <Layout.Panel>
          <ExistingFeatures features={person?.features} onRemovedCallback={reloadPerson} owner={{ id: personId }} />
        </Layout.Panel>
      </RenderBlocker>
    </>
  );
};

export const getEnv = (key, dvalue) => {
  const env = process.env[key];
  if (env !== undefined) {
    return env;
  }
  if (dvalue !== undefined) {
    return dvalue;
  }
  if (process.env['NODE_ENV'] === 'development') {
    console.warn(`Missing environment variable for ${key}`);
  }
};

export default getEnv;

import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { SiteEngineHttpApi, SiteEngineListResponse } from '../api/SiteEngineHttpApi';

export const useSiteEngines = (churchId?: string): UseQueryResult<SiteEngineListResponse, Error> => {
  return useQuery({
    staleTime: 1000 * 60 * 5,
    queryKey: ['siteEngines', churchId],
    queryFn: async () => {
      if (churchId === undefined) {
        throw new Error('church is required');
      }
      return await SiteEngineHttpApi.list({ id: churchId });
    },
    enabled: churchId !== undefined,
  });
};

import { useCallback, useState } from 'react';

export type AsyncState = null | 'pending' | 'fulfilled' | 'rejected';

export function useAsyncCallback<T extends (...args: any[]) => Promise<any>>(callback: T): [T, AsyncState] {
  const [state, setState] = useState<AsyncState>(null);

  const call = useCallback(
    async (...args: any[]) => {
      setState('pending');
      let result;
      try {
        result = await callback(...args);
      } catch (error) {
        setState('rejected');
        throw error;
      }
      setState('fulfilled');
      return result;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callback]
  ) as T;

  return [call, state];
}

import { Job } from '../../type/job';
import { NUCLEUS_PLATFORM_API_URL } from '../constant';
import { apiCall } from '../lib/fetch';

export const JobHttpApi = {
  start: async (job: Partial<Job>) => {
    return apiCall('POST', NUCLEUS_PLATFORM_API_URL, `/jobs`, { job: job });
  },
  status: async (job: Pick<Job, 'id'>) => {
    return apiCall('GET', NUCLEUS_PLATFORM_API_URL, `/jobs/${job.id}`);
  },
  abort: async (job: Pick<Job, 'id'>) => {
    return apiCall('POST', NUCLEUS_PLATFORM_API_URL, `/jobs/${job.id}/actions/abort`);
  },
};

import { Memorandum } from '../../type/memorandum';
import { SUPPORT_API_URL } from '../constant';
import { apiCall } from '../lib/fetch';

export const MemorandumApi = {
  create: async (subjectId: string, updates: Partial<Memorandum>) => {
    return apiCall('POST', SUPPORT_API_URL, `/memorandums/${subjectId}/memorandum`, updates);
  },

  list: async (subjectId: string) => {
    return await apiCall('GET', SUPPORT_API_URL, `/memorandums/${subjectId}/memorandum`);
  },

  update: (subjectId: string, memorandumId: string, updates: Partial<Memorandum>) => {
    return apiCall('PATCH', SUPPORT_API_URL, `/memorandums/${subjectId}/memorandum/${memorandumId}`, updates);
  },
};

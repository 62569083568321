import styled, { css } from 'styled-components';
import { media, MediaSize, SIZE } from './style-utils';
import { TypographyStyles } from './Typography';

const Item = styled.div``;
const Label = styled.dt`
  ${TypographyStyles.L2};
  margin: 0;
`;
const Value = styled.dd`
  margin: 0;
`;

interface ListProps {
  flexBreakpoint: MediaSize;
  inline?: boolean;
  inverted?: boolean;
  labelWidth: string;
  listWidth: string;
}

const ListComponent = styled.dl<ListProps>`
  max-width: 100%;
  margin: 0;
  margin-bottom: ${SIZE[6]};

  ${(props) => css`
    width: ${props.listWidth};
  `}

  ${(props) =>
    !props.inline &&
    css`
      ${Label} {
        ${TypographyStyles.L2};
      }
      ${Value} {
        ${TypographyStyles.P3};
      }
    `}

  ${Item} {
    margin-bottom: ${SIZE[4]};

    ${media.print`
      padding: 0px;
      page-break-inside:avoid;
      page-break-after: auto;
    `}
  }
  ${Label} {
    margin-bottom: ${SIZE[1]};

    ${media.print`
      padding: 0px;
    `}
  }
  ${Value} {
    margin-bottom: ${SIZE[1]};

    ${media.print`
      padding: 0px;
   `}
  }

  ${(props) =>
    props.inline &&
    css`
      ${Item} {
        margin-bottom: ${SIZE[2]};

        ${media[props.flexBreakpoint]`
          display: flex;
        `}
      }
      ${Label} {
        ${props.inverted ? TypographyStyles.P2 : TypographyStyles.L2};
        flex: 0 0 ${props.labelWidth};
        max-width: 100%;
        margin-bottom: 0;
        padding-right: ${SIZE[6]};
        > * {
          line-height: 1em;
        }
      }
      ${Value} {
        ${props.inverted ? TypographyStyles.L2 : TypographyStyles.P2};
        flex: 1 1 30rem;
        max-width: 100%;
        margin-bottom: 0;
        > * {
          line-height: 1em;
        }
      }
    `}

  /* No margin left behind */
  :last-child {
    margin-bottom: 0;

    ${Item}:last-child {
      margin-bottom: 0;
    }
    ${Label}:last-child {
      margin-bottom: 0;
    }
    ${Value}:last-child {
      margin-bottom: 0;
    }
  }
`;

const ListDefaultProps = {
  flexBreakpoint: MediaSize.SmallPhoneAndUp,
  labelWidth: '15rem',
  listWidth: '60rem',
};

export const List = Object.assign(ListComponent, {
  defaultProps: ListDefaultProps,
});

export const DataList = Object.assign(List, {
  Item: Item,
  Label: Label,
  Value: Value,
});

import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { SIZE, media } from './style-utils';

export interface Props {
  style?: React.CSSProperties;
}

export const MemorandumsWrapper: React.FC<PropsWithChildren<Props>> = (props) => {
  return <MemorandumsGridContainer style={props.style}>{props.children}</MemorandumsGridContainer>;
};

export const MemorandumsMain: React.FC<PropsWithChildren<Props>> = (props) => {
  return <MemorandumsGridMain style={props.style}>{props.children}</MemorandumsGridMain>;
};

export const MemorandumsSidebar: React.FC<PropsWithChildren<Props>> = (props) => {
  return (
    <MemorandumsGridSidebar style={props.style}>
      <MemorandumsSidebarContainer>{props.children}</MemorandumsSidebarContainer>
    </MemorandumsGridSidebar>
  );
};

const MemorandumsGridMain = styled.div``;
const MemorandumsGridSidebar = styled.div`
  background-color: ${(props) => props.theme.Panel.Color.Default.Background};
  border-radius: ${SIZE[2]};
  box-shadow: 0px 1px 8px 0px ${(props) => props.theme.Panel.Color.Default.BoxShadow};
  max-width: 60rem;
  outline: none;
  width: 100%;
`;

const MemorandumsSidebarContainer = styled.div`
  width: 100%;
  padding: ${SIZE[4]};

  ${media.tabletLandscapeAndUp`
    max-height: 100vh;
    overflow-y: auto;
    position: sticky;
    top: ${SIZE[12]};
    width: auto;
  `}

  ${media.print`
  display: none;
`}
`;

const MemorandumsGridContainer = styled.div`
  display: grid;
  gap: ${SIZE[2]};
  grid-template-areas:
    'main'
    'sidebar';
  grid-template-columns: minmax(90rem, 100%);
  grid-template-rows: auto minmax(0, 1fr);
  width: 100%;

  ${MemorandumsGridSidebar} {
    grid-area: sidebar;
    min-width: 60rem;
  }

  ${MemorandumsGridMain} {
    grid-area: main;
    min-width: 90rem;
  }

  ${media.tabletLandscapeAndUp`
    grid-template-areas: 
      'main sidebar' 
      'main sidebar';
  `}
`;

import * as dateFns from 'date-fns';
import React, { useEffect, useState } from 'react';
import { itemWithMeta } from '../../../type/entity';
import { Field, FieldLabel, FieldValue } from '../../component/LayoutParts';
import { SUPPORT_API_URL } from '../../constant';
import { apiCall } from '../../lib/fetch';
import { DATE_FULL_FORMATTER } from '../../lib/formatting';
import { valueOfKey } from '../../lib/key';

const defaultRule = { component: 'ReadOnly' };
const defaultStyle = { fontSize: '1.6rem', marginTop: '4px', marginBottom: '4px' };

export const ReadOnly = (props) => {
  let value = props.value;
  if (typeof props.value === 'object') {
    value = JSON.stringify(value);
  }

  return (
    <Field key={props.name}>
      <FieldLabel>{props.name}</FieldLabel>
      <FieldValue>{value}</FieldValue>
    </Field>
  );
};
export const PrefixedKey = (props) => {
  const [value, setValue] = useState(props.value);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (
      disabled === true ||
      value === props.value ||
      valueOfKey(value) === undefined ||
      valueOfKey(value).length !== 32
    ) {
      return;
    }
    const timer = setTimeout(() => {
      const confirmed = window.confirm(`Are you sure you want to update ${props.name} to ${value}?`);
      if (confirmed === false) {
        setValue(props.value);
        return () => clearTimeout(timer);
      }
      props.update(props.name, value, setValue);
      setDisabled(true);
      return () => clearTimeout(timer);
    }, 300);
  }, [disabled, value, props]);

  const onChangeHandler = (e) => {
    if (disabled === false && e.target.value && valueOfKey(e.target.value).length <= 32) {
      setValue(e.target.value);
    } else {
      setDisabled(true);
    }
  };

  return (
    <Field key={props.name}>
      <FieldLabel>{props.name}</FieldLabel>
      <input
        disabled={disabled}
        style={defaultStyle}
        size={60}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          onChangeHandler(e);
        }}
      />
    </Field>
  );
};

// this component sets a time where none exists
export const Timestamp = (props: any) => {
  const isDate = (value: any): boolean => dateFns.isValid(value);
  const [selected, setSelected] = useState(String(isDate(props.value)));
  const [timestamp, setTimestamp] = useState(isDate(props.value) ? DATE_FULL_FORMATTER(props.value) : undefined);

  const updateSelected = (value?: any) => {
    setTimestamp(isDate(value) ? DATE_FULL_FORMATTER(value) : undefined);
    setSelected(String(isDate(value)));
  };

  const onChangeHandler = (e) => {
    const action = e.target.value === 'true' ? 'set' : 'unset';
    const confirmed = window.confirm(`Are you sure you want to ${action} ${props.name} timestamp?`);
    if (confirmed === false) {
      return;
    }
    const newValue = e.target.value === 'true' ? Date.now() : null;
    props.update(props.name, newValue, updateSelected);
  };

  return (
    <Field key={props.name}>
      <FieldLabel>{props.name}</FieldLabel>
      <select value={selected} onChange={onChangeHandler} style={defaultStyle}>
        <option key="set" value="true">
          {timestamp ?? 'Set Now'}
        </option>
        <option key="notSet" value="false">
          Not Set
        </option>
      </select>
    </Field>
  );
};

export const Select = (props) => {
  const [selected, setSelected] = useState(props.value || 'notSet');
  const onChangeHandler = (e) => {
    if (e.target.value === 'notSet') {
      return;
    }
    const check = window.confirm(`Are you sure you want to update ${props.name} to ${e.target.value}?`);
    if (!check) {
      return;
    }
    if (e.target.value === selected) {
      return;
    }
    props.update(props.name, e.target.value, setSelected);
  };

  const notDefined = props.value === undefined;

  return (
    <Field key={props.name}>
      <FieldLabel>{props.name}</FieldLabel>
      <select value={selected} onChange={onChangeHandler} style={defaultStyle}>
        {props.options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
        {notDefined && <option value="notSet">not set</option>}
      </select>
    </Field>
  );
};

export const EditableArea = (props) => {
  async function update(name, newValue, updateState) {
    const payload = {
      key: props.entity.key.id,
      entityType: props.entity.entityType,
      attributes: { [name]: newValue },
    };

    try {
      await apiCall('POST', SUPPORT_API_URL, `/entity/update`, payload);
    } catch (e) {
      console.error(e);
    } finally {
      updateState(newValue);
    }
  }

  const subComponents = { PrefixedKey, ReadOnly, Select, Timestamp };
  const controlObject = buildControlObject(props.entity);
  const jsxList = Object.entries(controlObject).map((row: any) => {
    const [key, value] = row;
    const component = subComponents[value.component];
    if (!component) {
      return <p key={key}>don't have component: {value.component}</p>;
    }
    return component({ ...value, name: key, update: update });
  });
  return <>{jsxList}</>;
};

const buildControlObject = (entity: itemWithMeta) => {
  const original = Object.entries(entity.entity);
  const editable = entity.editor;

  const preppedBasedOnEntity = original.reduce((snowBall: any, row) => {
    const [key, value] = row;
    snowBall[key] = { ...defaultRule, ...editable[key], value: value };
    return snowBall;
  }, {});

  const preppedBasedOnEditor = Object.keys(editable).reduce((snowBall: any, key) => {
    snowBall[key] = { ...defaultRule, ...editable[key], value: undefined };
    return snowBall;
  }, {});

  return { ...preppedBasedOnEditor, ...preppedBasedOnEntity };
};

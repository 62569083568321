import React from 'react';
import { Layout } from '../../../component/Layout';
import { LoadingSpinnerBlock } from '../../../component/LoadingSpinner';
import { useChurches } from '../../../hooks/useChurches';
import { ChurchesList } from '../component/ChurchesList';

export const List = () => {
  const headline = 'List of Churches';
  const byline = 'Most recent first';
  const { data: churches, isLoading } = useChurches();

  if (isLoading) {
    return (
      <Layout.Panel>
        <LoadingSpinnerBlock />
      </Layout.Panel>
    );
  }
  return <ChurchesList headline={headline} statement={byline} data={churches} />;
};

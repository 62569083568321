import { useEffect } from 'react';

export function useOnClickOutside(refs, clickHandler, condition = true, eventTarget = document) {
  if (refs !== undefined && Array.isArray(refs) === false) {
    refs = [refs];
  }

  useEffect(() => {
    if (refs === undefined || !condition) {
      return;
    }
    function handleClickOutside(event) {
      const clickedInside = refs.reduce((acc, ref) => {
        let element = ref.current;
        if (typeof element === 'string') {
          element = document.getElementById(element);
        }
        return acc === true || (element && element.contains(event.target));
      }, false);

      if (clickedInside !== true && typeof clickHandler === 'function') {
        clickHandler(event);
      }
    }
    // Bind the event listener
    eventTarget.addEventListener('mousedown', handleClickOutside);

    return () => {
      eventTarget.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs, clickHandler, condition, eventTarget]);
}

import { useClickHandler } from '../hooks/useClickHandler';
import { partial as _partial } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from './Button';
import { Headline } from './Headline';
import { Church } from './Icons';
import { Statement } from './Statement';
import { font, media, SIZE } from './style-utils';
import { getThemeValue } from './Theme';
import { P } from './Typography';

const getScopedThemeValue = _partial(getThemeValue, 'Panel.Color');

const StyledPanel = styled.div`
  position: relative;
  padding: ${SIZE[1]} ${SIZE[2]};
  margin-top: ${SIZE[2]};
  border-radius: ${SIZE[2]};
  background-color: ${getScopedThemeValue('Default.Background')};
  box-shadow: 0px 1px 8px 0px ${getScopedThemeValue('Default.BoxShadow')};
  border-top-color: transparent;
  margin-top: 0px;

  ${(props) =>
    props.platform === 'rebelgive' &&
    css`
      border-top-color: rgb(11 186 230 / 24%);
    `}

  ${(props) =>
    props.platform === 'nucleus' &&
    css`
      border-top-color: rgb(82 0 153 / 12%);
    `}

  border-top-style: solid;
  border-top-width: ${SIZE[3]};

  & + & {
    margin-top: ${SIZE[4]};
  }

  ${media.tabletPortraitAndUp`
    padding: ${SIZE[2]} ${SIZE[4]};
  `}

  ${media.print`
    border: none;
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
  `}
`;

StyledPanel.propTypes = {
  platform: PropTypes.string,
};

const FullBleed = styled.div`
  margin: 0 -${SIZE[2]};

  > * {
    padding: 0 ${SIZE[2]};
  }

  ${media.tabletPortraitAndUp`
    margin: 0 -${SIZE[4]};

    > * {
      padding: 0 ${SIZE[4]};
    }
  `}
`;

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -${SIZE[2]};
  margin-bottom: -${SIZE[2]};

  & > * {
    flex: 1 0 auto;
    margin-left: ${SIZE[2]};
    margin-bottom: ${SIZE[2]};
    max-width: 100%;

    &:first-child {
      flex: 1 0 60%;
    }
  }

  ${media.print`
    display: none;
  `}
`;

const EmptyContainer = styled.div`
  align-items: center;
  background-color: ${getScopedThemeValue('Empty.Background')};
  border-radius: ${SIZE[2]};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  min-height: 15rem;
  padding: 6em;
  text-align: center;
  width: 100%;

  ${media.tabletPortraitAndUp`
    min-height: 20rem;
  `}
`;

const StyledChurch = styled(Church)`
  color: ${getScopedThemeValue('Empty.Color')};
  margin-bottom: ${SIZE[4]};
  width: 6rem;
`;

const EmptyHeadline = styled(P)`
  ${font(18, 'Bold', -0.25)}
  margin: 0;
  max-width: 35.5rem;
`;

const EmptyStatement = styled(P)`
  ${font(18, 'Light', -0.25, 26)}
  margin: 0;
  max-width: 35.5rem;

  a:hover {
    text-decoration: underline;
  }
`;

const Empty = (props) => {
  return (
    <EmptyContainer className={props.className}>
      {props.showIcon && <StyledChurch />}
      <EmptyHeadline>{props.title}</EmptyHeadline>
      <EmptyStatement>{props.statement}</EmptyStatement>
      {props.children}
    </EmptyContainer>
  );
};

Empty.propTypes = {
  title: PropTypes.string,
  statement: PropTypes.string,
  showIcon: PropTypes.bool,
};

const Card = styled.div`
  width: 100%;
  height: auto;
  padding: ${SIZE[1]} ${SIZE[2]};
  border-radius: ${SIZE[2]};
  background-color: ${(props) => props.theme.Card.Color.Default.Background};

  ${media.largePhoneAndUp`
    padding: ${SIZE[2]} ${SIZE[4]};
  `}

  ${(props) =>
    props.size === 'large' &&
    css`
      border-radius: ${SIZE[4]};
    `}
`;

const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: ${(props) => props.minHeight || '25rem'};
  padding: ${SIZE[4]} ${SIZE[2]};
  color: ${(props) => props.theme.Card.Color.Default.Foreground};
  text-align: center;
`;

const MessageHeadline = styled(Headline)`
  max-width: 16em;
  margin: 0;
`;

const MessageStatement = styled(Statement)`
  max-width: 30em;
  margin: 0;
  margin-top: ${SIZE[2]};

  a {
    text-decoration: underline;
  }
`;

const MessageButtons = styled.div`
  margin-top: ${SIZE[4]};
  margin-bottom: -${SIZE[2]};
  margin-left: -${SIZE[1]};

  > * {
    margin-left: ${SIZE[1]};
    margin-bottom: ${SIZE[1]};
  }
`;

const Message = styled((props) => {
  const showButtons = props.buttonText || props.buttons;
  const { icon, headline, statement, children, buttons, buttonText, onClickButton, ...rest } = props;
  return (
    <MessageContainer {...rest}>
      {props.icon}
      {props.headline && <MessageHeadline>{props.headline}</MessageHeadline>}
      {props.statement && <MessageStatement>{props.statement}</MessageStatement>}
      {props.children}
      {showButtons && (
        <MessageButtons>
          {props.buttonText && (
            <Button size="small" shape="pill" variant="secondary" width={SIZE[20]} onClick={props.onClickButton}>
              {props.buttonText}
            </Button>
          )}
          {props.buttons}
        </MessageButtons>
      )}
    </MessageContainer>
  );
})``;

Message.propTypes = {
  buttons: PropTypes.node,
  icon: PropTypes.node,
  headline: PropTypes.node,
  statement: PropTypes.node,
  buttonText: PropTypes.string,
  onClickButton: PropTypes.func,
};

const EmptyMessage = (props) => {
  return <Message {...props} />;
};

EmptyMessage.defaultProps = {
  headline: (
    <>
      Nothing to see here&nbsp;yet...
      <span role="img" aria-label="Eyes">
        👀
      </span>
    </>
  ),
  statement: (
    <>
      If you think there should be something else here, feel free to hit that refresh button on your browser. That might
      do the trick! Otherwise, as you create things and receive content in Nucleus, this panel will
      populate&nbsp;appropriately.
    </>
  ),
};

const ErrorMessage = (props) => {
  return <Message {...props} />;
};

ErrorMessage.defaultProps = {
  headline: 'Whoops, something went wrong...',
  statement: (
    <>
      Try hitting the “Reload” button below to see if that fixes it. If not, try refreshing your browser. Still having
      trouble? <a href="mailto:hello@nucleus.church">Let&nbsp;us&nbsp;know</a>
    </>
  ),
  buttonText: 'Reload',
  onClickButton: () => window.location.reload(),
};

const ComingSoonMessage = (props) => {
  return <Message {...props} />;
};

ComingSoonMessage.defaultProps = {
  icon: <StyledChurch />,
  headline: 'Coming Soon!',
};

const SaveButtons = (props) => {
  const showCancel = typeof props.onCancel === 'function';
  const onSaveClick = useClickHandler(props.onSave);
  const onCancelClick = useClickHandler(props.onCancel);
  const saveDisabled = props.disabled || props.saveDisabled;
  const cancelDisabled = props.disabled || props.cancelDisabled || props.saving;
  return (
    <Buttons>
      <Button disabled={saveDisabled} loading={props.saving} onClick={onSaveClick}>
        {props.saveText}
      </Button>
      {showCancel && (
        <Button variant="secondary" disabled={cancelDisabled} onClick={onCancelClick}>
          {props.cancelText}
        </Button>
      )}
    </Buttons>
  );
};

SaveButtons.defaultProps = {
  saveText: 'Save changes',
  cancelText: 'or, Discard my changes',
  onSave: () => {},
  disabled: false,
};

export const Panel = Object.assign(StyledPanel, {
  Card: Card,
  Message: Message,
  ErrorMessage: ErrorMessage,
  EmptyMessage: EmptyMessage,
  ComingSoonMessage: ComingSoonMessage,
  Empty: Empty,
  Buttons: Buttons,
  SaveButtons: SaveButtons,
  FullBleed: FullBleed,
});

import { useThunkReducer } from '../../hooks/useThunkReducer';
import React, { useEffect } from 'react';
import { memorandumsReducer, memorandumsInit, listMemorandum } from './MemorandumsState';
import { MemorandumsContext } from './MemorandumsContext';

export const MemorandumsController = (props: { children: React.ReactNode; subjectId: string }): JSX.Element => {
  const [, dispatch, useSelector] = useThunkReducer(memorandumsReducer, {
    devToolsName: 'Memorandums',
    initializer: memorandumsInit,
  });

  useEffect(() => {
    if (props.subjectId === undefined) {
      return;
    }
    dispatch(listMemorandum({ subjectId: props.subjectId }));
  }, [props.subjectId, dispatch]);

  const context = {
    dispatch: dispatch,
    subjectId: props.subjectId,
    useSelector: useSelector,
  };

  return <MemorandumsContext.Provider value={context}>{props.children}</MemorandumsContext.Provider>;
};

import React from 'react';
import styled from 'styled-components';

type Props = React.ImgHTMLAttributes<HTMLImageElement>;

export const CircleProfileImage = styled(({ className, style, ...props }: Props): JSX.Element | null => {
  return (
    <Wrapper className={className} style={style}>
      <Image {...props} />
    </Wrapper>
  );
})``;

const Wrapper = styled.div`
  background: #ffffff;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center top;
  position: absolute;
`;
